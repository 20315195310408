<template>
  <OffersList :loading="loading" :sway-offers="swayOffers" @offer-list-new-offer-modal-open="showNewOfferModal = true">
    <NewSwayOfferModal v-if="showNewOfferModal" @new-offer-modal="showNewOfferModal = false" @new-offer-modal-close="showNewOfferModal = false" />
  </OffersList>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import OffersList from '@/views/components/OffersList'
import NewSwayOfferModal from '@/components/Offers/NewOfferModals/NewSwayOfferModal'

export default Vue.extend({
  name: 'SwayOffers',

  metaInfo: {
    title: 'Sway Offers',
  },

  components: {
    OffersList,
    NewSwayOfferModal,
  },

  data: () => ({
    showNewOfferModal: false,
  }),

  computed: {
    ...mapGetters('swayoffer', ['loading', 'swayOffers']),
  },

  mounted() {
    this.$store.dispatch('swayoffer/refresh')
  },
})
</script>
