<template>
  <v-expansion-panel @click.native.stop>
    <v-expansion-panel-header>Redeem Instructions</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-form v-if="offerOrderFlowType === 'URL'" ref="offerUrlForm">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="offer_url"
              :rules="urlRules"
              outlined
              placeholder="https://www.google.com"
              label="Order URL"
              hint="Which website should locals visit to place their order?"
              @blur="handleSaveUrl()"
              @keydown.enter="$event.target.blur()"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n2">
          <v-col cols="12">
            <v-text-field
              v-model="offer_description_url"
              :rules="optionalUrlRules"
              outlined
              placeholder="https://www.google.com"
              label="Offer Description URL (optional)"
              hint="Do you have a website where locals can learn about the offer?"
              @blur="handleSaveUrl()"
              @keydown.enter="$event.target.blur()"
            />
          </v-col>
        </v-row>
      </v-form>

      <v-row v-if="offerOrderFlowType === 'CALL'">
        <v-col cols="12">
          <v-form ref="offerPhoneForm">
            <v-text-field
              v-model="offer_phone"
              v-mask="'(###) ###-####'"
              :rules="optionalPhoneRunes"
              type="tel"
              outlined
              placeholder="(555) 555-5555"
              label="Order Phone Number"
              hint="Which phone number should locals call to schedule?"
              @blur="handleSavePhone()"
              @keydown.enter="$event.target.blur()"
            />
          </v-form>
        </v-col>
      </v-row>

      <v-row v-if="offerOrderFlowType === 'CALL'">
        <v-col cols="12">
          <v-form ref="offerUrlForm">
            <v-text-field
              v-model="offer_url"
              :rules="urlRules"
              outlined
              placeholder="https://www.google.com"
              label="Order URL"
              hint="Which website should locals visit to schedule?"
              @blur="handleSaveUrl()"
              @keydown.enter="$event.target.blur()"
            />
          </v-form>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue from 'vue'
import { mask } from 'vue-the-mask'
import validationRules from '@/utils/validation'

export default Vue.extend({
  name: 'OfferRedeemInstructionsPanel',

  directives: {
    mask,
  },

  props: {
    offerOrderFlowType: {
      type: String,
      required: true,
    },
    offerUrl: {
      type: String,
      default: null,
    },
    offerDescriptionUrl: {
      type: String,
      default: null,
    },
    offerPhone: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    offer_url: null,
    offer_description_url: null,
    offer_phone: null,
    urlRules: validationRules.urlRules,
    optionalUrlRules: validationRules.optionalUrlRules,
    phoneRules: validationRules.phoneRules,
    optionalPhoneRunes: validationRules.optionalPhoneRunes,
  }),

  watch: {
    offerUrl: function () {
      this.offer_url = this.offerUrl
    },
    offerDescriptionUrl: function () {
      this.offer_description_url = this.offerDescriptionUrl
    },
    offerPhone: function () {
      this.offer_phone = this.offerPhone
    },
  },

  mounted: function () {
    this.offer_url = this.offerUrl
    this.offer_description_url = this.offerDescriptionUrl
    this.offer_phone = this.offerPhone
  },

  methods: {
    handleSaveUrl() {
      if (this.offer_url.trim().length === 0 || !this.$refs.offerUrlForm.validate()) return

      this.$emit('save', { offer_url: this.offer_url, offer_description_url: this.offer_description_url })
    },
    handleSavePhone() {
      if (!this.$refs.offerPhoneForm.validate()) return

      this.$emit('save', { offer_phone: this.offer_phone })
    },
  },
})
</script>
