<template>
  <div style="width: 100%">
    <app-spinner v-if="loading" class="mt-6" />
    <v-row v-else>
      <v-col cols="6">
        <v-select v-model="selectedLandingPageId" :items="landingPages" item-text="landing_page_name" item-value="id" outlined label="Landing Page" />
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn :loading="createLandingPageLoading" color="primary" @click="createLandingPage">Create Landing Page</v-btn>
      </v-col>
    </v-row>

    <app-spinner v-if="landingPageLoading" class="mt-6" />
    <div v-else-if="selectedLandingPage">
      <v-text-field
        :value="selectedLandingPage.landing_page_name"
        outlined
        type="text"
        label="Landing Page Name"
        @blur="handleLandingPagePropertyChanged('landing_page_name', $event)"
        @keydown.enter="$event.target.blur()"
      />

      <a :href="landingPageUrl" target="_blank" class="d-block mb-4">{{ landingPageUrl }}</a>

      <v-text-field
        :value="selectedLandingPage.custom_url"
        outlined
        type="text"
        label="Custom Url"
        @blur="handleLandingPagePropertyChanged('custom_url', $event)"
        @keydown.enter="$event.target.blur()"
      />

      <v-expansion-panels inset>
        <v-expansion-panel>
          <v-expansion-panel-header>Offers ({{ selectedLandingPage.sway_offers.length }})</v-expansion-panel-header>
          <v-expansion-panel-content>
            <SwayOfferSearchField :sway-offers="possibleOffers" class="mb-6" @sway-offer-selected="addOfferToLandingPage" />

            <draggable
              :value="selectedLandingPage.sway_offers"
              group="swayOffers"
              ghost-class="sway-offer-ghost"
              chosen-class="sway-offer-chosen"
              @update="reorderSwayOffers"
            >
              <v-row v-for="swayOffer in selectedLandingPage.sway_offers" :key="swayOffer.offer_id" class="sway-offer">
                <v-col cols="1" class="pa-0 d-flex text-center justify-center">
                  <v-icon>mdi-reorder-horizontal</v-icon>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    :value="swayOffer.offer_summary"
                    outlined
                    hide-details
                    type="text"
                    label="Offer Summary"
                    @blur="handleOfferSummaryChanged(swayOffer.offer_id, $event)"
                    @keydown.enter="$event.target.blur()"
                  />
                </v-col>
                <v-col cols="2" class="d-flex text-center align-center">
                  <v-btn class="primary" @click="removeOfferFromLandingPage(swayOffer.offer_id)">Remove</v-btn>
                </v-col>
              </v-row>
            </draggable>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Customization</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-for="(value, colorField) in selectedLandingPage.layout_data.colors" :key="colorField" class="mt-4">
              <input :name="colorField" :value="value" type="color" class="pointer" @change="handleCustomizationChange('colors', $event)" />
              <label :for="colorField" class="pl-2 pointer">{{ colorField | formatCustomizationName | prettyString }}</label>
            </div>

            <v-text-field
              v-for="(value, textField) in selectedLandingPage.layout_data.text"
              :key="textField"
              :name="textField"
              :value="value"
              :label="textField | formatCustomizationName | prettyString"
              outlined
              hide-details
              type="text"
              class="mt-6"
              @blur="handleCustomizationChange('text', $event)"
              @keydown.enter="$event.target.blur()"
            />

            <div v-for="(value, imageField) in selectedLandingPage.layout_data.images" :key="imageField" class="mt-6">
              <legend class="v-label theme--light mb-2" style="font-size: 14px">{{ imageField | formatCustomizationName | prettyString }}</legend>
              <ImageUploadForm
                image-type="offer"
                :edit-mode="true"
                :image-url="value"
                :allow-image-deletion="true"
                style="max-width: 380px"
                @image-deleted="handleCustomizationChange('images', { target: { name: imageField, value: '' } })"
                @image-uploaded="handleCustomizationChange('images', { target: { name: imageField, value: $event.image_url } })"
              >
                <h2>Click to upload image</h2>
              </ImageUploadForm>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <h4 class="mt-6 mb-2">Preview</h4>
      <v-divider class="ma-10 mt-0" />

      <LfSwayOffersLandingPage
        :key="lastLandingPageUpdate"
        :landing-page-id="selectedLandingPageId"
        :divoc-api-url="divocApiUrl"
        :offers-app-url="swayOffersAppUrl"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import { ApiService } from '@/services/index'

import draggable from 'vuedraggable'
import { LfSwayOffersLandingPage } from 'lf-common'
import ImageUploadForm from '@/components/ImageUploadForm'
import SwayOfferSearchField from '@/components/SwayOffers/SwayOfferSearchField'

export default Vue.extend({
  name: 'SwayOffersLandingPage',

  metaInfo: {
    title: 'Sway Offers Landing Page',
  },

  components: {
    draggable,
    ImageUploadForm,
    SwayOfferSearchField,
    LfSwayOffersLandingPage,
  },

  filters: {
    formatCustomizationName: (text) => {
      return text.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('_', ' '))
    },
  },

  data: () => ({
    loading: true,
    landingPageLoading: false,
    createLandingPageLoading: false,
    lastLandingPageUpdate: new Date().getTime(),
    selectedLandingPage: null,
    selectedLandingPageId: '',
    landingPages: [],
  }),

  computed: {
    ...mapGetters('swayoffer', ['swayOffers']),
    divocApiUrl() {
      return process.env.VUE_APP_DIVOC_API_URL
    },
    swayOffersAppUrl() {
      return process.env.VUE_APP_SWAY_OFFERS_APP_URL
    },
    possibleOffers() {
      const selectedOfferIds = this.selectedLandingPage.sway_offers.map((offer) => offer.offer_id)
      return this.swayOffers.filter((offer) => selectedOfferIds.indexOf(offer.id) === -1 && !offer.expired)
    },
    landingPageUrl() {
      return `${this.swayOffersAppUrl}/swayOffersLandingPage/${this.selectedLandingPageId}`
    },
  },

  watch: {
    selectedLandingPageId: function (landingPageId) {
      this.landingPageLoading = true

      ApiService.get(`offersLandingPage/sway/${landingPageId}`)
        .then((response) => {
          if (response.data.error) {
            this.$toast.error('Error loading landing page')
          } else {
            this.selectedLandingPage = response.data
          }
        })
        .finally(() => {
          this.landingPageLoading = false
        })
    },
  },

  mounted() {
    this.$store.dispatch('swayoffer/refresh')

    ApiService.get('offersLandingPage/sway/')
      .then((response) => {
        if (response.data.error) {
          this.$toast.error('Error loading landing pages')
        } else {
          this.landingPages = response.data
        }
      })
      .finally(() => {
        this.loading = false
      })
  },

  methods: {
    _updateLandingPage(updatedLandingPage) {
      Vue.set(
        this.landingPages,
        this.landingPages.findIndex((landingPage) => landingPage.id === updatedLandingPage.id),
        { ...updatedLandingPage, landing_page_name: updatedLandingPage.landing_page_name }
      )

      this.selectedLandingPage = updatedLandingPage
      this.lastLandingPageUpdate = new Date().getTime()
      this.$toast.success('Landing page updated')
    },

    createLandingPage() {
      this.createLandingPageLoading = true

      ApiService.post('offersLandingPage/sway/', {})
        .then((response) => {
          if (response.data.error) {
            this.$toast.error('Error creating landing page')
          } else {
            this.landingPages.push(response.data)
            this.selectedLandingPageId = response.data.id
            this.$toast.success('Landing Page Created')
          }
        })
        .finally(() => {
          this.createLandingPageLoading = false
        })
    },

    updateLandingPage(updatedLandingPage) {
      ApiService.put(`offersLandingPage/sway/${this.selectedLandingPageId}`, {
        customUrl: updatedLandingPage.custom_url,
        landingPageName: updatedLandingPage.landing_page_name,
        layoutData: updatedLandingPage.layout_data,
      }).then((response) => {
        if (response.data.error) {
          this.$toast.error('Error updating landing page')
        } else {
          this._updateLandingPage(response.data)
        }
      })
    },

    updateLandingPageOffers(swayOffers) {
      ApiService.put(`offersLandingPage/sway/${this.selectedLandingPageId}/offers`, { swayOffers }).then((response) => {
        if (response.data.error) {
          this.$toast.error('Error updating landing page offers')
        } else {
          this._updateLandingPage(response.data)
        }
      })
    },

    handleLandingPagePropertyChanged(property, event) {
      const { value } = event.target

      const update = {
        ...this.selectedLandingPage,
        [property]: value,
      }

      this.updateLandingPage(update)
    },

    handleCustomizationChange(type, event) {
      const { name, value } = event.target

      const update = {
        ...this.selectedLandingPage,
      }

      update.layout_data[type][name] = value

      this.updateLandingPage(update)
    },

    handleOfferSummaryChanged(offerId, event) {
      const { value } = event.target
      const swayOffers = this.selectedLandingPage.sway_offers.map((offer) => (offer.offer_id === offerId ? { ...offer, offer_summary: value } : offer))

      this.updateLandingPageOffers(swayOffers)
    },

    addOfferToLandingPage(offerId) {
      const swayOffers = [...this.selectedLandingPage.sway_offers]
      swayOffers.push({
        offer_id: offerId,
        ordinal: swayOffers.length,
        offer_summary: this.swayOffers.find((swayOffer) => swayOffer.id === offerId).offer_summary,
      })

      this.updateLandingPageOffers(swayOffers)
    },

    removeOfferFromLandingPage(offerId) {
      const swayOffers = this.selectedLandingPage.sway_offers.filter((offer) => offer.offer_id !== offerId)
      swayOffers.map((offer, index) => {
        offer.ordinal = index
      })

      this.updateLandingPageOffers(swayOffers)
    },

    reorderSwayOffers(event) {
      const swayOffers = [...this.selectedLandingPage.sway_offers]
      const swayOffer = swayOffers[event.oldIndex]
      swayOffers.splice(event.oldIndex, 1)
      swayOffers.splice(event.newIndex, 0, swayOffer)
      swayOffers.map((offer, index) => {
        offer.ordinal = index
      })

      this.updateLandingPageOffers(swayOffers)
    },
  },
})
</script>

<style scoped>
.sway-offer {
  padding: 0;
  cursor: pointer;
}

.sway-offer-ghost {
  opacity: 0.4;
}

.sway-offer-chosen {
  background-color: #eee;
}
</style>
