// @ts-nocheck

import Vue from 'vue'
import { ApiService } from '@/services'

import { Location } from '@/models/Location'
interface LocationState {
  loading: boolean
  newLocationSaving: boolean
  entities: Location[]
}

const state: LocationState = {
  loading: false,
  newLocationSaving: false,
  entities: [],
}

const getters = {
  loading: () => state.loading,
  newLocationSaving: () => state.newLocationSaving,
  entities: () => state.entities,
  locationsByState: () => {
    const locationsByState = {}

    state.entities.forEach((location) => {
      if (!locationsByState[location.state]) {
        locationsByState[location.state] = [location]
      } else {
        locationsByState[location.state].push(location)
      }
    })

    return locationsByState
  },
}

const actions = {
  refresh({ commit }) {
    commit('loading', true)
    ApiService.get('address/all').then((response) => {
      commit('refresh', response.data)
      commit('loading', false)
    })
  },
  archiveLocation({ commit, dispatch }, { addressId }: { addressId: string }) {
    dispatch('app/setAppProgressBarVisibility', { visible: true }, { root: true })

    ApiService.delete(`address/${addressId}`).then(() => {
      commit('archivelocation', addressId)

      dispatch('business/getCurrentBusiness', null, { root: true })
      dispatch('app/setAppProgressBarVisibility', { visible: false }, { root: true })

      Vue.prototype.$toast.success('Location Archived!')
    })
  },
  async saveLocation({ commit, dispatch }, { addressData }: { addressData: Location }) {
    commit('newLocationSaving', true)
    await ApiService.post('address', addressData).then((response) => {
      addressData.address.id = response.data.id

      commit('refresh', state.entities.concat(addressData.address))
      commit('newLocationSaving', false)

      dispatch('business/getCurrentBusiness', null, { root: true })

      Vue.prototype.$toast.success('Location Added!')
    })
  },
  updateLocation({ commit, dispatch }, { addressData }: { addressData: Location }) {
    dispatch('app/setAppProgressBarVisibility', { visible: true }, { root: true })

    ApiService.put(`address/${addressData.address.id}`, {
      customTags: addressData.address.custom_tags,
      addressPhone: addressData.address.address_phone,
      googleReviewUrl: addressData.address.google_review_url,
      instagramMentions: addressData.address.instagram_mentions,
      facebookMentions: addressData.address.facebook_mentions,
      hashtags: addressData.address.hashtags,
    }).then(() => {
      commit('updateLocation', addressData.address)
      dispatch('app/setAppProgressBarVisibility', { visible: false }, { root: true })

      Vue.prototype.$toast.success('Location Updated!')
    })
  },
}

const mutations = {
  loading(state: LocationState, loading: boolean) {
    state.loading = loading
  },
  newLocationSaving(state: LocationState, newLocationSaving: boolean) {
    state.newLocationSaving = newLocationSaving
  },
  archivelocation(state: LocationState, addressId: string) {
    Vue.delete(
      state.entities,
      state.entities.findIndex((location) => location.id === addressId)
    )
  },
  updateLocation(state: LocationState, payload: Location) {
    Vue.set(
      state.entities,
      state.entities.findIndex((location) => location.id === payload.id),
      payload
    )
  },
  refresh(state: LocationState, payload: [Location[]]) {
    state.entities = [...payload].sort((a, b) => {
      if (a.state > b.state) return 1
      if (a.state === b.state && a.city > b.city) return 1
      if (a.state === b.state && a.city === b.city && a.zipcode < b.zipcode) return 1

      return -1
    })
  },
}

export const location = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
