<template>
  <OffersList :loading="loading" :network-offers="networkOffers" @offer-list-new-offer-modal-open="handleCreateNewOfferClicked">
    <NewOfferModal v-if="showNewOfferModal" @new-offer-modal="showNewOfferModal = false" @new-offer-modal-close="showNewOfferModal = false" />
  </OffersList>
</template>

<script>
import Vue from 'vue'

import { mapGetters } from 'vuex'

import OffersList from '@/views/components/OffersList'
import NewOfferModal from '@/components/Offers/NewOfferModals/NewOfferModal'

export default Vue.extend({
  name: 'Offers',

  metaInfo: {
    title: 'Offers',
  },

  components: {
    OffersList,
    NewOfferModal,
  },

  data: () => ({
    showNewOfferModal: false,
  }),

  computed: {
    ...mapGetters('business', ['entity']),
    ...mapGetters('offer', ['loading', 'networkOffers']),
  },

  mounted() {
    this.$store.dispatch('offer/refresh')
  },

  methods: {
    handleCreateNewOfferClicked() {
      if (!this.entity.default_hashtags || this.entity.default_hashtags.length <= 0) {
        this.$toast.error('Business hashtags not set. Please add them to the settings before making an offer.')
        return
      }

      this.showNewOfferModal = true
    },
  },
})
</script>
