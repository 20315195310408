<template>
  <div id="settings">
    <app-spinner v-if="settingsLoading" />
    <v-container v-else>
      <v-row class="ma-0 pa-0" align="center" justify="center">
        <v-col cols="2" class="ma-0 pa-0" align="left" justify="center">
          <span class=""> Instustry </span>
        </v-col>
        <v-col cols="2" class="ma-0 pa-0" align="left" justify="center">
          <span class=""> Category </span>
        </v-col>
        <v-col cols="2" class="ma-0 pa-0">
          <span class=""> Post </span>
        </v-col>
        <v-col cols="2" class="ma-0 pa-0">
          <span class=""> Review </span>
        </v-col>
        <v-col cols="2" class="ma-0 pa-0">
          <span class=""> Ticket </span>
        </v-col>
        <v-col cols="2" class="ma-0 pa-0">
          <span class=""> Ind. Proof Ratio </span>
        </v-col>
      </v-row>
      <v-row v-for="(ind, idx) in industries" :key="ind.industry_category.id" class="ma-0 pa-0" align="center" justify="center">
        <v-col cols="2" class="ma-0" align="left" justify="center">
          <span class=""> {{ ind.industry.title }} </span>
        </v-col>
        <v-col cols="2" class="ma-0" align="left" justify="center">
          <span class=""> {{ ind.industry_category.title }} </span>
        </v-col>
        <v-col cols="2" class="ma-0">
          <v-text-field
            v-model="ind.industry_category_value_settings.post_marketing_value"
            hide-details
            single-line
            type="number"
            prefix="$"
            @change="updatePostMarketingValue(idx, ind.industry_category.id)"
          />
        </v-col>
        <v-col cols="2" class="ma-0">
          <v-text-field
            v-model="ind.industry_category_value_settings.review_marketing_value"
            hide-details
            single-line
            type="number"
            prefix="$"
            @change="updateReviewMarketingValue(idx, ind.industry_category.id)"
          />
        </v-col>
        <v-col cols="2" class="ma-0">
          <v-text-field
            v-model="ind.industry_category_value_settings.average_ticket_value"
            hide-details
            single-line
            type="number"
            prefix="$"
            @change="updateAverageTicketValue(idx, ind.industry_category.id)"
          />
        </v-col>
        <v-col cols="2" class="ma-0">
          <v-text-field
            v-model="ind.industry_category_value_settings.industry_proof_ratio"
            hide-details
            single-line
            type="number"
            @change="updateIndustryProofRatio(idx, ind.industry_category.id)"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ApiService } from '@/services'

export default {
  name: 'AdminSettings',

  metaInfo: {
    title: 'AdminSettings',
  },
  data: () => ({
    industries: [],
    settingsLoading: false,
  }),

  mounted() {
    ApiService.get(`adminSettings`)
      .then((response) => {
        this.industries = response.data.map((e) => {
          if (e.industry_category_value_settings) return e
          return {
            ...e,
            industry_category_value_settings: {
              post_marketing_value: 0,
              review_marketing_value: 0,
              average_ticket_value: 0,
              industry_proof_ratio: 0,
            },
          }
        })
      })
      .finally(() => {
        this.settingsLoading = false
      })
  },
  methods: {
    updatePostMarketingValue(idx, industry_category_id) {
      ApiService.put(`adminSettings/cost/post/${industry_category_id}`, {
        newValue: this.industries[idx].industry_category_value_settings.post_marketing_value,
      }).then((r) => {
        if (r.error) {
          this.$toast.error(`Error updating value ${r.error}`)
        }
        this.$toast.success('Post Value Updated')
      })
    },
    updateReviewMarketingValue(idx, industry_category_id) {
      ApiService.put(`adminSettings/cost/review/${industry_category_id}`, {
        newValue: this.industries[idx].industry_category_value_settings.review_marketing_value,
      }).then((r) => {
        if (r.error) {
          this.$toast.error(`Error updating value ${r.error}`)
        }
        this.$toast.success('Review Value Updated')
      })
    },
    updateAverageTicketValue(idx, industry_category_id) {
      ApiService.put(`adminSettings/cost/avgTicket/${industry_category_id}`, {
        newValue: this.industries[idx].industry_category_value_settings.average_ticket_value,
      }).then((r) => {
        if (r.error) {
          this.$toast.error(`Error updating value ${r.error}`)
        }
        this.$toast.success('Average Ticket Value')
      })
    },
    updateIndustryProofRatio(idx, industry_category_id) {
      ApiService.put(`adminSettings/proofRatio/${industry_category_id}`, {
        newValue: this.industries[idx].industry_category_value_settings.industry_proof_ratio,
      }).then((r) => {
        if (r.error) {
          this.$toast.error(`Error updating value ${r.error}`)
        }
        this.$toast.success('Average Ticket Value')
      })
    },
  },
}
</script>

<style scoped>
#settings {
  width: 100%;
}
</style>
