<template>
  <div>
    <div class="text-right pt-1 pb-1 pr-1">
      <v-btn color="primary" small outlined @click.stop="$emit('social-proof-preview', id)">
        Preview
        <v-icon small class="pl-2">mdi-image-multiple-outline</v-icon>
      </v-btn>
    </div>

    <v-card width="350" class="mx-auto" @click="$emit('social-proof-selected', id)">
      <v-img v-if="imgUrl" :src="imgUrl" height="200" />

      <v-row>
        <v-col cols="4" class="text-right pt-2">
          <v-list-item-avatar :src="avatarImgUrl" height="65" width="65" class="pb-0">
            <v-img :src="avatarImgUrl" />
          </v-list-item-avatar>
        </v-col>
        <v-col cols="8" class="text-left">
          <v-list-item-content class="pt-0 pb-0">
            <v-list-item-title class="subtitle-1">{{ formattedInfluencerName }}</v-list-item-title>
            <v-list-item-subtitle>{{ businessName }}</v-list-item-subtitle>
            <v-list-item-subtitle class="mt-1">{{ platform }} {{ type }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-col>
      </v-row>

      <v-card-text class="text-left pt-0">{{ message }}</v-card-text>

      <v-divider />

      <v-card-actions>
        <v-row class="mt-n3">
          <v-col cols="6" class="body-2 text-center body-2 font-weight-light">{{ statusCode }}</v-col>
          <v-col cols="6" class="body-2 text-center body-2 font-weight-light">{{ viewCount || '---' }} views</v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'SocialProofCard',

  props: {
    id: {
      type: String,
      required: true,
    },
    accessKey: {
      type: String,
      required: true,
    },
    avatarImgUrl: {
      type: String,
      required: true,
    },
    businessName: {
      type: String,
      required: true,
    },
    imgUrl: {
      type: String,
      required: true,
    },
    influencerName: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    platform: {
      type: String,
      required: true,
    },
    rating: {
      type: Number,
      required: true,
    },
    statusCode: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    viewCount: {
      type: Number,
      required: true,
    },
  },

  computed: {
    formattedInfluencerName() {
      return this.platform === 'INSTAGRAM' ? `@${this.influencerName}` : this.influencerName
    },
  },
}
</script>
