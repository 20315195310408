declare global {
  interface Window {
    EncTracking: any
  }
}

const actions = {
  identify({ commit }, { userId, email }: { userId: string; email: string }) {
    if (!window.EncTracking) return

    window.EncTracking.identify({
      email,
      userId,
    })
  },
}

export const encTracking = {
  namespaced: true,
  state: {},
  mutations: {},
  actions,
  getters: {},
}
