<template>
  <div>
    <h3 id="socialProofWidgetSettings">Social Proof Widget</h3>
    <v-row v-if="!settings">
      <v-col class="text-center">
        <v-btn color="primary" @click="navigateToSocialProofs">Enable Social Proof Widget</v-btn>
      </v-col>
    </v-row>
    <v-form v-else ref="socialProofWidgetSettingsForm">
      <v-row class="mt-0">
        <v-col cols="12">
          <v-text-field v-model="settings.verified_by_name" outlined label="Verified By Name" :rules="validationRules.verifiedByNameRule" />
        </v-col>
      </v-row>

      <v-row class="mt-n2">
        <v-col cols="12">
          <v-text-field v-model="settings.verified_by_icon" outlined label="Verified By Icon" :rules="validationRules.urlRule" />
        </v-col>
      </v-row>

      <v-row class="mt-n2">
        <v-col cols="12">
          <v-text-field v-model="settings.time_between_posts" outlined label="Time Between Posts" hint="(in seconds)" :rules="validationRules.numberRule" />
        </v-col>
      </v-row>

      <v-row class="mt-n2">
        <v-col cols="12">
          <v-text-field
            v-model="settings.cycle_delay"
            outlined
            label="Cycle Delay"
            hint="Time before/after post expands/collapses (in seconds)"
            :rules="validationRules.numberRule"
          />
        </v-col>
      </v-row>

      <v-row class="mt-n2">
        <v-col cols="12">
          <v-text-field
            v-model="settings.install_url"
            outlined
            label="Install URL"
            hint="URL where the client has installed the widget"
            :rules="validationRules.urlRule"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn :loading="loading" color="primary" @click="handleSaveButtonClicked">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'SocialProofWidgetSettings',

  props: {
    settings: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    loading: false,
    validationRules: {
      urlRule: [(v) => !!v || 'Required', (v) => /^http.+/.test(v) || 'Must be a valid URL'],
      numberRule: [(v) => !!v || 'Required', (v) => /^[0-9]+$/.test(v) || 'Must be a valid number'],
      verifiedByNameRule: [(v) => !!v || 'Required'],
    },
  }),

  methods: {
    ...mapActions('settings', ['updateSocialProofSettings']),
    navigateToSocialProofs() {
      this.$router.push({ name: 'Social Proof Widget' })
    },
    async handleSaveButtonClicked() {
      if (!this.$refs.socialProofWidgetSettingsForm.validate()) return

      this.loading = true

      const socialProofSettings = {
        verified_by_name: this.settings.verified_by_name,
        verified_by_icon: this.settings.verified_by_icon,
        time_between_posts: this.settings.time_between_posts,
        cycle_delay: this.settings.cycle_delay,
        install_url: this.settings.install_url,
      }

      await this.updateSocialProofSettings({ socialProofSettings: { ...socialProofSettings } })

      this.loading = false
      this.$toast.success('Social Proof Settings Updated')
    },
  },
})
</script>
