import { ApiService } from '@/services'
import { SwayCodeList, CodeList } from '@/models/CodeList'

type CouponCodesState = {
  loading: boolean
  loadingSway: boolean
  codeLists: CodeList[]
  swayCodeLists: SwayCodeList[]
}

const state: CouponCodesState = {
  loading: false,
  loadingSway: false,
  codeLists: [],
  swayCodeLists: [],
}

const getters = {
  loading: () => state.loading,
  loadingSway: () => state.loadingSway,
  codeLists: () => state.codeLists,
  swayCodeLists: () => state.swayCodeLists,
}

const actions = {
  refresh({ commit }) {
    commit('loading', true)
    ApiService.get('coupon/codeLists').then((response) => {
      commit('refresh', response.data)
      commit('loading', false)
    })
    ApiService.get('coupon/swayCodeLists').then((response) => {
      commit('refreshSwayCodeList', response.data)
      commit('loading', false)
    })
  },
}

const mutations = {
  loading(state: CouponCodesState, loading: boolean) {
    state.loading = loading
  },
  loadingSway(state: CouponCodesState, loading: boolean) {
    state.loadingSway = loading
  },
  refresh(state: CouponCodesState, codeLists: CodeList[]) {
    state.codeLists = codeLists.map((codeList) => {
      codeList.total_codes = codeList.codes.length
      codeList.used_codes = codeList.codes.filter((code) => code.used_by).length
      codeList.used_percentage = Math.round((100 * codeList.used_codes) / codeList.total_codes)

      return codeList
    })
  },
  refreshSwayCodeList(state: CouponCodesState, codeLists: SwayCodeList[]) {
    state.swayCodeLists = codeLists.map((codeList) => {
      codeList.total_codes = codeList.codes.length
      codeList.used_codes = codeList.codes.filter((code) => code.used_by).length
      codeList.used_percentage = Math.round((100 * codeList.used_codes) / codeList.total_codes)

      return codeList
    })
  },
}

export const couponCodes = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
