export interface Proof {
  id: number
  created_at?: string
  updated_at?: string
  proof_date: string
  proof_type: ProofType
  img_url?: string
  voucher_id?: number
  message_content?: string
  app_user_id?: number
  internal_post_id?: number
  avatar_image_url?: string
  client_feedback?: EnumProofFeedback
}

export interface ProofFeedback {
  type: string
  icon: string
  title: string
}

export enum ProofType {
  FACEBOOK_CHECKIN,
  FACEBOOK_POST,
  INSTAGRAM_POST,
  ONLINE_REVIEW,
}

export enum EnumProofFeedback {
  GOOD_PHOTO,
  GOOD_COMMENT,
}

export const ProofFeedback = Object.freeze({
  GOOD_PHOTO: {
    type: 'GOOD_PHOTO',
    icon: 'mdi-camera',
    title: 'Great Photo',
  } as ProofFeedback,
  GOOD_COMMENT: {
    type: 'GOOD_COMMENT',
    icon: 'mdi-comment-check',
    title: 'Great Caption',
  } as ProofFeedback,
})
