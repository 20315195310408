<template>
  <v-col class="offer-modal-option" :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
    <div :class="[selected ? 'option-selected' : '', 'option']" @click="() => $emit('offer-modal-option-button-clicked')">
      <img width="28" class="pr-1 mb-n1" :src="require(`@/assets/images/${icon}.png`)" />
      <div class="option-sub-title d-inline">{{ subTitle }}</div>
      <div class="option-title mt-3">{{ title }}</div>
      <div class="option-text">{{ text }}</div>
    </div>
  </v-col>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'OfferTypeButton',

  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
})
</script>

<style scoped>
.offer-modal-option {
  cursor: pointer;
}

.option {
  padding: 15px 20px 20px 20px;
  min-height: 200px;
  border: 1px solid #707070;
}

.option-sub-title {
  line-height: 1;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 14px;
  color: #393939 !important;
}

.option-title {
  line-height: 1;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #393939 !important;
}

.option-text {
  margin-top: 16px;
  line-height: 1.2;
  color: #707070 !important;
}

.option-selected {
  border: 3px solid #fb0552;
}
</style>
