export enum LocationTagWarningModalMode {
  ARCHIVE = 'ARCHIVE',
  SAVE = 'SAVE',
}

export interface Location {
  id: string
  google_place_id: string
  address: string
  city: string
  state: string
  zipcode: string
  latitude: number
  longitude: number
  address_phone: string
  google_review_url: string
  instagram_mentions: Array<string>
  facebook_mentions: Array<string>
  hashtags: Array<string>
}
