<template>
  <v-dialog :value="true" persistent max-width="500">
    <v-card>
      <v-card-title class="headline">Add Template</v-card-title>
      <v-card-text>
        <v-form ref="templateForm">
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="title" :rules="requiredRule" label="Title" outlined />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="emailTemplateId" :rules="requiredRule" label="Email Template ID" outlined />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select v-model="type" :items="swayProofReviewTemplateTypes" outlined label="Type" :rules="requiredRule"></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="$emit('new-template-modal-cancel')">Cancel</v-btn>
        <v-btn v-if="id" color="darken-1" text @click="deleteTemplate">Delete</v-btn>
        <v-btn :loading="templateSaving" :disabled="!template || templateSaving" color="primary darken-1" text @click="handleModalSave"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import { ApiService } from '@/services/index'
import validationRules from '@/utils/validation'

export default Vue.extend({
  name: 'NewSwayProofReviewTemplateModal',

  props: {
    template: {
      type: Object,
      default: () => {},
    },
  },

  data: function () {
    return {
      id: null,
      title: '',
      emailTemplateId: '',
      type: '',
      templateSaving: false,
      requiredRule: validationRules.requiredRule,
    }
  },

  computed: {
    swayProofReviewTemplateTypes() {
      return ['APPROVED', 'DENIED']
    },
  },

  mounted: function () {
    this.id = this.template.id || null
    this.title = this.template.title || ''
    this.emailTemplateId = this.template.email_template_id || ''
    this.type = this.template.type || ''
  },

  methods: {
    handleModalSave() {
      if (!this.$refs.templateForm.validate()) return

      this.templateSaving = true

      if (this.id) {
        this.editTemplate()
      } else {
        this.addTemplate()
      }
    },
    addTemplate() {
      ApiService.post('adminSettings/swayProofReviewTemplates', {
        title: this.title,
        email_template_id: this.emailTemplateId,
        type: this.type,
      })
        .then((response) => {
          if (response.data.error) {
            this.$toast.error(`Error adding template`)
            return
          }

          this.$toast.success('Template added!')
          this.$emit('new-template-modal-saved', response.data)
        })
        .finally(() => {
          this.templateSaving = false
        })
    },
    editTemplate() {
      ApiService.put(`adminSettings/swayProofReviewTemplates/${this.id}`, {
        title: this.title,
        email_template_id: this.emailTemplateId,
        type: this.type,
      })
        .then((response) => {
          if (response.data.error) {
            this.$toast.error(`Error updating template`)
            return
          }

          this.$toast.success('Template updated!')
          this.$emit('new-template-modal-updated', response.data)
        })
        .finally(() => {
          this.templateSaving = false
        })
    },
    deleteTemplate() {
      ApiService.delete(`adminSettings/swayProofReviewTemplates/${this.id}`)
        .then((response) => {
          if (response.data.error) {
            this.$toast.error(`Error deleting template`)
            return
          }

          this.$toast.success('Template deleted!')
          this.$emit('new-template-modal-deleted', this.id)
        })
        .finally(() => {
          this.templateSaving = false
        })
    },
  },
})
</script>

<style scoped></style>
