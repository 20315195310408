declare global {
  interface Window {
    google: any
  }
}

const GoogleMapService = {
  loadGoogleMapsSdk() {
    return new Promise((resolve) => {
      if (window.google) {
        resolve(window.google)
      }

      const googleMapsScriptId = 'google-maps-sdk'
      const firstScriptTag = document.querySelectorAll('script')[0]
      if (!firstScriptTag || !firstScriptTag.parentNode || document.getElementById(googleMapsScriptId)) {
        return
      }

      const googleMapsScript = document.createElement('script')
      googleMapsScript.id = googleMapsScriptId
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_APP_ID}&libraries=places`
      googleMapsScript.addEventListener('load', () => {
        resolve(window.google)
      })

      firstScriptTag.parentNode.insertBefore(googleMapsScript, firstScriptTag)
    })
  },

  async init() {
    await this.loadGoogleMapsSdk()
  },
}

export default GoogleMapService
