// @ts-nocheck

import Vue from 'vue'
import dayjs from 'dayjs'

import { Proof, EnumProofFeedback } from '../../models'

import { ApiService, UserService } from '../../services'

type ProofState = {
  proofs: Proof[]
  queryDate: Date | string
  stickyDate: Date | string
  events: [string]

  loading: boolean
  clientProofFeedbackSaveLoading: boolean
}

const state: ProofState = {
  proofs: [{}],
  queryDate: dayjs().valueOf(),
  stickyDate: undefined,
  markerDates: [],

  loading: true,
  clientProofFeedbackSaveLoading: false,
}

const mutations = {
  refresh(state: ProofState, payload: any) {
    state.proofs = payload
  },
  loading(state: ProofState, payload: boolean) {
    state.loading = payload
  },
  clientProofFeedbackSaveLoading(state: ProofState, payload: boolean) {
    state.clientProofFeedbackSaveLoading = payload
  },
  queryDate(state: ProofState, payload: Date | string) {
    state.queryDate = payload
  },
  stickyDate(state: ProofState, payload: Date | string) {
    state.stickyDate = payload
  },
  markerDates(state: ProofState, payload: [string]) {
    state.markerDates = payload
  },
  updateProof(state: OfferState, payload: Proof) {
    Vue.set(
      state.proofs,
      state.proofs.findIndex((proof) => proof.id === payload.id),
      payload
    )
  },
}

const actions = {
  async refresh({ commit }, { selectedDate, params }?: { selectedDate?: string; params?: any }) {
    commit('loading', true)

    const queryDate = selectedDate ? dayjs(selectedDate).valueOf() : dayjs().valueOf()
    const queryParams = params ? params.join('&') : ''

    commit('queryDate', queryDate)

    await ApiService.get(`proof/before/${queryDate}?${queryParams}`).then((response) => {
      commit('refresh', response.data)
      commit('loading', false)
    })
  },

  async saveClientProofFeedback({ commit }, { proof, clientFeedback }?: { proof: Proof; clientFeedback: EnumProofFeedback[] }) {
    commit('clientProofFeedbackSaveLoading', true)

    await ApiService.put(`proof/clientFeedback/${proof.id}`, { clientFeedback }).then((response) => {
      commit('updateProof', { ...proof, client_feedback: clientFeedback, client_feedback_app_user_id: UserService.getUserId() })
      commit('clientProofFeedbackSaveLoading', false)
    })
  },

  async updateProofImage({ commit }, proof: Proof) {
    await ApiService.put(`proof/${proof.id}/updateProofImage`, { proofImageUrl: proof.img_url }).then(() => {
      commit('updateProof', { ...proof })
    })
  },

  stickyDate({ commit }, payload: Date | string) {
    commit('stickyDate', payload)
  },

  markerDates({ commit }, payload: [string]) {
    commit('stickyDate', payload)
  },
}

const getters = {
  proofs: () => state.proofs,
  queryDate: () => state.queryDate,
  stickyDate: () => state.stickyDate,
  markerDates: () => state.markerDates,

  loading: () => state.loading,
  clientProofFeedbackSaveLoading: () => state.clientProofFeedbackSaveLoading,
}

export const timeline = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
