import { ApiService } from '@/services'
import { BusinessSettings, BusinessSocialSettings, BusinessValueSettings } from '@/models/Business'
import { SocialProofSettings } from '@/models/SocialProof'

type SettingsState = {
  loading: boolean
  businessSettings: BusinessSettings
  businessSocialSettings: BusinessSocialSettings
  socialProofSettings: SocialProofSettings
  businessValueSettings: BusinessValueSettings
}

const state: SettingsState = {
  loading: false,
  businessSettings: {
    brand: null,
    title: null,
    subIndustry: null,
  },
  businessSocialSettings: {
    default_instagram_mentions: null,
    default_facebook_mentions: null,
    default_hashtags: null,
  },
  socialProofSettings: {
    verified_by_name: null,
    verified_by_icon: null,
    time_between_posts: null,
    cycle_delay: null,
  },
  businessValueSettings: {
    post_marketing_value: null,
    review_marketing_value: null,
    average_ticket_value: null,
  },
}

const getters = {
  loading: () => state.loading,
  businessSettings: () => state.businessSettings,
  businessSocialSettings: () => state.businessSocialSettings,
  socialProofSettings: () => state.socialProofSettings,
}

const actions = {
  refresh({ commit }) {
    commit('loading', true)
    ApiService.get('settings/all').then((response) => {
      commit('saveBusinessSettings', response.data.businessSettings)
      commit('saveBusinessSocialSettings', response.data.businessSocialSettings)
      commit('saveBusinessValueSettings', response.data.businessValueSettings)
      commit('saveSocialProofSettings', response.data.socialProofSettings)
      commit('loading', false)
    })
  },
  async updateBusinessSettings({ commit, dispatch }, { businessSettings }: { businessSettings: BusinessSettings }) {
    await ApiService.put('settings/business', { ...businessSettings }).then((response) => {
      commit('saveBusinessSettings', response.data)
      dispatch('business/updateBusiness', { ...response.data }, { root: true })
    })
  },
  async updateBusinessSocialSettings({ commit, dispatch }, { businessSocialSettings }: { businessSocialSettings: BusinessSocialSettings }) {
    await ApiService.put('settings/socialDefaults', { businessSocialSettings }).then((response) => {
      commit('saveBusinessSocialSettings', response.data)
      dispatch('business/updateBusinessSocial', { ...response.data }, { root: true })
    })
  },
  async updateBusinessValue({ commit, dispatch }, { businessValueSettings }: { businessValueSettings: BusinessValueSettings }) {
    await ApiService.put('settings/businessValue', { businessValueSettings }).then((response) => {
      commit('saveBusinessValueSettings', response.data)
      dispatch('business/updateBusinessValue', { ...response.data }, { root: true })
    })
  },
  async updateSocialProofSettings({ commit }, { socialProofSettings }: { socialProofSettings: SocialProofSettings }) {
    await ApiService.put('settings/socialProof', { configuration: socialProofSettings }).then((response) => {
      commit('saveSocialProofSettings', response.data)
    })
  },
}

const mutations = {
  loading(state: SettingsState, payload: boolean) {
    state.loading = payload
  },
  saveBusinessSettings(state: SettingsState, payload: BusinessSettings) {
    state.businessSettings = payload
  },
  saveBusinessSocialSettings(state: SettingsState, payload: BusinessSocialSettings) {
    state.businessSocialSettings = payload
  },
  saveSocialProofSettings(state: SettingsState, payload: SocialProofSettings) {
    state.socialProofSettings = payload
  },
  saveBusinessValueSettings(state: SettingsState, payload: BusinessValueSettings) {
    state.businessValueSettings = payload
  },
}

export const settings = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
