<template>
  <v-card class="highlight-card w-full" elevation="17">
    <app-spinner v-if="loading" :spinner-size="80" class="spinner-background" />
    <div v-if="!loading && currentMonthData && Object.keys(currentMonthData).length > 0">
      <v-row align="center" justify="center" class="mb-0">
        <v-col cols="12" align="center" justify="center">
          <span class="w-full text-h5 font-weight-black"> {{ currentMonth }} </span>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="mt-n8">
        <v-col cols="12" align="center" justify="center">
          <VueApexCharts width="320px" height="200px" :options="data" :series="data.series" />
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="mt-n10">
        <v-col cols="12" align="center" justify="center">
          <span class="money-legend-lrg font-weight-black text--darken-1">${{ totalAmount }}</span>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="mt-n5">
        <v-col cols="12" align="center" justify="center">
          <span class="money-legend font-weight-bold review-color">Reviews:</span>
          <span class="money-legend font-weight-black review-color text--darken-1"> ${{ currentMonthData['review'] }}</span>

          | <span class="money-legend font-weight-bold post-color">Posts:</span>
          <span class="money-legend font-weight-black post-color text--darken-1"> ${{ currentMonthData['post'] }}</span>

          | <span class="money-legend font-weight-bold ticket-color">Ticket:</span>
          <span class="money-legend font-weight-black ticket-color text-darken-1"> ${{ currentMonthData['redeem'] }}</span>
        </v-col>
      </v-row>
    </div>
    <v-row v-else align="center" justify="center" class="pa-5 fill-height">
      <v-col cols="12" align="center" justify="center">
        <h1>No data</h1>
        <h3>In the past 3 months</h3>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import { ApiService } from '@/services/index'

export default Vue.extend({
  name: 'MonthRingCard',

  components: { VueApexCharts },

  data: () => ({
    currentMonthData: {},
    graphData: {},
    totalAmount: {},
    loading: true,
    currentMonth: '',
  }),

  computed: {
    data: function () {
      return {
        chart: {
          type: 'radialBar',
        },
        colors: ['#98E3F8', '#1F487E', '#376996'],
        series: [
          (this.currentMonthData['review'] / this.totalAmount) * 100,
          (this.currentMonthData['post'] / this.totalAmount) * 100,
          (this.currentMonthData['redeem'] / this.totalAmount) * 100,
        ],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined,
            },
            track: {
              show: true,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
              total: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
        labels: ['Reviews', 'Posts', 'Ticket'],
      }
    },
  },
  mounted() {
    ApiService.get('dashboard/current').then((response) => {
      this.currentMonthData = response.data.reduce((pv, cv) => {
        return { ...pv, [cv.type]: parseInt(cv.totalamount) }
      }, {})
      this.totalAmount = response.data.map((e) => parseInt(e.totalamount)).reduce((pv, cv) => pv + cv, 0)
      this.loading = false
      this.currentMonth = new Date(response.data[0].month).toLocaleString('default', { month: 'long' })
    })
  },
})
</script>

<style lang="scss" scoped>
.money-legend {
  font-size: 14px;
  font-family: arial, helvetica, sans-serif;
}

.money-legend-lrg {
  font-size: 24px;
  font-family: arial, helvetica, sans-serif;
}

.highlight-card {
  width: 320px;
  height: 300px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 13px;
}

.review-color {
  color: #98e3f8 !important;
}

.post-color {
  color: #1f487e !important;
}

.ticket-color {
  color: #376996 !important;
}
</style>
