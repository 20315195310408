<template>
  <div class="proof-card grid-item">
    <div class="grid-item-container">
      <v-row dense>
        <v-col cols="12" class="pt-4 pr-4">
          <h5 class="color light-gray text-right">{{ proof.date_submitted | prettyDate }}</h5>
          <h6 class="color light-gray text-right">
            <span v-if="proof.first_name">{{ proof.first_name }}</span>
            <span v-if="proof.email || proof.email_address">{{ proof.email || proof.email_address }}</span>
            <span v-if="proof.phone" class="ml-4">{{ proof.phone }}</span>
          </h6>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <h4 class="color light-gray text-center">{{ proof.offer_summary }}</h4>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-img :src="proof.proof_image_url" cover :eager="true" @click="proofImageClicked" />
          <ImageUploadForm
            ref="imageUploadForm"
            image-type="reverseFlowProof"
            :edit-mode="true"
            :image-url="proof.proof_image_url"
            style="display: none"
            @image-uploaded="handleUpdateOfferImage"
          />
        </v-col>
      </v-row>

      <v-row dense class="mt-0 py-4 px-6 text-center">
        <v-col cols="6">
          <v-btn color="primary" @click="proofReviewStatus = 'APPROVED'">Approve</v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn color="darken-1" @click="proofReviewStatus = 'DENIED'">Deny</v-btn>
        </v-col>
      </v-row>

      <div v-if="isAdmin">
        <v-divider class="py-4 mt-2" />
        <v-row class="px-6 text-center">
          <v-col cols="12">
            <v-select v-model="proof.proof_type" :items="taskTypes" hide-details item-text="title" item-value="type" outlined label="Proof Type" />
          </v-col>
        </v-row>
        <v-row :key="proof.id" class="mb-4 px-6 text-center">
          <v-col cols="6" justify="center"> <v-btn @click="handleDeleteProof">Delete 😱</v-btn></v-col>
          <v-col cols="6" justify="center">
            <v-btn color="primary" :loading="updateProofRequestLoading" @click="handleSaveButtonClicked">Save</v-btn>
          </v-col>
        </v-row>
      </div>
    </div>

    <SwayProofReviewModal
      v-if="proofReviewStatus"
      :proof-id="proof.id"
      :review-type="proofReviewStatus"
      @sway-offer-deny-modal-close="proofReviewStatus = null"
      @sway-offer-deny-modal-proof-reviewed="removeProof(proof.id)"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { ApiService } from '@/services/index'
import taskTypes from '../../models/taskType'

import ImageUploadForm from '@/components/ImageUploadForm'
import SwayProofReviewModal from '@/components/SwayOffers/SwayProofReviewModal'

export default Vue.extend({
  name: 'SwayOfferProofApprovalCard',

  components: {
    ImageUploadForm,
    SwayProofReviewModal,
  },

  props: {
    proof: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    proofReviewStatus: null,
    updateProofRequestLoading: false,
  }),

  computed: {
    ...mapGetters('account', ['isAdmin']),
    taskTypes() {
      return Object.values(taskTypes)
    },
  },

  methods: {
    proofImageClicked() {
      if (!this.isAdmin) return

      this.$refs.imageUploadForm.chooseImage()
    },
    removeProof(proofId) {
      this.proofReviewStatus = null

      this.$emit('sway-offer-approval-card-proof-reviewed', proofId)
    },
    handleSaveButtonClicked() {
      if (!this.proof || !this.proof.proof_type) return

      this.updateProofRequestLoading = true

      ApiService.put(`proof/${this.proof.id}/updateSwayProofType/${this.proof.proof_type}`).then((response) => {
        this.updateProofRequestLoading = false
        if (response.data.error) {
          this.$toast.error(`Error updating ${JSON.stringify(response.data.error)}`)
        } else {
          this.$toast.success(`Type updated to ${JSON.stringify(response.data)}`)
        }
      })
    },
    handleUpdateOfferImage(event) {
      ApiService.put(`proof/${this.proof.id}/updateProofImage`, { proofImageUrl: event.image_url }).then(() => {
        this.$emit('sway-proof-updated', { ...this.proof, proof_image_url: event.image_url })
        this.$toast.success('Proof image updated!')
      })
    },
    handleDeleteProof() {
      ApiService.delete(`proof/${this.proof.id}`).then((response) => {
        if (response.data.error) {
          this.$toast.error(`Error deleting ${JSON.stringify(response.data.error)}`)
        } else {
          this.$toast.success(`Proof Deleted... ${JSON.stringify(response.data)} Hope that's what you wanted. 😱`)
          this.$emit('proof-deleted', this.proof.id)
        }
      })
    },
  },
})
</script>

<style scoped>
.proof-card {
  border: solid 1px #eee;
  border-radius: 10px;
}
</style>
