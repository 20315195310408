const state = {
  prettyDate: null,
}

const mutations = {
  prettyDate(state: any, payload: string) {
    state.prettyDate = payload
  },
}

const actions = {
  prettyDate({ commit }, date: any) {
    // Not working
    const fulldate = new Date(date)
    const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(fulldate)
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(fulldate)
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(fulldate)
    const pretty = `${month} ${day}, ${year}`

    commit('prettyDate', pretty)
  },
}

const getters = {
  prettyDate: () => state.prettyDate,
}

export const helpers = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
