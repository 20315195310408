<template>
  <v-expansion-panel @click.native.stop>
    <v-expansion-panel-header>Short URLs</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-dialog v-if="addNewUrl" :value="true" persistent max-width="400">
        <v-card>
          <v-card-title class="headline pb-0">Add URL</v-card-title>
          <v-card-text class="pt-5 pb-0">
            <v-form ref="offerUrlForm">
              <v-row class="mt-n4">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12">
                      <span class="caption"></span>
                      <v-text-field
                        v-model="urlShortCode"
                        label="Short URL"
                        outlined
                        :prefix="baseShortUrl"
                        :rules="validationRules.urlShortCodeRule"
                        :error-messages="errors.shortUrlError"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-n6">
                    <v-col cols="12">
                      <v-text-field v-model="urlParameters" outlined label="Url Parameters (optional)" :persistent-hint="true" :hint="computedUrl" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="addShortUrlLoading" color="darken-1" text @click="addNewUrl = false">Cancel</v-btn>
            <v-btn :loading="addShortUrlLoading" color="primary darken-1" text @click="addShortUrl()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row v-if="urls.length === 0" class="mt-n4">
        <v-col cols="12" class="text-center">
          <h3 class="font-weight-light">No Short Urls</h3>
        </v-col>
      </v-row>

      <v-row v-for="(url, index) in urls" :key="index" class="mt-n4" align="center">
        <v-col cols="12">
          <v-row align="center" justify="center">
            <v-col cols="2">
              <v-btn x-small text class="py-4" @click="copyUrl(url)">
                <v-icon style="cursor: pointer">mdi-content-copy</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="10">
              <div class="body-1 text-left">{{ baseShortUrl }}{{ url.id }}</div>
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="center">
            <v-col cols="12" align="center" justify="center">
              <ShortUrlQrCode :url="baseShortUrl + url.id" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="isAdmin">
        <v-col cols="12" class="text-right">
          <v-btn small color="primary" @click="showAddUrlUi">
            <v-icon class="pr-1">mdi-link-plus</v-icon>
            Add URL
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue from 'vue'

import { nanoid } from 'nanoid'
import { mapGetters } from 'vuex'

import { ApiService } from '@/services/index'
import { copyToClipboard } from '@/mixins/copyToClipboard'
import ShortUrlQrCode from '@/components/Offers/OfferPanels/ShortUrlQrCode'

export default Vue.extend({
  name: 'OfferUrlPanel',

  components: { ShortUrlQrCode },

  mixins: [copyToClipboard],

  props: {
    urls: {
      type: Array,
      required: true,
    },
    offerId: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    addNewUrl: false,
    addShortUrlLoading: false,
    urlShortCode: null,
    urlParameters: null,
    errors: {
      shortUrlError: null,
    },
    validationRules: {
      urlShortCodeRule: [(v) => !!v || 'Required'],
    },
  }),

  computed: {
    ...mapGetters('account', ['isAdmin']),
    baseShortUrl() {
      return process.env.VUE_APP_SHORT_URL
    },
    shortUrl() {
      return `${this.baseShortUrl}${this.urlShortCode}`
    },
    computedUrl() {
      const url = `${process.env.VUE_APP_DIVOC_API_URL}/share/offer/${this.offerId}`

      return this.urlParameters.length === 0 ? url : `${url}?${this.urlParameters}`
    },
  },

  watch: {
    urlShortCode() {
      this.errors.shortUrlError = null
    },
  },

  methods: {
    showAddUrlUi() {
      this.urlShortCode = nanoid(10)
      this.urlParameters = ''
      this.addNewUrl = true
    },
    addShortUrl() {
      if (!this.$refs.offerUrlForm.validate()) return

      this.addShortUrlLoading = true

      ApiService.post(`k/offer/${this.offerId}`, { url: this.computedUrl, shortCode: this.urlShortCode }).then((response) => {
        if (response.data.error) {
          this.addShortUrlLoading = false
          this.errors.shortUrlError = response.data.error
          return
        }

        this.$emit('save', { urls: this.urls.concat(response.data) })

        this.addNewUrl = false
        this.addShortUrlLoading = false
      })
    },
    copyUrl(url) {
      this.copyToClipboard(`${this.baseShortUrl}${url.id}`)
      this.$toast.success('Copied to clipboard!')
    },
  },
})
</script>

<style>
.v-text-field__prefix {
  color: rgba(0, 0, 0, 0.6);
}
</style>
