<template>
  <v-expansion-panel>
    <v-expansion-panel-header>{{ getDescriptionFromType }}</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12">
          <h2 class="mb-2">Colors</h2>
          <div>
            <input
              id="color_proof_border"
              type="color"
              name="color_proof_border"
              :value="widget.color_proof_border"
              class="pointer"
              @change="handleColorChange"
            />
            <label for="color_proof_border" class="pl-2 pointer">Proof Border Color</label>
          </div>

          <div v-if="widget.type === 'CAROUSEL'">
            <input
              id="color_carousel_indicator_button"
              type="color"
              name="color_carousel_indicator_button"
              :value="widget.color_carousel_indicator_button"
              class="pointer"
              @change="handleColorChange"
            />
            <label for="color_carousel_indicator_button" class="pl-2 pointer">Carousel Indicator Button Color</label>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h2 class="mb-2">Install</h2>
          <p>
            To add the {{ getDescriptionFromType.toLowerCase() }} widget to your website, copy the following div HTML tag and insert it where you'd like the
            widget to display on your site:
          </p>
          <p>{{ widgetContainerSnippet }}</p>
          <p>Then copy the following script tag and insert it right before the closing body tag, ie the &lt;/body&gt; tag:</p>
          <p>{{ widget.install_snippet }}</p>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <h2 class="mb-2">Details</h2>
        <v-col cols="12">
          <v-textarea
            outlined
            label="Access Key"
            append-outer-icon="mdi-content-copy"
            :readonly="true"
            :rows="1"
            :no-resize="true"
            :value="widget.access_key"
            @click:append-outer="copyAccessKey"
          />
        </v-col>
      </v-row>

      <v-row class="mt-n6">
        <v-col cols="12">
          <v-textarea
            outlined
            label="Widget Container Snippet"
            append-outer-icon="mdi-content-copy"
            :readonly="true"
            :rows="1"
            :no-resize="true"
            :value="widgetContainerSnippet"
            @click:append-outer="copyContainerSnippet"
          />
        </v-col>
      </v-row>

      <v-row class="mt-n6">
        <v-col cols="12">
          <v-textarea
            outlined
            label="Install Script Snippet"
            append-outer-icon="mdi-content-copy"
            :readonly="true"
            :rows="4"
            :no-resize="true"
            :value="widget.install_snippet"
            @click:append-outer="copyInstallSnippet"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import Vue from 'vue'

import { ApiService } from '@/services/index'
import { copyToClipboard } from '@/mixins/copyToClipboard'

export default Vue.extend({
  name: 'SwayWallOfLoveSettings',

  mixins: [copyToClipboard],

  props: {
    widget: {
      type: Object,
      required: true,
    },
  },

  computed: {
    widgetContainerSnippet() {
      return `<div id="swayWallOfLove"></div>`
    },

    getDescriptionFromType() {
      switch (this.widget.type) {
        case 'CAROUSEL':
          return 'Carousel'
        case 'PINTEREST':
          return 'Wall Of Love'
        default:
          return `Internal Error Unknown Type - [${this.widget.type}]`
      }
    },
  },

  methods: {
    copyAccessKey() {
      this.copyToClipboard(this.widget.access_key)
      this.$toast.success('Copied to clipboard!')
    },
    copyContainerSnippet() {
      this.copyToClipboard(this.widgetContainerSnippet)
      this.$toast.success('Copied to clipboard!')
    },

    copyInstallSnippet() {
      this.copyToClipboard(this.widget.install_snippet)
      this.$toast.success('Copied to clipboard!')
    },

    handleColorChange(event) {
      const { id, value } = event.target
      this.$emit('color-updated', { accessKey: this.widget.access_key, field: id, value })
    },
  },
})
</script>

<style scoped>
.install-details-link {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}
</style>
