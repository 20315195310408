<template>
  <v-dialog :value="true" :fullscreen="$vuetify.breakpoint.smAndDown" persistent max-width="850">
    <v-card>
      <v-card-text :class="$vuetify.breakpoint.smAndDown ? 'pa-0' : ''">
        <v-stepper v-model="offerFlowStep" vertical class="elevation-0">
          <v-stepper-step :complete="offerFlowStep > 1" :editable="!!selectedOfferSummary" step="1" edit-icon="$complete">
            <h2 class="step-title">Select a pre-approved offer</h2>
            <small v-if="offerFlowStep == 1" class="step-sub-title">
              Below are deals that perform best for your business category.
              <a href="https://localfluencehelp.helpscoutdocs.com/article/48-what-makes-a-great-offer" target="blank">Why Pre-approved offers?</a>
              <v-icon small color="primary" class="mt-n1 pr-1 ml-1">mdi-open-in-new</v-icon>
            </small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-row align="center" justify="center">
              <v-col cols="11" class="pb-0">
                <OfferSummarySelector v-model="selectedOfferSummary" />
              </v-col>
            </v-row>

            <v-card-actions class="justify-end">
              <v-btn color="darken-1" class="mr-4" @click="() => $emit('new-offer-modal-close')">Cancel</v-btn>
              <v-btn :disabled="!selectedOfferSummary" color="primary" @click="offerFlowStep = 2">Continue</v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-step :complete="offerFlowStep > 2" :editable="!!offerType" step="2" edit-icon="$complete">
            <h2 class="step-title">How do locals get their stuff?</h2>
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-row class="ma-0" align="center" justify="center">
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 5" align="center" justify="center">
                <OrderFlowTypeButton
                  icon="offer-type-web"
                  title="Delivery"
                  text="Locals will redeem their offer online & you will ship it"
                  :selected="offerType === 'WEB'"
                  @offer-flow-type-selected="
                    offerType = 'WEB'
                    offerOrderFlowType = null
                  "
                />
              </v-col>

              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 5" align="center" justify="center">
                <OrderFlowTypeButton
                  icon="offer-type-local"
                  title="In Person"
                  text="Locals will redeem their offer at your physical location"
                  :selected="offerType === 'LOCAL'"
                  @offer-flow-type-selected="
                    offerType = 'LOCAL'
                    offerOrderFlowType = null
                  "
                />
              </v-col>
            </v-row>

            <template v-if="offerType === 'LOCAL'">
              <v-row>
                <v-col class="pb-0 mb-4">
                  <label class="subtitle-1">Where do you want to run the offer?</label>
                </v-col>
              </v-row>

              <LocationsQuickSummary @handle-update-locations="handleUpdateLocations" />
            </template>

            <v-card-actions class="justify-end">
              <v-btn color="darken-1" class="mr-4" @click="() => $emit('new-offer-modal-close')">Cancel</v-btn>
              <v-btn :disabled="!offerType" color="primary" @click="offerFlowStep = 3">Continue</v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-step :complete="offerFlowStep > 3" :editable="!!offerOrderFlowType" step="3" edit-icon="$complete">
            <h2 class="step-title">How do locals start the ordering process?</h2>
          </v-stepper-step>

          <v-stepper-content step="3" :class="$vuetify.breakpoint.smAndDown ? 'pl-0' : ''">
            <template v-if="offerType === 'LOCAL'">
              <v-row class="ma-0" align="center" justify="center">
                <v-col
                  v-for="orderFlowType in localOfferOrderFlowTypes"
                  :key="`offer-type-${orderFlowType.type}`"
                  :cols="$vuetify.breakpoint.smAndDown ? 12 : 6"
                  align="center"
                  justify="center"
                >
                  <OrderFlowTypeButton
                    :icon="orderFlowType.icon"
                    :title="orderFlowType.title"
                    :text="orderFlowType.text"
                    :selected="offerOrderFlowType === orderFlowType.type"
                    @offer-flow-type-selected="offerOrderFlowType = orderFlowType.type"
                  />
                </v-col>
              </v-row>
            </template>

            <template v-else-if="offerType === 'WEB'">
              <v-row class="ma-0" align="center" justify="center">
                <v-col
                  v-for="orderFlowType in webOfferOrderFlowTypes"
                  :key="`offer-type-${orderFlowType.type}`"
                  :cols="$vuetify.breakpoint.smAndDown ? 12 : 5"
                  align="center"
                  justify="center"
                >
                  <OrderFlowTypeButton
                    :icon="orderFlowType.icon"
                    :title="orderFlowType.title"
                    :text="orderFlowType.text"
                    :selected="offerOrderFlowType === orderFlowType.type"
                    @offer-flow-type-selected="offerOrderFlowType = orderFlowType.type"
                  />
                </v-col>
              </v-row>
            </template>

            <template v-if="offerOrderFlowType === 'URL'">
              <v-form ref="urlOfferOrderFlowForm">
                <v-row class="mt-4">
                  <v-col class="pb-0">
                    <label class="subtitle-1">Which website should locals visit to place their order?</label>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col cols="11" class="pt-0 pb-0">
                    <v-text-field
                      key="offerUrlInput"
                      v-model="url"
                      :rules="urlRules"
                      :validate-on-blur="true"
                      outlined
                      placeholder="https://www.google.com"
                      type="url"
                    />
                  </v-col>
                </v-row>

                <v-row class="mt-2">
                  <v-col class="pb-0">
                    <label class="subtitle-1">Do you have a website where locals can learn about the offer? (optional)</label>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col cols="11" class="pt-0 pb-0">
                    <v-text-field
                      key="offerDescriptionUrlInput"
                      v-model="descriptionUrl"
                      :rules="optionalUrlRules"
                      :validate-on-blur="true"
                      outlined
                      placeholder="https://www.google.com"
                      type="url"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </template>

            <template v-if="offerOrderFlowType === 'CALL'">
              <v-form ref="callOfferOrderFlowForm">
                <v-row class="mt-4">
                  <v-col class="pb-0">
                    <label class="subtitle-1">Which phone number should locals call to place their order?</label>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col cols="11" class="pt-0 pb-0">
                    <v-text-field
                      key="offerPhoneInput"
                      v-model="phone"
                      v-mask="'(###) ###-####'"
                      :rules="optionalPhoneRules"
                      :validate-on-blur="true"
                      outlined
                      type="tel"
                      placeholder="(555) 555-5555"
                    />
                  </v-col>
                </v-row>
                <v-row class="mt-4">
                  <v-col class="pb-0">
                    <label class="subtitle-1">Which website should locals visit to place their order?</label>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col cols="11" class="pt-0 pb-0">
                    <v-text-field
                      key="offerUrlInput"
                      v-model="url"
                      :rules="optionalUrlRules"
                      :validate-on-blur="true"
                      outlined
                      placeholder="https://www.google.com"
                      type="url"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </template>

            <template v-if="offerOrderFlowType === 'EVENT'">
              <v-row class="mt-4">
                <v-col class="pb-0">
                  <label class="subtitle-1">Enter dates and times for the event.</label>
                </v-col>
              </v-row>
              <v-row dense :class="['mt-2', $vuetify.breakpoint.smAndDown ? '' : 'ml-7']">
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
                  <v-menu
                    v-model="eventDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="slide-y-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field v-model="eventDate" label="Date" prepend-inner-icon="mdi-calendar-outline" readonly outlined v-on="on" />
                    </template>
                    <v-date-picker v-model="eventDate" :show-current="false" @input="eventDatePicker = false" />
                  </v-menu>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 5 : 3">
                  <v-menu
                    ref="startTimePickerMenu"
                    v-model="startTimePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="startTime"
                    transition="scale-transition"
                    offset-y
                    width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="startTimeAP"
                        label="Start"
                        prepend-inner-icon="mdi-clock-time-four-outline"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-time-picker
                      v-if="startTimePicker"
                      v-model="startTime"
                      :allowed-minutes="allowedMinutes"
                      full-width
                      format="ampm"
                      ampm-in-title
                      @click:minute="$refs.startTimePickerMenu.save(startTime)"
                    />
                  </v-menu>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 5 : 3">
                  <v-menu
                    ref="endTimePickerMenu"
                    v-model="endTimePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="endTimePicker"
                    transition="scale-transition"
                    offset-y
                    width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="endTimeAP"
                        label="End"
                        prepend-inner-icon="mdi-clock-time-four-outline"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-time-picker
                      v-if="endTimePicker"
                      v-model="endTime"
                      :allowed-minutes="allowedMinutes"
                      full-width
                      format="ampm"
                      ampm-in-title
                      @click:minute="$refs.endTimePickerMenu.save(endTime)"
                    />
                  </v-menu>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 2 : 1" class="pt-3" align="center">
                  <v-btn icon elevation="2" class="primary" @click="addTime(eventDate, startTime, endTime)">
                    <v-icon class="white--text">mdi-calendar-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-0" dense>
                <v-col cols="12">
                  <v-chip
                    v-for="time in displayEventTimes"
                    :key="time"
                    color="primary lighten-1"
                    class="ma-1"
                    close
                    close-icon="mdi-delete"
                    @click:close="removeTime(time)"
                  >
                    {{ time }}
                  </v-chip>
                </v-col>
              </v-row>
            </template>

            <v-card-actions class="mt-4 justify-end">
              <v-btn color="darken-1" class="mr-4" @click="() => $emit('new-offer-modal-close')">Cancel</v-btn>
              <v-btn :disabled="!offerOrderFlowType" color="primary" @click="offerFlowStep = 4">Continue</v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-step :complete="offerFlowStep > 4" :editable="!!offerImageUrl" step="4" edit-icon="$complete">
            <h2 class="step-title">Offer Image</h2>
            <small v-if="offerFlowStep == 4" class="step-sub-title">
              Upload a 380x200 image that represents the offer or your brand.
              <a href="https://localfluencehelp.helpscoutdocs.com/article/48-what-makes-a-great-offer" target="_blank">Need help finding an image?</a>
              <v-icon small color="primary" class="mt-n1 pr-1 ml-1">mdi-open-in-new</v-icon>
            </small>
          </v-stepper-step>

          <v-stepper-content step="4" align="center" :class="$vuetify.breakpoint.smAndDown ? 'pl-0' : ''">
            <ImageUploadForm
              image-type="offer"
              :edit-mode="true"
              :image-url="offerImageUrl"
              class="mb-4"
              style="max-width: 380px"
              @image-uploaded="handleUpdateOfferImage"
            >
              <h2>Click to upload image</h2>
              <h5 class="font-weight-light">380x200 image dimensions</h5>
            </ImageUploadForm>

            <v-card-actions class="justify-end">
              <v-btn color="darken-1" class="mr-4" @click="() => $emit('new-offer-modal-close')">Cancel</v-btn>
              <v-btn :loading="offerMutationLoading" color="primary" @click="createNewOffer()">SUBMIT FOR APPROVAL</v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { mask } from 'vue-the-mask'

import validationRules from '@/utils/validation'
import { hourStringToAP } from '@/utils/hoursstringtoap'
import { offerOrderFlowTypes } from '@/models'

import { mapActions, mapGetters } from 'vuex'
import ImageUploadForm from '@/components/ImageUploadForm'

import LocationsQuickSummary from '@/components/Offers/OfferForm/LocationsQuickSummary'
import OrderFlowTypeButton from '@/components/Offers/NewOfferModals/OrderFlowTypeButton'
import OfferSummarySelector from '@/components/Offers/NewOfferModals/OfferSummarySelector'

export default Vue.extend({
  name: 'NewOfferModal',

  directives: {
    mask,
  },

  components: {
    ImageUploadForm,
    LocationsQuickSummary,
    OrderFlowTypeButton,
    OfferSummarySelector,
  },

  data: () => ({
    offerFlowStep: 1,
    url: '',
    descriptionUrl: '',
    phone: '',
    offerImageUrl: null,
    offerType: null,
    offerOrderFlowType: null,
    selectedOfferSummary: null,
    urlRules: validationRules.urlRules,
    optionalUrlRules: validationRules.optionalUrlRules,
    phoneRules: validationRules.phoneRules,
    optionalPhoneRules: validationRules.optionalPhoneRules,
    startDatePicker: false,
    selectedTags: [],
    selectedLocations: [],
    addressTags: [],
    eventDatePicker: false,
    eventDate: null,
    startTime: null,
    startTimePicker: false,
    endTime: null,
    endTimePicker: false,
    eventTimes: [],
  }),

  computed: {
    ...mapGetters('offer', ['offerMutationLoading']),
    localOfferOrderFlowTypes() {
      return [offerOrderFlowTypes.VISIT, offerOrderFlowTypes.CALL, offerOrderFlowTypes.URL, offerOrderFlowTypes.EVENT]
    },
    webOfferOrderFlowTypes() {
      return [offerOrderFlowTypes.CALL, offerOrderFlowTypes.URL]
    },
    allowedMinutes() {
      return [0, 15, 30, 45]
    },
    endTimeAP() {
      return hourStringToAP(this.endTime)
    },
    startTimeAP() {
      return hourStringToAP(this.startTime)
    },
    displayEventTimes() {
      return this.eventTimes.map((e) => `${e.event_date} ${hourStringToAP(e.start_time)}-${hourStringToAP(e.end_time)}`)
    },
  },

  methods: {
    ...mapActions('offer', ['createOffer', 'updateLocationForOffer']),
    handleUpdateLocations(evt) {
      this.selectedTags = evt.location_tags
      this.selectedLocations = evt.locations
    },
    createNewOffer() {
      if (this.offerOrderFlowType === 'URL' && !this.$refs.urlOfferOrderFlowForm.validate()) return
      if (this.offerOrderFlowType === 'CALL' && !this.$refs.callOfferOrderFlowForm.validate()) return
      if (this.offerOrderFlowType === 'EVENT' && this.eventTimes.length === 0) {
        this.$toast.error('No event times selected')
        return
      }

      const eventTimesAp = this.eventTimes
        ? this.eventTimes.map((e) => {
            return { ...e, start_time: hourStringToAP(e.start_time), end_time: hourStringToAP(e.end_time) }
          })
        : undefined

      this.createOffer({
        offer: {
          offer_type: this.offerType,
          offer_order_flow_type: this.offerOrderFlowType,
          offer_summary: this.selectedOfferSummary.summary_value,
          offer_summary_id: this.selectedOfferSummary.id,
          offer_image_url: this.offerImageUrl,
          offer_url: this.url ? this.url.trim() : null,
          offer_description_url: this.offerOrderFlowType === 'URL' ? this.descriptionUrl.trim() : null,
          offer_phone: this.offerOrderFlowType === 'CALL' ? this.phone.trim() : null,
          event_date_option: eventTimesAp ?? null,
        },
      }).then((offerId) => {
        this.updateLocationForOffer({ offerId, locationTagIds: this.selectedTags.map((e) => e.id) }).then(() => {
          this.$emit('new-offer-modal-close')
        })
      })
    },
    addTime(eventDate, startTime, endTime) {
      if (!eventDate) {
        this.$toast.error(`No Date Selected.`)
        return
      }
      if (!startTime) {
        this.$toast.error(`No Start Time Selected.`)
        return
      }

      if (!endTime) {
        this.$toast.error(`No End Time Selected.`)
        return
      }

      const startHour = this.startTime.split(':')[0]
      const startMin = this.startTime.split(':')[1]

      const endHour = this.endTime.split(':')[0]
      const endMin = this.endTime.split(':')[1]

      if (startHour > endHour || (startHour === endHour && startMin > endMin)) {
        this.$toast.error(`End time must come after start time.`)
        return
      }

      if (this.eventTimes.find((e) => e.eventDate === eventDate && e.start_time === startTime && e.end_time === endTime)) {
        this.$toast.error(`Event Date And Time Already added.`)
        return
      }
      this.eventTimes.push({ event_date: eventDate, start_time: startTime, end_time: endTime })
      this.eventDate = null
    },
    removeTime(time) {
      this.eventTimes = this.eventTimes.filter((e) => time !== `${e.event_date} ${hourStringToAP(e.start_time)}-${hourStringToAP(e.end_time)}`)
    },
    handleUpdateOfferImage(event) {
      this.offerImageUrl = event.image_url
    },
  },
})
</script>

<style scoped>
.step-title {
  line-height: 1.2;
  font-weight: 300;
  color: #707070 !important;
}

.step-sub-title {
  margin-top: 6px;
  font-weight: 400;
  line-height: 1.3;
  color: #707070 !important;
}
</style>
