<template>
  <div class="mt-4">
    <ul>
      <li><a href="/adminSettings"> Admin Settings</a></li>
      <li><a href="/simpleReports"> Simple Reports</a></li>
      <li><a href="/userLookup">User Lookup</a></li>
      <li><a href="/mailingLists">Mailing Lists</a></li>
      <li><a href="/dashboard">Dashboard</a></li>
      <li><a href="/chargebeeControl">Chargebee Control</a></li>
      <li><a href="/swayProofReviewTemplates">Sway Proof Review Templates</a></li>
    </ul>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ClientLiteOffers',

  metaInfo: {
    title: 'Admin Controls',
  },
})
</script>
<style scoped>
ul,
ol,
dl,
p {
  font-size: 1.5rem;
  list-style-type: none;
}
</style>