<template>
  <div id="swayProofReviewTemplates">
    <NewSwayProofReviewTemplateModal
      v-if="selectedTemplate"
      :template="selectedTemplate"
      @new-template-modal-cancel="selectedTemplate = null"
      @new-template-modal-saved="addTemplate"
      @new-template-modal-updated="updateTemplate"
      @new-template-modal-deleted="deleteTemplate"
    />

    <div class="text-center">
      <v-btn color="primary" @click="selectedTemplate = {}">Add Template</v-btn>
    </div>

    <h1 class="template-title">Approved Templates</h1>
    <div v-if="approvedTemplates.length === 0">No Templates</div>
    <div v-for="template in approvedTemplates" :key="template.id" class="template" @click="selectedTemplate = template">{{ template.title }}</div>

    <h1 class="template-title">Denied Templates</h1>
    <div v-if="deniedTemplates.length === 0">No Templates</div>
    <div v-for="template in deniedTemplates" :key="template.id" class="template" @click="selectedTemplate = template">{{ template.title }}</div>
  </div>
</template>

<script>
import { ApiService } from '@/services'

import NewSwayProofReviewTemplateModal from '@/components/Settings/NewSwayProofReviewTemplateModal'

export default {
  name: 'SwayProofReviewTemplates',

  metaInfo: {
    title: 'Sway Proof Review Templates',
  },

  components: {
    NewSwayProofReviewTemplateModal,
  },

  data: () => ({
    templates: [],
    loadingTemplates: true,
    selectedTemplate: null,
  }),

  computed: {
    approvedTemplates() {
      return this.templates.filter((template) => template.type === 'APPROVED')
    },
    deniedTemplates() {
      return this.templates.filter((template) => template.type === 'DENIED')
    },
  },

  mounted() {
    ApiService.get(`adminSettings/swayProofReviewTemplates`)
      .then((response) => {
        if (response.data.error) {
          this.$toast.error(`Error fetching templates`)
          return
        }

        this.templates = response.data
      })
      .finally(() => {
        this.loadingTemplates = false
      })
  },

  methods: {
    addTemplate(template) {
      this.selectedTemplate = null
      this.templates = [...this.templates, template]
    },
    updateTemplate(updatedTemplate) {
      this.selectedTemplate = null
      this.$set(
        this.templates,
        this.templates.findIndex((template) => template.id === updatedTemplate.id),
        { ...updatedTemplate }
      )
    },
    deleteTemplate(deletedTemplateId) {
      this.selectedTemplate = null
      this.$delete(
        this.templates,
        this.templates.findIndex((template) => template.id === deletedTemplateId)
      )
    },
  },
}
</script>

<style scoped>
#swayProofReviewTemplates {
  width: 100%;
  padding: 10px;
}

.template {
  padding: 10px;
}

.template:hover {
  cursor: pointer;
  font-weight: 600;
  background-color: #f5f5f5;
}

.template-title:not(:first-child) {
  padding-top: 20px;
}
</style>
