<template>
  <div id="timelineReview">
    <app-spinner v-if="loading" />

    <v-container v-else-if="swayOffers.length === 0">
      <h3 class="color light-gray text-center">
        No sway offers created. Contact <strong class="primary--text">team@localfluence.com</strong> to learn more about how to turn your current customers into
        social brand advocates.
      </h3>
    </v-container>

    <v-container v-else>
      <div class="text-center mb-6">
        <v-btn :href="swayDashboardLink" target="_blank">View Sway Dashboard</v-btn>
      </div>

      <SwayOfferSearchField :selected-offer-id="selectedId" :sway-offers="swayOffers" @sway-offer-selected="handleOfferSelected" />

      <app-spinner v-if="proofsLoading" />

      <div v-else>
        <h3 v-if="selectedId !== null && proofs.length === 0" class="text-center mt-5">No Proofs Yet</h3>

        <div v-else-if="proofs.length > 0" class="mt-5">
          <h4>Please review and approve the proofs</h4>
          <h6 class="caption font-weight-light text--secondary">
            If this is a giveaway, once the offer has expired please select and contact a winner ({{ entriesLabel }} entries)
          </h6>
        </div>

        <div class="grid-container mt-4">
          <template v-for="proof in proofs">
            <SwayOfferProofApprovalCard
              :key="proof.id"
              :proof="proof"
              @sway-proof-updated="handleProofUpdated"
              @sway-offer-approval-card-proof-reviewed="handleProofReviewed"
              @proof-deleted="handleProofReviewed"
            />
            <div :key="`hack-spacer-${proof.id}`" style="width: 100%; height: 1px"></div>
          </template>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { ApiService, TokenService } from '@/services/index'

import SwayOfferSearchField from '@/components/SwayOffers/SwayOfferSearchField'
import SwayOfferProofApprovalCard from '@/components/SwayOffers/SwayOfferProofApprovalCard'

export default {
  name: 'Timeline',

  metaInfo: {
    title: 'Timeline Review',
  },

  components: {
    SwayOfferSearchField,
    SwayOfferProofApprovalCard,
  },

  data: () => ({
    proofs: [],
    proofsLoading: false,
    selectedId: null,
  }),

  computed: {
    ...mapGetters('swayoffer', ['loading', 'swayOffers']),
    entriesLabel() {
      return `${this.proofs.length} ${this.proofs.length === 1 ? 'entry' : 'entries'}`
    },
    swayDashboardLink() {
      return `${process.env.VUE_APP_SWAY_CLIENT_APP_URL}/auth/login/${TokenService.getToken()}`
    },
  },

  watch: {
    selectedId: function (event) {
      if (!this.selectedId) return

      this.proofsLoading = true

      ApiService.get(`/swayOffer/proofsForOffer/${this.selectedId}`)
        .then((response) => {
          this.proofs = response.data
        })
        .finally(() => {
          this.proofsLoading = false
        })
    },
  },

  mounted() {
    this.$store.dispatch('swayoffer/refresh')
  },

  methods: {
    handleProofUpdated(updatedProof) {
      Vue.set(
        this.proofs,
        this.proofs.findIndex((proof) => proof.id === updatedProof.id),
        updatedProof
      )
    },
    handleProofReviewed(proofId) {
      this.proofs.splice(
        this.proofs.findIndex((proof) => proof.id === proofId),
        1
      )
    },
    handleOfferSelected(swayOfferId) {
      this.selectedId = swayOfferId
    },
  },
}
</script>

<style scoped lang="scss">
#timelineReview {
  width: 100%;
}
</style>
