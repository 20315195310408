<template>
  <v-container fluid>
    <v-form v-model="valid">
      <v-row>
        <v-col cols="6">
          <v-text-field v-model="firstName" :rules="nameRules" label="First name" outlined required />
        </v-col>

        <v-col cols="6">
          <v-text-field v-model="lastName" :rules="nameRules" label="Last name" outlined required />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn :disabled="!valid" :loading="loading" color="primary" class="mr-4" @click="doUpdateName">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <h4 class="version font-weight-light">Version {{ appVersion }}</h4>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Account',

  metaInfo: {
    title: 'Account Details',
  },

  data: () => ({
    valid: false,
    loading: false,
    firstName: '',
    lastName: '',
    nameRules: [(v) => !!v || 'required'],
  }),

  computed: {
    ...mapGetters('app', ['appVersion']),
    ...mapGetters('account', ['getFirstName', 'getLastName']),
  },

  mounted: function () {
    this.firstName = this.getFirstName
    this.lastName = this.getLastName
  },

  methods: {
    ...mapActions('account', ['updateName']),
    async doUpdateName() {
      this.loading = true

      await this.updateName({ firstName: this.firstName, lastName: this.lastName })

      this.loading = false
      this.$toast.success('Account Details Updated')
    },
  },
}
</script>

<style scoped>
.version {
  left: 0px;
  right: 0px;
  bottom: 40px;
  width: 100%;
  position: fixed;
  text-align: center;
}
</style>
