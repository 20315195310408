<template>
  <v-expansion-panel @click.native.stop>
    <v-expansion-panel-header>Coupon</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-n6">
        <v-col cols="12">
          <p class="text-left font-weight-regular caption text--secondary">
            Coupons allow locals to present you or your team members with a code or image. These are optional.
          </p>
        </v-col>
      </v-row>

      <v-row class="mt-n6">
        <v-col cols="12">
          <v-text-field
            v-model="coupon_code"
            outlined
            label="Coupon Code"
            hint="Generic code (all locals will use this code)"
            @blur="handleSaveCode()"
            @keydown.enter="$event.target.blur()"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'OfferCouponPanel',

  components: {},

  props: {
    couponCode: {
      type: String,
      default: () => null,
    },
    couponImageUrl: {
      type: String,
      default: () => null,
    },
  },

  data: () => ({
    coupon_code: null,
    coupon_image_url: null,
  }),

  watch: {
    couponImageUrl: function (event) {
      this.coupon_image_url = this.couponImageUrl
    },
  },

  mounted: function () {
    this.coupon_code = this.couponCode
    this.coupon_image_url = this.couponImageUrl
  },

  methods: {
    handleImageUploaded(event) {
      this.$emit('save', { coupon_image_url: event.image_url })
    },
    handleImageDeleted() {
      this.$emit('save', { coupon_image_url: null })
    },
    handleSaveCode() {
      this.$emit('save', { coupon_code: this.coupon_code })
    },
  },
})
</script>
