import Vue from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(utc)

Vue.filter('shortDate', (dateValue: Date) => {
  dayjs.extend(advancedFormat)
  return dayjs(dateValue).format('MMMM Do')
})

Vue.filter('monthYear', (dateValue: Date) => {
  dayjs.extend(advancedFormat)
  return dayjs(dateValue).format('MMMM YYYY')
})

Vue.filter('shorterDate', (dateValue: Date) => {
  dayjs.extend(advancedFormat)
  return dayjs(dateValue).utc().format('MMM Do')
})

Vue.filter('prettyDate', (dateValue: Date) => {
  dayjs.extend(advancedFormat)
  return dayjs(dateValue).format('MMMM DD, YYYY')
})

Vue.filter('prettyDateTime', (dateValue: Date) => {
  dayjs.extend(advancedFormat)
  return dayjs(dateValue).format('MMMM DD, YYYY hh:mma')
})

Vue.filter('monthDayYearDate', (dateValue: Date) => {
  dayjs.extend(advancedFormat)
  return dayjs(dateValue).format('MM/DD/YY')
})
