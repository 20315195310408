<template>
  <div class="mb-4">
    <OfferLocationsModal
      v-if="showLocationsModal"
      :initial-locations="selectedLocations"
      :initial-location-tags="selectedTags"
      @save="handleUpdateLocations"
      @offer-locations-modal-close="showLocationsModal = false"
    />

    <v-row v-if="addressTagsLoading">
      <v-col cols="12" align="left" justify="left">
        <div>Loading locations...</div>
      </v-col>
    </v-row>
    <v-row v-if="!addressTagsLoading && !addressesSetup" class="mt-n6" align="center" justify="center">
      <v-col cols="11" align="left" justify="left">
        <p>You don't have any locations. Create locations and come back to this offer to finish.</p>
        <p><a href="./locations"> Go to Location Creation Page </a></p>
      </v-col>
    </v-row>
    <v-row v-else align="center" justify="center">
      <v-col cols="9" align="left" justify="left" class="pl-6">
        <div v-if="selectedTags.length > 0">
          <span class="info-font"> {{ selectedLocations.length }} {{ 'Location' | pluralize(selectedLocations.length) }} in </span>
          <v-chip v-for="curTag in selectedTags.slice(0, 2)" :key="curTag.id" small color="primary lighten-1" class="ml-2">
            <strong>{{ curTag.title }}</strong>
          </v-chip>
          <span v-if="selectedTags.length > 2">... </span>
        </div>
        <div v-if="selectedTags.length === 0">
          <v-chip small color="primary lighten-1">
            <strong>All</strong>
          </v-chip>
          <span class="info-font"> {{ selectedLocations.length }} {{ 'Location' | pluralize(selectedLocations.length) }} </span>
        </div>
      </v-col>
      <v-col cols="2" align="left" justify="left" class="pa-0">
        <a href="#" class="market-link" @click.stop="showLocationsModal = true"> Change </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'

import OfferLocationsModal from '@/components/Offers/OfferLocationsModal'
import { ApiService } from '@/services/index'

export default Vue.extend({
  name: 'LocationsQuickSummary',
  components: {
    OfferLocationsModal,
  },
  props: {
    offerId: { type: String, default: null },
  },
  data: () => ({
    showLocationsModal: false,
    addressTagsLoading: true,
    addressesSetup: false,
    selectedTags: [],
    selectedLocations: [],
  }),
  mounted: function () {
    if (this.offerId) {
      ApiService.get(`offer/addresses/${this.offerId}`).then((response) => {
        this.selectedTags = response.data.locationTags || []
        this.selectedLocations = response.data.locations || []
        this.addressesSetup = true
        this.addressTagsLoading = false
      })
    } else {
      ApiService.get('address/all').then((response) => {
        this.addressesSetup = response.data.length > 0
        this.selectedLocations = response.data || []
        this.addressTagsLoading = false
      })
    }
  },
  methods: {
    handleUpdateLocations(evt) {
      this.selectedTags = evt.location_tags
      this.selectedLocations = evt.locations
      this.$emit('handle-update-locations', { locations: this.selectedLocations, location_tags: this.selectedTags })
      this.showLocationsModal = false
    },
  },
})
</script>

<style scoped>
.info-font {
  font-size: 14px;
}

.market-link {
  text-decoration: none;
}

.market-link:hover,
.market-link:focus {
  text-decoration: underline;
}
</style>
