import { ApiService, UserService } from '@/services'

type AccountDetailsResponse = {
  first_name: string
  last_name: string
}

type AccountState = {
  firstName: string
  lastName: string
}

const state: AccountState = {
  firstName: '',
  lastName: '',
}

const getters = {
  isAdmin: () => UserService.isAdmin(),
  isClientLite: () => UserService.isClientLite(),
  getUserType: () => UserService.getUserType(),
  getFirstName: () => state.firstName,
  getLastName: () => state.lastName,
}

const actions = {
  async getAccountDetails({ commit, dispatch }) {
    return await ApiService.get('user/loggedIn').then((response) => {
      commit('accountDetails', response.data)

      const { first_name, last_name, id, email } = response.data
      dispatch('encTracking/identify', { userId: id, email }, { root: true })
      dispatch('heap/updateUserProperties', { firstName: first_name, lastName: last_name, email: email }, { root: true })

      return response.data
    })
  },
  async updateName({ commit }, { firstName, lastName }: { firstName: string; lastName: string }) {
    await ApiService.put(`user/account/name`, {
      firstName,
      lastName,
    }).then(() => {
      commit('accountDetails', { first_name: firstName, last_name: lastName })
    })
  },
}

const mutations = {
  accountDetails(state: AccountState, payload: AccountDetailsResponse) {
    state.firstName = payload.first_name
    state.lastName = payload.last_name
  },
}

export const account = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
