<template>
  <v-expansion-panel @click.native.stop>
    <v-expansion-panel-header>
      <div>
        <v-badge :value="!image_url" content="!" offset-y="6" bordered>Image</v-badge>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <ImageUploadForm
        :edit-mode="true"
        :allow-image-deletion="true"
        :image-type="imageType"
        :image-url="image_url"
        @image-deleted="handleUpdateOfferImage"
        @image-uploaded="handleUpdateOfferImage"
      >
        <slot></slot>
      </ImageUploadForm>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue from 'vue'

import ImageUploadForm from '@/components/ImageUploadForm'

export default Vue.extend({
  name: 'OfferImagePanel',

  components: {
    ImageUploadForm,
  },

  props: {
    imageType: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    image_url: '',
  }),

  watch: {
    imageUrl: function () {
      this.image_url = this.imageUrl
    },
  },

  mounted() {
    this.image_url = this.imageUrl
  },

  methods: {
    handleUpdateOfferImage: function (event) {
      this.$emit('save', { offer_image_url: event.image_url })
    },
  },
})
</script>

<style>
.v-text-field__prefix {
  color: rgba(0, 0, 0, 0.6);
}
</style>
