<template>
  <v-expansion-panel @click.native.stop>
    <v-expansion-panel-header>Dates</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-n4">
        <v-col cols="12">
          <v-menu v-model="startDatePicker" :close-on-content-click="false" :nudge-right="40" transition="slide-y-transition" offset-y min-width="290px">
            <template #activator="{ on }">
              <v-text-field v-model="startDateFormatted" label="Start Date" outlined readonly v-on="on" />
            </template>
            <v-date-picker v-model="offer_start_date" :show-current="false" @input="startDatePicker = false" @change="handleSaveStartDate()" />
          </v-menu>
        </v-col>
      </v-row>

      <v-row v-if="isAdmin" class="mt-n6">
        <v-col cols="12">
          <v-menu v-model="expirationDatePicker" :close-on-content-click="false" :nudge-right="40" transition="slide-y-transition" offset-y min-width="290px">
            <template #activator="{ on }">
              <v-text-field
                v-model="expirationDateFormatted"
                label="Expiration Date"
                placeholder="Ongoing"
                :persistent-hint="true"
                outlined
                readonly
                clearable
                v-on="on"
                @click:clear="clearExpirationDate()"
              />
            </template>
            <v-date-picker v-model="expiration_date" :show-current="false" @input="expirationDatePicker = false" @change="handleSaveExpirationDate()" />
          </v-menu>
        </v-col>
      </v-row>
      <v-row v-if="offerOrderFlowType === 'EVENT'" class="mt-n6">
        <v-col class="pb-0">
          <label v-if="needsApproval" class="subtitle-1">Enter dates and times for the event.</label>
          <label v-else class="subtitle-1">Dates and times for the event.</label>
        </v-col>
      </v-row>
      <v-row v-if="offerOrderFlowType === 'EVENT' && needsApproval">
        <v-col cols="4" class="pa-0 pl-3">
          <v-menu v-model="eventDatePicker" :close-on-content-click="false" :nudge-right="40" transition="slide-y-transition" offset-y min-width="290px">
            <template #activator="{ on }">
              <v-text-field v-model="eventDate" label="Date" readonly outlined v-on="on" />
            </template>
            <v-date-picker v-model="eventDate" :show-current="false" @input="eventDatePicker = false" />
          </v-menu>
        </v-col>
        <v-col cols="4" class="pa-0 pl-3">
          <v-menu
            ref="startTimePickerMenu"
            v-model="startTimePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="startTime"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="startTimeAP"
                label="Start"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="startTimePicker"
              v-model="startTime"
              :allowed-minutes="allowedMinutes"
              full-width
              format="ampm"
              ampm-in-title
              @click:minute="$refs.startTimePickerMenu.save(startTime)"
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="4" class="pa-0 pl-3">
          <v-menu
            ref="endTimePickerMenu"
            v-model="endTimePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="endTimePicker"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="endTimeAP"
                label="End"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="endTimePicker"
              v-model="endTime"
              :allowed-minutes="allowedMinutes"
              full-width
              format="ampm"
              ampm-in-title
              @click:minute="$refs.endTimePickerMenu.save(endTime)"
            ></v-time-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row v-if="offerOrderFlowType === 'EVENT' && needsApproval" class="mt-n6">
        <v-col cols="12">
          <v-btn class="primary rounded" @click="addTime(eventDate, startTime, endTime)">+</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="offerOrderFlowType === 'EVENT' && needsApproval" class="mt-n2">
        <v-col cols="12">
          <v-chip
            v-for="time in displayEventTimes"
            :key="time"
            color="primary lighten-1"
            class="ma-1"
            close
            close-icon="mdi-delete"
            @click:close="removeTime(time)"
          >
            {{ time }}
          </v-chip></v-col
        >
      </v-row>
      <v-row v-if="offerOrderFlowType === 'EVENT' && !needsApproval" class="mt-n2">
        <v-col cols="12">
          <v-chip v-for="time in displayEventTimes" :key="time" color="primary lighten-1" class="ma-1">
            {{ time }}
          </v-chip></v-col
        >
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue from 'vue'
import { hourStringToAP } from '@/utils/hoursstringtoap'

export default Vue.extend({
  name: 'OfferDatesPanel',

  props: {
    startDate: {
      type: String,
      default: () => null,
    },
    expirationDate: {
      type: String,
      default: () => null,
    },
    eventDates: {
      type: Array,
      default: () => null,
    },
    offerOrderFlowType: {
      type: String,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    needsApproval: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    startDatePicker: false,
    offer_start_date: null,
    expirationDatePicker: false,
    expiration_date: null,
    eventDatePicker: false,
    eventDate: null,
    startTime: null,
    startTimePicker: false,
    endTime: null,
    endTimePicker: false,
    eventTimes: [],
  }),

  computed: {
    endTimeAP() {
      return hourStringToAP(this.endTime)
    },
    startTimeAP() {
      return hourStringToAP(this.startTime)
    },
    allowedMinutes() {
      return [0, 15, 30, 45]
    },
    displayEventTimes() {
      return this.eventTimes.map((e) => `${e.event_date} ${e.start_time}-${e.end_time}`)
    },
    startDateFormatted: {
      get: function () {
        return this.formatDate(this.offer_start_date)
      },
      set: function (newValue) {
        if (newValue === null) {
          this.offer_start_date = null
        }
      },
    },
    expirationDateFormatted: {
      get: function () {
        return this.formatDate(this.expiration_date)
      },
      set: function (newValue) {
        if (newValue === null) {
          this.expiration_date = null
        }
      },
    },
  },

  mounted: function () {
    const formattedStartDate = this.formatDate(this.startDate)
    this.offer_start_date = new Date(formattedStartDate).toISOString().substr(0, 10)

    if (this.expirationDate) {
      const formattedExpirationDate = this.formatDate(this.expirationDate)
      this.expiration_date = new Date(formattedExpirationDate).toISOString().substr(0, 10)
    }

    if (this.eventDates) {
      this.eventTimes = this.eventDates.slice(0, this.eventDates.length)
    }
  },

  methods: {
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day.substring(0, 2)}/${year}`
    },

    handleSaveStartDate() {
      this.$emit('save', { offer_start_date: this.offer_start_date })
    },

    clearExpirationDate() {
      this.$emit('save', { expiration_date: null })
    },

    handleSaveExpirationDate() {
      this.$emit('save', { expiration_date: this.expiration_date })
    },
    addTime(eventDate, startTime, endTime) {
      if (!eventDate) {
        this.$toast.error(`No Date Selected.`)
        return
      }
      if (!startTime) {
        this.$toast.error(`No Start Time Selected.`)
        return
      }

      if (!endTime) {
        this.$toast.error(`No End Time Selected.`)
        return
      }

      if (this.eventTimes.find((e) => e.event_date === eventDate && e.start_time === startTime && e.end_time === endTime)) {
        this.$toast.error(`Event Date And Time Already added.`)
        return
      }
      this.eventTimes.push({ event_date: eventDate, start_time: hourStringToAP(startTime), end_time: hourStringToAP(endTime) })
      this.eventDate = null

      this.$emit('save', { event_date_option: this.eventTimes })
    },
    removeTime(time) {
      this.eventTimes = this.eventTimes.filter((e) => time !== `${e.event_date} ${e.start_time}-${e.end_time}`)

      this.$emit('save', { event_date_option: this.eventTimes })
    },
  },
})
</script>
