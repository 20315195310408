import validUrl from 'valid-url'

export default {
  requiredRule: [(v) => !!v || 'Required'],
  nameRules: [(name) => !!name || 'required'],
  urlRules: [(url) => !!validUrl.isWebUri(url) || 'Invaild URL'],
  optionalUrlRules: [(url) => !url || !!validUrl.isWebUri(url) || 'Invaild URL'],
  phoneRules: [(phoneNumber) => /^\(\d{3}\)\s\d{3}-\d{4}$/.test(phoneNumber) || 'Invalid phone number'],
  optionalPhoneRules: [(phoneNumber) => /^$|\(\d{3}\)\s\d{3}-\d{4}$/.test(phoneNumber) || 'Invalid phone number'],
  emailRules: [(email) => !!email || 'E-mail is required', (email) => /^\S+@\S+\.\S+$/.test(email) || 'E-mail must be valid'],
  zipcodeRules: [(zipcode) => !!zipcode || 'Zipcode is required', (zipcode) => /^\d{5}$/.test(zipcode) || 'Zipcode must be valid'],
  latitudeRules: [
    (latitude) => !!latitude || 'Latitude is required',
    (latitude) => /^(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/.test(latitude) || 'Invalid latitude',
  ],
  longitudeRules: [
    (longitude) => !!longitude || 'Longitude is required',
    (longitude) => /^-(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/.test(longitude) || 'Invalid longitude',
  ],
  isIntegerRules: [(v) => v !== null || !isNaN(parseInt(v)) || 'Invalid Number'],
}
