<template>
  <v-dialog :value="true" persistent scrollable max-width="800">
    <v-card>
      <v-card-title class="headline">Website</v-card-title>
      <v-card-text>
        <v-form ref="offerWebMarketsForm">
          <v-row>
            <v-col class="pb-0">
              <label class="subtitle-1">Which website should locals visit to place their order?</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0">
              <v-text-field
                key="offerUrlInput"
                v-model="offer_url"
                :rules="urlRules"
                :validate-on-blur="true"
                placeholder="https://www.google.com"
                type="url"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="handleModalClose">Cancel</v-btn>
        <v-btn :loading="false" color="primary darken-1" text @click="handleModalSave">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import validationRules from '@/utils/validation'

export default Vue.extend({
  name: 'OfferWebMarketsModal',

  props: {
    url: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    offer_url: null,
    urlRules: validationRules.urlRules,
  }),

  mounted: function () {
    this.offer_url = this.url
  },

  methods: {
    handleModalSave() {
      if (!this.$refs.offerWebMarketsForm.validate()) return

      this.$emit('save', { offer_url: this.offer_url })
    },
    handleModalClose() {
      this.$emit('offer-web-markets-modal-close')
    },
  },
})
</script>
