<template>
  <v-dialog :value="true" persistent max-width="800" @keydown.esc="handleModalClose">
    <app-spinner v-if="loading" :spinner-size="80" class="spinner-background" />
    <v-card>
      <v-card-text class="pb-0 pt-8">
        <v-row v-if="isSway" align="center">
          <v-col cols="2"> Sway Offer </v-col>
          <v-col cols="10">
            <SwayOfferSearchField :selected-offer-id="selectedOfferId" :sway-offers="swayOffers" @sway-offer-selected="handleOfferSelected" />
          </v-col>
        </v-row>
        <v-row v-else align="center">
          <v-col cols="2"> <span>Network Offer</span> </v-col>
          <v-col cols="10">
            <NetworkOfferSearchField :selected-offer-id="selectedOfferId" :network-offers="networkOffers" @network-offer-selected="handleOfferSelected" />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="2"> Title </v-col>
          <v-col cols="10"> <v-text-field v-model="listName" outlined label="CodeListName" /></v-col>
        </v-row>
        <v-row v-if="isSway" align="center">
          <v-col cols="2"> Pending Code Template ID </v-col>
          <v-col cols="10"> <v-text-field v-model="pendingCodeTemplateId" outlined label="Email To Send When Pending Codes" /></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('cancel-modal')">Cancel</v-btn>
        <v-btn color="primary darken-1" text @click="$emit('create-code-list', { isSway, selectedOfferId, listName, pendingCodeTemplateId })"> CREATE </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import { mapGetters } from 'vuex'

import SwayOfferSearchField from '@/components/SwayOffers/SwayOfferSearchField'
import NetworkOfferSearchField from '@/components/Offers/NetworkOfferSearchField'

export default Vue.extend({
  name: 'NewCodeListModal',

  components: {
    SwayOfferSearchField,
    NetworkOfferSearchField,
  },
  props: {
    isSway: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    offerSummary: null,
    selectedOfferId: null,
    listName: '',
    pendingCodeTemplateId: null,
  }),

  computed: {
    ...mapGetters('swayoffer', ['loading', 'swayOffers']),
    ...mapGetters('offer', ['loading', 'networkOffers']),
  },

  mounted() {
    this.$store.dispatch('swayoffer/refresh')
    this.$store.dispatch('offer/refresh')
  },

  methods: {
    handleOfferSelected(selectedId) {
      this.selectedOfferId = selectedId
      const selectedOffer = this.isSway ? this.swayOffers.filter((e) => e.id == selectedId) : this.networkOffers.filter((e) => e.id == selectedId)
      this.listName = selectedOffer[0].offer_summary + ' code list'
    },
  },
})
</script>
<style scoped>
.row-height {
  height: 8em;
}
</style>