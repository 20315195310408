<template>
  <v-expansion-panel @click.native.stop>
    <v-expansion-panel-header>Socials</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-n6">
        <v-col cols="12">
          <p class="text-left font-weight-regular caption text--secondary">
            Locals will be asked to include the following hashtags and mentions when sharing their proof.
          </p>
        </v-col>
      </v-row>
      <SocialsForm :hash-tags="hashtags" :instagram-mentions="instagramMentions" :facebook-mentions="facebookMentions" @save="onSocialsFormSaved" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue from 'vue'

import SocialsForm from '@/components/Offers/OfferForm/SocialsForm'

export default Vue.extend({
  name: 'OfferSocialsPanel',

  components: {
    SocialsForm,
  },

  props: {
    hashtags: {
      type: Array,
      default: () => [],
    },
    instagramMentions: {
      type: Array,
      default: () => [],
    },
    facebookMentions: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    onSocialsFormSaved(event) {
      this.$emit('save', { event })
    },
  },
})
</script>
