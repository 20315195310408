<template>
  <v-dialog :value="true" persistent max-width="500">
    <v-card>
      <v-card-title class="headline">Add Location</v-card-title>
      <v-card-text>
        <app-spinner v-if="!googleMapsInitialized" />
        <v-form v-else ref="locationForm" @submit.prevent="return false">
          <v-row>
            <v-col cols="12">
              <VueGoogleAutocomplete
                id="addressSearch"
                ref="vueGoogleAutoComplete"
                class="address-search v-input v-text-field"
                placeholder="Enter a location"
                types="establishment"
                :country="'us'"
                @placechanged="getAddressData"
              />
            </v-col>
          </v-row>

          <v-row v-if="location">
            <v-col cols="12" class="text-center pb-0">
              <img :src="getMapUrl" />
            </v-col>
          </v-row>

          <v-row v-if="addedToOffers.length > 0" class="mb-n6">
            <v-col cols="12">
              <h3 class="font-weight-regular">
                This location will be
                <span class="font-weight-bold">added</span> to the following offers:
              </h3>
              <ul>
                <li v-for="(offer, index) in addedToOffers" :key="index" class="mt-1">{{ offer.offer_summary }}</li>
              </ul>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="$emit('new-location-modal-cancel')">Cancel</v-btn>
        <v-btn
          :loading="locationSaving"
          :disabled="!location || locationSaving"
          color="primary darken-1"
          text
          @click="$emit('new-location-modal-save', location)"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import VueGoogleAutocomplete from 'vue-google-autocomplete'

import { ApiService } from '@/services/index'
import GoogleMapService from '@/services/googleMap.service'

export default Vue.extend({
  name: 'NewLocationModal',

  components: {
    VueGoogleAutocomplete,
  },

  props: {
    locationSaving: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      location: null,
      addedToOffers: [],
      googleMapsInitialized: false,
      addressDetailsLoading: false,
    }
  },

  computed: {
    getMapUrl() {
      const width = this.$vuetify.breakpoint.smAndDown ? 250 : 400
      return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+ec1f56(${this.location.longitude},${this.location.latitude})/${this.location.longitude},${this.location.latitude},11/${width}x150?logo=false&access_token=${process.env.VUE_APP_MAPBOX_API_KEY}`
    },
  },

  mounted: function () {
    GoogleMapService.init().then(() => {
      this.googleMapsInitialized = true
    })
  },

  methods: {
    getAddressData: function (addressData, placeResultData) {
      this.addressDetailsLoading = true

      const address = addressData.street_number && addressData.route ? `${addressData.street_number} ${addressData.route}` : placeResultData.name
      const state = placeResultData.address_components.find((addressComponent) => addressComponent.types.includes('administrative_area_level_1'))

      const systemTags = [
        ...(address ? [address] : []),
        ...(addressData.locality ? [addressData.locality] : []),
        ...(addressData.postal_code ? [addressData.postal_code] : []),
        ...(state ? [state.long_name] : []),
      ]

      ApiService.get('tag/offer/', {
        params: {
          tags: systemTags,
        },
      }).then((response) => {
        this.addedToOffers = response.data.addedToOffers

        this.location = {
          city: addressData.locality,
          state: state ? state.long_name : '',
          address: address,
          zipcode: addressData.postal_code,
          latitude: addressData.latitude,
          longitude: addressData.longitude,
          google_place_id: placeResultData.place_id,
          google_review_url: `http://search.google.com/local/writereview?placeid=${placeResultData.place_id}`,
          system_tags: systemTags,
          custom_tags: [],
        }
      })
    },
  },
})
</script>

<style scoped>
.address-search {
  width: 100%;
  padding: 12px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
}
</style>
