<template>
  <v-expansion-panel :disabled="!panelEnabled" @click.native.stop>
    <v-expansion-panel-header class="pl-10">Social Proof Requirements</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mb-n8">
        <v-col cols="12">
          <v-text-field
            v-model="title"
            :disabled="!panelEnabled"
            outlined
            label="Requirements Title"
            append-icon="mdi-restore"
            @blur="updatePanelData()"
            @click:append="resetTitle()"
            @keydown.enter="$event.target.blur()"
          />
        </v-col>
      </v-row>

      <v-row class="mb-n8">
        <v-col cols="12">
          <v-textarea
            v-model="subtitle"
            :disabled="!panelEnabled"
            outlined
            label="Requirements Subtitle"
            append-icon="mdi-restore"
            rows="3"
            @blur="updatePanelData()"
            @click:append="resetSubtitle()"
            @keydown.enter.prevent="$event.target.blur()"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-list flat class="mt-n1">
            <SwayOfferSteps
              :steps="steps"
              :default-steps="proofRequirementsDataFlowDefaults.instructions"
              :enabled="panelEnabled"
              :include-sub-title="true"
              :include-referral-code="true"
              title="Steps"
              @save="saveSteps"
            />
          </v-list>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="specialNotesTitle"
            :disabled="!panelEnabled"
            outlined
            label="Special Notes Title"
            append-icon="mdi-restore"
            @blur="updatePanelData()"
            @click:append="resetSpecialNotesTitle()"
            @keydown.enter="$event.target.blur()"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-list flat class="mt-n1">
            <legend :class="['v-label theme--light', !panelEnabled ? 'grey--text' : '']" style="font-size: 14px">Special Notes</legend>
            <v-icon class="float-right mt-n5" style="cursor: pointer" @click="resetSpecialNotes()">mdi-restore</v-icon>
            <v-list-item v-if="specialNotes.length === 0">
              <v-list-item-content class="justify-center grey--text">No Special Notes</v-list-item-content>
            </v-list-item>
            <v-list-item v-for="(note, index) in specialNotes" v-else :key="`special-note-${index}`">
              <template #default>
                <v-list-item-action>
                  <v-icon v-if="panelEnabled" color="primary" @click="removeSpecialNote(index)">mdi-playlist-remove</v-icon>
                  <v-icon v-else color="grey">mdi-playlist-remove</v-icon>
                </v-list-item-action>

                <v-list-item-content three-line>
                  <v-list-item-title>
                    {{ note }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-form ref="newSpecialNoteForm">
                  <legend :class="['v-label theme--light mb-1', !panelEnabled ? 'grey--text' : '']" style="font-size: 14px">New Step</legend>
                  <v-sheet :outlined="true" elevation="1" class="pa-4">
                    <v-text-field v-model="newSpecialNote" :disabled="!panelEnabled" :rules="requiredRule" outlined label="Note" />
                    <div class="text-right">
                      <v-btn :disabled="!panelEnabled" :tile="true" @click="addSpecialNote">
                        <v-icon left color="primary">mdi-playlist-plus</v-icon>
                        Add Note
                      </v-btn>
                    </div>
                  </v-sheet>
                </v-form>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          Examples:
          <v-icon class="float-right" style="cursor: pointer" @click="resetExampleImages()">mdi-restore</v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="url in exampleImageUrls" :key="`example-image-container-${url.split('/').pop()}`" align="center">
          <div style="position: relative">
            <v-img :src="url" max-width="100px" />
            <v-btn fab x-small elevation="3" color="primary" class="remove-example-image-button" @click="handleExampleImageRemoved(url)">
              <v-icon color="white">mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <ImageUploadForm
            :edit-mode="panelEnabled"
            :allow-image-deletion="true"
            image-type="proofRequirementExample"
            @image-uploaded="handleExampleImageAdded"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import validationRules from '@/utils/validation'

import ImageUploadForm from '@/components/ImageUploadForm'
import SwayOfferSteps from '@/components/SwayOffers/SwayOfferSteps'

export default Vue.extend({
  name: 'SwayProofRequirementsFlowPanel',

  components: {
    SwayOfferSteps,
    ImageUploadForm,
  },

  props: {
    swayOfferId: {
      type: String,
      required: true,
    },
    panelEnabled: {
      type: Boolean,
      default: false,
    },
    panelData: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    title: null,
    subtitle: null,
    steps: [],
    specialNotesTitle: null,
    specialNotes: [],
    exampleImageUrls: [],
    newSpecialNote: null,
    requiredRule: validationRules.requiredRule,
  }),

  computed: {
    ...mapGetters('swayoffer', ['proofRequirementsDataFlowDefaults']),
  },

  mounted: function () {
    this.title = this.panelData.title || null
    this.subtitle = this.panelData.subtitle || null
    this.steps = this.panelData.steps || []
    this.specialNotesTitle = this.panelData.special_notes_title || null
    this.specialNotes = this.panelData.special_notes || []
    this.exampleImageUrls = this.panelData.example_image_urls || []
  },

  methods: {
    ...mapActions('swayoffer', ['updateSwayOfferFlow']),

    resetTitle() {
      this.title = this.proofRequirementsDataFlowDefaults.title
      this.updatePanelData()
    },

    resetSubtitle() {
      this.subtitle = this.proofRequirementsDataFlowDefaults.subtitle
      this.updatePanelData()
    },

    resetExampleImages() {
      this.exampleImageUrls = [...this.proofRequirementsDataFlowDefaults.example_image_urls]
      this.updatePanelData()
    },

    resetSpecialNotesTitle() {
      this.specialNotesTitle = this.proofRequirementsDataFlowDefaults.special_notes_title
      this.updatePanelData()
    },

    resetSpecialNotes() {
      this.specialNotes = this.proofRequirementsDataFlowDefaults.special_notes
      this.updatePanelData()
    },

    removeSpecialNote(specialNoteIndex) {
      this.specialNotes.splice(specialNoteIndex, 1)
      this.updatePanelData()
    },

    addSpecialNote() {
      if (!this.$refs.newSpecialNoteForm.validate()) return

      this.specialNotes.push(this.newSpecialNote)
      this.updatePanelData()

      this.newSpecialNote = null

      this.$refs.newSpecialNoteForm.resetValidation()
    },

    saveSteps(newSteps) {
      this.steps = [...newSteps]
      this.updatePanelData()
    },

    handleExampleImageAdded(event) {
      this.exampleImageUrls.push(event.image_url)
      this.updatePanelData()
    },

    handleExampleImageRemoved(imageUrl) {
      this.exampleImageUrls.splice(
        this.exampleImageUrls.findIndex((url) => url === imageUrl),
        1
      )
      this.updatePanelData()
    },

    updatePanelData() {
      this.updateSwayOfferFlow({
        swayOfferId: this.swayOfferId,
        swayOfferFlow: {
          proof_requirements_data: {
            ...this.panelData,
            title: this.title,
            subtitle: this.subtitle,
            steps: this.steps,
            special_notes_title: this.specialNotesTitle,
            special_notes: this.specialNotes,
            example_image_urls: this.exampleImageUrls,
          },
        },
      })
    },
  },
})
</script>

<style scoped>
.remove-example-image-button {
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  position: absolute;
}
</style>
