import { ShortUrl } from './ShortUrl'

export enum OfferType {
  LOCAL = 'LOCAL',
  WEB = 'WEB',
}

export enum OfferOrderFlowTypeEnum {
  VISIT = 'VISIT',
  CALL = 'CALL',
  URL = 'URL',
  EVENT = 'EVENT',
}

export interface OfferOrderFlowType {
  type: OfferOrderFlowTypeEnum
  icon: string
  title: string
  text: string
}

export const offerOrderFlowTypes = Object.freeze({
  VISIT: {
    type: OfferOrderFlowTypeEnum.VISIT,
    title: 'In Person',
    icon: 'order-flow-type-visit',
    text: 'Locals will redeem their offer at your physical location',
  } as OfferOrderFlowType,
  EVENT: {
    type: OfferOrderFlowTypeEnum.EVENT,
    title: 'Event',
    icon: 'order-flow-type-event',
    text: 'Locals are invited to show up at a time and location',
  } as OfferOrderFlowType,
  CALL: {
    type: OfferOrderFlowTypeEnum.CALL,
    title: 'Schedule',
    icon: 'order-flow-type-call',
    text: 'Locals will schedule an appointment',
  } as OfferOrderFlowType,
  URL: {
    type: OfferOrderFlowTypeEnum.URL,
    title: 'Web',
    icon: 'order-flow-type-url',
    text: 'Place online order',
  } as OfferOrderFlowType,
})

export enum OfferStatus {
  ACTIVE = 'ACTIVE',
  SCHEDULED = 'SCHEDULED',
  COMPLETE = 'COMPLETE',
  NEEDS_APPROVAL = 'NEEDS_APPROVAL',
  FULL = 'FULL',
}

export interface EventDate {
  event_date: Date
  start_time: string
  end_time: string
}

export interface Offer {
  id: string
  created_at: Date
  updated_at: Date
  offer_start_date: Date
  expiration_date: Date
  offer_type: OfferType
  offer_order_flow_type: OfferOrderFlowTypeEnum
  offer_status: OfferStatus
  business_id: string
  creator_app_user_id: string
  offer_summary: string
  offer_summary_id: string
  offer_image_url: string
  offers_to_grant_per_month: number
  number_tasks_required: number
  display_priority: number | null
  coupon_code: string | null
  coupon_image_url: string | null
  hashtags: Array<string>
  tasks: Array<string>
  instagram_mentions: Array<string>
  facebook_mentions: Array<string>
  urls: Array<ShortUrl>
  event_date_option: Array<EventDate> | null
}

export interface OfferBase {
  offer_type: OfferType
  offer_order_flow_type: OfferOrderFlowTypeEnum
  offer_summary: string
  offer_summary_id: string | null
  offer_image_url: string | null
  offer_url: string | null
  offer_description_url: string | null
  offer_phone: string | null
  hashtags: Array<string>
  instagram_mentions: Array<string>
  facebook_mentions: Array<string>
}

export interface RefreshOffer {
  id: string
  offer_status: OfferStatus
  short_url_id?: string
  expiration_date?: Date
}
