interface AppState {
  appOutOfDate: boolean
  appProgressBarVisible: boolean
}

const state: AppState = {
  appOutOfDate: false,
  appProgressBarVisible: false,
}

const getters = {
  appOutOfDate: () => state.appOutOfDate,
  appVersion: () => process.env.APP_VERSION,
  appEnvironment: () => ({
    environment: process.env.VUE_APP_ENVIRONMENT,
    bannerVisible: process.env.VUE_APP_ENVIRONMENT === 'Staging',
  }),
  appProgressBarVisible: () => state.appProgressBarVisible,
}

const actions = {
  setAppOutOfDate({ commit }, { appOutOfDate }: { appOutOfDate: boolean }) {
    commit('appOutOfDate', appOutOfDate)
  },
  setAppProgressBarVisibility({ commit }, { visible }: { visible: boolean }) {
    commit('appProgressBarVisible', visible)
  },
}

const mutations = {
  appOutOfDate(state: AppState, appOutOfDate: boolean) {
    state.appOutOfDate = appOutOfDate
  },
  appProgressBarVisible(state: AppState, visible: boolean) {
    state.appProgressBarVisible = visible
  },
}

export const app = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
