<template>
  <v-container fluid>
    <app-spinner v-if="loading" :spinner-size="80" class="spinner-background" />
    <div v-else fluid>
      <v-row class="ma-0" align="center" justify="center">
        <v-col cols="2">
          <v-select v-model="selectedType" :loading="loading" :disabled="loading" :items="availableTypes" label="Report Type" />
        </v-col>
        <v-col :cols="selectedType === 'File' ? 4 : 8">
          <v-text-field v-model="newListName" persistent-hint hint="List Name" />
        </v-col>
        <v-col v-if="selectedType === 'File'" cols="4" align="center" justify="center">
          <v-file-input v-model="newListFile" show-size persistent-hint hint="File (Max 50MB)" />
        </v-col>
        <v-col cols="1">
          <v-btn color="primary" @click="generateList()"><v-icon>mdi-pen-plus</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-row v-if="lastUploadErrors">
        <v-col cols="12">
          <p v-for="(error, idx) in lastUploadErrors" :key="idx" class="ma-0 pa-0 text-caption">{{ error }}</p>
          <p v-if="lastUploadErrors.length === 50" color="primary">Only showing first 100</p>
        </v-col>
      </v-row>
      <hr />

      <v-row v-if="lists && lists.length > 0" class="ma-0" align="center" justify="center">
        <v-col cols="12">
          <div>
            <v-row>
              <v-col cols="2" class="title">ID</v-col>
              <v-col cols="2" class="title">Created</v-col>
              <v-col cols="3" class="title">Name</v-col>
              <v-col cols="1" class="title">Type</v-col>
              <v-col cols="2" class="title">Status</v-col>
              <v-col cols="1" class="title"></v-col>
            </v-row>
            <v-row v-for="list in lists" :key="list.id" class="mt-0">
              <v-col cols="2" class="cell-ellipsis">
                <v-btn x-small text class="py-4" @click="copyId(list.id)">
                  <v-icon style="cursor: pointer">mdi-content-copy</v-icon>
                </v-btn>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ list.id }}</span>
                  </template>
                  <span>{{ list.id }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="2" class="cell-ellipsis">{{ list.created_at | prettyDateTime }}</v-col>
              <v-col cols="3" class="cell-ellipsis">{{ list.name }}</v-col>
              <v-col cols="1" class="cell-ellipsis">{{ list.mailing_list_type | prettyString }}</v-col>
              <v-col cols="2" class="cell-ellipsis">{{ list.mailing_list_status | prettyString }}</v-col>
              <v-col cols="1" class="cell-ellipsis">
                <v-btn color="primary" @click.stop="archiveList(list.id)"><v-icon>mdi-delete</v-icon></v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" align="center" justify="center">
          <h1 class="mt-12">No Lists Found. You get to create the first!</h1>
          <v-icon x-large color="primary" class="mt-5">mdi-arm-flex</v-icon>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { ApiService } from '@/services/index'
import dayjs from 'dayjs'
import { copyToClipboard } from '@/mixins/copyToClipboard'

export default {
  name: 'SimpleReports',

  metaInfo: {
    title: 'Simple Reports',
  },
  mixins: [copyToClipboard],
  data: () => ({
    loading: true,
    newListName: null,
    newListFile: null,
    lists: [],
    selectedType: 'File',
    lastUploadErrors: [],
  }),
  computed: {
    availableTypes() {
      return ['File', 'Survey']
    },
  },
  watch: {
    newListFile: function (evt) {
      if (!this.newListName || this.newListName.trim.length === 0) {
        if (this.selectedType === 'File') {
          this.newListName = `${this.newListFile.name.replace('.csv', '')}_${dayjs().format('YYYY_MMM_DD')}`
        }
      }
    },
    selectedType: function (evt) {
      if (this.selectedType === 'Survey') {
        this.newListName = `Survey_${dayjs().format('MMM_DD_YYYY')}`
      } else {
        if (!this.newListFile) this.newListName = ``
      }
    },
  },
  mounted() {
    this.loadListData()
  },

  methods: {
    copyId(Id) {
      this.copyToClipboard(`${Id}`)
      this.$toast.success('Copied to clipboard!')
    },
    generateList() {
      if (!this.selectedType || !this.newListName) {
        this.$toast.error('You must enter in a list name')
        return
      }
      this.lastUploadErrors = []
      ApiService.post(`mailingList/new/${this.selectedType}/${this.newListName}`).then((r) => {
        this.loadListData()
        ApiService.upload(`mailingList/attachFile/${r.data}`, this.newListFile).then((res) => {
          if (res.data.errors) {
            this.lastUploadErrors = res.data.errors
          }
          this.$toast.success(`list created ${res.data.message}`)
        })
      })
    },
    loadListData() {
      this.loading = true
      ApiService.get('mailingList/').then((response) => {
        this.lists = response.data
        if (response.data.error) this.$toast.error(`Error loading mailing lists ${JSON.stringify(response.data.error)}`)
        this.loading = false
      })
    },
    archiveList(id) {
      ApiService.delete(`mailingList/${id}`).then((response) => {
        if (response.data.error) this.$toast.error(`${JSON.stringify(response.data.error)}`)
        else this.$toast.success(`List Deleted`)
        this.loadListData()
      })
    },
  },
}
</script>

<style scoped>
.cell-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
