<template>
  <v-dialog :value="true" persistent max-width="500" scrollable>
    <v-card>
      <v-card-title class="headline">Add Location Manually</v-card-title>
      <v-card-text style="max-height: 500px">
        <v-form ref="manualLocationForm">
          <v-row class="mt-1">
            <v-col cols="12">
              <v-text-field v-model="googlePlaceId" outlined label="Google Place ID" :rules="requiredRule" />
            </v-col>
          </v-row>

          <v-row class="mt-n4">
            <v-col cols="6">
              <v-text-field v-model="street" outlined label="Street" :rules="requiredRule" />
            </v-col>

            <v-col cols="6">
              <v-text-field v-model="city" outlined label="City" :rules="requiredRule" />
            </v-col>
          </v-row>

          <v-row class="mt-n4">
            <v-col cols="6">
              <v-select v-model="state" :items="states" outlined label="State" :rules="requiredRule"></v-select>
            </v-col>

            <v-col cols="6">
              <v-text-field v-model="zipcode" outlined label="Zipcode" type="number" :rules="zipcodeRule" />
            </v-col>
          </v-row>

          <v-row class="mt-n4">
            <v-col cols="6">
              <v-text-field v-model="latitude" outlined label="Latitude" :rules="latitudeRules" />
            </v-col>

            <v-col cols="6">
              <v-text-field v-model="longitude" outlined label="Longitude" :rules="longitudeRules" />
            </v-col>
          </v-row>

          <v-row v-if="latitude && longitude" class="mt-n4">
            <v-col cols="12" class="text-center pb-0">
              <img :src="getMapUrl" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="$emit('manual-location-modal-cancel')">Cancel</v-btn>
        <v-btn :loading="locationSaving" :disabled="locationSaving" color="primary darken-1" text @click="handleModalSave()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import validationRules from '@/utils/validation'

export default Vue.extend({
  name: 'ManualLocationModal',

  props: {
    locationSaving: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      street: '',
      city: '',
      state: '',
      zipcode: '',
      latitude: '',
      longitude: '',
      googlePlaceId: '',
      requiredRule: [(v) => !!v || 'Required'],
      zipcodeRule: validationRules.zipcodeRules,
      latitudeRules: validationRules.latitudeRules,
      longitudeRules: validationRules.longitudeRules,
      states: [
        'Alabama',
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'Florida',
        'Georgia',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming',
      ],
    }
  },

  computed: {
    getMapUrl() {
      const width = this.$vuetify.breakpoint.smAndDown ? 250 : 400
      return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+ec1f56(${this.longitude},${this.latitude})/${this.longitude},${this.latitude},11/${width}x150?logo=false&access_token=${process.env.VUE_APP_MAPBOX_API_KEY}`
    },
  },

  methods: {
    handleModalSave() {
      if (!this.$refs.manualLocationForm.validate()) return

      const location = {
        city: this.city.trim(),
        state: this.state.trim(),
        address: this.street.trim(),
        zipcode: this.zipcode.trim(),
        latitude: this.latitude.trim(),
        longitude: this.longitude.trim(),
        google_place_id: this.googlePlaceId.trim(),
        system_tags: [this.street.trim(), this.city.trim(), this.state.trim(), this.zipcode.trim()],
        custom_tags: [],
        google_review_url: '',
      }

      this.$emit('manual-location-modal-save', location)
    },
  },
})
</script>
