// @ts-nocheck

import Vue from 'vue'
import { ApiService } from '@/services'

import { Offer, RefreshOffer } from '@/models/Offer'

interface OfferState {
  loading: boolean
  offerMutationLoading: boolean
  offers: Offer[]
}

const state: OfferState = {
  loading: false,
  offerMutationLoading: false,
  offers: [],
}

const getters = {
  loading: () => state.loading,
  networkOffers: () => state.offers,
  offerMutationLoading: () => state.offerMutationLoading,
}

const actions = {
  async createOffer({ commit, dispatch }, { offer }: { offer: OfferBase }) {
    commit('offerMutationLoading', true)
    dispatch('app/setAppProgressBarVisibility', { visible: true }, { root: true })

    return await ApiService.post('offer/', { offer }).then((response) => {
      commit('refresh', [...state.offers, response.data])

      commit('offerMutationLoading', false)
      dispatch('app/setAppProgressBarVisibility', { visible: false }, { root: true })
      dispatch('business/getCurrentBusiness', null, { root: true })

      Vue.prototype.$toast.success('Offer Created!')
      return response.data.id
    })
  },
  updateOffer({ commit, dispatch }, { offerData }: Offer) {
    dispatch('app/setAppProgressBarVisibility', { visible: true }, { root: true })

    ApiService.put(`offer/${offerData.id}`, {
      offer: offerData,
    }).then((response) => {
      commit('updateOffer', { ...offerData, offer_status: response.data.offer_status, offer_summary: response.data.offer_summary })
      dispatch('app/setAppProgressBarVisibility', { visible: false }, { root: true })

      Vue.prototype.$toast.success('Offer Updated!')
    })
  },
  updateLocationForOffer({ commit, dispatch }, { offerId, locationTagIds }: { offerId: number; locationTagIds: Array<String> }) {
    dispatch('app/setAppProgressBarVisibility', { visible: true }, { root: true })

    ApiService.put(`offer/updateLocations/${offerId}`, {
      locationTagIds,
    }).then((response) => {
      dispatch('app/setAppProgressBarVisibility', { visible: false }, { root: true })

      Vue.prototype.$toast.success('Offer Updated!')
    })
  },
  refresh({ commit }) {
    commit('loading', true)
    ApiService.get('offer').then((response) => {
      commit('refresh', response.data)
      commit('loading', false)
    })
  },
  refreshOffer({ commit }, { refreshOfferData }: RefreshOffer) {
    commit('refreshOffer', refreshOfferData)
  },
}

const mutations = {
  loading(state: OfferState, payload: boolean) {
    state.loading = payload
  },
  offerMutationLoading(state: OfferState, payload: boolean) {
    state.offerMutationLoading = payload
  },
  updateOffer(state: OfferState, payload: Offer) {
    Vue.set(
      state.offers,
      state.offers.findIndex((offer) => offer.id === payload.id),
      payload
    )
  },
  refreshOffer(state: OfferState, payload: RefreshOffer) {
    const offerIndex = state.offers.findIndex((offer) => offer.id === payload.id)

    Vue.set(state.offers, offerIndex, { ...state.offers[offerIndex], ...payload })
  },
  refresh(state: OfferState, payload: [Offer[]]) {
    state.offers = payload
  },
}

export const offer = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
