var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',{attrs:{"disabled":_vm.disabled},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('v-expansion-panel-header',{staticClass:"pl-10"},[_vm._v("Social Proof Tags & Mentions")]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{class:['v-label theme--light', _vm.disabled ? 'grey--text' : ''],staticStyle:{"font-size":"14px"}},[_vm._v(" Customers will be asked to include the following hashtags and mentions when sharing their proof. ")])])],1),_c('v-row',{staticClass:"mt-n6 mb-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{ref:"hashtags",attrs:{"items":[],"append-icon":null,"disabled":_vm.disabled,"label":"Hashtags","flat":"","chips":"","outlined":"","multiple":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return _vm.$refs.hashtags.blur()},"change":function($event){return _vm.handleSaveHashtags()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"color":((_vm.disabled ? 'light-grey' : 'primary') + " lighten-1")}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(_vm.formatHashtag(item)))])])]}}]),model:{value:(_vm.hash_tags),callback:function ($$v) {_vm.hash_tags=$$v},expression:"hash_tags"}})],1)],1),_vm._l((_vm.socialPostTaskTypes.concat( _vm.reviewTaskTypes)),function(taskType,index){return _c('v-row',{key:index,staticClass:"task-type-row",attrs:{"align":"center"}},[_c('v-col',{staticClass:"pa-0 mt-n2",attrs:{"cols":"2","align":"center"}},[_c('v-img',{class:['p-0 d-inline-flex', taskType.enabled ? '' : 'disabled'],attrs:{"src":taskType.task.icon,"height":"24","width":"24"}}),_c('v-switch',{staticClass:"task-type-switch",attrs:{"disabled":_vm.disabled,"color":_vm.disabled ? 'grey' : 'primary',"inset":""},on:{"change":_vm.handleToggleTaskType},model:{value:(taskType.enabled),callback:function ($$v) {_vm.$set(taskType, "enabled", $$v)},expression:"taskType.enabled"}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(taskType.mentions)?_c('v-combobox',{attrs:{"items":[],"append-icon":null,"disabled":_vm.disabled || !taskType.enabled,"label":"@ Mentions","append-outer-icon":"mdi-content-copy","flat":"","chips":"","outlined":"","multiple":"","hide-details":""},on:{"change":function($event){return _vm.handleSaveMentions(taskType)},"click:append-outer":function($event){return _vm.copyMentions(taskType.mentions)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"disabled":!taskType.enabled,"color":((_vm.disabled ? 'light-grey' : 'primary') + " lighten-1")}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(_vm.formatMention(item)))])])]}}],null,true),model:{value:(taskType.mentions),callback:function ($$v) {_vm.$set(taskType, "mentions", $$v)},expression:"taskType.mentions"}}):_c('v-text-field',{attrs:{"disabled":_vm.disabled || !taskType.enabled,"rules":_vm.optionalUrlRules,"flat":"","outlined":"","hide-details":"","label":"Review Url"},on:{"change":function($event){return _vm.handleSaveReviewUrl(taskType)}},model:{value:(taskType.url),callback:function ($$v) {_vm.$set(taskType, "url", $$v)},expression:"taskType.url"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"disabled":_vm.disabled || !taskType.enabled,"flat":"","outlined":"","hide-details":"","rows":2,"label":("Sample " + (taskType.task.verb))},on:{"blur":function($event){return _vm.handleSaveSampleProof(taskType)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return $event.target.blur()}},model:{value:(taskType.proofSample),callback:function ($$v) {_vm.$set(taskType, "proofSample", $$v)},expression:"taskType.proofSample"}})],1)],1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }