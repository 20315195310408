<template>
  <div>
    <h3>Social Media Settings</h3>
    <div class="ml-2">
      <h4>Offer Defaults</h4>

      <v-row>
        <v-col>
          These are used as the defaults when you create a new offer. You can change these after you create the new offer if you're running a special promotion.
        </v-col>
      </v-row>
      <v-form ref="businessSettingsForm">
        <v-row class="mt-2">
          <v-col cols="12">
            <v-combobox
              ref="hashtags"
              v-model="settings.default_hashtags"
              :items="[]"
              label="Default Hashtags"
              hint="Locals will be required to use these hashtags in their social media posts. These tags will be used by default in your offers."
              flat
              chips
              outlined
              multiple
              @keydown.space="$refs.hashtags.blur()"
            >
              <template #selection="{ attrs, item, selected }">
                <v-chip v-bind="attrs" :input-value="selected" color="primary lighten-1">
                  <strong>{{ formatHashtag(item) }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>

        <v-row class="mt-n2">
          <v-col cols="12">
            <v-combobox
              v-model="settings.default_instagram_mentions"
              :items="[]"
              label="Instagram Mentions"
              hint="Locals will be asked to use these Instagram mentions in their social media posts. This setting will be used by default in your offers."
              flat
              chips
              outlined
              multiple
            >
              <template #selection="{ attrs, item, selected }">
                <v-chip v-bind="attrs" :input-value="selected" color="primary lighten-1">
                  <strong>{{ formatMention(item) }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>

        <v-row class="mt-n2">
          <v-col cols="12">
            <v-combobox
              v-model="settings.default_facebook_mentions"
              :items="[]"
              label="Facebook Mentions"
              hint="Locals will be asked to use these Facebook mentions in their social media posts. This setting will be used by default in your offers."
              flat
              chips
              outlined
              multiple
            >
              <template #selection="{ attrs, item, selected }">
                <v-chip v-bind="attrs" :input-value="selected" color="primary lighten-1">
                  <strong>{{ formatMention(item) }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>

        <h4>Facebook Review Helper</h4>
        <v-row>
          <v-col>This is used in the app to direct users to your business facebook review page. </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="settings.facebook_review_url" outlined label="Facebook Review Url" :rules="[facebookReviewUrlRule]" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn :loading="loading" color="primary" @click="handleSaveButtonClicked">Save</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import validUrl from 'valid-url'

export default Vue.extend({
  name: 'BusinessSettings',

  props: {
    settings: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    loading: false,
    facebookReviewUrlRule: (v) => {
      if (!v || v.length === 0) return true
      if (validUrl.isWebUri(v) && v.includes('facebook.com')) return true
      return 'URL must be a valid Facebook Review URL'
    },
  }),

  methods: {
    ...mapActions('settings', ['updateBusinessSocialSettings']),
    async handleSaveButtonClicked() {
      if (!this.$refs.businessSettingsForm.validate()) {
        this.$toast.error('Validation failed please check your entries')
        return
      }

      this.loading = true

      const businessSocialSettings = {
        default_instagram_mentions: this.settings.default_instagram_mentions,
        default_facebook_mentions: this.settings.default_facebook_mentions,
        default_hashtags: this.settings.default_hashtags,
        facebook_review_url: this.settings.facebook_review_url,
      }

      await this.updateBusinessSocialSettings({ businessSocialSettings: { ...businessSocialSettings } })

      this.loading = false
      this.$toast.success('Business Settings Updated')
    },
    formatHashtag(rawHashtag) {
      const hashtag = rawHashtag.trim()
      return hashtag.startsWith('#') ? hashtag : `#${hashtag}`
    },

    formatMention(rawMention) {
      const mention = rawMention.trim()
      return mention.startsWith('@') ? mention : `@${mention}`
    },
  },
})
</script>
