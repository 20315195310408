<template>
  <div id="couponCodes">
    <NewCodeListModal v-if="showNewSwayListModal" :is-sway="true" @create-code-list="createCodeList" @cancel-modal="showNewSwayListModal = false" />
    <NewCodeListModal v-if="showNewNetworkListModal" :is-sway="false" @create-code-list="createCodeList" @cancel-modal="showNewNetworkListModal = false" />
    <v-container>
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="2" align="center" justify="center"> </v-col>

            <v-col cols="4" align="center" justify="center">
              <v-btn color="primary" @click="showNewNetworkListModal = true"> Add Network List </v-btn>
            </v-col>
            <v-col cols="4" align="center" justify="center">
              <v-btn color="primary" @click="showNewSwayListModal = true"> Add Sway List </v-btn>
            </v-col>
            <v-col cols="2" align="center" justify="center"> </v-col>
          </v-row>

          <app-spinner v-if="loading || loadingSway" />

          <div v-else>
            <SwayCodeList />
            <NetworkCodeList class="mt-4" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from 'vue'

import { mapGetters } from 'vuex'
import NewCodeListModal from '@/components/CodeList/NewCodeListModal'
import NetworkCodeList from '@/components/CodeList/NetworkCodeList'
import SwayCodeList from '@/components/CodeList/SwayCodeList'
import { ApiService } from '../../services'

export default Vue.extend({
  name: 'CouponCodes',

  metaInfo: {
    title: 'Coupon Codes',
  },
  components: {
    NewCodeListModal,
    NetworkCodeList,
    SwayCodeList,
  },
  data: () => ({
    showNewNetworkListModal: false,
    showNewSwayListModal: false,
  }),
  computed: {
    ...mapGetters('couponCodes', ['loading']),
    ...mapGetters('couponCodes', ['loadingSway']),
  },

  mounted() {
    this.$store.dispatch('couponCodes/refresh')
  },
  methods: {
    createNetworkCodeList: () => {
      showNewNetworkListModal = false
    },
    createCodeList({ isSway, selectedOfferId, listName, pendingCodeTemplateId }) {
      const cleanTemplateId = pendingCodeTemplateId.trim() || ''
      ApiService.post(`/coupon/codeList/${isSway ? 'sway' : 'network'}`, {
        selectedOfferId,
        listName,
        pendingCodeTemplateId: cleanTemplateId,
      }).then((r) => {
        if (r.data.error) {
          this.$toast.error(r.data.error)
          return
        }
        this.$toast.success(`Code List created ${listName}`)
        this.$store.dispatch('couponCodes/refresh')
      })
      if (isSway) {
        this.showNewSwayListModal = false
      } else {
        this.showNewNetworkListModal = false
      }
    },
  },
})
</script>

<style lang="scss" scoped>
#couponCodes {
  width: 100%;
}
</style>
