<template>
  <v-expansion-panel @click.native.stop>
    <v-expansion-panel-header>Locations Included</v-expansion-panel-header>

    <v-expansion-panel-content> <LocationsQuickSummary :offer-id="offerId" @handle-update-locations="handleUpdateLocations" /> </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue from 'vue'
import LocationsQuickSummary from '@/components/Offers/OfferForm/LocationsQuickSummary'

export default Vue.extend({
  name: 'OfferLocationsPanel',
  components: {
    LocationsQuickSummary,
  },

  props: {
    offerId: { type: String, required: true },
  },
  methods: {
    handleUpdateLocations(updateData) {
      this.$emit('handle-update-locations', updateData)
      this.showLocationsModal = false
    },
  },
})
</script>
