<template>
  <BaseOfferPanel
    offer-type-icon="ad-icon"
    :offer-type="offer.offer_type"
    :offer-image-url="offer.offer_image_url"
    :offer-summary="offer.offer_summary"
    :offer-start-date="offer.offer_start_date"
    :offer-expiration-date="offer.expiration_date"
  >
    <template #offerPreview>
      <NetworkOfferPreview :offer="offer" />
    </template>

    <template #offerControls>
      <OfferControls :offer-id="offer.id" :offer-status="offer.offer_status" />
    </template>

    <template #formBase>
      <OfferFormBase :offer="offer" />
    </template>
  </BaseOfferPanel>
</template>

<script>
import Vue from 'vue'

import OfferFormBase from '@/components/Offers/OfferForm/OfferFormBase'
import OfferControls from '@/components/Offers/OfferForm/OfferControls'
import BaseOfferPanel from '@/components/Offers/OfferPanels/BaseOfferPanel'

import NetworkOfferPreview from '@/components/Offers/OfferForm/NetworkOfferPreview'

export default Vue.extend({
  name: 'BaseNetworkOfferPanel',

  components: {
    BaseOfferPanel,
    OfferFormBase,
    OfferControls,
    NetworkOfferPreview,
  },

  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
})
</script>
