<template>
  <div v-if="isAdmin" class="offer-controls">
    <v-btn :loading="actionLoading" small @click.stop="toggleOffer()">{{ actionText }}</v-btn>
    <v-btn :loading="copyOfferLoading" small @click.stop="copyOffer()"><v-icon small class="mr-1">mdi-content-copy</v-icon> Copy</v-btn>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'SwayOfferControls',

  props: {
    swayOffer: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    actionLoading: false,
    copyOfferLoading: false,
  }),

  computed: {
    ...mapGetters('account', ['isAdmin']),
    actionText() {
      return this.swayOffer.expired ? 'Start' : 'Stop'
    },
  },

  methods: {
    ...mapActions('swayoffer', ['copySwayOffer', 'updateSwayOffer']),
    copyOffer() {
      this.copyOfferLoading = true
      this.copySwayOffer({
        swayOfferId: this.swayOffer.id,
      }).then(() => {
        this.copyOfferLoading = false
        this.$toast.success('Offer copied!')
      })
    },
    toggleOffer() {
      this.actionLoading = true
      this.updateSwayOffer({
        swayOffer: { ...this.swayOffer, expired: !this.swayOffer.expired },
      }).then((this.actionLoading = false))
    },
  },
})
</script>

<style scoped>
.offer-controls {
  display: flex;
  margin-bottom: 14px;
  flex-direction: row;
  justify-content: center;
}

.offer-controls > button {
  margin-left: 5px;
  margin-right: 5px;
}
</style>
