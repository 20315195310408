import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueApexCharts from 'vue-apexcharts'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { ApiService, TokenService } from './services/index'
import './filters'

Vue.use(VueMeta)
Vue.use(VueApexCharts)

Vue.config.productionTip = false

Vue.component('AppSpinner', () => import('@/components/AppSpinner.vue'))

ApiService.init(process.env.VUE_APP_DIVOC_API_URL)

if (TokenService.getToken()) {
  ApiService.setAuthHeader()
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
