<template>
  <div id="timeline">
    <v-row>
      <v-col cols="12">
        <h1 class="welcome-header"><span class="font-weight-regular">Welcome,</span> {{ entity.title }}.</h1>
      </v-col>
    </v-row>

    <v-row v-if="voucherActivityLoading" class="mt-0">
      <v-col cols="12" class="pt-0">
        <app-spinner :spinner-size="40" class="mt-3" />
      </v-col>
    </v-row>

    <div v-else-if="proofCount > 0">
      <p class="welcome-text mt-4 mb-0">
        You've been a valued customer since <strong>{{ entity.created_at | prettyDate }}</strong> and you've seen <strong>{{ voucherCount }}</strong> customers
        come into your locations!
      </p>

      <v-row id="timeline-dashboard" class="mt-5">
        <v-col id="timeline-calendar-col" class="px-lg-8" md="4" sm="12">
          <h5 class="color light-gray text-uppercase">Calendar</h5>
          <v-date-picker v-model="picker" no-title full-width header-color="#fff" color="#539FDB" :events="[]" @change="setSelectedDate" />
        </v-col>
        <v-col id="timeline-events-col" class="px-lg-8" md="8" sm="12">
          <h5 class="color light-gray text-uppercase">Events</h5>

          <ProofCardList />
        </v-col>
      </v-row>
    </div>

    <template v-else>
      <div v-if="isClientLite">
        <v-row class="mt-6">
          <v-col class="text-h5 font-weight-bold text-center">Your account has been created!</v-col>
        </v-row>
        <v-row>
          <v-col cols="10" offset="1" class="text-center">
            Now that your account has been created, it's time to build a campaign that you can share with your customers. We've prepared a video that will help
            you get started.
          </v-col>
        </v-row>
      </div>

      <v-row class="video-row">
        <v-col class="col-md-8 pa-0">
          <div class="embed-container">
            <iframe
              :src="isClientLite ? 'https://player.vimeo.com/video/670951729' : 'https://player.vimeo.com/video/549569755'"
              frameborder="0"
              allowFullScreen
            />
          </div>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import dayjs from 'dayjs'

import { ApiService } from '@/services/index'

import ProofCardList from '../../components/ProofCardList/ProofCardList'

export default {
  name: 'Timeline',

  metaInfo: {
    title: 'Timeline',
  },

  components: {
    ProofCardList,
  },

  data() {
    return {
      proofCount: 0,
      voucherCount: 0,
      voucherActivityLoading: true,
      picker: null,
      flicker: [],
    }
  },

  computed: {
    ...mapGetters('business', ['entity']),
    ...mapGetters('account', ['isClientLite']),
    ...mapGetters('timeline', ['stickyDate', 'proofs', 'loading']),

    prettyDate() {
      const date = this.picker

      return dayjs(date).format('MMMM DD, YYYY')
    },
  },

  watch: {
    stickyDate() {
      const stickyDate = dayjs(this.stickyDate).format('YYYY-MM-DD')
      this.picker = stickyDate
    },
  },

  created() {
    ApiService.get('businesses/voucherActivity').then((response) => {
      this.proofCount = response.data.proof_count
      this.voucherCount = response.data.voucher_count
      this.voucherActivityLoading = false
    })
  },

  methods: {
    ...mapActions('timeline', ['refresh']),

    setSelectedDate() {
      this.flicker.push(dayjs(this.picker).valueOf())

      if (this.flicker.length > 2) {
        this.flicker.shift()
      }

      if (this.flicker[0] !== this.flicker[1]) {
        this.refresh({ selectedDate: this.picker })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#timeline {
  width: 100%;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-row {
  margin: 10px auto;
  margin-top: 18px;
  justify-content: center;
}

@media (min-width: 960px) {
  #timeline-calendar-col {
    order: 2;
  }
  #timeline-events-col {
    order: 1;
  }
}
</style>
