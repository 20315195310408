<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template #default="{ open }">
        <v-row no-gutters>
          <v-col v-if="!open" :cols="$vuetify.breakpoint.xsOnly ? 3 : 1">
            <v-img v-if="offerImageUrl" :src="offerImageUrl" :eager="true" contain class="rounded-lg full-height offer-image" />
          </v-col>
          <v-col :cols="open ? 12 : $vuetify.breakpoint.xsOnly ? 9 : 11" :class="open ? '' : 'pl-4'">
            <div>{{ offerSummary }}</div>
            <div v-if="!open" class="caption font-italic font-weight-light text--secondary mt-1 mb-n1">
              <img v-if="offerTypeIcon" width="26" class="pr-1 mb-n1" :src="require(`@/assets/images/${offerTypeIcon}.png`)" />
              <v-chip v-if="offerType" x-small>{{ offerType | prettyString }}</v-chip>
              <div class="d-block">
                <template v-if="offerStartDate">{{ offerStartDate | prettyDate }}</template>
                <template v-if="offerStartDate && offerExpirationDate"> - </template>
                <template v-if="offerExpirationDate">{{ offerExpirationDate | prettyDate }}</template>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
    </v-expansion-panel-header>

    <v-expansion-panel-content class="py-4">
      <v-row>
        <v-col id="previewContainer" :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="text-center">
          <slot name="offerPreview">Preview Coming Soon</slot>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 1" class="text-center mt-0">
          <v-divider :vertical="!$vuetify.breakpoint.smAndDown" class="mt-0" />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 5">
          <slot name="offerControls" />
          <slot name="formBase" />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'BaseOfferPanel',

  props: {
    offerSummary: {
      type: String,
      required: true,
    },
    offerImageUrl: {
      type: String,
      default: null,
    },
    offerStartDate: {
      type: String || Date,
      default: null,
    },
    offerExpirationDate: {
      type: String || Date,
      default: null,
    },
    offerType: {
      type: String,
      default: null,
    },
    offerTypeIcon: {
      type: String,
      default: null,
    },
  },
})
</script>

<style scoped>
.offer-image >>> .v-responsive__content {
  width: 100% !important;
}
</style>
