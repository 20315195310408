import Vue from 'vue'
import Vuex from 'vuex'

// Store Modules
import {
  app,
  account,
  auth,
  business,
  helpers,
  location,
  timeline,
  ui,
  offer,
  swayoffer,
  user,
  socialProof,
  settings,
  analytics,
  heap,
  couponCodes,
  encTracking,
} from './modules/index'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    account,
    auth,
    business,
    helpers,
    location,
    timeline,
    ui,
    offer,
    swayoffer,
    user,
    socialProof,
    settings,
    analytics,
    heap,
    couponCodes,
    encTracking,
  },
})
