<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ getFormMode }} Social Proof</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="socialProofForm">
          <v-row class="mt-n4">
            <v-col cols="12">
              <v-select
                :items="proofTypeOptions"
                :return-object="true"
                :value="socialProof.type"
                :rules="[formValidation.valueRequiredRule]"
                outlined
                label="Type"
                @change="onTypeSelected"
              />
            </v-col>
          </v-row>
          <v-row v-if="isReviewType" class="mt-n6">
            <v-col cols="12">
              <h4 :class="`font-weight-medium ${formValidation.proofRatingError ? 'error--text' : ''}`">Rating</h4>
            </v-col>
          </v-row>
          <v-row v-if="isReviewType" class="mt-n6">
            <v-col cols="12">
              <v-rating
                v-model="socialProof.rating"
                :error="formValidation.proofRatingError"
                :error-messages="formValidation.proofRatingError ? 'Required' : ''"
                color="primary"
                background-color="grey darken-1"
                empty-icon="$ratingFull"
                half-increments
                hover
                dense
                @input="formValidation.proofRatingError = false"
              />
              <span v-if="formValidation.proofRatingError" class="v-messages theme--light error--text">Required</span>
            </v-col>
          </v-row>
          <v-row v-if="isReviewType" class="mt-n3">
            <v-col cols="12">
              <v-text-field v-model="socialProof.location" outlined label="Location" hint="(city, state)" />
            </v-col>
          </v-row>
          <v-row class="mt-n4">
            <v-col cols="12">
              <v-menu v-model="postedDatePicker" :close-on-content-click="false" :nudge-right="40" transition="slide-y-transition" offset-y min-width="290px">
                <template #activator="{ on }">
                  <v-text-field v-model="postedDateFormatted" outlined label="Date" readonly v-on="on" />
                </template>
                <v-date-picker v-model="socialProof.date" :show-current="false" @input="postedDatePicker = false" />
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="mt-n4">
            <v-col cols="12">
              <v-textarea
                v-model="socialProof.message"
                :rows="4"
                :counter="255"
                :rules="[formValidation.valueRequiredRule]"
                outlined
                label="Content"
                maxlength="255"
              />
            </v-col>
          </v-row>
          <v-row class="mt-n4">
            <v-col cols="12">
              <h4 class="font-weight-medium">Photo</h4>
            </v-col>
          </v-row>
          <v-row class="mt-n2">
            <v-col cols="12" class="text-center upload-placeholder">
              <div class="base-image-input" :style="getPhotoUploadBackgroundImage(proofImageData, socialProof.img_url)" @click="chooseProofImage">
                <h2 v-if="!proofImageData && !socialProof.img_url" class="upload-text font-weight-medium text--secondary">Click to upload image</h2>
                <input ref="proofImageFileInput" class="file-input" type="file" accept="image/*" @change="onProofImageFileSelected" />
              </div>
              <v-progress-linear
                v-if="proofImageUploadProgress >= 0 && proofImageUploadProgress < 100"
                v-model="proofImageUploadProgress"
                color="pink"
                class="mt-3"
                striped
              />
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col cols="12">
              <v-select
                :items="proofPlatformOptions"
                :return-object="true"
                :value="socialProof.platform"
                :rules="[formValidation.valueRequiredRule]"
                outlined
                label="Platform"
                @change="onPlatformSelected"
              />
            </v-col>
          </v-row>
          <v-row class="mt-n4">
            <v-col cols="12">
              <v-select
                :items="statusCodesOptions"
                :return-object="true"
                :value="socialProof.status_code"
                outlined
                label="Status Code"
                @change="onStatusCodeSelected"
              />
            </v-col>
          </v-row>
          <v-row class="mt-n4">
            <v-col cols="12">
              <v-text-field v-model="socialProof.business_name" :rules="[formValidation.valueRequiredRule]" outlined label="Business Name" />
            </v-col>
          </v-row>
          <v-row class="mt-n4">
            <v-col cols="12">
              <v-text-field v-model="socialProof.influencer_name" :rules="[formValidation.valueRequiredRule]" outlined label="Influencer Name" />
            </v-col>
          </v-row>
          <v-row class="mt-n4">
            <v-col cols="12">
              <h4 :class="`font-weight-medium ${formValidation.influencerAvatarImageError ? 'error--text' : ''}`">Influencer Avatar</h4>
            </v-col>
          </v-row>
          <v-row class="mt-n2">
            <v-col cols="12" :class="`text-center upload-placeholder ${formValidation.influencerAvatarImageError ? 'upload-placeholder-error' : ''}`">
              <div
                class="base-image-input"
                :style="getPhotoUploadBackgroundImage(influencerAvatarImageData, socialProof.avatar_img_url)"
                @click="chooseInfluencerAvatarImage"
              >
                <h2 v-if="!influencerAvatarImageData && !socialProof.avatar_img_url" class="upload-text font-weight-medium text--secondary">
                  Click to upload image
                </h2>
                <input ref="influencerAvatarImageFileInput" class="file-input" type="file" accept="image/*" @change="onInfluencerAvatarImageFileSelected" />
              </div>
              <v-progress-linear
                v-if="influencerAvatarImageUploadProgress >= 0 && influencerAvatarImageUploadProgress < 100"
                v-model="influencerAvatarImageUploadProgress"
                color="pink"
                class="mt-3"
                striped
              />
            </v-col>
            <span v-if="formValidation.influencerAvatarImageError" class="v-messages theme--light error--text">Required</span>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="darken-1" text @click="$emit('social-proof-form-cancel')">Cancel</v-btn>
      <v-btn :loading="socialProofSaving" color="primary darken-1" text @click="handleSaveButtonClicked">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'

import { ApiService } from '@/services/index'
import { SocialProofStatus, SocialProofPlatform, SocialProofType } from '@/models/SocialProof'

export default Vue.extend({
  name: 'SocialProofForm',

  props: {
    currentSocialProof: {
      type: Object,
      default: function () {
        return {
          avatar_img_url: null,
          business_name: '',
          date: new Date().toISOString().substr(0, 10),
          id: '',
          img_url: null,
          location: '',
          message: '',
          platform: '',
          rating: null,
          status_code: SocialProofStatus.DRAFT,
          type: '',
        }
      },
    },
  },

  data: function () {
    return {
      postedDatePicker: false,
      socialProofSaving: false,
      proofImageData: null,
      influencerAvatarImageData: null,
      proofImageUploadProgress: -1,
      influencerAvatarImageUploadProgress: -1,
      socialProof: this.currentSocialProof,
      formValidation: {
        valueRequiredRule: (value) => !!value || 'Required',
        proofRatingError: false,
        influencerAvatarImageError: false,
      },
    }
  },

  computed: {
    getFormMode() {
      return this.socialProof.id ? 'Edit' : 'Add'
    },
    postedDateFormatted() {
      return this.formatDate(this.socialProof.date)
    },
    statusCodesOptions() {
      return Object.values(SocialProofStatus)
    },
    proofTypeOptions() {
      return Object.values(SocialProofType)
    },
    proofPlatformOptions() {
      return Object.values(SocialProofPlatform)
    },
    isReviewType() {
      return this.socialProof.type == SocialProofType.REVIEW
    },
  },

  watch: {
    currentSocialProof: function () {
      this.socialProof = this.currentSocialProof
      this.$refs.socialProofForm.resetValidation()
    },
  },

  mounted: function () {
    if (this.socialProof) {
      const formattedPostedDate = this.formatDate(this.socialProof.date)
      this.socialProof.date = new Date(formattedPostedDate).toISOString().substr(0, 10)
    }
  },

  methods: {
    ...mapActions('socialProof', ['saveSocialProof', 'updateSocialProof']),
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day.substring(0, 2)}/${year}`
    },
    onStatusCodeSelected: function (selectedStatusCode) {
      this.socialProof.status_code = selectedStatusCode
    },
    onPlatformSelected: function (selectedPlatform) {
      this.socialProof.platform = selectedPlatform
    },
    onTypeSelected: function (selectedType) {
      this.socialProof.type = selectedType
    },
    chooseProofImage: function () {
      const proofImageFileInput = this.$refs.proofImageFileInput
      proofImageFileInput.click()
    },
    chooseInfluencerAvatarImage: function () {
      const influencerAvatarImageFileInput = this.$refs.influencerAvatarImageFileInput
      influencerAvatarImageFileInput.click()
    },
    onProofImageFileSelected: function (event) {
      if (!event.target.files || !event.target.files[0]) {
        this.$toast.error('Error Uploading Image')
        return
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        if (!e || !e.target || !e.target.result) {
          this.$toast.error('Error Uploading Image')
          return
        }

        this.proofImageData = e.target.result
      }

      const file = event.target.files[0]
      reader.readAsDataURL(file)
      this.uploadProofImage(file)
    },
    onInfluencerAvatarImageFileSelected: function (event) {
      if (!event.target.files || !event.target.files[0]) {
        this.$toast.error('Error Uploading Image')
        return
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        if (!e || !e.target || !e.target.result) {
          this.$toast.error('Error Uploading Image')
          return
        }

        this.influencerAvatarImageData = e.target.result
      }

      const file = event.target.files[0]
      reader.readAsDataURL(file)
      this.uploadInfluencerAvatarImage(file)
    },
    async uploadProofImage(file) {
      await ApiService.get(`signedUrl/socialProofWidget/${file.type.split('/')[1]}`)
        .then((response) => {
          return response.data
        })
        .then((signedUrlResponse) => {
          const requestConfig = {
            headers: { 'Content-Type': file.type },
            onUploadProgress: (progressEvent) => {
              this.proofImageUploadProgress = (progressEvent.loaded / progressEvent.total) * 100
            },
          }

          return ApiService.customRequest()
            .put(signedUrlResponse.signedUrl, file, requestConfig)
            .then(() => {
              this.socialProof.img_url = signedUrlResponse.publicUrl
            })
        })
    },
    async uploadInfluencerAvatarImage(file) {
      await ApiService.get(`signedUrl/socialProofWidget/${file.type.split('/')[1]}`)
        .then((response) => {
          return response.data
        })
        .then((signedUrlResponse) => {
          const requestConfig = {
            headers: { 'Content-Type': file.type },
            onUploadProgress: (progressEvent) => {
              this.influencerAvatarImageUploadProgress = (progressEvent.loaded / progressEvent.total) * 100
            },
          }

          return ApiService.customRequest()
            .put(signedUrlResponse.signedUrl, file, requestConfig)
            .then(() => {
              this.socialProof.avatar_img_url = signedUrlResponse.publicUrl
              this.formValidation.influencerAvatarImageError = false
            })
        })
    },
    handleSaveButtonClicked() {
      this.formValidation.influencerAvatarImageError = !this.socialProof.avatar_img_url
      this.formValidation.proofRatingError = this.socialProof.type == SocialProofType.REVIEW && !this.socialProof.rating

      if (!this.$refs.socialProofForm.validate() || this.formValidation.influencerAvatarImageError || this.formValidation.proofRatingError) return

      this.socialProofSaving = true

      if (this.socialProof.id) {
        this.updateSocialProof({ socialProofData: this.socialProof })
      } else {
        this.saveSocialProof({ socialProofData: this.socialProof })
      }

      this.$emit('social-proof-form-save', this.socialProof)
    },
    getPhotoUploadBackgroundImage(imageData, imageUrl) {
      return `background-image: url("${imageData ? imageData : imageUrl}")`
    },
  },
})
</script>

<style scoped>
.base-image-input {
  display: block;
  width: 100%;
  height: 250px;
  cursor: pointer;
  background-size: contain;
  background-position: center center;
}

.upload-placeholder {
  background: #f0f0f0;
}

.upload-placeholder:hover {
  background: #e0e0e0;
}

.upload-placeholder-error {
  border: 1px solid red;
}

.upload-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-input {
  display: none;
}
</style>
