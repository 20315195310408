<template>
  <div id="settings">
    <app-spinner v-if="loading" />
    <v-container v-else>
      <div v-if="isAdmin">
        <BusinessSettings :settings="businessSettings" />
      </div>
      <BusinessSocialSettings :settings="businessSocialSettings" />
      <BusinessValueSettings :settings="businessSocialSettings" />
      <div v-if="isAdmin">
        <SocialProofWidgetSettings :settings="socialProofSettings" />
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import BusinessSettings from '@/components/Settings/BusinessSettings.vue'
import BusinessSocialSettings from '@/components/Settings/BusinessSocialSettings.vue'
import SocialProofWidgetSettings from '@/components/Settings/SocialProofWidgetSettings'
import BusinessValueSettings from '@/components/Settings/BusinessValueSettings'

export default {
  name: 'Settings',

  metaInfo: {
    title: 'Settings',
  },

  components: {
    BusinessSettings,
    BusinessSocialSettings,
    SocialProofWidgetSettings,
    BusinessValueSettings,
  },

  computed: {
    ...mapGetters('settings', ['loading', 'businessSettings', 'businessSocialSettings', 'socialProofSettings']),
    ...mapGetters('account', ['isAdmin']),
  },

  mounted() {
    this.$store.dispatch('settings/refresh')
  },
}
</script>

<style scoped>
#settings {
  width: 100%;
}
</style>
