// @ts-nocheck

import Vue from 'vue'
import { ApiService } from '@/services'

import { SwayOffer, SwayOfferBase, SwayOfferFlowBase, SwayOfferFlow, SwayOfferFlowDataDefaults } from '@/models/SwayOffer'

interface OfferState {
  loading: boolean
  swayOfferMutationLoading: boolean
  swayOffers: SwayOffer[]
  flowDataDefaults: SwayOfferFlowDataDefaults
}

const state: OfferState = {
  loading: false,
  swayOfferMutationLoading: false,
  swayOffers: [],
  flowDataDefaults: {},
}

const getters = {
  loading: () => state.loading,
  swayOfferMutationLoading: () => state.swayOfferMutationLoading,
  swayOffers: () => state.swayOffers,
  claimDataFlowDefaults: () => state.flowDataDefaults.claimData,
  proofRequirementsDataFlowDefaults: () => state.flowDataDefaults.proofRequirementsData,
  contactInfoDataFlowDefaults: () => state.flowDataDefaults.contactInfoData,
  voucherDataFlowDefaults: () => state.flowDataDefaults.voucherData,
  whatsNextDataFlowDefaults: () => state.flowDataDefaults.whatsNextData,
}

const actions = {
  async createSwayOffer({ commit }, { swayOfferBase, flow }: { swayOfferBase: SwayOfferBase; flow: SwayOfferFlowBase }) {
    await ApiService.post('swayOffer', { ...swayOfferBase, flow }).then((response) => {
      commit('refresh', { swayOffers: [...state.swayOffers, response.data], flowDataDefaults: { ...state.flowDataDefaults } })
    })
  },
  async copySwayOffer({ commit }, { swayOfferId }: { swayOfferId: string }) {
    await ApiService.post(`swayOffer/${swayOfferId}/copy`).then((response) => {
      commit('refresh', { swayOffers: [...state.swayOffers, response.data], flowDataDefaults: { ...state.flowDataDefaults } })
    })
  },
  updateSwayOffer({ commit, dispatch }, { swayOffer }: SwayOffer) {
    commit('swayOfferMutationLoading', true)
    dispatch('app/setAppProgressBarVisibility', { visible: true }, { root: true })

    ApiService.put(`swayOffer/${swayOffer.id}`, { ...swayOffer }).then((response) => {
      commit('updateSwayOffer', { ...response.data })
      commit('swayOfferMutationLoading', false)
      dispatch('app/setAppProgressBarVisibility', { visible: false }, { root: true })

      Vue.prototype.$toast.success('Offer Updated!')
    })
  },
  updateSwayOfferFlow({ commit, dispatch }, { swayOfferId, swayOfferFlow }: { swayOfferId: string; swayOfferFlow: SwayOfferFlow }) {
    commit('swayOfferMutationLoading', true)
    dispatch('app/setAppProgressBarVisibility', { visible: true }, { root: true })

    const existingFlow = state.swayOffers.find((swayOffer) => swayOffer.id === swayOfferId)
    if (!existingFlow || !existingFlow.flow) {
      commit('swayOfferMutationLoading', false)
      dispatch('app/setAppProgressBarVisibility', { visible: false }, { root: true })

      Vue.prototype.$toast.error('There was a problem updating your offer. Try again.')
      return
    }

    ApiService.put(`swayOffer/${swayOfferId}/flow`, { ...existingFlow.flow, ...swayOfferFlow }).then((response) => {
      commit('updateSwayOffer', { ...response.data })
      commit('swayOfferMutationLoading', false)
      dispatch('app/setAppProgressBarVisibility', { visible: false }, { root: true })

      Vue.prototype.$toast.success('Offer Updated!')
    })
  },
  refresh({ commit }) {
    commit('loading', true)

    ApiService.get('swayOffer').then((response) => {
      commit('refresh', response.data)
      commit('loading', false)
    })
  },
}

const mutations = {
  loading(state: OfferState, payload: boolean) {
    state.loading = payload
  },
  swayOfferMutationLoading(state: OfferState, payload: boolean) {
    state.swayOfferMutationLoading = payload
  },
  updateSwayOffer(state: OfferState, payload: SwayOffer) {
    Vue.set(
      state.swayOffers,
      state.swayOffers.findIndex((swayOffer) => swayOffer.id === payload.id),
      { ...payload, offer_status: payload.expired ? 'COMPLETE' : 'ACTIVE' }
    )
  },
  refresh(state: OfferState, payload: { swayOffers: SwayOffer[]; flowDataDefaults: SwayOfferFlowDataDefaults }) {
    state.flowDataDefaults = payload.flowDataDefaults
    state.swayOffers = payload.swayOffers.map((e) => {
      return { ...e, offer_status: e.expired ? 'COMPLETE' : 'ACTIVE' }
    })
  },
}

export const swayoffer = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
