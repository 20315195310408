<template>
  <v-expansion-panel @click.native.stop>
    <v-expansion-panel-header>Admin</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-form ref="adminPanelForm">
        <v-row class="mt-n4">
          <v-col cols="12">
            <v-text-field
              v-model="custom_offer_summary"
              outlined
              label="Custom Description"
              :rules="requiredRule"
              @blur="handleSaveCustomOfferSummary()"
              @keydown.enter="$event.target.blur()"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n4">
          <v-col cols="12">
            <v-autocomplete
              v-model="offer_tasks"
              :items="taskOptions"
              label="Task Options"
              item-text="title"
              item-value="type"
              flat
              chips
              outlined
              multiple
              @change="onTaskOptionSelected"
            >
              <template #selection="{ attrs, item, selected }">
                <div class="mt-1">
                  <v-chip v-bind="attrs" :input-value="selected" color="primary lighten-1">
                    <v-img :src="item.icon" aspect-ratio="1" height="16" width="16" class="p-0 mr-1 d-inline-flex" style="filter: brightness(0) invert(1)" />
                    <strong>{{ item.title }}</strong>
                  </v-chip>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="mt-n6">
          <v-col cols="12">
            <v-text-field
              v-model="number_tasks_required"
              outlined
              label="Number Tasks Required"
              type="number"
              min="0"
              :rules="requiredRule"
              @blur="handleSaveNumberTasksRequired()"
              @keydown.enter="$event.target.blur()"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n6">
          <v-col cols="12">
            <v-text-field
              v-model="offers_to_grant_per_month"
              outlined
              label="Offers Per location"
              type="number"
              min="0"
              :rules="requiredRule"
              @blur="handleSaveOffersToGrantPerMonth()"
              @keydown.enter="$event.target.blur()"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n8">
          <v-col cols="12" class="pt-0">
            <v-switch v-model="isOneTime" outlined label="One Time only" @click="handleToggleOneTime()" />
          </v-col>
        </v-row>

        <v-row v-if="!isOneTime" class="mt-n4">
          <v-col cols="12">
            <v-text-field
              v-model="min_days_between_offer"
              outlined
              label="Min days between offer"
              type="number"
              min="0"
              @blur="handleMinDaysBetween()"
              @keydown.enter="$event.target.blur()"
            />
          </v-col>
        </v-row>

        <v-row v-if="offerType === 'WEB'" class="mt-n6">
          <v-col cols="12">
            <v-text-field
              v-model="display_priority"
              :persistent-hint="true"
              outlined
              type="number"
              label="Display Priority"
              hint="Lower number = shown earlier in the survey (1 = first)"
              @blur="handleSaveDisplayPriority()"
              @keydown.enter="$event.target.blur()"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue from 'vue'

import taskTypes from '@/models/taskType'
import validationRules from '@/utils/validation'

export default Vue.extend({
  name: 'OfferAdminPanel',

  props: {
    offerType: {
      type: String,
      required: true,
    },
    tasks: {
      type: Array,
      required: true,
    },
    customOfferSummary: {
      type: String,
      required: true,
    },
    displayPriority: {
      type: Number,
      default: () => null,
    },
    numberTasksRequired: {
      type: Number,
      required: true,
    },
    offersToGrantPerMonth: {
      type: Number,
      required: true,
    },
    minDaysBetweenOffer: {
      type: Number,
      default: () => null,
    },
  },

  data: () => ({
    offer_tasks: [],
    display_priority: null,
    custom_offer_summary: null,
    number_tasks_required: null,
    offers_to_grant_per_month: null,
    min_days_between_offer: null,
    isOneTime: null,
    requiredRule: validationRules.requiredRule,
  }),

  computed: {
    taskOptions() {
      return Object.values(taskTypes)
    },
  },

  mounted: function () {
    this.offer_tasks = this.tasks
    this.display_priority = this.displayPriority
    this.custom_offer_summary = this.customOfferSummary
    this.number_tasks_required = this.numberTasksRequired
    this.offers_to_grant_per_month = this.offersToGrantPerMonth
    this.min_days_between_offer = this.minDaysBetweenOffer
    this.isOneTime = this.minDaysBetweenOffer && this.minDaysBetweenOffer === 36500
  },

  methods: {
    onTaskOptionSelected(selectedTaskOptions) {
      this.offer_tasks = selectedTaskOptions
      this.$emit('save', { tasks: this.offer_tasks })
    },
    handleSaveCustomOfferSummary() {
      if (!this.$refs.adminPanelForm.validate()) return

      this.$emit('save', { offer_summary: this.custom_offer_summary })
    },
    handleSaveNumberTasksRequired() {
      if (!this.$refs.adminPanelForm.validate()) return

      this.$emit('save', { number_tasks_required: parseInt(this.number_tasks_required) })
    },
    handleSaveOffersToGrantPerMonth() {
      if (!this.$refs.adminPanelForm.validate()) return

      this.$emit('save', { offers_to_grant_per_month: parseInt(this.offers_to_grant_per_month) })
    },
    handleSaveDisplayPriority() {
      this.$emit('save', { display_priority: this.display_priority ? parseInt(this.display_priority) : null })
    },
    handleMinDaysBetween() {
      this.$emit('save', { min_days_between_offer: this.min_days_between_offer ? parseInt(this.min_days_between_offer) : null })
    },
    handleToggleOneTime() {
      this.$emit('save', { min_days_between_offer: this.isOneTime ? 36500 : this.min_days_between_offer })
    },
  },
})
</script>
