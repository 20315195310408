// @ts-nocheck

import router from '@/router'

import { UserService } from '../../services'

interface AuthState {
  accessToken: null | string
  authenticationError: string
}

const state: AuthState = {
  authenticationError: '',
  accessToken: localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_KEY),
}

const getters = {
  loggedIn: (state: AuthState) => {
    return !!state.accessToken
  },

  authenticationError: (state: AuthState) => {
    return state.authenticationError
  },
}

const actions = {
  async login({ commit, dispatch }, { token }: { token: string }) {
    commit('loginRequest')

    await UserService.setAuthToken(token)

    commit('loginSuccess', token)

    const userId = UserService.getUserId()
    dispatch('heap/init', userId, { root: true })

    router.push({ path: router.currentRoute.query.redirect ? router.currentRoute.query.redirect.toString() : '/' })
    return true
  },

  async logout({ commit, dispatch }) {
    UserService.logout()

    commit('logoutSuccess')

    dispatch('heap/dispose', null, { root: true })

    router.push({ path: '/auth' })
  },
}

const mutations = {
  loginRequest(state: AuthState) {
    state.authenticationError = ''
  },

  loginSuccess(state: AuthState, accessToken: string) {
    state.accessToken = accessToken
  },

  loginError(state: AuthState, { errorMessage }: { errorMessage: string }) {
    state.authenticationError = errorMessage
  },

  logoutSuccess(state: AuthState) {
    state.accessToken = ''
  },
}

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
