<template>
  <div>
    <UploadCodeListModal v-if="showUploadList" :code-list-id="codeList.id" @upload-new-codes="handleUploadCodes" @cancel-modal="showUploadList = false" />
    <v-row v-if="codeList.offer" class="mt-0">
      <v-col cols="8" class="body-2" align="left" justify="center"> Applied to: {{ codeList.offer.summary }} </v-col>
      <v-col cols="4" align="right" justify="right">
        <span class="body-2 mr-2"> Upload CSV:</span>
        <v-btn color="primary" @click="showUploadList = true"><v-icon>mdi-upload</v-icon> </v-btn></v-col
      >
    </v-row>

    <v-row>
      <v-col>
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th class="text-left">Code</th>
                <th class="text-left">Used At</th>
                <th class="text-left">Used By</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="code in codeList.codes" :key="code.id">
                <td>{{ code.value }}</td>
                <td v-if="code.used_at">{{ code.used_at | monthDayYearDate }}</td>
                <td v-else></td>
                <td>{{ code.used_by }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <h2 v-if="codeList.pending_codes">Pending Codes</h2>
    <v-row v-if="codeList.pending_codes">
      <v-col>
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Email</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="code in codeList.pending_codes" :key="code.id">
                <td>{{ code.name }}</td>
                <td v-if="code.email">{{ code.email }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import UploadCodeListModal from '@/components/CodeList/UploadCodeListModal'
import { ApiService } from '../../services'

export default Vue.extend({
  name: 'CodeListDetails',
  components: { UploadCodeListModal },

  props: {
    codeList: {
      type: Object,
      required: true,
    },
  },
  data: () => ({ showUploadList: false }),

  computed: {},

  mounted() {},

  methods: {
    handleUploadCodes({ codes }) {
      this.showUploadList = false
      ApiService.post(`coupon/addCodes/${this.codeList.id}`, { codes }).then((r) => {
        this.$toast.success(`Uploaded ${r.data.addedCodes} codes`)
        this.$emit('code-lists-added')
      })
    },
  },
})
</script>
