<template>
  <v-dialog max-width="400" :value="true" :fullscreen="true" :transition="false" :hide-overlay="true" :no-click-animation="true" persistent>
    <div>
      <v-card id="socialProofWidgetPreviewControls" :elevation="24">
        <v-card-title class="headline">Social Proof Widget Preview</v-card-title>
        <v-card-text>
          <v-select
            v-model="widgetDisplayMode"
            :items="displayOptions"
            :return-object="true"
            outlined
            item-text="value"
            item-value="type"
            label="Widget Mode"
            class="preview-mode-selector"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('social-proof-widget-preview-close')">Close</v-btn>
        </v-card-actions>
      </v-card>
      <iframe
        style="position: absolute; left: 0; bottom: 0"
        height="460"
        :width="widgetDisplayMode.width"
        frameborder="0"
        :src="`${divocApiUrl}/socialProof/preview/${accessKey}?proofId=${proofId}&disableAnalytics=true&enableLandscape=true&state=${widgetDisplayMode.mode}`"
      >
      </iframe>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'SocialProofWidgetPreview',

  props: {
    proofId: {
      type: String,
      required: true,
    },
    accessKey: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    widgetDisplayMode: {
      width: 860,
      mode: 'EXPANDED',
      type: 'DESKTOP_EXPANDED',
      value: 'Desktop - Expanded',
    },
    displayOptions: [
      {
        width: 860,
        mode: 'NORMAL',
        type: 'DESKTOP_NORMAL',
        value: 'Desktop - Normal',
      },
      {
        width: 860,
        mode: 'EXPANDED',
        type: 'DESKTOP_EXPANDED',
        value: 'Desktop - Expanded',
      },
      {
        width: 385,
        mode: 'NORMAL',
        type: 'MOBILE_NORMAL',
        value: 'Mobile - Normal',
      },
      {
        width: 385,
        mode: 'EXPANDED',
        type: 'MOBILE_EXPANDED',
        value: 'Mobile - Expanded',
      },
    ],
  }),

  computed: {
    divocApiUrl() {
      return process.env.VUE_APP_DIVOC_API_URL
    },
  },
}
</script>

<style scoped>
#socialProofWidgetPreviewControls {
  top: 30%;
  left: 40%;
  z-index: 1;
  position: fixed;
}
</style>
