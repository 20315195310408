import Vue from 'vue'
import { ApiService } from '@/services'
import { SocialProof } from '@/models/SocialProof'

type SocialProofState = {
  loading: boolean
  accessKey: string
  entities: SocialProof[]
  widgetInstallSnippet: string
  widgetInstallSnippetLoading: boolean
  verifiyingInstallation: boolean
  installationVerificationResults: object
}

const state: SocialProofState = {
  loading: false,
  accessKey: null,
  entities: [],
  widgetInstallSnippet: null,
  widgetInstallSnippetLoading: false,
  verifiyingInstallation: false,
  installationVerificationResults: {},
}

const getters = {
  loading: () => state.loading,
  accessKey: () => state.accessKey,
  entities: () => state.entities,
  widgetInstallSnippet: () => state.widgetInstallSnippet,
  widgetInstallSnippetLoading: () => state.widgetInstallSnippetLoading,
  verifiyingInstallation: () => state.verifiyingInstallation,
  installationVerificationResults: () => state.installationVerificationResults,
}

const actions = {
  refresh({ commit }) {
    commit('loading', true)
    ApiService.get('socialProof/all').then((response) => {
      const { socialProofs, accessKey } = response.data

      commit('updateAccessKey', accessKey)
      commit('updateSocialProofs', socialProofs)
      commit('loading', false)
    })
  },
  enableBusiness({ commit }) {
    commit('loading', true)
    ApiService.post('/socialProof/enableBusiness', {}).then((response) => {
      commit('updateSocialProofs', [])
      commit('setWidgetInstallSnippet', response.data.widgetInstallSnippet)
      commit('updateAccessKey', response.data.socialProofConfiguration.access_key)
      commit('loading', false)
    })
  },
  getWidgetInstallSnippet({ commit }) {
    commit('widgetInstallSnippetLoading', true)
    ApiService.get('socialProof/widgetInstallSnippet').then((response) => {
      commit('setWidgetInstallSnippet', response.data)
      commit('widgetInstallSnippetLoading', false)
    })
  },
  saveSocialProof({ commit }, { socialProofData }: { socialProofData: SocialProof }) {
    commit('loading', true)
    ApiService.post('socialProof', { socialProof: socialProofData }).then((response) => {
      socialProofData.id = response.data.id

      commit('saveSocialProof', socialProofData)
      commit('loading', false)
    })
  },
  updateSocialProof({ commit }, { socialProofData }: { socialProofData: SocialProof }) {
    commit('loading', true)
    ApiService.put(`socialProof/${socialProofData.id}`, { socialProof: socialProofData }).then(() => {
      commit('updateSocialProof', socialProofData)
      commit('loading', false)
    })
  },
  verifyInstallation({ commit }) {
    commit('verifiyingInstallation', true)
    ApiService.get('socialProof/verifyInstallation').then((response) => {
      commit('installationVerificationResults', response.data)
      commit('verifiyingInstallation', false)
    })
  },
}

const mutations = {
  loading(state: SocialProofState, payload: boolean) {
    state.loading = payload
  },
  widgetInstallSnippetLoading(state: SocialProofState, payload: boolean) {
    state.widgetInstallSnippetLoading = payload
  },
  saveSocialProof(state: SocialProofState, payload: SocialProof) {
    state.entities = state.entities.concat([payload])
  },
  updateSocialProof(state: SocialProofState, payload: SocialProof) {
    Vue.set(
      state.entities,
      state.entities.findIndex((socialProof) => socialProof.id === payload.id),
      payload
    )
  },
  updateSocialProofs(state: SocialProofState, payload: SocialProof[]) {
    state.entities = payload
  },
  updateAccessKey(state: SocialProofState, payload: string) {
    state.accessKey = payload
  },
  setWidgetInstallSnippet(state: SocialProofState, payload: string) {
    state.widgetInstallSnippet = payload
  },
  verifiyingInstallation(state: SocialProofState, payload: boolean) {
    state.verifiyingInstallation = payload
  },
  installationVerificationResults(state: SocialProofState, payload: object) {
    state.installationVerificationResults = payload
  },
}

export const socialProof = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
