<template>
  <div>
    <h3>Sway Codes</h3>
    <h2 v-if="swayCodeLists.length === 0" class="text-center font-weight-light">No Codes</h2>
    <v-expansion-panels v-else inset focusable>
      <v-expansion-panel v-for="(codeList, index) in swayCodeLists" :key="index">
        <v-expansion-panel-header>
          <span>{{ codeList.list_name }}</span>
          <span v-if="codeList.total_codes === 0" class="text-right mr-2">
            EMPTY
            <span v-if="pendingCodeCount(codeList) >= 0" class="font-weight-bold" style="color: red"> - {{ pendingCodeCount(codeList) }} pending</span></span
          >
          <span v-else class="text-right mr-2">
            {{ codeList.used_codes }} / {{ codeList.total_codes }} <span v-if="pendingCodeCount > 0">- {{ pendingCodeCount(codeList) }}</span> ({{
              codeList.used_percentage
            }}%)
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <CodeListDetails :code-list="codeList" @code-lists-added="refreshCodeList" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import CodeListDetails from '@/components/CodeList/CodeListDetails'

export default Vue.extend({
  name: 'SwayCodeList',
  components: {
    CodeListDetails,
  },
  props: {},
  data: () => ({}),

  computed: {
    ...mapGetters('couponCodes', ['loadingSway', 'swayCodeLists']),
  },

  mounted() {},

  methods: {
    refreshCodeList() {
      this.$store.dispatch('couponCodes/refresh')
    },
    pendingCodeCount(codeList) {
      return codeList?.pending_codes?.length || 0
    },
  },
})
</script>
