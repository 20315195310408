import Vue from 'vue'

Vue.filter('pluralize', (word: string, amount: number) => (amount > 1 || amount === 0 ? `${word}s` : word))

Vue.filter('prettyString', (type: String) => {
  if (!type) return null

  return type.replace(/\_/g, ' ').replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
  })
})
