<template>
  <v-dialog :value="true" persistent max-width="500">
    <v-card>
      <v-alert text type="warning" icon="mdi-alert-circle-outline">Warning!</v-alert>
      <v-card-text v-if="addedToOffers.length > 0">
        <h3 class="font-weight-regular">
          This location will be
          <span class="font-weight-bold">added</span> to the following offers
        </h3>
        <ul>
          <li v-for="(offer, index) in addedToOffers" :key="index" class="mt-1">{{ offer.offer_summary }}</li>
        </ul>
      </v-card-text>
      <v-card-text v-if="removedFromOffers.length > 0">
        <h3 class="font-weight-regular">
          This location will be
          <span class="font-weight-bold">removed</span> from the following offers
        </h3>
        <ul>
          <li v-for="(offer, index) in removedFromOffers" :key="index" class="mt-1">{{ offer.offer_summary }}</li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="$emit('location-tag-warning-modal-closed', initialCustomTags)">Cancel</v-btn>
        <v-btn color="primary" @click="$emit('location-tag-warning-modal-saved', newCustomTags)">Save & Update Offers</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'LocationTagWarningModal',

  props: {
    addedToOffers: {
      type: Array,
      default: () => [],
    },
    removedFromOffers: {
      type: Array,
      default: () => [],
    },
    newCustomTags: {
      type: Array,
      required: true,
    },
    initialCustomTags: {
      type: Array,
      required: true,
    },
  },
})
</script>
