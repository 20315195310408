<template>
  <v-container>
    <app-spinner />
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'TokenLogin',

  metaInfo: {
    title: 'Login',
  },

  mounted: function () {
    this.login({ token: this.$router.currentRoute.params.token })
  },

  methods: {
    ...mapActions('auth', ['login']),
  },
})
</script>
