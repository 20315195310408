import { UserType } from '@/models/User'
import { ApiService, TokenService } from './index'

export const UserService = {
  setAuthToken: async function (token: string) {
    TokenService.saveToken(token)
    ApiService.setAuthHeader()

    return token
  },

  isAdmin(): boolean {
    return TokenService.getDecodedToken()?.sub?.userType === UserType.ADMIN
  },

  isClientLite(): boolean {
    return TokenService.getDecodedToken()?.sub?.userType === UserType.CLIENT_LITE
  },

  getUserType(): UserType | null {
    const decodedToken = TokenService.getDecodedToken()
    if (decodedToken.sub && decodedToken.sub.userType) return decodedToken.sub.userType

    return null
  },

  getEmail(): string | null {
    const decodedToken = TokenService.getDecodedToken()
    if (decodedToken.sub && decodedToken.sub.email) return decodedToken.sub.email

    return null
  },

  getUserId(): string | null {
    const decodedToken = TokenService.getDecodedToken()
    if (decodedToken.sub && decodedToken.sub.userId) return decodedToken.sub.userId

    return null
  },

  logout() {
    TokenService.removeToken()
    ApiService.removeAuthHeader()
  },
}
