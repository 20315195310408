<template>
  <div class="option" @click="() => $emit('offer-flow-type-selected')">
    <div :class="['icon', selected ? 'selected' : '']">
      <img width="64" height="54" class="mt-1" :src="require(`@/assets/images/${icon}.png`)" />
    </div>
    <div class="contents">
      <h3>{{ title }}</h3>
      <div class="offer-type-font mt-2 mb-2">{{ text }}</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'OrderFlowTypeButton',

  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
})
</script>

<style scoped>
.offer-type-font {
  font-size: 13px;
  line-height: 1em;
  font-weight: 400;
}

.option {
  display: flex;
  cursor: pointer;
  border: 1px solid #8b8b8b;
}

.option > .icon {
  padding: 8px;
  vertical-align: baseline;
  background-color: #8b8b8b;
}

.option > .icon.selected {
  background-color: #e61b43;
}

.option > .contents {
  padding: 8px 12px;
  text-align: left;
}
</style>
