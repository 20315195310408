
<template>
  <v-card class="highlight-card w-full" elevation="17">
    <app-spinner v-if="loading" :spinner-size="80" class="spinner-background" />
    <div v-if="!loading && totalAmount > 0">
      <v-row align="center" justify="center" class="mb-0">
        <v-col cols="12" align="center" justify="center"> <span class="w-full text-h5 font-weight-black"> Monthly Value Totals </span></v-col>
      </v-row>
      <v-row align="center" justify="center" class="mt">
        <v-col cols="12" align="center" justify="center">
          <VueApexCharts width="280px" :options="data" :series="data.series" />
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="mt-n10">
        <v-col cols="12" align="center" justify="center">
          <span class="money-legend-lrg font-weight-black text--darken-1">{{ totalAmount | dollars }}</span>
        </v-col>
      </v-row>
    </div>
    <v-row v-else align="center" justify="center" class="pa-5 fill-height">
      <v-col cols="12" align="center" justify="center">
        <h1>No data</h1>
        <h3>In the past 6 months</h3>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import { ApiService } from '@/services/index'

export default Vue.extend({
  name: 'MonthlyTotalCard',

  components: { VueApexCharts },

  data: () => ({
    monthlyData: {},
    loading: true,
  }),
  computed: {
    data: function () {
      return {
        colors: ['#8F8389'],
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        series: [
          {
            name: 'Total',
            data: this.monthlyData.map((e) => e.totalamount),
          },
        ],
        stroke: {
          lineCap: 'round',
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return '$' + (val > 1000 ? Math.round(val / 100) / 10 + 'k' : val)
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#8F8389'],
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '80%',
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        grid: {
          show: false,
        },

        xaxis: {
          categories: this.monthlyData.map((e) => new Date(e.month).toLocaleString('default', { month: 'short' })),
        },
        yaxis: {
          show: false,
        },
        labels: ['Reviews', 'Posts', 'Ticket'],

        legend: {
          show: false,
        },
      }
    },
    totalAmount: function () {
      return this.monthlyData.reduce((pv, cv) => pv + parseInt(cv.totalamount), 0)
    },
  },
  mounted() {
    ApiService.get('dashboard/monthly').then((response) => {
      this.monthlyData = response.data
      this.loading = false
    })
  },
})
</script>

<style lang="scss" scoped>
.money-legend {
  font-size: 14px;
  font-family: arial, helvetica, sans-serif;
}

.money-legend-lrg {
  font-size: 24px;
  font-family: arial, helvetica, sans-serif;
}

.highlight-card {
  width: 320px;
  height: 300px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 12px;
}
</style>
