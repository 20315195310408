<template>
  <v-dialog :value="true" persistent max-width="800" @keydown.esc="handleModalClose">
    <app-spinner v-if="loading" :spinner-size="80" class="spinner-background" />

    <v-card>
      <v-card-text class="pb-0 pt-8">
        <v-row>
          <v-col cols="4" align="center" justify="center" class="grow">
            <h5 class="color light-gray text-uppercase opacity-50 ml-3">
              <v-img :src="getProofIcon" aspect-ratio="1" height="24" width="24" class="p-0 mr-1 d-inline-flex" />
            </h5>
            <ScreenShotCarousel v-if="proofs" :proofs="proofs" classes="" />
          </v-col>
          <v-col cols="8">
            <v-row align="center" justify="center" class="mt-0">
              <v-col cols="3" align="center" justify="center">
                <v-icon large class="pa-0 ma-0 mt-n4">mdi-account-circle-outline</v-icon>
                <span class="overline d-block">local</span>
              </v-col>
              <v-col cols="9" align="left" justify="center">
                <h1>{{ user.first_name || 'Unknown' }}</h1>
                <p class="font-weight-light caption mt-2 ml-6 mb-0">joined us on {{ user.created_at | prettyDate }}</p>
                <p class="font-weight-light caption ml-6 mt-0">posts shared: {{ user_number_of_proofs }}</p>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-0">
              <v-col cols="3" align="center" justify="center">
                <VueApexCharts :options="data" :series="data.series" />
              </v-col>
              <v-col cols="9" align="left" justify="center">
                <p class="caption mb-0">
                  On <strong>{{ voucher.date_redeemed | prettyDate }}</strong
                  >, {{ user.first_name || 'Local' }} visited your location. They were the
                  <span class="ticket-color font-weight-bold">{{ voucher_in_month_number }} of {{ voucher_month_total }}</span> customers to visit.
                </p>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-0">
              <v-col cols="3" align="center" justify="center">
                <v-icon large class="pa-0 ma-0 block">mdi-offer</v-icon>
                <span class="overline d-block">offer</span>
              </v-col>
              <v-col cols="9" align="left" justify="center">
                <v-row>
                  <v-col cols="12">
                    <span class="title-2 mb-0">{{ offer.offer_summary }}</span>
                  </v-col>
                </v-row>
                <h5 class="color light-gray text-uppercase opacity-50"></h5>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-0">
              <v-col cols="3" align="center" justify="center">
                <p class="text-h4 font-weight-medium mb-0 post-color">${{ amount }}</p>
                <span class="overline d-block post-color">value</span>
              </v-col>
              <v-col cols="9" align="left" justify="center" class="pa-0">
                <h5 class="color light-gray text-uppercase opacity-50 ml-3">
                  <v-img :src="getProofIcon" aspect-ratio="1" height="24" width="24" class="p-0 mr-1 d-inline-flex" />
                  <span class="text-uppercase"> {{ commaProofTypes | prettyString }} </span>
                </h5>
              </v-col>
            </v-row>
            <v-row v-if="proofs && proofs.length > 0" align="center" justify="center" class="mt-0">
              <v-col cols="3" align="center" justify="center">
                <v-icon large class="pa-0 ma-0 block">mdi-comment-quote-outline</v-icon>
                <span class="overline d-block">feedback</span>
              </v-col>
              <v-col cols="9" align="left" justify="center">
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      v-if="!proofs[0].client_feedback_app_user_id"
                      fab
                      x-small
                      elevation="3"
                      color="#539FDB"
                      class="client-feedback-button"
                      @click="showProofFeedbackModal = true"
                    >
                      <v-icon color="white">mdi-chat-plus</v-icon>
                    </v-btn>
                    <div v-else>
                      <span v-for="fb in proofs[0].client_feedback" :key="fb" class="ml-4">
                        <v-icon>{{ iconForType(fb) }}</v-icon> {{ fb | prettyString }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
                <h5 class="color light-gray text-uppercase opacity-50"></h5>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="handleModalClose">Close</v-btn>
        <!-- <v-btn :loading="false" color="primary darken-1" text @click="handleModalClose">Feedback</v-btn> -->
      </v-card-actions>
    </v-card>

    <ProofFeedbackModal v-if="showProofFeedbackModal" :proof="proofWithUser" @proof-feedback-modal-close="handleFeedbackClose" />
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import taskTypes from '../../models/taskType'

import { ApiService } from '@/services/index'
import { ProofFeedback } from '@/models/Proof'

import ScreenShotCarousel from '@/components/ProofCardList/ProofCardContent/ScreenShotCarousel'
import ProofFeedbackModal from '@/components/ProofCardList/ProofFeedbackModal'
import VueApexCharts from 'vue-apexcharts'

export default Vue.extend({
  name: 'ProofDetailModal',
  components: {
    ScreenShotCarousel,
    ProofFeedbackModal,
    VueApexCharts,
  },
  props: {
    voucherId: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    user: {},
    proofs: null,
    offer: {},
    voucher: {},
    user_number_of_proofs: null,
    loading: false,
    showProofFeedbackModal: false,
    voucher_in_month_number: 0,
    voucher_month_total: 0,
  }),
  computed: {
    proofWithUser() {
      return { ...this.proof, influencer: { ...this.user } }
    },
    feedbackTypes() {
      return Object.values(ProofFeedback)
    },
    commaProofTypes() {
      if (this.proofs && this.proofs.length > 0) return this.proofs.map((e) => e.proof_type).join(', ')
      return ''
    },
    data: function () {
      return {
        chart: {
          type: 'radialBar',
        },
        colors: ['#98E3F8', '#1F487E'],
        series: [(this.voucher_in_month_number / this.voucher_month_total) * 100],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined,
            },
            track: {
              show: true,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
        labels: ['Number'],
      }
    },
    getProofIcon() {
      if (!this.proof || !this.proof.proof_type) return require('@/assets/svg/localfluence.svg')

      const proofType = taskTypes[this.proof.proof_type]
      return proofType ? proofType.icon : ''
    },
  },
  mounted: function () {
    ApiService.get(`dashboard/voucherDetails/${this.voucherId}`).then((response) => {
      this.user = response.data.user
      this.proofs = response.data.proofs
      this.offer = response.data.offer
      this.voucher = response.data.voucher
      this.user_number_of_proofs = response.data.user_number_of_proofs
      this.voucher_in_month_number = response.data.voucher_in_month_number
      this.voucher_month_total = response.data.voucher_month_total
      this.loading = false
    })
  },

  methods: {
    handleModalClose() {
      this.$emit('detail-modal-close')
    },
    handleFeedbackClose() {
      this.showProofFeedbackModal = false
      ApiService.get(`dashboard/voucherDetails/${this.voucherId}`).then((response) => {
        this.user = response.data.user
        this.proof = response.data.proof
        this.offer = response.data.offer
        this.voucher = response.data.voucher
        this.user_number_of_proofs = response.data.user_number_of_proofs
        this.voucher_in_month_number = response.data.voucher_month_total
        this.voucher_month_total = response.data.voucher_month_total
        this.loading = false
      })
    },
    iconForType(fb) {
      return ProofFeedback[fb].icon
    },
  },
})
</script>

<style scoped>
.review-color {
  color: #98e3f8 !important;
}

.post-color {
  color: #1f487e !important;
}

.ticket-color {
  color: #376996 !important;
}

.border {
  border: thin solid #cccccc;
}
</style>
