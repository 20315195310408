export enum SocialProofType {
  POST = 'POST',
  REVIEW = 'REVIEW',
}

export enum SocialProofPlatform {
  INSTAGRAM = 'INSTAGRAM',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
}

export enum SocialProofStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED',
  REMOVED_CLIENT = 'REMOVED_CLIENT',
  REMOVED_INFLUENCER = 'REMOVED_INFLUENCER',
}

export interface SocialProof {
  id: string
  created_at: Date
  updated_at: Date
  date: Date
  access_key: string
  type: string
  platform: string
  business_name: string
  status_code: string
  avatar_img_url: string
  img_url: string
  message: string
  influencer_name: string
  location: string
  rating: number
  expand_duration: number
}

export interface SocialProofSettings {
  verified_by_name: string
  verified_by_icon: string
  time_between_posts: number
  cycle_delay: number
}
