<template>
  <v-expansion-panel @click.native.stop>
    <v-expansion-panel-header>Special Instructions</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12">
          <v-form ref="offerSpecialInstructionsForm">
            <v-text-field
              v-model="offer_special_instructions"
              outlined
              label="Special Instructions"
              @blur="handleSave()"
              @keydown.enter="$event.target.blur()"
            />
          </v-form>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'OfferSpecialInstructionsPanel',

  props: {
    offerSpecialInstructions: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    offer_special_instructions: null,
  }),

  watch: {
    offerSpecialInstructions: function () {
      this.offer_special_instructions = this.offerSpecialInstructions
    },
  },

  mounted: function () {
    this.offer_special_instructions = this.offerSpecialInstructions
  },

  methods: {
    handleSave() {
      this.$emit('save', { offer_special_instructions: this.offer_special_instructions })
    },
  },
})
</script>
