<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ getFormMode }} User</span>
      <div v-if="userId" class="user-controls">
        <v-btn :loading="sendLoginEmailLoading" small outlined color="red accent-3" class="mr-1" @click="sendLoginEmail()">Send LocalFluence Login Email</v-btn>
        <v-btn :loading="sendLoginSwayEmailLoading" small outlined color="red accent-3" class="mr-1" @click="sendSwayLoginEmail()">Send Sway Login Email</v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="userForm">
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="user.first_name" :rules="nameRules" label="First Name" outlined />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="user.last_name" :rules="nameRules" label="Last Name" outlined />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="user.email" :disabled="!emailEditable" :rules="emailRules" label="Email" outlined />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="darken-1" text @click="$emit('user-form-cancel')">Cancel</v-btn>
      <v-btn :disabled="!user" :loading="userSaving" color="primary darken-1" text @click="handleSaveButtonClicked">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import validationRules from '@/utils/validation'

import { ApiService } from '@/services/index'

export default Vue.extend({
  name: 'UserForm',

  props: {
    currentUser: {
      type: Object,
      default: function () {
        return {
          id: null,
          email: '',
          first_name: '',
          last_name: '',
        }
      },
    },
  },

  data: function () {
    return {
      userSaving: false,
      user: this.currentUser,
      sendLoginEmailLoading: false,
      sendLoginSwayEmailLoading: false,
      nameRules: validationRules.nameRules,
      emailRules: validationRules.emailRules,
    }
  },

  computed: {
    ...mapGetters('account', ['isAdmin']),
    userId() {
      return this.user.id
    },
    getFormMode() {
      return this.user.id ? 'Edit' : 'Add'
    },
    emailEditable() {
      return this.isAdmin || !this.user.id
    },
  },

  watch: {
    currentUser: function () {
      this.user = this.currentUser
      this.$refs.userForm.resetValidation()
    },
  },

  methods: {
    ...mapActions('user', ['saveUser', 'updateUser']),
    sendLoginEmail() {
      this.sendLoginEmailLoading = true

      ApiService.post('auth/client', { email: this.user.email }).then((response) => {
        this.sendLoginEmailLoading = false
        this.$toast.success('Login Email Sent')
      })
    },
    sendSwayLoginEmail() {
      this.sendLoginSwayEmailLoading = true

      ApiService.post('auth/swayClient', { email: this.user.email }).then((response) => {
        this.sendLoginSwayEmailLoading = false
        this.$toast.success('Login Email Sent')
      })
    },
    handleSaveButtonClicked() {
      if (!this.$refs.userForm.validate()) return

      this.userSaving = true

      if (this.user.id) {
        this.updateUser({ userData: this.user })
      } else {
        this.saveUser({ userData: this.user })
      }

      this.$emit('user-form-save', this.user)
    },
  },
})
</script>

<style scoped>
.user-controls {
  flex: 1;
  text-align: right;
}
</style>
