export enum UserStatus {
  ACTIVE,
  PAUSED,
}

export enum UserType {
  CLIENT = 'CLIENT',
  CLIENT_LITE = 'CLIENT_LITE',
  ADMIN = 'ADMIN',
}

export interface User {
  id: string
  email: string
  first_name: string
  last_name: string
  user_status: UserStatus
}
