// Types
type Drawer = {
  drawer: boolean
}

const state: Drawer = {
  drawer: true,
}

const mutations = {
  drawer(state: Drawer, payload: boolean) {
    state.drawer = payload
  },
}

const actions = {
  setDrawer({ commit }, payload: boolean) {
    commit('drawer', payload)
  },
}

const getters = {
  drawer: () => state.drawer,
}

export const ui = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
