<template>
  <v-expansion-panel @click.native.stop>
    <v-expansion-panel-header>
      <template #actions>
        <v-icon v-if="!panelEnabled" color="grey">mdi-eye-off</v-icon>
      </template>
      <template #default="{ open }">
        <v-row no-gutters>
          <v-col cols="4" :class="!panelEnabled ? 'grey--text' : ''">Voucher</v-col>
          <v-col cols="7">
            <v-switch v-if="open" v-model="flowEnabled" label="Enabled" class="mt-0 float-right" @click.stop="updatePanelData()" />
          </v-col>
        </v-row>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="title"
                :disabled="!panelEnabled"
                outlined
                label="Title"
                append-icon="mdi-restore"
                @blur="updatePanelData()"
                @click:append="resetTitle()"
                @keydown.enter="$event.target.blur()"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="subtitle"
                :disabled="!panelEnabled"
                outlined
                label="Subtitle"
                append-icon="mdi-restore"
                @blur="updatePanelData()"
                @click:append="resetSubtitle()"
                @keydown.enter="$event.target.blur()"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-list flat class="mt-n1">
                <SwayOfferSteps :steps="steps" :default-steps="voucherDataFlowDefaults.steps" :enabled="panelEnabled" title="Voucher Steps" @save="saveSteps" />
              </v-list>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <legend :class="['v-label theme--light', !panelEnabled ? 'grey--text' : '']" style="font-size: 14px">Coupon</legend>
                </v-col>
              </v-row>

              <v-row class="mt-n4">
                <v-col cols="12">
                  <ImageUploadForm
                    image-type="swayOfferVoucherImageCoupon"
                    :edit-mode="true"
                    :allow-image-deletion="true"
                    :image-url="couponImgUrl"
                    class="mb-4"
                    @image-deleted="handleUpdateCouponImage"
                    @image-uploaded="handleUpdateCouponImage"
                  >
                    <h2>Click to upload image</h2>
                  </ImageUploadForm>
                </v-col>
              </v-row>
              <v-row class="mt-n4">
                <v-col cols="12">
                  <v-select
                    v-model="couponSize"
                    :disabled="!couponImgUrl || couponImgUrl.length < 0"
                    :items="['small', 'medium', 'large']"
                    outlined
                    label="Coupon Image Size"
                    @change="updatePanelData()"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-n4">
                <v-col cols="12">
                  <v-switch v-model="couponOutline" label="Show Coupon Outline" class="mt-0" @click.stop="updatePanelData()" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import ImageUploadForm from '@/components/ImageUploadForm'
import SwayOfferSteps from '@/components/SwayOffers/SwayOfferSteps'

export default Vue.extend({
  name: 'SwayVoucherFlowPanel',

  components: { ImageUploadForm, SwayOfferSteps },

  props: {
    swayOfferId: {
      type: String,
      required: true,
    },
    panelEnabled: {
      type: Boolean,
      default: false,
    },
    panelData: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    flowEnabled: false,
    title: null,
    subtitle: null,
    steps: [],
    newVoucherStep: '',
    couponImgUrl: '',
    couponSize: 'medium',
    couponOutline: false,
  }),

  computed: {
    ...mapGetters('swayoffer', ['voucherDataFlowDefaults']),
  },

  mounted: function () {
    this.flowEnabled = this.panelEnabled
    this.title = this.panelData.title || null
    this.subtitle = this.panelData.subtitle || null
    this.steps = this.panelData.steps || []
    this.couponImgUrl = this.panelData.couponImgUrl || ''
    this.couponSize = this.panelData.couponSize || 'medium'
    this.couponShape = this.panelData.couponShape || 'square'
    this.couponOutline = this.panelData.couponOutline
  },

  methods: {
    ...mapActions('swayoffer', ['updateSwayOfferFlow']),

    saveSteps(newSteps) {
      this.steps = [...newSteps]
      this.updatePanelData()
    },

    resetTitle() {
      this.title = this.voucherDataFlowDefaults.title
      this.updatePanelData()
    },

    resetSubtitle() {
      this.subtitle = this.voucherDataFlowDefaults.subtitle
      this.updatePanelData()
    },

    resetCouponUrl() {
      this.couponImgUrl = ''
      this.updatePanelData()
    },

    handleUpdateCouponImage(event) {
      this.couponImgUrl = event.image_url
      this.updatePanelData()
    },

    updatePanelData() {
      this.updateSwayOfferFlow({
        swayOfferId: this.swayOfferId,
        swayOfferFlow: {
          end_type: this.flowEnabled ? 'VOUCHER' : 'WHATS_NEXT',
          voucher_data: {
            ...this.panelData,
            title: this.title,
            subtitle: this.subtitle,
            steps: this.steps,
            couponImgUrl: this.couponImgUrl,
            couponSize: this.couponSize,
            couponOutline: this.couponOutline,
          },
        },
      })
    },
  },
})
</script>
