<template>
  <v-row class="mb-2" align="center">
    <v-row>
      <v-col cols="1">
        <template v-if="address.short_url_id">
          <v-btn x-small text class="py-4" @click="copyUrl(address.short_url_id)">
            <v-icon style="cursor: pointer">mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <v-btn v-else :loading="urlGenerating" x-small text class="py-4" @click="generateUrl(address.id)">
          <v-icon color="primary" style="cursor: pointer">mdi-link-variant-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="11">
        <div class="body-2 text-left">{{ address.address }} {{ address.city }}, {{ address.state }}, {{ address.zipcode }}</div>
        <div v-if="address.short_url_id" class="caption text-left text--secondary">{{ baseShortUrl }}{{ address.short_url_id }}</div>
      </v-col>
    </v-row>
    <template v-if="address.short_url_id">
      <ShortUrlQrCode :url="baseShortUrl + address.short_url_id" :size="125" />
    </template>
  </v-row>
</template>

<script>
import Vue from 'vue'

import { ApiService } from '@/services/index'
import { copyToClipboard } from '@/mixins/copyToClipboard'
import ShortUrlQrCode from '@/components/Offers/OfferPanels/ShortUrlQrCode'

export default Vue.extend({
  name: 'SwayOfferAddress',

  components: { ShortUrlQrCode },

  mixins: [copyToClipboard],

  props: {
    swayOfferId: {
      type: String,
      required: true,
    },
    address: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    urlGenerating: false,
  }),

  computed: {
    baseShortUrl() {
      return process.env.VUE_APP_SHORT_URL
    },
  },

  methods: {
    copyUrl(urlId) {
      this.copyToClipboard(`${this.baseShortUrl}${urlId}`)
      this.$toast.success('Copied to clipboard!')
    },
    generateUrl(addressId) {
      this.urlGenerating = true

      ApiService.post(`swayOffer/address`, {
        addressId,
        swayOfferId: this.swayOfferId,
      }).then((response) => {
        this.$emit('sway-offer-address-url-generated', { addressId, shortUrlId: response.data.id })

        this.urlGenerating = false
      })
    },
  },
})
</script>
