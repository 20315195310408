<template>
  <div id="settings">
    <v-container>
      <v-row>
        <v-col cols="10">
          <v-text-field v-model="userSearchEmail" label="Users Email To Lookup" hide-details single-line @change="lookupEmail()" />
        </v-col>
        <v-col cols="2">
          <v-btn color="primary" @click="lookupEmail">Search</v-btn>
        </v-col>
      </v-row>

      <h1>Proofs</h1>
      <p>{{ swayProofs.length }} Proofs Found</p>

      <div v-if="loadingProofs">
        <app-spinner :spinner-size="40" class="mt-3" />
      </div>
      <div :key="`grid-container-1`" class="grid-container mt-4">
        <template v-for="proof in swayProofs">
          <SwayOfferProofApprovalCard :key="proof.id" :proof="proof" />
          <div :key="`hack-spacer-${proof.id}`" style="width: 100%; height: 1px"></div>
        </template>
      </div>
    </v-container>
  </div>
</template>

<script>
import { ApiService } from '@/services'
import SwayOfferProofApprovalCard from '@/components/SwayOffers/SwayOfferProofApprovalCard'

export default {
  name: 'SwayUserLookup',

  metaInfo: {
    title: 'AdminSettings',
  },
  components: { SwayOfferProofApprovalCard },
  data: () => ({
    userSearchEmail: null,
    swayProofs: [],
    loadingProofs: false,
  }),

  mounted() {},
  methods: {
    lookupEmail() {
      this.loadingProofs = true
      ApiService.get(`user/lookupSway/${this.userSearchEmail}`).then((r) => {
        if (r.error) {
          this.$toast.error(`Error updating value ${r.error}`)
        }
        this.swayProofs = r.data.swayProofs
        this.loadingProofs = false
      })
    },
  },
}
</script>

<style scoped>
#settings {
  width: 100%;
}
</style>
