var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-n6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{ref:"hashtags",attrs:{"items":[],"append-icon":null,"label":"Hashtags","hint":_vm.hashTagHints,"persistent-hint":"","flat":"","chips":"","outlined":"","multiple":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return _vm.$refs.hashtags.blur()},"change":function($event){return _vm.handleSaveHashtags()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"color":"primary lighten-1"}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(_vm.formatHashtag(item)))])])]}}]),model:{value:(_vm.hash_tags),callback:function ($$v) {_vm.hash_tags=$$v},expression:"hash_tags"}})],1)],1),_c('v-row',{staticClass:"mt-n6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"items":[],"append-icon":null,"label":"Instagram Mentions","hint":_vm.instagramMentionsHint,"persistent-hint":"","flat":"","chips":"","outlined":"","multiple":""},on:{"change":function($event){return _vm.handleSaveInstagramMentions()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"color":"primary lighten-1"}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(_vm.formatMention(item)))])])]}}]),model:{value:(_vm.instagram_mentions),callback:function ($$v) {_vm.instagram_mentions=$$v},expression:"instagram_mentions"}})],1)],1),_c('v-row',{staticClass:"mt-n6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"items":[],"append-icon":null,"label":"Facebook Mentions","hint":_vm.facebookMentionsHint,"persistent-hint":"","flat":"","chips":"","outlined":"","multiple":""},on:{"change":function($event){return _vm.handleSaveFacebookMentions()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"color":"primary lighten-1"}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(_vm.formatMention(item)))])])]}}]),model:{value:(_vm.facebook_mentions),callback:function ($$v) {_vm.facebook_mentions=$$v},expression:"facebook_mentions"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }