<template>
  <div id="networkOfferPreview">
    <div v-if="offer.offer_status === 'NEEDS_APPROVAL'" class="network-preview-container body-2">
      <v-row>
        <v-col align="left" justify="left">
          <div class="text-h6 font-weight-bold">Your Campaign Is Under Review 😎</div>
        </v-col>
      </v-row>
      <div class="network-preview-inner-container">
        <v-row>
          <v-col cols="12" class="text-left">
            <div>Once your campaign is approved, your offer will be live on localfluence.com and locals can begin to view and claim it for use.</div>
            <div class="mt-6">
              Use the preview dropdown below to see how your offer will be viewed by locals from when they first see your ad, to when they complete their post
            </div>
            <div v-if="!offer.offer_image_url" class="mt-6">
              <span class="primary--text">Needs Your Attention:</span>
              Your campaign cannot be approved until an image is uploaded

              <ImageUploadForm image-type="offer" :edit-mode="true" :image-url="offer.offer_image_url" class="mt-6" @image-uploaded="handleUpdateOfferImage">
                <h2>Click to upload image</h2>
                <h5 class="font-weight-light">380x200 image dimensions</h5>
              </ImageUploadForm>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-select v-model="previewMode" :items="previewModes" item-text="label" label="Preview Type" return-object outlined />
    <v-select
      v-if="offer.locations && offer.locations.length > 0"
      v-model="previewLocation"
      :items="offer.locations"
      :item-text="(location) => `${location.address}, ${location.city}, ${location.state}`"
      label="Offer Location"
      return-object
      outlined
    />

    <LfDealSurveyCard
      v-if="previewMode.label === 'Survey'"
      :id="offer.id"
      :business-brand="entity.brand"
      :image-url="offer.offer_image_url"
      :deal-title="offer.offer_summary"
      :expiration-date="offer.expiration_date"
      :vouchers-left="offer.offers_to_grant_per_month"
      :distance="offer.offer_type === 'LOCAL' ? 10 : null"
      :city="previewLocation ? previewLocation.city : null"
      class="inline-block"
    />

    <LfVoucherTicket
      v-if="previewMode.label === 'Voucher'"
      :business-brand="entity.brand"
      :business-logo-url="entity.logo_url"
      :address="previewLocation"
      :ticket-summary="offer.offer_summary"
      :special-instructions="offer.offer_special_instructions"
      :min-days-between-offer="offer.min_days_between_offer"
      :coupon-code="offer.coupon_code"
      :date-redeemed="dateRedeemed"
      :date-voucher-expires="offer.expiration_date"
      @activate-voucher-clicked="dateRedeemed = new Date()"
      @used-voucher-clicked="dateRedeemed = null"
    />

    <LfProofUpload
      v-if="previewMode.label.endsWith('Upload')"
      :key="previewMode.taskType.type"
      :task-type="previewMode.taskType"
      :business-brand="entity.brand"
      :hashtags="[...offer.hashtags, ...(previewLocation && previewLocation.hashtags ? previewLocation.hashtags : [])]"
      :facebook-mentions="[...offer.facebook_mentions, ...(previewLocation && previewLocation.facebook_mentions ? previewLocation.facebook_mentions : [])]"
      :instagram-mentions="[...offer.instagram_mentions, ...(previewLocation && previewLocation.instagram_mentions ? previewLocation.instagram_mentions : [])]"
      :facebook-review-url="previewLocation ? previewLocation.facebook_review_url : null"
      :google-review-url="previewLocation ? previewLocation.google_review_url : null"
    />
  </div>
</template>

<script>
import Vue from 'vue'

import { mapActions, mapGetters } from 'vuex'

import ImageUploadForm from '@/components/ImageUploadForm'
import { LfDealSurveyCard, LfVoucherTicket, LfProofUpload } from 'lf-common'

import taskTypes from '@/models/taskType'

const DEFAULT_PREVIEW_MODES = [{ label: 'Survey' }, { label: 'Voucher' }]

export default Vue.extend({
  name: 'NetworkOfferPreview',

  components: {
    ImageUploadForm,
    LfDealSurveyCard,
    LfVoucherTicket,
    LfProofUpload,
  },

  props: {
    offer: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    dateRedeemed: null,
    previewLocation: null,
    previewMode: DEFAULT_PREVIEW_MODES[0],
  }),

  computed: {
    ...mapGetters('business', ['entity']),
    previewModes: function () {
      const previewModes = [...DEFAULT_PREVIEW_MODES]

      this.offer.tasks.forEach((taskType) => {
        if (taskTypes[taskType]) {
          previewModes.push({ label: `${taskTypes[taskType].title} Upload`, taskType: taskTypes[taskType] })
        }
      })

      return previewModes
    },
  },

  mounted: function () {
    this.previewMode = this.previewModes[0]
    this.previewLocation = this.offer.locations?.length > 0 ? this.offer.locations[0] : null
  },

  methods: {
    ...mapActions('offer', ['updateOffer']),
    handleUpdateOfferImage: function (event) {
      this.updateOffer({ offerData: { ...this.offer, offer_image_url: event.image_url } })
    },
  },
})
</script>

<style scoped>
#networkOfferPreview {
  color: #707070 !important;
}

.network-preview-container {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fafafa;
}

.network-preview-inner-container {
  padding: 15px;
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #e8e8e8;
}

@media (max-width: 480px) {
  .deal-card {
    width: 100% !important;
  }
}
</style>
