<template>
  <div>
    <v-row class="mt-n6">
      <v-col cols="12">
        <v-combobox
          ref="hashtags"
          v-model="hash_tags"
          :items="[]"
          :append-icon="null"
          label="Hashtags"
          :hint="hashTagHints"
          persistent-hint
          flat
          chips
          outlined
          multiple
          @keydown.space="$refs.hashtags.blur()"
          @change="handleSaveHashtags()"
        >
          <template #selection="{ attrs, item, selected }">
            <v-chip v-bind="attrs" :input-value="selected" color="primary lighten-1">
              <strong>{{ formatHashtag(item) }}</strong>
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
    </v-row>

    <v-row class="mt-n6">
      <v-col cols="12">
        <v-combobox
          v-model="instagram_mentions"
          :items="[]"
          :append-icon="null"
          label="Instagram Mentions"
          :hint="instagramMentionsHint"
          persistent-hint
          flat
          chips
          outlined
          multiple
          @change="handleSaveInstagramMentions()"
        >
          <template #selection="{ attrs, item, selected }">
            <v-chip v-bind="attrs" :input-value="selected" color="primary lighten-1">
              <strong>{{ formatMention(item) }}</strong>
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
    </v-row>

    <v-row class="mt-n6">
      <v-col cols="12">
        <v-combobox
          v-model="facebook_mentions"
          :items="[]"
          :append-icon="null"
          label="Facebook Mentions"
          :hint="facebookMentionsHint"
          persistent-hint
          flat
          chips
          outlined
          multiple
          @change="handleSaveFacebookMentions()"
        >
          <template #selection="{ attrs, item, selected }">
            <v-chip v-bind="attrs" :input-value="selected" color="primary lighten-1">
              <strong>{{ formatMention(item) }}</strong>
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'

import { formatHashtag, formatMention } from '@/utils/formatsocials'

export default Vue.extend({
  name: 'SocialsForm',

  props: {
    hashTags: {
      type: Array,
      default: () => [],
    },
    instagramMentions: {
      type: Array,
      default: () => [],
    },
    facebookMentions: {
      type: Array,
      default: () => [],
    },
    hashTagHints: {
      type: String,
      default: null,
    },
    instagramMentionsHint: {
      type: String,
      default: null,
    },
    facebookMentionsHint: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    hash_tags: [],
    instagram_mentions: [],
    facebook_mentions: [],
  }),

  mounted: function () {
    this.hash_tags = this.hashTags
    this.instagram_mentions = this.instagramMentions
    this.facebook_mentions = this.facebookMentions
  },

  methods: {
    trim(strings) {
      return strings.map((string) => string.trim())
    },

    formatHashtag: (hashtag) => formatHashtag(hashtag),

    formatMention: (mention) => formatMention(mention),

    handleSaveHashtags() {
      this.$emit('save', { hashtags: this.trim(this.hash_tags) })
      this.$emit('save-hashtags', this.trim(this.hash_tags))
    },

    handleSaveInstagramMentions() {
      this.$emit('save', { instagram_mentions: this.trim(this.instagram_mentions) })
      this.$emit('save-instagram-mentions', this.trim(this.instagram_mentions))
    },

    handleSaveFacebookMentions() {
      this.$emit('save', { facebook_mentions: this.trim(this.facebook_mentions) })
      this.$emit('save-facebook-mentions', this.trim(this.facebook_mentions))
    },
  },
})
</script>
