<template>
  <router-view />
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'App',

  metaInfo: {
    title: 'Localfluence',
    titleTemplate: '%s | Localfluence',
  },

  mounted() {
    const thirdPartyWidgets = []

    if (process.env.VUE_APP_HEAP_API_KEY) {
      thirdPartyWidgets.push(`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
      heap.load(${process.env.VUE_APP_HEAP_API_KEY});`)
    }

    if (process.env.VUE_APP_HELP_SCOUT_API_KEY) {
      thirdPartyWidgets.push(`!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
      window.Beacon('init', '${process.env.VUE_APP_HELP_SCOUT_API_KEY}')`)
    }

    thirdPartyWidgets.forEach((widget) => {
      const thirdPartyWidget = document.createElement('script')
      thirdPartyWidget.append(widget)
      document.head.appendChild(thirdPartyWidget)
    })
  },
})
</script>

<style lang="scss" scoped></style>
