<template>
  <div>
    <VoucherDetailModal v-if="showVoucherModal" :voucher-id="selectedProofId" :amount="selectedAmount" @detail-modal-close="closeModal" />
    <SwayProofDetailModal v-if="showSwayProofModal" :sway-proof-id="selectedProofId" :amount="selectedAmount" @detail-modal-close="closeSwayDetailModal" />
    <v-card class="table-card" elevation="17">
      <v-row align="center" justify="center" class="mb-0">
        <v-col cols="2" align="center" justify="center">
          <a v-if="prevMonth" class="caption blue--text text--darken-3" @click="loadPreviousMonth"> {{ prevMonth | monthYear }}</a>
        </v-col>
        <v-col cols="8" align="center" justify="center">
          <h1 class="mt-1 ml-1 w-full font-weight-light text-center">{{ currentMonth | monthYear }}</h1>
        </v-col>
        <v-col cols="2" align="center" justify="center">
          <a v-if="nextMonth" class="caption blue--text text--darken-3" @click="loadNextMonth"> {{ nextMonth | monthYear }} &gt;&gt; </a>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="2" class="pa-3" align="center" justify="center">
          <span class="w-full caption font-weight-bold"> Activity </span>
        </v-col>
        <v-col cols="6" align="center" justify="center">
          <span class="w-full caption font-weight-bold"> Offer </span>
        </v-col>
        <v-col cols="2" align="center" justify="center"> <span class="w-full caption font-weight-bold"> Value </span> </v-col>
        <hr />
      </v-row>
      <v-row v-if="!dashboardData || dashboardData.length === 0">
        <v-col cols="12" class="pa-3" align="center" justify="center"> <h1>No Data</h1> </v-col>
      </v-row>
      <v-row v-for="row in dashboardData" v-else :key="row.id" class="mb-2" align="center" justify="center">
        <v-col cols="3" class="pa-3" align="center" justify="center">
          <div
            v-if="row.img_url && row.img_url.length > 0"
            class="album"
            @click="activeImage[row.detail_id] = (activeImage[row.detail_id] + 1) % row.img_url.length"
          >
            <img
              v-for="(curImg, index) in row.img_url"
              :key="`${row.id}-${index}`"
              :style="`z-index: ${100 - index}`"
              :src="curImg"
              width="120"
              height="120"
              :class="`rounded-xl img photo${index} ${activeImage[row.detail_id] === index ? 'active' : ''}`"
            />
          </div>
          <div v-else>
            <div style="width: 120px; height: 120px" class="loading-proof">
              <v-icon class="pt-11"> {{ iconFromType(row.type) }}</v-icon>
            </div>
          </div>
        </v-col>
        <v-col cols="4" align="left" justify="center" @click="openModal(row.detail_id, row.amount, row.proof_type)">
          <span class="caption blue--text">{{ row.activity_date | shorterDate }}</span>
          <br />
          <span class="offer-summary">
            {{ row.offer_summary }}
          </span>
          <span v-if="row.proof_type" class="proof-type"> {{ row.proof_type | prettyString }} </span>
        </v-col>
        <v-col cols="3" align="center" justify="center" @click="openModal(row.detail_id, row.amount, row.proof_type)">
          <span v-if="!row.amount" class="text-body-2 font-weight-light">--</span>
          <span v-else :class="['text-h4', 'font-weight-medium', colorFromType(row.type)].join(' ')">${{ row.amount }}</span>
          <br />
          <span :class="['caption', 'font-weight-medium', colorFromType(row.type)].join(' ')">{{
            actionName(row.type, row.proof_types, row.activity_date)
          }}</span>
        </v-col>
        <hr />
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue'
import { ApiService } from '@/services/index'
import VoucherDetailModal from '@/components/Dashboard/VoucherDetailModal'
import SwayProofDetailModal from '@/components/Dashboard/SwayProofDetailModal'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import minMax from 'dayjs/plugin/minMax'
dayjs.extend(utc)
dayjs.extend(minMax)

export default Vue.extend({
  name: 'ActivityLogTable',

  components: { VoucherDetailModal, SwayProofDetailModal },
  props: {
    businessCreated: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    dashboardData: {},
    loading: false,
    showVoucherModal: false,
    showSwayProofModal: false,
    selectedProofId: null,
    selectedAmount: null,
    currentMonth: dayjs(),
    activeImage: {},
  }),
  computed: {
    prevMonth() {
      const prevMonth = dayjs(this.currentMonth).subtract(1, 'month')
      if (this.businessCreated && prevMonth.isBefore(dayjs(this.businessCreated).startOf('month'))) return null
      return prevMonth
    },
    nextMonth() {
      const nextMonth = dayjs(this.currentMonth).add(1, 'month').startOf('month')
      if (nextMonth.isAfter(dayjs().startOf('month'))) return null
      return nextMonth
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    openModal(proofId, amount, proofType) {
      if (!proofId) return
      this.selectedProofId = proofId
      this.selectedAmount = amount
      if (proofType.indexOf('Sway', 0) >= 0) {
        this.showSwayProofModal = true
      } else {
        this.showVoucherModal = true
      }
    },
    closeModal() {
      this.selectedProofId = null
      this.selectedAmount = null
      this.showVoucherModal = false
    },
    closeSwayDetailModal(newType, wasDeleted) {
      if (newType || wasDeleted) {
        const curProof = this.dashboardData.find((e) => e.detail_id === this.selectedProofId)
        if (newType) {
          curProof.proof_type = `Sway ${newType}`
        }
        if (wasDeleted) {
          this.dashboardData = this.dashboardData.filter((e) => e.detail_id != this.selectedProofId)
        }
      }

      this.selectedProofId = null
      this.selectedAmount = null
      this.showSwayProofModal = false
    },
    loadPreviousMonth() {
      this.currentMonth = dayjs(this.currentMonth).subtract(1, 'month')
      this.loadData()
    },
    loadNextMonth() {
      this.currentMonth = dayjs(this.currentMonth).add(1, 'month')
      this.loadData()
    },
    loadData() {
      this.loading = true
      ApiService.get(`dashboard/activity/${this.currentMonth.toISOString()}`).then((response) => {
        this.dashboardData = response.data
        // This craziness makes a map of ids with a 0 for the count
        this.activeImage = response.data.reduce((p, v) => ({ ...p, [v.detail_id]: 0 }), {})
        if (this.dashboardData.length === 0) {
          if (!this.currentMonth) this.currentMonth = dayjs().startOf('month')
        } else {
          this.currentMonth = dayjs.min(this.dashboardData.map((e) => dayjs(e.activity_date).utc().startOf('month')))
          this.currentMonth = this.currentMonth.utc().startOf('month')
        }
        this.loading = false
      })
    },
    iconFromType(type) {
      switch (type) {
        case 'post':
          return 'mdi-account-arrow-up'

        case 'redeem':
          return 'mdi-timer-sand'

        case 'review':
          return 'mdi-star-check'

        case 'offer_starts':
          return 'mdi-play'

        case 'offer_end':
          return 'mdi-alert-octagon'

        default:
          return ''
          break
      }
    },
    actionName(type, types, date) {
      switch (type) {
        case 'offer_starts':
          return 'STARTED'

        case 'offer_end':
          return new Date(date) > new Date() ? 'STOPPING' : 'STOPPED'

        default:
          return ['TICKET', ...types].join(' + ').replace(/\_/g, ' ').toUpperCase()
          break
      }
    },
    colorFromType(type) {
      switch (type) {
        case 'post':
          return 'post-color'

        case 'redeem':
          return 'ticket-color'

        case 'review':
          return 'review-color'

        case 'offer_end':
          return 'red--text'

        case 'offer_starts':
          return 'green--text'

        default:
          return ''
          break
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.table-card {
  cursor: pointer;
  border-radius: 12px;
}

hr {
  overflow: visible;
  border: none;
  background-color: lightgray;
  height: 1px;
  display: block;
  width: 85%;
}

.offer-summary {
  font-family: arial, helvetica, sans-serif;
  font-size: 12px;
  line-height: 12pt;
  font-weight: 900;
  color: black;
}

.proof-type {
  font-family: arial, helvetica, sans-serif;
  font-size: 8px;
  line-height: 12pt;
  font-weight: 500;
  color: grey;
  margin-top: 0px;
  display: block;
}

.review-color {
  color: #98e3f8 !important;
}

.post-color {
  color: #1f487e !important;
}

.ticket-color {
  color: #376996 !important;
}

.loading-proof {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='25' ry='25' stroke='lightgray' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 25px;
}

/* the A tag */
.album {
  position: relative;
  z-index: 5;
  height: 130px;
  width: 130px;
  display: block;
}

/* the images */
.album img {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 1px 1px 2px #666;
  display: block;
  transition: all 0.3s ease-in-out;
}

.album .active {
  transform: rotate(0) translate(0, 0px) scale(1.1) !important;
  z-index: 101 !important;
}

.album .photo1 {
  transform: rotate(6deg) translate(15px, 0px) scale(0.9);
}

.album .photo2 {
  transform: rotate(6deg) translate(30px, 0px) scale(0.8);
}

.album .photo3 {
  transform: rotate(6deg) translate(45px, 0px) scale(0.7);
}

.album .photo4 {
  transform: rotate(6deg) translate(60px, 0px) scale(0.6);
}
</style>
