var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("Social Media Settings")]),_c('div',{staticClass:"ml-2"},[_c('h4',[_vm._v("Offer Defaults")]),_c('v-row',[_c('v-col',[_vm._v(" These are used as the defaults when you create a new offer. You can change these after you create the new offer if you're running a special promotion. ")])],1),_c('v-form',{ref:"businessSettingsForm"},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{ref:"hashtags",attrs:{"items":[],"label":"Default Hashtags","hint":"Locals will be required to use these hashtags in their social media posts. These tags will be used by default in your offers.","flat":"","chips":"","outlined":"","multiple":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return _vm.$refs.hashtags.blur()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"color":"primary lighten-1"}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(_vm.formatHashtag(item)))])])]}}]),model:{value:(_vm.settings.default_hashtags),callback:function ($$v) {_vm.$set(_vm.settings, "default_hashtags", $$v)},expression:"settings.default_hashtags"}})],1)],1),_c('v-row',{staticClass:"mt-n2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"items":[],"label":"Instagram Mentions","hint":"Locals will be asked to use these Instagram mentions in their social media posts. This setting will be used by default in your offers.","flat":"","chips":"","outlined":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"color":"primary lighten-1"}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(_vm.formatMention(item)))])])]}}]),model:{value:(_vm.settings.default_instagram_mentions),callback:function ($$v) {_vm.$set(_vm.settings, "default_instagram_mentions", $$v)},expression:"settings.default_instagram_mentions"}})],1)],1),_c('v-row',{staticClass:"mt-n2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"items":[],"label":"Facebook Mentions","hint":"Locals will be asked to use these Facebook mentions in their social media posts. This setting will be used by default in your offers.","flat":"","chips":"","outlined":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"color":"primary lighten-1"}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(_vm.formatMention(item)))])])]}}]),model:{value:(_vm.settings.default_facebook_mentions),callback:function ($$v) {_vm.$set(_vm.settings, "default_facebook_mentions", $$v)},expression:"settings.default_facebook_mentions"}})],1)],1),_c('h4',[_vm._v("Facebook Review Helper")]),_c('v-row',[_c('v-col',[_vm._v("This is used in the app to direct users to your business facebook review page. ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Facebook Review Url","rules":[_vm.facebookReviewUrlRule]},model:{value:(_vm.settings.facebook_review_url),callback:function ($$v) {_vm.$set(_vm.settings, "facebook_review_url", $$v)},expression:"settings.facebook_review_url"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":_vm.handleSaveButtonClicked}},[_vm._v("Save")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }