<template>
  <v-dialog :value="true" persistent max-width="450">
    <v-card>
      <v-card-title class="headline">{{ action }} Proof</v-card-title>

      <v-card-text>
        <v-form ref="reviewForm">
          <v-select
            v-model="selectedReviewReason"
            :items="reviewReasons"
            :loading="reviewReasonsLoading"
            :disabled="reviewRequestLoading"
            outlined
            clearable
            return-object
            item-text="title"
            label="Select a reason"
          />
          <v-text-field v-model="reviewComments" :disabled="reviewRequestLoading" outlined label="Comments" />

          <div v-if="!loadingCode" class="d-flex align-center">
            <div>
              <v-text-field v-model="code" :disabled="loadingCode" outlined label="Code" />
            </div>
            <div v-if="codeStatus === 'OK'" class="d-flex align-center">
              <v-icon style="cursor: pointer" class="ml-2" @click="copyCode()">mdi-content-copy</v-icon> {{ countLeft }} left
            </div>
          </div>

          <div class="d-flex justify-center">
            <v-btn
              :loading="reviewRequestLoading"
              :disabled="reviewType === 'DENIED' && !selectedReviewReason && !selectedReviewReason && !reviewComments"
              color="primary darken-1"
              class="mr-2"
              @click="reviewProof()"
              >{{ action }}
            </v-btn>
            <v-btn :loading="reviewRequestLoading" :disabled="!selectedReviewReason" color="primary darken-1" class="ml-2" @click="reviewProof(true)">
              {{ action }} & Email <v-icon dark class="ml-2">mdi-email-fast</v-icon>
            </v-btn>
          </div>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="reviewRequestLoading" color="darken-1" text @click="$emit('sway-offer-deny-modal-close')">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { ApiService } from '@/services/index'
import { copyToClipboard } from '@/mixins/copyToClipboard'

export default Vue.extend({
  name: 'SwayProofReviewModal',

  mixins: [copyToClipboard],

  props: {
    proofId: {
      type: String,
      required: true,
    },
    reviewType: {
      type: String,
      required: true,
      validator(value) {
        return ['APPROVED', 'DENIED'].includes(value)
      },
    },
  },

  data: () => ({
    reviewReasons: [],
    reviewComments: '',
    loadingCode: true,
    code: '',
    codeStatus: null,
    countLeft: null,
    selectedReviewReason: null,
    reviewReasonsLoading: true,
    reviewRequestLoading: false,
  }),

  computed: {
    action() {
      return this.reviewType === 'APPROVED' ? 'Approve' : 'Deny'
    },
  },

  mounted() {
    ApiService.get(`adminSettings/swayProofReviewTemplates`)
      .then((response) => {
        if (response.data.error) {
          this.$toast.error(`Error fetching review reasons`)
          return
        }

        this.reviewReasons = response.data.filter((reviewReason) => reviewReason.type === this.reviewType)
        ApiService.get(`swayOffer/defaultApprovalEmailForProof/${this.proofId}`)
          .then((response) => {
            if (response.data.error || !response.data.defaultApprovalId) {
              this.$toast.error(`No default configured`)
              return
            }
            const theMatchingReasons = this.reviewReasons.filter((e) => e.email_template_id.trim() === response.data.defaultApprovalId.trim())
            if (theMatchingReasons && theMatchingReasons.length > 0) {
              this.selectedReviewReason = theMatchingReasons[0]
            }
          })
          .finally(() => {
            this.loadingCode = false
          })
      })
      .finally(() => {
        this.reviewReasonsLoading = false
      })

    this.loadingCode = true
    ApiService.get(`coupon/codeList/sway/getNextForProof/${this.proofId}`)
      .then((response) => {
        if (response.data.error) {
          this.$toast.error(`Error fetching code`)
          return
        }
        if (response.data.codeStatus === 'OK') {
          this.code = response.data.code
          this.codeStatus = response.data.codeStatus
          this.countLeft = response.data.countLeft
        }
      })
      .finally(() => {
        this.loadingCode = false
      })
  },

  methods: {
    reviewProof(sendEmail) {
      if (sendEmail && !this.selectedReviewReason) {
        this.$toast.error(`Select a review reason`)
        return
      }

      if (this.reviewType === 'DENIED' && !this.selectedReviewReason && !this.reviewComments) {
        this.$toast.error(`Select a review reason or leave a comment`)
        return
      }

      this.reviewRequestLoading = true

      ApiService.put(`reverseFlow/proof/review/${this.proofId}`, {
        reviewType: this.reviewType,
        reviewComments: this.reviewComments,
        reviewTemplateTitle: this.selectedReviewReason ? this.selectedReviewReason.title : null,
        reviewEmailTemplateId: sendEmail ? this.selectedReviewReason.email_template_id : null,
        couponCode: this.codeStatus === 'OK' ? this.code : undefined,
      })
        .then(() => {
          this.$emit('sway-offer-deny-modal-proof-reviewed')
          this.$toast.success(`Proof Reviewed`)
        })
        .catch(() => {
          this.$toast.error(`Error reviewing proof`)
          this.reviewRequestLoading = false
        })
    },
    copyCode() {
      this.copyToClipboard(this.code.trim())
      this.$toast.success(`${this.code} Copied to clipboard!`)
    },
  },
})
</script>
