import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import Index from '@/views/Index.vue'
import Auth from '@/views/Auth.vue'

import Timeline from '@/views/pages/Timeline.vue'
import TimelineReview from '@/views/pages/TimelineReview.vue'
import Reports from '@/views/pages/Reports.vue'
import Users from '@/views/pages/Users.vue'
import Locations from '@/views/pages/Locations.vue'
import Offers from '@/views/pages/Offers.vue'
import ClientLiteOffers from '@/views/pages/ClientLiteOffers.vue'
import SwayOffers from '@/views/pages/SwayOffers.vue'
import Account from '@/views/pages/Account.vue'
import Login from '@/views/pages/auth/Login.vue'
import TokenLogin from '@/views/pages/auth/TokenLogin.vue'
import CouponCodes from '@/views/pages/CouponCodes.vue'
import SocialProofWidget from '@/views/pages/SocialProofWidget.vue'
import SwayWallOfLove from '@/views/pages/SwayWallOfLove.vue'
import Settings from '@/views/pages/Settings.vue'
import Setup from '@/views/pages/Setup.vue'
import Dashboard from '@/views/pages/Dashboard.vue'
import SwayOffersLandingPage from '@/views/pages/SwayOffersLandingPage.vue'

import MailingLists from '@/views/pages/adminControls/MailingLists.vue'
import SimpleReports from '@/views/pages/adminControls/SimpleReports.vue'
import AdminSettings from '@/views/pages/adminControls/AdminSettings.vue'
import SwayUserLookup from '@/views/pages/adminControls/SwayUserLookup.vue'
import SwayProofReviewTemplates from '@/views/pages/SwayProofReviewTemplates.vue'
import ChargebeeControl from '@/views/pages/adminControls/ChargebeeControl.vue'
import AdminControls from '@/views/pages/adminControls/AdminControls.vue'

import { UserType } from '@/models/User'
import { TokenService, UserService } from '@/services'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Index,
    children: [
      {
        name: 'Setup',
        path: '',
        alias: '/setup',
        component: Setup,
        meta: { authorize: [UserType.CLIENT] },
      },
      {
        name: 'Timeline',
        path: '/timeline',
        component: Timeline,
        meta: { authorize: [UserType.CLIENT, UserType.CLIENT_LITE], icon: 'mdi-map-marker-distance' },
      },
      {
        name: 'Timeline Review',
        path: '/timelineReview',
        component: TimelineReview,
        meta: { authorize: [UserType.ADMIN], icon: 'mdi-map-marker-path' },
      },
      {
        name: 'Reports',
        path: '/reports',
        component: Reports,
        meta: { authorize: [UserType.CLIENT], icon: 'mdi-finance' },
      },
      {
        name: 'Users',
        path: '/users',
        component: Users,
        meta: { authorize: [UserType.CLIENT], icon: 'mdi-account-check-outline' },
      },
      {
        name: 'Locations',
        path: '/locations',
        component: Locations,
        meta: { authorize: [UserType.CLIENT], icon: 'mdi-map-marker' },
      },
      {
        name: 'Offers',
        path: '/offers',
        component: Offers,
        meta: { authorize: [UserType.CLIENT], icon: 'mdi-tag' },
      },
      {
        name: UserService.isAdmin() ? 'Client Lite Campaigns' : 'Campaigns',
        path: '/clientLiteOffers',
        component: ClientLiteOffers,
        meta: { authorize: [UserType.CLIENT_LITE], icon: 'mdi-tag' },
      },
      {
        name: 'Sway Offers',
        path: '/swayOffers',
        component: SwayOffers,
        meta: { authorize: [], icon: 'mdi-tag-text' },
      },
      {
        name: 'Sway Offers Landing Page',
        path: '/swayOffersLandingPage',
        component: SwayOffersLandingPage,
        meta: { authorize: [UserType.ADMIN], icon: 'mdi-tag-text' },
      },
      {
        name: 'Account Details',
        path: '/account',
        component: Account,
        meta: { authorize: [UserType.CLIENT, UserType.CLIENT_LITE], hidden: true },
      },
      {
        name: 'Coupon Codes',
        path: '/couponCodes',
        component: CouponCodes,
        meta: { authorize: [], icon: 'mdi-ticket-percent' },
      },
      {
        name: 'Social Proof Widget',
        path: '/socialProofWidget',
        component: SocialProofWidget,
        meta: { authorize: [], icon: 'mdi-puzzle' },
      },
      {
        name: 'Sway Wall Of Love',
        path: '/swayWallOfLove',
        component: SwayWallOfLove,
        meta: { authorize: [UserType.ADMIN], icon: 'mdi-puzzle' },
      },
      {
        name: 'Settings',
        path: '/settings',
        component: Settings,
        meta: { authorize: [UserType.CLIENT], icon: 'mdi-cog' },
      },
      {
        name: 'Admin Settings',
        path: '/adminSettings',
        component: AdminSettings,
        meta: { authorize: [], hidden: true },
      },
      {
        name: 'Admin Controls',
        path: '/admin',
        component: AdminControls,
        meta: { authorize: [UserType.ADMIN], hidden: true },
      },
      {
        name: 'Simple Reports',
        path: '/simpleReports',
        component: SimpleReports,
        meta: { authorize: [], hidden: true },
      },
      {
        name: 'Mailing List',
        path: '/mailingLists',
        component: MailingLists,
        meta: { authorize: [], hidden: true },
      },
      {
        name: 'Dashboard',
        path: '/dashboard',
        component: Dashboard,
        meta: { authorize: [], hidden: true },
      },
      {
        name: 'User Lookup',
        path: '/userLookup',
        component: SwayUserLookup,
        meta: { authorize: [UserType.ADMIN], hidden: true },
      },
      {
        name: 'Chargbee Control',
        path: '/chargebeeControl',
        component: ChargebeeControl,
        meta: { authorize: [UserType.ADMIN], hidden: true },
      },
      {
        name: 'Sway Proof Review Templates',
        path: '/swayProofReviewTemplates',
        component: SwayProofReviewTemplates,
        meta: { authorize: [UserType.ADMIN], hidden: true },
      },
    ],
  },
  {
    path: '/auth',
    component: Auth,
    children: [
      {
        name: 'Login',
        path: '',
        component: Login,
      },
      {
        path: '/auth/login/:token',
        name: 'TokenLogin',
        component: TokenLogin,
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
          })
        }, 100)
      })
    }
  },
})

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta

  if (authorize) {
    if (!TokenService.getToken()) {
      return next({
        path: '/auth',
        query: { redirect: to.fullPath },
      })
    }

    if (UserService.isAdmin()) {
      return next()
    }

    const currentUserType = UserService.getUserType()
    if (!authorize.includes(currentUserType)) {
      return next({
        path: '/timeline',
      })
    }
  }

  next()
})

export default router
