<template>
  <v-expansion-panel @click.native.stop>
    <v-expansion-panel-header>
      <template #actions>
        <v-icon v-if="!panelEnabled" color="grey">mdi-eye-off</v-icon>
      </template>
      <template #default="{ open }">
        <v-row no-gutters>
          <v-col cols="4" :class="!panelEnabled ? 'grey--text' : ''">What's Next</v-col>
          <v-col cols="7">
            <v-switch v-if="open" v-model="flowEnabled" label="Enabled" class="mt-0 float-right" @click.stop="updatePanelData()" />
          </v-col>
        </v-row>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="title"
            :disabled="!panelEnabled"
            outlined
            label="Title"
            append-icon="mdi-restore"
            @blur="updatePanelData()"
            @click:append="resetTitle()"
            @keydown.enter="$event.target.blur()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="subtitle"
            :disabled="!panelEnabled"
            outlined
            label="Subtitle"
            append-icon="mdi-restore"
            @blur="updatePanelData()"
            @click:append="resetSubtitle()"
            @keydown.enter="$event.target.blur()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="stepsTitle"
            :disabled="!panelEnabled"
            outlined
            label="What's Next Steps Title"
            append-icon="mdi-restore"
            @blur="updatePanelData()"
            @click:append="resetStepsTitle()"
            @keydown.enter="$event.target.blur()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <SwayOfferSteps
            :steps="steps"
            :default-steps="whatsNextDataFlowDefaults.steps"
            :enabled="panelEnabled"
            :include-icon="true"
            :include-sub-title="true"
            title="Whats Next Steps"
            @save="saveSteps"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-radio-group v-model="stepsIncludeSocial" :disabled="!panelEnabled" label="Include social step?" class="mt-n2" @change="updatePanelData()">
            <v-radio label="Yes" :value="true" />
            <v-radio label="No" :value="false" />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row v-if="stepsIncludeSocial">
        <v-col cols="12">
          <v-text-field
            v-model="fbMention"
            :disabled="!panelEnabled"
            outlined
            label="Facebook Mention"
            @blur="updatePanelData()"
            @keydown.enter="$event.target.blur()"
          />
          <v-text-field
            v-model="igMention"
            :disabled="!panelEnabled"
            outlined
            label="Instagram Mention"
            @blur="updatePanelData()"
            @keydown.enter="$event.target.blur()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-radio-group v-model="hasReferralCode" :disabled="!panelEnabled" label="Have referral code?" class="mt-n2" @change="updatePanelData()">
            <v-radio label="Yes" :value="true" />
            <v-radio label="No" :value="false" />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row v-if="hasReferralCode">
        <v-col cols="12">
          <v-text-field
            v-model="referTitle"
            :disabled="!panelEnabled"
            outlined
            label="Referral Title"
            @blur="updatePanelData()"
            @keydown.enter="$event.target.blur()"
          />
          <v-text-field
            v-model="referSubtitle"
            :disabled="!panelEnabled"
            outlined
            label="Referral Subtitle"
            @blur="updatePanelData()"
            @keydown.enter="$event.target.blur()"
          />

          <v-text-field
            v-model="referCall"
            :disabled="!panelEnabled"
            outlined
            label="Referral Call to Action"
            @blur="updatePanelData()"
            @keydown.enter="$event.target.blur()"
          />
          <v-text-field
            v-model="referCode"
            :disabled="!panelEnabled"
            outlined
            label="Referral Code"
            @blur="updatePanelData()"
            @keydown.enter="$event.target.blur()"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import SwayOfferSteps from '@/components/SwayOffers/SwayOfferSteps'

export default Vue.extend({
  name: 'SwayWhatsNextFlowPanel',

  components: { SwayOfferSteps },

  props: {
    swayOfferId: {
      type: String,
      required: true,
    },
    panelEnabled: {
      type: Boolean,
      default: false,
    },
    panelData: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    flowEnabled: false,
    title: null,
    subtitle: null,
    stepsTitle: null,
    steps: [],
    stepsIncludeSocial: false,
    fbMention: null,
    igMention: null,
    hasReferralCode: false,
    referTitle: null,
    referSubtitle: null,
    referCall: null,
    referCode: null,
  }),

  computed: {
    ...mapGetters('swayoffer', ['whatsNextDataFlowDefaults']),
  },

  mounted: function () {
    this.flowEnabled = this.panelEnabled
    this.title = this.panelData.title || null
    this.subtitle = this.panelData.subtitle || null
    this.stepsTitle = this.panelData.steps_title || null
    this.steps = this.panelData.steps || []
    this.stepsIncludeSocial = this.panelData.steps_include_social || false
    this.fbMention = this.panelData.fb_mention || null
    this.igMention = this.panelData.ig_mention || null
    this.hasReferralCode = this.panelData.hasReferralCode || null
    this.referTitle = this.panelData.referTitle || null
    this.referSubtitle = this.panelData.referSubtitle || null
    this.referCall = this.panelData.referCall || null
    this.referCode = this.panelData.referCode || null
  },

  methods: {
    ...mapActions('swayoffer', ['updateSwayOfferFlow']),

    resetTitle() {
      this.title = this.whatsNextDataFlowDefaults.title
      this.updatePanelData()
    },

    resetSubtitle() {
      this.subtitle = this.whatsNextDataFlowDefaults.subtitle
      this.updatePanelData()
    },

    resetStepsTitle() {
      this.stepsTitle = this.whatsNextDataFlowDefaults.steps_title
      this.updatePanelData()
    },

    saveSteps(newSteps) {
      this.steps = [...newSteps]
      this.updatePanelData()
    },

    updatePanelData() {
      this.updateSwayOfferFlow({
        swayOfferId: this.swayOfferId,
        swayOfferFlow: {
          end_type: this.flowEnabled ? 'WHATS_NEXT' : 'VOUCHER',
          whats_next_data: {
            ...this.panelData,
            title: this.title,
            subtitle: this.subtitle,
            steps_title: this.stepsTitle,
            steps: this.steps,
            steps_include_social: this.stepsIncludeSocial,
            fb_mention: this.fbMention,
            ig_mention: this.igMention,
            hasReferralCode: this.hasReferralCode,
            referTitle: this.referTitle,
            referSubtitle: this.referSubtitle,
            referCall: this.referCall,
            referCode: this.referCode,
          },
        },
      })
    },
  },
})
</script>
