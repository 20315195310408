<template>
  <v-expansion-panel @click.native.stop>
    <v-expansion-panel-header>Side Effect</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-n6">
        <v-col cols="12">
          <p class="text-left font-weight-regular caption text--secondary">Select from the possible addons to charge</p>
        </v-col>
      </v-row>

      <v-row class="mt-n6">
        <v-col cols="12">
          <v-text-field
            v-model="proofCreatedEmailTemplateId"
            :persistent-hint="true"
            outlined
            class="mt-2"
            label="Proof Created Email Template ID"
            hint="We'll use this template to send an email once the user submits their email address"
            @blur="updatePanelData()"
            @keydown.enter="$event.target.blur()"
          />
        </v-col>
      </v-row>

      <v-row class="mt-n2">
        <v-col cols="12">
          <v-select
            v-model="selectedReviewReason"
            :items="reviewReasons"
            :loading="reviewReasonsLoading"
            :disabled="reviewRequestLoading"
            outlined
            clearable
            return-object
            item-text="title"
            label="Default Approve Email Template ID"
            @change="updatePanelData()"
          />
        </v-col>
      </v-row>

      <v-row class="mt-n6">
        <v-col cols="12">
          <v-select
            v-model="selectedAddonId"
            :items="addons"
            item-text="prettyName"
            item-value="id"
            outlined
            label="Chargebee Addon"
            @change="updatePanelData()"
          />
        </v-col>
      </v-row>

      <v-row class="mt-n6">
        <v-col cols="12">
          <v-select
            v-model="autoCheckProofs"
            :items="proofLevels"
            item-text="label"
            item-value="level"
            outlined
            label="Review Level"
            @change="updatePanelData()"
          />
        </v-col>
      </v-row>

      <v-list v-if="showOcr" flat class="mt-n1">
        <v-text-field
          v-model="extraWordTitle"
          outlined
          label="Extra Words Requirement Title"
          @change="updatePanelData()"
          @keydown.enter="$event.target.blur()"
        />
        <v-text-field
          v-model="extraWordDescription"
          outlined
          label="Extra Words Description"
          @change="updatePanelData()"
          @keydown.enter="$event.target.blur()"
        />

        <legend class="v-label theme--light grey--text" style="font-size: 14px">Extra words to check</legend>
        <v-list-item v-for="(word, index) in extraWordCheck" :key="`extra-check-word-${index}`">
          <template #default>
            <v-list-item-action>
              <v-icon color="primary" @click="deleteExtraWord(index)">mdi-playlist-remove</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <span class="grey--text">{{ word }}</span>
            </v-list-item-content>
          </template>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-text-field v-model="newExtraWord" outlined label="New Extra Word" @blur="addExtraWord()" @keydown.enter="$event.target.blur()" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { ApiService } from '../../../services'

export default Vue.extend({
  name: 'SwaySideEffectPanel',

  components: {},

  props: {
    swayOfferId: {
      type: String,
      required: true,
    },
    panelData: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    addons: [],
    addonsLoading: true,
    selectedAddonId: '',
    autoCheckProofs: null,
    extraWordTitle: '',
    extraWordDescription: '',
    extraWordCheck: [],
    newExtraWord: '',
    proofCreatedEmailTemplateId: null,
    reviewReasons: [],
    selectedReviewReason: null,
    reviewReasonsLoading: true,
    reviewRequestLoading: false,
  }),
  computed: {
    proofLevels() {
      return [
        {
          label: 'Auto - No OCR, Approve all uploads',
          level: 'AUTO',
          showOcr: false,
        },
        {
          label: 'OCR Permissive - Show Proof Check but allow user overrides',
          level: 'OCR_PERMISSIVE',
          showOcr: true,
        },
        {
          label: 'OCR Strict - Show Proof Check but user overrides manually checked',
          level: 'OCR_STRICT',
          showOcr: true,
        },
        {
          label: 'OCR Manual - Show Proof check but also manually check',
          level: 'OCR_MANUAL',
          showOcr: true,
        },
        {
          label: 'Review - No OCR Review all',
          level: 'REVIEW',
          showOcr: false,
        },
      ]
    },
    showOcr() {
      const selectedLevel = this.proofLevels?.filter((e) => e.level === this.autoCheckProofs)

      if (selectedLevel && selectedLevel.length > 0) {
        return selectedLevel[0].showOcr
      } else return false
    },
  },
  mounted: function () {
    this.selectedAddonId = this.panelData?.chargebee_addon_id ? this.panelData?.chargebee_addon_id : 'noAddon'
    this.autoCheckProofs = this.panelData?.auto_check_proofs ? this.panelData?.auto_check_proofs : false
    this.extraWordTitle = this.panelData?.extra_word_title ? this.panelData?.extra_word_title : ''
    this.extraWordDescription = this.panelData?.extra_word_description ? this.panelData?.extra_word_description : ''
    this.extraWordCheck = this.panelData?.extra_word_check ? this.panelData?.extra_word_check : ''
    this.proofCreatedEmailTemplateId = this.panelData.proof_created_email_template_id || null

    ApiService.get(`adminSettings/swayProofReviewTemplates`)
      .then((response) => {
        if (response.data.error) {
          this.$toast.error(`Error fetching review reasons`)
          return
        }

        this.reviewReasons = response.data.filter((reviewReason) => reviewReason.type === 'APPROVED')

        if (this.panelData.default_approve_email_template_id) {
          this.selectedReviewReason = response.data.find((e) => e.email_template_id.trim() === this.panelData.default_approve_email_template_id.trim()) || null
        }
      })
      .finally(() => {
        this.reviewReasonsLoading = false
      })

    ApiService.get('/chargebeeAdmin/chargebeeAddons').then((r) => {
      this.addons = r.data.map((e) => {
        return { ...e, prettyName: `${e.id} - ${e.name}` }
      })
      this.addons.unshift({ id: 'noAddon', name: 'No Addon', prettyName: `No Addon` })
      this.addonsLoading = false
    })
  },

  methods: {
    ...mapActions('swayoffer', ['updateSwayOfferFlow']),
    updatePanelData() {
      this.updateSwayOfferFlow({
        swayOfferId: this.swayOfferId,
        swayOfferFlow: {
          side_effect_data: {
            ...this.panelData,
            chargebee_addon_id: this.selectedAddonId !== 'noAddon' ? this.selectedAddonId : null,
            auto_check_proofs: this.autoCheckProofs,
            extra_word_title: this.extraWordTitle,
            extra_word_description: this.extraWordDescription,
            extra_word_check: this.extraWordCheck,
            proof_created_email_template_id: this.proofCreatedEmailTemplateId,
            default_approve_email_template_id: this.selectedReviewReason ? this.selectedReviewReason.email_template_id : null,
          },
        },
      })
    },
    addExtraWord() {
      if (!this.newExtraWord || this.newExtraWord.trim().length === 0) return
      this.extraWordCheck.push(this.newExtraWord.trim())
      this.updatePanelData()
      this.newExtraWord = ''
    },
    deleteExtraWord(wordIdx) {
      this.extraWordCheck.splice(wordIdx, 1)
      this.updatePanelData()
    },
  },
})
</script>
