<template>
  <div id="swayOfferPreview" class="body-2">
    <div class="sway-preview-container">
      <v-select
        :value="previewMode"
        :items="previewModes"
        item-text="label"
        label="Preview Type"
        return-object
        outlined
        @change="$emit('sway-offer-preview-mode-change', $event)"
      />

      <div v-if="isCampaignPreviewMode">
        <v-row>
          <v-col align="left" justify="left">
            <div class="text-h6 font-weight-bold">Your campaign is created 🎉</div>
          </v-col>
        </v-row>
        <div class="sway-preview-inner-container">
          <v-row>
            <v-col cols="12" class="text-left">
              <div class="font-weight-bold text-subtitle-1 mb-2">Campaign URL</div>
              <div class="d-flex" align="center">
                <v-text-field :value="shortUrl" disabled outlined />
                <v-icon style="cursor: pointer" class="mt-n7 ml-2" @click="copyUrl()">mdi-content-copy</v-icon>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="text-left">
              <div class="font-weight-bold text-subtitle-1 mb-2">Campaign QR Code</div>
              <ShortUrlQrCode :url="shortUrl" :size="150" />

              <v-row v-if="addressesLoading">
                <v-col cols="12" class="text-h4 pt-0">
                  <app-spinner />
                </v-col>
              </v-row>
              <v-row v-else-if="addresses.length > 0">
                <v-col>
                  <v-divider />
                  <div class="font-weight-bold text-subtitle-1 mt-1">Per Location Campaign QR Code</div>
                </v-col>
              </v-row>

              <SwayOfferAddress
                v-for="address in addresses"
                :key="address.id"
                :address="address"
                :sway-offer-id="swayOffer.id"
                @sway-offer-address-url-generated="handleAddressUrlGenerated"
              />
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col class="text-left">
            <div class="text-h6 mt-4">Next: Choose how to advertise to your customers</div>
            <v-divider />
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-6">
          <v-col cols="1" class="pa-0">
            <v-img :src="require('@/assets/images/http-link-icon.png')" width="48" :eager="true" class="avatar" />
          </v-col>
          <v-col cols="10" class="pl-4 pt-0 text-left">
            <div class="mt-n1 body-2 font-weight-bold">USING YOUR CAMPAIGN URL TO ADVERTISE DIGITALLY</div>
          </v-col>
        </v-row>

        <v-row v-for="(item, index) in advertiseDigitallyContent" :key="`${swayOffer.id}-digital-${index}`" class="mt-6">
          <v-col cols="7" class="text-left">
            <div class="sway-preview-section-header">{{ item.title }}</div>
            <div class="mt-2">
              {{ item.content }}<br />
              <a :href="item.helpLink" target="_blank">Learn how</a>
            </div>
          </v-col>
          <v-col cols="5" class="text-left">
            <v-img :src="item.image" contain max-height="125" />
          </v-col>
        </v-row>

        <v-row justify="center" class="mt-10">
          <v-col cols="1" class="pa-0">
            <v-img :src="require('@/assets/images/qr-code_icon.png')" :eager="true" class="avatar" />
          </v-col>
          <v-col cols="10" class="pl-4 pt-0 text-left">
            <div class="mt-n1 body-2 font-weight-bold">USING YOUR CAMPAIGN QR CODE TO ADVERTISE WITH PRINT</div>
          </v-col>
        </v-row>

        <v-row v-for="(item, index) in advertisePrintContent" :key="`${swayOffer.id}-print-${index}`" class="mt-6">
          <v-col cols="7" class="text-left">
            <div class="sway-preview-section-header">{{ item.title }}</div>
            <div class="mt-2">
              {{ item.content }}<br />
              <a :href="item.helpLink" target="_blank">Learn how</a>
            </div>
          </v-col>
          <v-col cols="5" class="text-left">
            <v-img :src="item.image" contain max-height="125" />
          </v-col>
        </v-row>
      </div>

      <LfSwayOfferClaim
        v-if="isScreenshotPreviewMode"
        :offer-summary="swayOffer.offer_summary"
        :claim-data="swayOffer.flow.claim_data"
        :offer-image-url="swayOffer.offer_image_url"
        :business-logo-url="entity.logo_url"
        :hash-tags="swayOffer.hashtags"
        :proof-types="enabledProofTypes"
        :mentions="mentions"
        :review-url="reviewUrl"
        :proof-sample="proofSample"
        :selected-proof-type="selectedProofType"
        @sway-offer-claim-proof-type-clicked="onSwayOfferClaimProofTypeClicked"
        @sway-offer-claim-proof-requirements-clicked="onSwayOfferClaimProofRequirementsClicked"
      />

      <LfOfferProofRequirementsTutorial v-if="isProofRequirementsTutorialPreviewMode" :proof-requirements-data="swayOffer.flow.proof_requirements_data" />

      <LfSwayOfferContactInfo
        v-if="isContactInfoPreviewMode"
        :business-brand="entity.brand"
        :contact-info-data="swayOffer.flow.contact_info_data"
        @sway-offer-contact-info-submit="onContactInfoSubmitted"
        @sway-offer-contact-info-submit-and-send-via-email="onContactInfoSubmittedViaEmail"
        @sway-offer-contact-info-submit-and-send-via-text="onContactInfoSubmittedViaText"
      />

      <LfSwayOfferWhatsNext v-if="isWhatsNextPreviewMode" :has-email="true" :whats-next-data="swayOffer.flow.whats_next_data" :proof-type="probableProofType" />

      <LfSwayOfferRedemptionLocal
        v-if="isVoucherPreviewMode"
        :voucher-data="swayOffer.flow.voucher_data"
        :business-logo-url="entity.logo_url"
        :offer-summary="swayOffer.offer_summary"
        :proof-url="
          swayOffer.offer_type === 'LOCAL'
            ? 'https://storage.googleapis.com/localfluence-library-production/socialPostExamples/igPostExample-04262021.png'
            : null
        "
        :proof-type="probableProofType"
        :date-redeemed="dateRedeemed"
        @sway-offer-redemption-local-mark-as-used="onSwayOfferRedemptionLocalMarkAsUsed"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

import { mapGetters } from 'vuex'
import { ApiService } from '@/services/index'

import taskTypes from '@/models/taskType'
import { SWAY_OFFER_PREVIEW_MODE_TYPES } from '@/models/SwayOffer'

import { copyToClipboard } from '@/mixins/copyToClipboard'
import SwayOfferAddress from '@/components/SwayOffers/SwayOfferAddress'
import ShortUrlQrCode from '@/components/Offers/OfferPanels/ShortUrlQrCode'

import { LfSwayOfferClaim, LfOfferProofRequirementsTutorial, LfSwayOfferContactInfo, LfSwayOfferWhatsNext, LfSwayOfferRedemptionLocal } from 'lf-common'

export default Vue.extend({
  name: 'SwayOfferPreview',

  components: {
    ShortUrlQrCode,
    SwayOfferAddress,
    LfSwayOfferClaim,
    LfOfferProofRequirementsTutorial,
    LfSwayOfferContactInfo,
    LfSwayOfferWhatsNext,
    LfSwayOfferRedemptionLocal,
  },

  mixins: [copyToClipboard],

  props: {
    swayOffer: {
      type: Object,
      required: true,
    },
    previewMode: {
      type: String,
      required: true,
    },
    previewModes: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    selectedProofType: null,
    addresses: [],
    addressesLoading: true,
    dateRedeemed: null,
  }),

  computed: {
    ...mapGetters('business', ['entity']),
    apiUrl() {
      return process.env.VUE_APP_DIVOC_API_URL
    },
    shortUrl() {
      return `${process.env.VUE_APP_SHORT_URL}${this.swayOffer.short_url_id}`
    },
    enabledProofTypes() {
      return Object.values(taskTypes).filter((taskType) => this.swayOffer.tasks.includes(taskType.type))
    },
    advertiseDigitallyContent() {
      return [
        {
          title: 'Promote on your social media accounts',
          content: 'Add your campaign url in your social media bio, to your stories, or make a post highlighting your special deal to spread the word.',
          helpLink: 'https://localfluencehelp.helpscoutdocs.com/article/66-using-your-campaign-link',
          image: 'https://storage.googleapis.com/lf-client-app-assets/swayPreview/url-1.png',
        },
        {
          title: 'Add the offer to your website',
          content: 'Create a tab or banner on your website to link visitors to your offer.',
          helpLink: 'https://localfluencehelp.helpscoutdocs.com/article/67-using-your-campaign-link-on-websites',
          image: 'https://storage.googleapis.com/lf-client-app-assets/swayPreview/url-2.png',
        },
        {
          title: 'Text your link to clients',
          content: 'Easily copy your link from your Localfluence dashboard and include it in followup texts to your clients after they visit.',
          helpLink: 'https://localfluencehelp.helpscoutdocs.com/article/69-using-your-campaign-link-in-text-messages',
          image: 'https://storage.googleapis.com/lf-client-app-assets/swayPreview/url-3.png',
        },
        {
          title: 'Add a button link to emails',
          content: 'Add a button link to your email to easily send clients right to your special offer.',
          helpLink: 'https://localfluencehelp.helpscoutdocs.com/article/70-using-your-campaign-link-in-emails',
          image: 'https://storage.googleapis.com/lf-client-app-assets/swayPreview/url-4.png',
        },
      ]
    },
    advertisePrintContent() {
      return [
        {
          title: 'Design through an online printing company',
          content: 'Choose from a variety of online printers and find a pre-made template or create your own through their print portal.',
          helpLink: 'https://localfluencehelp.helpscoutdocs.com/article/71-how-to-use-an-online-print-portal-for-your-offer',
          image: 'https://storage.googleapis.com/lf-client-app-assets/swayPreview/qr-1.png',
        },
        {
          title: 'Design your own',
          content: 'Use a simple app to create your own design. Drop in your QR code and then send the design to an online printer.',
          helpLink: 'https://localfluencehelp.helpscoutdocs.com/article/72-design-your-own-print-material-to-advertise-your-offer',
          image: 'https://storage.googleapis.com/lf-client-app-assets/swayPreview/qr-2.png',
        },
        {
          title: 'Localfluence design team',
          content: 'Need help with your design? Our team can help. $65 one-time fee.',
          helpLink: 'https://localfluencehelp.helpscoutdocs.com/article/73-how-to-submit-a-request-for-a-localfluence-design',
          image: 'https://storage.googleapis.com/lf-client-app-assets/swayPreview/qr-3.png',
        },
      ]
    },
    isCampaignPreviewMode() {
      return this.previewMode === SWAY_OFFER_PREVIEW_MODE_TYPES.YOUR_CAMPAIGN
    },
    isScreenshotPreviewMode() {
      return this.previewMode === SWAY_OFFER_PREVIEW_MODE_TYPES.SCREENSHOT
    },
    isProofRequirementsTutorialPreviewMode() {
      return this.previewMode === SWAY_OFFER_PREVIEW_MODE_TYPES.PROOF_REQUIREMENTS_TUTORIAL
    },
    isContactInfoPreviewMode() {
      return this.previewMode === SWAY_OFFER_PREVIEW_MODE_TYPES.CONTACT_INFO
    },
    isVoucherPreviewMode() {
      return this.previewMode === SWAY_OFFER_PREVIEW_MODE_TYPES.VOUCHER
    },
    isWhatsNextPreviewMode() {
      return this.previewMode === SWAY_OFFER_PREVIEW_MODE_TYPES.WHATS_NEXT
    },
    mentions() {
      if (!this.selectedProofType) return []

      if (this.selectedProofType.type === 'TIK_TOK') return this.swayOffer.tiktok_mentions
      if (this.selectedProofType.type === 'FACEBOOK_POST') return this.swayOffer.facebook_mentions
      if (this.selectedProofType.type === 'INSTAGRAM_POST') return this.swayOffer.instagram_mentions

      return []
    },
    reviewUrl() {
      if (!this.selectedProofType) return null

      if (this.selectedProofType.type === 'ONLINE_REVIEW')
        return this.swayOffer.address?.google_review_url ? this.swayOffer.address.google_review_url : this.swayOffer.google_review_url
      if (this.selectedProofType.type === 'FACEBOOK_REVIEW') return this.swayOffer.facebook_review_url

      return null
    },
    proofSample() {
      if (!this.selectedProofType) return null

      if (this.selectedProofType.type === 'TIK_TOK') return this.swayOffer.tiktok_proof_sample
      if (this.selectedProofType.type === 'FACEBOOK_POST') return this.swayOffer.facebook_proof_sample
      if (this.selectedProofType.type === 'INSTAGRAM_POST') return this.swayOffer.instagram_proof_sample
      if (this.selectedProofType.type === 'ONLINE_REVIEW') return this.swayOffer.google_review_proof_sample
      if (this.selectedProofType.type === 'FACEBOOK_REVIEW') return this.swayOffer.facebook_review_proof_sample

      return null
    },
    probableProofType() {
      // we require contact info but not a proof
      if (this.swayOffer?.flow?.contact_info_enabled && !this.swayOffer?.flow?.claim_enabled) {
        return 'CONTACT_INFO'
      }
      return null
    },
  },

  mounted: function () {
    ApiService.get(`swayOffer/${this.swayOffer.id}/addresses`).then((response) => {
      this.addresses = response.data
      this.addressesLoading = false
    })
  },

  methods: {
    copyUrl() {
      this.copyToClipboard(this.shortUrl)
      this.$toast.success('Copied to clipboard!')
    },
    onSwayOfferClaimProofTypeClicked(event) {
      this.selectedProofType = event
    },
    onContactInfoSubmittedViaEmail(event) {
      this.$toast.success('Email for voucher would be sent')
    },
    onContactInfoSubmittedViaText(event) {
      this.$toast.success('Text would be sent')
    },
    onContactInfoSubmitted(event) {
      this.$toast.success('User forwarded to voucher')
    },
    onSwayOfferClaimProofRequirementsClicked() {
      this.$toast.info('Users are shown the Proof Requirements Tutorial')
    },
    handleAddressUrlGenerated(event) {
      const { addressId, shortUrlId } = event
      const addressIndex = this.addresses.findIndex((address) => address.id === addressId)

      this.addresses[addressIndex].short_url_id = shortUrlId
    },
    onSwayOfferRedemptionLocalMarkAsUsed() {
      this.dateRedeemed = new Date().toISOString().split('T')[0]
    },
  },
})
</script>

<style scoped>
#swayOfferPreview {
  color: #707070 !important;
}

.sway-preview-container {
  padding: 20px;
  background-color: #fafafa;
}

.sway-preview-inner-container {
  padding: 15px;
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #e8e8e8;
}

.sway-preview-section-header {
  color: var(--v-primary-base);
  font-weight: 800;
}
</style>
