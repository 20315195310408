<template>
  <v-container fluid>
    <app-spinner v-if="loading" :spinner-size="80" class="spinner-background" />
    <div v-else class="">
      <v-select
        v-model="selectedReportId"
        :loading="loading"
        :disabled="loading"
        :items="availableReports"
        item-text="selection_text"
        item-value="id"
        label="Select report"
      />
      <v-container v-if="currentReport" fluid>
        <h1 class="text-center">{{ currentReport.query_name }}</h1>
        <v-row align="center" justify="center" class="mt-10">
          <v-col v-if="currentReport.query_type === 'FLAT'" align="center" justify="center" cols="6">
            <p>No parameters needed</p>
          </v-col>
          <v-col v-if="currentReport.query_type === 'OFFER'" align="center" justify="center" cols="6">
            <v-row>
              <v-col cols="12">
                <v-autocomplete v-model="offerSearchModel" :items="offerSearchItems" :loading="offerSearchLoading" :search-input.sync="offerSearch">
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" align="right"> Offer ID </v-col>
              <v-col cols="8" align="left">
                <span v-if="offerSearchModel">
                  {{ offerSearchModel.offer.id }}
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="currentReport.query_type === 'SWAY_OFFER'" align="center" justify="center" cols="6">
            <v-row>
              <v-col cols="12">
                <v-autocomplete v-model="offerSearchModel" :items="offerSearchItems" :loading="offerSearchLoading" :search-input.sync="offerSearch">
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" align="right"> Sway Offer Id </v-col>
              <v-col cols="8" align="left">
                <span v-if="offerSearchModel">
                  {{ offerSearchModel.swayOffer.id }}
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="currentReport.query_type === 'ADDRESS'" align="center" justify="center" cols="6">
            <v-row>
              <v-col cols="12">
                <VueGoogleAutocomplete
                  id="addressSearch"
                  ref="vueGoogleAutoComplete"
                  class="address-search v-input v-text-field"
                  placeholder="Search a location for long lat"
                  :country="'us'"
                  @placechanged="getAddressData"
                />
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="4">
                <v-text-field v-model="long" label="long" persistent-hint hint="Longitude (ex: -111.89)" />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="lat" label="lat" persistent-hint hint="Latitude (ex: 40.79)" />
              </v-col>

              <v-col cols="4"><v-text-field v-model="addressWithinDistance" label="distance" persistent-hint hint="Distance in miles" /></v-col>
            </v-row>
            <v-row class="mt-n10">
              <v-col cols="12">
                <v-menu v-model="startDatePicker" :close-on-content-click="false" :nudge-right="40" transition="slide-y-transition" offset-y min-width="290px">
                  <template #activator="{ on }">
                    <v-text-field v-model="redeemAfterDate" readonly persistent-hint hint="Include locals that have redeemed after" v-on="on" />
                  </template>
                  <v-date-picker v-model="redeemAfterDate" :show-current="false" @input="startDatePicker = false" />
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="6" align="center" justify="center">
            <v-btn color="primary" @click="generateReport()">Generate</v-btn>
          </v-col>
        </v-row>
        <hr class="mt-10" />
        <app-spinner v-if="reportsLoading" :spinner-size="80" class="spinner-background" />
        <div v-else>
          <h3>Previous Reports</h3>
          <em>only showing most recent 30</em>
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th>Report Name</th>
                  <th>Date Run</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="runReport in cleanedRunReports" :key="runReport.id" class="mt-5">
                  <td class="ml-3">
                    {{ runReport.report_name }}
                  </td>
                  <td class="ml-3">
                    {{ runReport.cleanDate }}
                  </td>
                  <td class="ml-3">
                    <v-btn download @click="downloadReport(runReport.file_name, runReport.download_name)">{{ runReport.download_name }}</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-container>

      <v-row v-else align="center" justify="center" class="text-center">
        <v-col cols="12"> <strong>no report selected</strong> </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { ApiService } from '@/services/index'
import dayjs from 'dayjs'

import VueGoogleAutocomplete from 'vue-google-autocomplete'
import GoogleMapService from '@/services/googleMap.service'

export default {
  name: 'SimpleReports',

  metaInfo: {
    title: 'Simple Reports',
  },
  components: { VueGoogleAutocomplete },
  data: () => ({
    availableReports: [],
    savedReports: [],
    selectedReportId: null,
    loading: true,
    runReports: [],
    reportsLoading: true,
    long: null,
    lat: null,
    addressWithinDistance: 30,
    redeemAfterDate: dayjs().subtract(9, 'months').startOf('month').format('YYYY-MM-DD'),
    startDatePicker: false,
    offerSearchModel: null,
    offerSearchLoading: false,
    offerSearchResults: [],
    offerSearch: null,
  }),
  computed: {
    currentReport() {
      if (!this.selectedReportId) return null
      return this.availableReports.filter((e) => e.id === this.selectedReportId)[0]
    },
    cleanedRunReports() {
      return this.runReports.map((e) => {
        return {
          ...e.simple_report_run,
          cleanDate: dayjs(e.simple_report_run.report_date).format('YYYY-MM-DD hh:mm A'),
          download_name:
            e.simple_report_run.report_name +
            '_' +
            (e.business ? '' + e.business.brand.trim() + '_' : '') +
            dayjs(e.simple_report_run.report_date).format('YYYY_MM_DD') +
            '.csv',
        }
      })
    },
    offerSearchItems() {
      if (!this.offerSearchResults) return []
      const d = this.offerSearchResults.map((e) => {
        return {
          text:
            (e.business.brand == e.business.title) != 0
              ? e.business.brand.toUpperCase() + ' ~~ ' + (e.offer ? e?.offer?.offer_summary : e?.swayOffer?.offer_summary)
              : e.business.brand.toUpperCase() +
                '-' +
                e.business.title.toUpperCase() +
                ' ~~ ' +
                (e.offer ? e?.offer?.offer_summary : e?.swayOffer?.offer_summary),
          value: e,
        }
      })
      return d
    },
  },
  watch: {
    selectedReportId: function (event) {
      this.offerSearchLoading = false
      this.offerSearchResults = []
      this.offerSearchModel = null
      this.offerSearch = null
      this.refreshRunReports()
    },
    offerSearch(val) {
      this.offerSearchLoading = true
      ApiService.get(`offer/${this.currentReport.query_type === 'OFFER' ? 'lookupOffer' : 'lookupSway'}/${val}`)
        .then((response) => {
          if (response.data.length > 0) this.offerSearchResults = response.data
        })
        .finally(() => (this.offerSearchLoading = false))
    },
  },
  mounted() {
    GoogleMapService.init().then(() => {
      this.googleMapsInitialized = true
    })
    ApiService.get('simpleReports/available').then((response) => {
      this.availableReports = response.data
      this.availableReports = this.availableReports.map((e) => {
        return { ...e, selection_text: '' + e.query_name + ' : ' + e.query_description }
      })
      this.loading = false
    })
  },

  methods: {
    refreshRunReports() {
      this.reportsLoading = true
      if (this.selectedReportId) {
        ApiService.get(`simpleReports/runReportsForQuery/${this.selectedReportId}`)
          .then((response) => {
            this.runReports = response.data
          })
          .finally(() => {
            this.reportsLoading = false
          })
      } else {
        this.reportsLoading = false
      }
    },
    downloadReport(id, fileName) {
      ApiService.download(`simpleReports/report/${id}`, fileName)
    },

    generateReport() {
      if (this.currentReport.query_type === 'OFFER') {
        if (!this?.offerSearchModel.offer) {
          this.$toast.error(`you must an offer`)
          return
        }
        ApiService.put(`simpleReports/generateForOffer/${this.selectedReportId}/${this.offerSearchModel.offer.id}`).then((response) => {
          if (response.data.error) {
            this.$toast.error(response.data.error)
            return
          }
          this.refreshRunReports()
        })
      } else if (this.currentReport.query_type === 'SWAY_OFFER') {
        console.log(this.offerSearchModel)
        if (!this?.offerSearchModel?.swayOffer) {
          this.$toast.error(`you must select a sway offer`)
          return
        }
        ApiService.put(`simpleReports/generateForSwayOffer/${this.selectedReportId}/${this.offerSearchModel.swayOffer.id}`).then((response) => {
          if (response.data.error) {
            this.$toast.error(response.data.error)
            return
          }
          this.refreshRunReports()
        })
      } else if (this.currentReport.query_type === 'ADDRESS') {
        ApiService.put(
          `simpleReports/generateForAddress/${this.selectedReportId}/${this.long}/${this.lat}/${this.addressWithinDistance}/${this.redeemAfterDate}`
        ).then((response) => {
          if (response.data.error) {
            this.$toast.error(response.data.error)
            return
          }
          this.refreshRunReports()
        })
      } else {
        ApiService.put(`simpleReports/generate/${this.selectedReportId}`).then((response) => {
          if (response.data.error) {
            this.$toast.error(response.data.error)
            return
          }
          this.refreshRunReports()
        })
      }
    },
    getAddressData: function (addressData, placeResultData) {
      this.addressDetailsLoading = true

      const address = addressData.street_number && addressData.route ? `${addressData.street_number} ${addressData.route}` : placeResultData.name
      const state = placeResultData.address_components.find((addressComponent) => addressComponent.types.includes('administrative_area_level_1'))

      const systemTags = [
        ...(address ? [address] : []),
        ...(addressData.locality ? [addressData.locality] : []),
        ...(addressData.postal_code ? [addressData.postal_code] : []),
        ...(state ? [state.long_name] : []),
      ]
      this.lat = addressData.latitude
      this.long = addressData.longitude
    },
  },
}
</script>

<style scoped>
.address-search {
  width: 100%;
  padding: 12px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
}
</style>
