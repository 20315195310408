import dayjs, { Dayjs, ManipulateType } from 'dayjs'
import { ApiService } from '@/services'

type ActionsResponse = {
  series: []
  options: object
}

type AnalyticsState = {
  loading: boolean
  actions: ActionsResponse | null
}

const state: AnalyticsState = {
  loading: true,
  actions: null,
}

const buildGraphConfig = (xaxis, seriesData, missingNum = 0) => {
  const defaultConfig = {
    legend: {
      position: 'top',
      fontSize: '17px',
      itemMargin: {
        horizontal: 15,
      },
    },
  }

  return {
    line: {
      markers: {
        size: 5,
      },
      chart: {
        id: 'activity-graph',
        type: 'line',
        height: '100%',
        toolbar: {
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      tooltip: {
        followCursor: true,
        x: {
          formatter: (index) => {
            if (index === xaxis.length) {
              return `${xaxis[index - 1]} - now`
            }
            return `${dayjs(xaxis[index - 1]).format('ddd MMM D')} - ${dayjs(xaxis[index]).subtract(1, 'day').format('ddd MMM D')}`
          },
        },
      },
      ...(missingNum > 0
        ? {
            annotations: {
              xaxis: [
                {
                  x: xaxis[0],
                  x2: xaxis[missingNum],
                  borderColor: '#775DD0',
                },
                {
                  x: xaxis[missingNum],
                  borderColor: '#775DD0',
                  label: {
                    textAnchor: 'middle',
                    offsetY: 50,
                    offsetX: 10,
                    style: {
                      color: '#000',
                    },
                    text: 'Activity Started',
                  },
                },
              ],
            },
          }
        : {}),
      xaxis: {
        categories: xaxis,
      },
      yaxis: [
        {
          seriesName: 'Views',
          opposite: true,
        },
        {
          seriesName: 'Pinned',
        },
        {
          seriesName: 'Pinned',
          show: false,
        },
        {
          seriesName: 'Pinned',
          show: false,
        },
        {
          seriesName: 'Pinned',
          show: false,
        },
      ],
      ...defaultConfig,
    },
    bar: {
      chart: {
        id: 'activity-graph',
        type: 'bar',
        height: xaxis.length * 200,
      },
      tooltip: {
        followCursor: true,
        x: {
          formatter: (index) => {
            if (index.length > 22) {
              return index.substring(0, 22) + '...'
            }
            return index
          },
        },
      },
      xaxis: {
        categories: xaxis,
      },
      yaxis: [],
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '100%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          const viewSeriesIndex = seriesData.findIndex((series) => series.name.startsWith('Views'))

          if (opts.seriesIndex === viewSeriesIndex && val > 0) return `${val}k`
          return val
        },
        style: {
          colors: ['#000'],
        },
        offsetY: 0,
        offsetX: 30,
      },
      ...defaultConfig,
    },
  }
}

const getters = {
  loading: () => state.loading,
  actionData: () => state.actions,
}

const actions = {
  clearActionData({ commit }) {
    commit('clearActionData')
  },
  async getActionData({ commit }, { startDate, timeSeriesGroup, graphType }: { startDate: Dayjs; timeSeriesGroup: ManipulateType; graphType: string }) {
    commit('loading', true)
    await ApiService.get(`analytics/actions/${graphType}/${startDate.toISOString()}/${timeSeriesGroup}`).then((response) => {
      let dataMissing = null
      if (graphType === 'DATE') {
        const dataStart = response.data.axis[0]
        dataMissing = dayjs(dayjs(response.data.axis[0])).diff(startDate, timeSeriesGroup)

        if (dataMissing > 0) {
          for (let i = 0; i < dataMissing; i++) {
            response.data.axis.unshift(dayjs(dataStart).subtract(1, timeSeriesGroup).format('YYYY-MM-DD'))
            response.data.series.forEach((element) => {
              element.data.unshift(null)
            })
          }
        }
      }
      if (graphType === 'SWAY') {
        const dataStart = response.data.axis[0]
        dataMissing = dayjs(dayjs(response.data.axis[0])).diff(startDate, timeSeriesGroup)

        if (dataMissing > 0) {
          for (let i = 0; i < dataMissing; i++) {
            response.data.axis.unshift(dayjs(dataStart).subtract(1, timeSeriesGroup).format('YYYY-MM-DD'))
            response.data.series.forEach((element) => {
              element.data.unshift(null)
            })
          }
        }
      }
      commit('setActionData', {
        series: response.data.series,
        options: buildGraphConfig(response.data.axis, response.data.series, dataMissing),
      })
      commit('loading', false)
    })
  },
}

const mutations = {
  clearActionData(state: AnalyticsState) {
    state.actions = {
      series: [],
      options: buildGraphConfig([], []),
    }
  },
  loading(state: AnalyticsState, payload: boolean) {
    state.loading = payload
  },
  setActionData(state: AnalyticsState, payload: ActionsResponse) {
    state.actions = payload
  },
}

export const analytics = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
