<template>
  <v-dialog :value="true" persistent max-width="800">
    <v-card>
      <v-card-title class="headline">Locations</v-card-title>
      <v-card-text class="pb-0">
        <p>Add locations to this offer below (search via street address, city, state, or any custom tag you've created).</p>

        <v-autocomplete
          v-model="locationTags"
          :items="addressTags"
          :return-object="true"
          :loading="addressTagsLoading"
          :disabled="addressTagsLoading"
          label="Locations"
          placeholder="All Locations"
          item-text="title"
          item-value="id"
          flat
          outlined
          multiple
          @change="getAddressesForTagSelection"
        >
          <template #selection="{ attrs, item, selected }">
            <div class="mt-2">
              <v-chip v-bind="attrs" :input-value="selected" color="primary lighten-1">
                <strong>{{ item.title }}</strong>
              </v-chip>
            </div>
          </template>
        </v-autocomplete>

        <h3 v-if="locations.length == 0" class="text-center">No Locations</h3>
        <div v-else>
          <span class="body-1">
            Locals can redeem this offer at the following
            <span v-if="locations.length === 1">location:</span>
            <span v-else>{{ locations.length }} {{ 'location' | pluralize(locations.length) }}:</span>
          </span>

          <ul class="mt-2 address-list">
            <li v-for="(location, index) in locations" :key="index" class="mt-1">{{ getFormattedAddress(location) }}</li>
          </ul>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="handleModalClose">Cancel</v-btn>
        <v-btn :loading="false" color="primary darken-1" text @click="handleModalSave">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import { ApiService } from '@/services/index'

export default Vue.extend({
  name: 'OfferLocationsModal',

  props: {
    initialLocations: {
      type: Array,
      default: () => [],
    },
    initialLocationTags: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    locations: [],
    addressTags: [],
    locationTags: [],
    addressTagsLoading: true,
  }),

  mounted: function () {
    this.locations = [...this.initialLocations]
    this.locationTags = [...this.initialLocationTags]

    ApiService.get('tag/all/address').then((response) => {
      this.addressTags = response.data
      this.addressTagsLoading = false
    })
  },

  methods: {
    getFormattedAddress(location) {
      return [location.address, location.city, location.state, location.zipcode].filter(Boolean).join(', ')
    },
    getAddressesForTagSelection() {
      ApiService.get('tag/search/address', {
        params: {
          tag: this.locationTags.map((tag) => tag.id),
        },
      }).then((response) => {
        this.locations = response.data
      })
    },
    handleModalSave() {
      this.$emit('save', { locations: this.locations, location_tags: this.locationTags })
    },
    handleModalClose() {
      this.$emit('offer-locations-modal-close')
    },
  },
})
</script>

<style scoped>
.address-list {
  height: 200px;
  overflow: auto;
}
</style>
