<template>
  <v-dialog :value="true" :fullscreen="$vuetify.breakpoint.smAndDown" persistent scrollable max-width="850">
    <v-card class="pt-4">
      <v-card-text :class="$vuetify.breakpoint.smAndDown ? 'pa-0' : ''">
        <v-stepper v-model="offerFlowStep" vertical class="elevation-0">
          <v-stepper-step :complete="offerFlowStep > 1" :editable="!!offerSummary" step="1" edit-icon="$complete">
            <h2 class="step-title">What would you like to offer your existing customers?</h2>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-row align="center" justify="center" class="my-0">
              <v-col cols="11" class="pb-0">
                <v-text-field v-model="offerSummary" outlined label="Offer Summary" />
              </v-col>
            </v-row>

            <v-card-actions class="justify-end">
              <v-btn color="darken-1" class="mr-4" @click="() => $emit('new-offer-modal-close')">Cancel</v-btn>
              <v-btn :disabled="offerSummary.length === 0" color="primary" @click="offerFlowStep = 2">Next</v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-step :complete="claimEnabled !== null" :editable="claimEnabled !== null" step="2" edit-icon="$complete">
            <h2 class="step-title">Do customers upload a screenshot?</h2>
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-radio-group v-model="claimEnabled" row class="mt-1 ml-3">
              <v-radio label="Yes" :value="true" />
              <v-radio label="No" :value="false" />
            </v-radio-group>

            <v-card-actions class="justify-end">
              <v-btn color="darken-1" class="mr-4" @click="() => $emit('new-offer-modal-close')">Cancel</v-btn>
              <v-btn :disabled="claimEnabled === null" color="primary" @click="offerFlowStep = 3">Next</v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-step :complete="contactInfoEnabled !== null" :editable="contactInfoEnabled !== null" step="3" edit-icon="$complete">
            <h2 class="step-title">Do you want to collect customer information?</h2>
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-radio-group v-model="contactInfoEnabled" row class="mt-1 ml-3">
              <v-radio label="Yes" :value="true" />
              <v-radio label="No" :value="false" />
            </v-radio-group>

            <v-card-actions class="justify-end">
              <v-btn color="darken-1" class="mr-4" @click="() => $emit('new-offer-modal-close')">Cancel</v-btn>
              <v-btn :disabled="contactInfoEnabled === null" color="primary" @click="offerFlowStep = 4">Next</v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-step :complete="endType !== null" :editable="endType !== null" step="4" edit-icon="$complete">
            <h2 class="step-title">Does the customer redeem in person?</h2>
          </v-stepper-step>

          <v-stepper-content step="4">
            <v-radio-group v-model="endType" row class="mt-1 ml-3">
              <v-radio label="Yes" value="VOUCHER" />
              <v-radio label="No" value="WHATS_NEXT" />
            </v-radio-group>

            <v-card-actions class="mt-4 justify-end">
              <v-btn :disabled="addSwayOfferLoading" color="darken-1" class="mr-4" @click="() => $emit('new-offer-modal-close')">Cancel</v-btn>
              <v-btn :disabled="endType === null" :loading="addSwayOfferLoading" color="primary" @click="handleAddSwayOfferModalSave()">Create Offer</v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'NewSwayOfferModal',

  data: () => ({
    offerFlowStep: 1,
    claimEnabled: null,
    contactInfoEnabled: null,
    endType: null,

    offerSummary: '',
    addSwayOfferLoading: false,
  }),

  mounted() {
    this.$store.dispatch('swayoffer/refresh')
  },

  methods: {
    ...mapActions('swayoffer', ['createSwayOffer']),
    handleAddSwayOfferModalSave() {
      this.addSwayOfferLoading = true

      this.createSwayOffer({
        swayOfferBase: {
          offer_summary: this.offerSummary,
        },
        flow: {
          claim_enabled: this.claimEnabled,
          contact_info_enabled: this.contactInfoEnabled,
          end_type: this.endType,
        },
      })
        .then(() => {
          this.$emit('new-offer-modal-close')
          this.$toast.success('Sway Offer Created!')

          Vue.nextTick(() => {
            this.$vuetify.goTo('#offerlist-ACTIVE')
          })
        })
        .finally(() => {
          this.addSwayOfferLoading = false
        })
    },
  },
})
</script>

<style scoped>
.step-title {
  line-height: 1.2;
  font-weight: 300;
  color: #707070 !important;
}

.step-sub-title {
  margin-top: 6px;
  font-weight: 400;
  line-height: 1.3;
  color: #707070 !important;
}
</style>
