<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="12">
        <v-form ref="offerSummaryForm">
          <v-text-field
            v-model="offerSummary"
            :rules="requiredRule"
            hide-details="auto"
            outlined
            label="Offer Summary"
            @blur="handleUpdateOfferSummary()"
            @keydown.enter="$event.target.blur()"
          />
        </v-form>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="internalNotes"
          :hide-details="true"
          outlined
          label="Internal Notes"
          @blur="handleUpdateInternalNotes()"
          @keydown.enter="$event.target.blur()"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="offerMetaTitle"
          outlined
          hide-details="auto"
          label="Meta Title"
          clearable
          @blur="handleUpdateMetaTitle()"
          @keydown.enter="$event.target.blur()"
        />
      </v-col>
    </v-row>

    <v-row class="mb-4">
      <v-col cols="12">
        <v-text-field
          v-model="offerMetaDescription"
          outlined
          clearable
          hide-details="auto"
          label="Meta Description"
          @blur="handleUpdateMetaDescription()"
          @keydown.enter="$event.target.blur()"
        />
      </v-col>
    </v-row>

    <v-row class="mb-4">
      <v-col cols="12">
        <legend class="v-label theme--light mt-n5 mb-1" style="font-size: 14px">Offer Image</legend>

        <ImageUploadForm
          :edit-mode="true"
          :allow-image-deletion="true"
          :image-url="swayOffer.offer_image_url"
          image-type="swayOffer"
          @image-deleted="handleUpdateOfferImage"
          @image-uploaded="handleUpdateOfferImage"
        />
      </v-col>
    </v-row>

    <v-expansion-panels v-model="currentPanel">
      <SwayScreenshotFlowPanel
        v-if="isAdmin"
        :sway-offer-id="swayOffer.id"
        :panel-enabled="swayOffer.flow.claim_enabled"
        :panel-data="swayOffer.flow.claim_data"
      />

      <SwayOfferSocialsPanel
        :disabled="!swayOffer.flow.claim_enabled || !swayOffer.flow.claim_data.require_social_proof"
        :tasks="swayOffer.tasks"
        :hashtags="swayOffer.hashtags"
        :instagram-mentions="swayOffer.instagram_mentions"
        :facebook-mentions="swayOffer.facebook_mentions"
        :tiktok-mentions="swayOffer.tiktok_mentions"
        :google-review-url="swayOffer.google_review_url"
        :facebook-review-url="swayOffer.facebook_review_url"
        :instagram-proof-sample="swayOffer.instagram_proof_sample"
        :facebook-proof-sample="swayOffer.facebook_proof_sample"
        :tiktok-proof-sample="swayOffer.tiktok_proof_sample"
        :google-review-proof-sample="swayOffer.google_review_proof_sample"
        :facebook-review-proof-sample="swayOffer.facebook_review_proof_sample"
        @save="handleUpdateSwayOffer"
      />

      <SwayProofRequirementsFlowPanel
        v-if="isAdmin"
        :sway-offer-id="swayOffer.id"
        :panel-enabled="swayOffer.flow.claim_enabled && swayOffer.flow.claim_data.require_social_proof"
        :panel-data="swayOffer.flow.proof_requirements_data"
      />

      <SwayContactInfoFlowPanel
        v-if="isAdmin"
        :sway-offer-id="swayOffer.id"
        :panel-enabled="swayOffer.flow.contact_info_enabled"
        :panel-data="swayOffer.flow.contact_info_data"
      />

      <SwayVoucherFlowPanel
        v-if="isAdmin"
        :sway-offer-id="swayOffer.id"
        :panel-enabled="swayOffer.flow.end_type === 'VOUCHER'"
        :panel-data="swayOffer.flow.voucher_data"
      />

      <SwayWhatsNextFlowPanel
        v-if="isAdmin"
        :sway-offer-id="swayOffer.id"
        :panel-enabled="swayOffer.flow.end_type === 'WHATS_NEXT'"
        :panel-data="swayOffer.flow.whats_next_data"
      />
      <SwaySideEffectPanel v-if="isAdmin" :sway-offer-id="swayOffer.id" :panel-data="swayOffer.flow.side_effect_data" @save="handleUpdateSwayOffer" />
    </v-expansion-panels>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import validationRules from '@/utils/validation'
import { SWAY_OFFER_PREVIEW_MODE_TYPES } from '@/models/SwayOffer'

import ImageUploadForm from '@/components/ImageUploadForm'
import SwayVoucherFlowPanel from '@/components/Offers/OfferPanels/SwayVoucherFlowPanel'
import SwayOfferSocialsPanel from '@/components/Offers/OfferPanels/SwayOfferSocialsPanel'
import SwayWhatsNextFlowPanel from '@/components/Offers/OfferPanels/SwayWhatsNextFlowPanel'
import SwaySideEffectPanel from '@/components/Offers/OfferPanels/SwaySideEffectPanel'
import SwayScreenshotFlowPanel from '@/components/Offers/OfferPanels/SwayScreenshotFlowPanel'
import SwayContactInfoFlowPanel from '@/components/Offers/OfferPanels/SwayContactInfoFlowPanel'
import SwayProofRequirementsFlowPanel from '@/components/Offers/OfferPanels/SwayProofRequirementsFlowPanel'

export default Vue.extend({
  name: 'SwayOfferFormBase',

  components: {
    ImageUploadForm,
    SwayVoucherFlowPanel,
    SwayOfferSocialsPanel,
    SwayWhatsNextFlowPanel,
    SwaySideEffectPanel,
    SwayScreenshotFlowPanel,
    SwayContactInfoFlowPanel,
    SwayProofRequirementsFlowPanel,
  },

  props: {
    swayOffer: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    offerSummary: null,
    internalNotes: null,
    offerMetaTitle: null,
    offerMetaDescription: null,
    offerMetaTitleIsDefault: true,
    currentPanel: SWAY_OFFER_PREVIEW_MODE_TYPES.YOUR_CAMPAIGN,
    requiredRule: validationRules.requiredRule,
  }),

  computed: {
    ...mapGetters('account', ['isAdmin']),
  },

  watch: {
    currentPanel: function (newPanelIndex) {
      const PANEL_CHANGE_EVENT = 'sway-screenshot-flow-panel-change'

      switch (newPanelIndex) {
        case 0:
        case 1:
          this.$emit(PANEL_CHANGE_EVENT, SWAY_OFFER_PREVIEW_MODE_TYPES.SCREENSHOT)
          break
        case 2:
          this.$emit(PANEL_CHANGE_EVENT, SWAY_OFFER_PREVIEW_MODE_TYPES.PROOF_REQUIREMENTS_TUTORIAL)
          break
        case 3:
          this.$emit(PANEL_CHANGE_EVENT, SWAY_OFFER_PREVIEW_MODE_TYPES.CONTACT_INFO)
          break
        case 4:
          this.$emit(PANEL_CHANGE_EVENT, SWAY_OFFER_PREVIEW_MODE_TYPES.VOUCHER)
          break
        case 5:
          this.$emit(PANEL_CHANGE_EVENT, SWAY_OFFER_PREVIEW_MODE_TYPES.WHATS_NEXT)
          break
        default:
          this.$emit(PANEL_CHANGE_EVENT, SWAY_OFFER_PREVIEW_MODE_TYPES.YOUR_CAMPAIGN)
          break
      }
    },
  },

  mounted: function () {
    this.offerSummary = this.swayOffer.offer_summary
    this.internalNotes = this.swayOffer.internal_notes
    this.offerMetaTitle = this.swayOffer.meta_title
    this.offerMetaTitleIsDefault = this.swayOffer.meta_title !== null && this.swayOffer.meta_title !== undefined
    this.offerMetaDescription = this.swayOffer.meta_description
  },

  methods: {
    ...mapActions('swayoffer', ['updateSwayOffer']),

    handleUpdateOfferSummary: function () {
      if (!this.$refs.offerSummaryForm.validate()) return

      this.updateSwayOffer({
        swayOffer: { ...this.swayOffer, offer_summary: this.offerSummary },
      })
    },

    handleUpdateInternalNotes: function () {
      this.updateSwayOffer({ swayOffer: { ...this.swayOffer, internal_notes: this.internalNotes } })
    },

    handleUpdateMetaTitle: function () {
      this.updateSwayOffer({
        swayOffer: { ...this.swayOffer, meta_title: this.offerMetaTitle ? this.offerMetaTitle : '' },
      })
    },

    handleUpdateMetaDescription: function () {
      this.updateSwayOffer({
        swayOffer: { ...this.swayOffer, meta_description: this.offerMetaDescription ? this.offerMetaDescription : '' },
      })
    },

    handleUpdateOfferImage: function (event) {
      this.updateSwayOffer({ swayOffer: { ...this.swayOffer, offer_image_url: event.image_url } })
    },

    handleUpdateSwayOffer(event) {
      this.updateSwayOffer({ swayOffer: { ...this.swayOffer, ...event } })
    },
  },
})
</script>
