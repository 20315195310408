<template>
  <div id="socialProofWidget">
    <app-spinner v-if="loading" />

    <v-container v-else-if="!accessKey">
      <v-row>
        <v-col class="text-center">
          <v-btn color="primary" @click="enableBusiness">Enable Social Proof Widget</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <template v-else>
      <v-row>
        <v-col cols="12" class="text-right px-8">
          <v-btn color="primary" class="mt-n5" @click="handleCreateNewSocialProofClicked">
            <img width="24" height="24" class="pr-1" :src="require('@/assets/svg/social-proofs-add.svg')" /> Add Social Proof
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>Access Key & Install Details</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="mt-n4">
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      label="Access Key"
                      append-outer-icon="mdi-content-copy"
                      :readonly="true"
                      :value="accessKey"
                      @click:append-outer="copyAccessKey"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-n6">
                  <v-col cols="12">
                    <v-textarea
                      outlined
                      label="Install Snippet"
                      append-outer-icon="mdi-content-copy"
                      :readonly="true"
                      :rows="4"
                      :no-resize="true"
                      :value="widgetInstallSnippet"
                      :loading="widgetInstallSnippetLoading"
                      @click:append-outer="copyInstallSnippet"
                    />
                  </v-col>
                </v-row>

                <v-row class="mt-n6">
                  <v-col cols="12" class="text-center">
                    <v-btn :loading="verifiyingInstallation" color="info" @click="verifyInstallation">Verify Installation</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row>
        <v-col id="social-proofs-list-col" class="px-lg-8" md="5" sm="12">
          <h2 v-if="!entities || entities.length === 0" class="text-center font-weight-light mt-3">No Social Proofs</h2>
          <div v-else class="col-mobile-scrolling text-center relative">
            <v-list-item-group id="social-proof-card-list" v-model="currentSocialProofIndex" color="primary">
              <v-list-item
                v-for="socialProof in entities"
                :key="socialProof.id"
                class="py-md-4 px-md-0 mx-md-0 pa-sm-4 social-proof-card-container"
                :ripple="false"
                @click.prevent="handleSocialProofCardClicked(socialProof.id)"
              >
                <SocialProofCard
                  :id="socialProof.id"
                  :access-key="accessKey"
                  :avatar-img-url="socialProof.avatar_img_url"
                  :business-name="socialProof.business_name"
                  :img-url="socialProof.img_url"
                  :influencer-name="socialProof.influencer_name"
                  :message="socialProof.message"
                  :platform="socialProof.platform"
                  :rating="socialProof.rating"
                  :status-code="socialProof.status_code"
                  :type="socialProof.type"
                  :view-count="socialProof.view_count"
                  @social-proof-preview="showSocialProofWidgetPreview"
                  @social-proof-selected="handleSocialProofCardClicked"
                />
              </v-list-item>
            </v-list-item-group>
            <div class="fadeout" />
          </div>
        </v-col>

        <v-col id="social-proof-manage-col" class="px-lg-8" md="7" sm="12">
          <h2 v-if="showSelectSocialProofMessage" class="text-center font-weight-light">Select or Create a Social Proof</h2>
          <SocialProofForm
            v-if="showSocialProofForm && !showSelectSocialProofMessage"
            :current-social-proof="currentSocialProof"
            @social-proof-form-save="handleSocialProofFormSave"
            @social-proof-form-cancel="handleSocialProofFormCancel"
          />
        </v-col>
      </v-row>

      <SocialProofWidgetPreview
        v-if="socialProofWidgetPreviewProofId"
        :access-key="accessKey"
        :proof-id="socialProofWidgetPreviewProofId"
        @social-proof-widget-preview-close="socialProofWidgetPreviewProofId = null"
      />
    </template>
  </div>
</template>

<script>
import Vue from 'vue'

import { mapActions, mapGetters } from 'vuex'

import { copyToClipboard } from '@/mixins/copyToClipboard'
import SocialProofCard from '@/components/SocialProofWidget/SocialProofCard'
import SocialProofForm from '@/components/SocialProofWidget/SocialProofForm'
import SocialProofWidgetPreview from '@/components/SocialProofWidget/SocialProofWidgetPreview'

export default Vue.extend({
  name: 'SocialProofWidget',

  metaInfo: {
    title: 'Social Proofs',
  },

  components: {
    SocialProofCard,
    SocialProofForm,
    SocialProofWidgetPreview,
  },

  mixins: [copyToClipboard],

  data: () => ({
    showSocialProofForm: false,
    showSelectSocialProofMessage: true,
    currentSocialProof: undefined,
    currentSocialProofIndex: undefined,
    showSocialProofFormTimer: 100,
    socialProofWidgetPreviewProofId: null,
  }),

  computed: {
    ...mapGetters('socialProof', [
      'loading',
      'accessKey',
      'entities',
      'widgetInstallSnippet',
      'widgetInstallSnippetLoading',
      'verifiyingInstallation',
      'installationVerificationResults',
    ]),
  },

  watch: {
    installationVerificationResults: function () {
      if (this.installationVerificationResults.error) {
        this.$toast.error(this.installationVerificationResults.error)

        if (this.installationVerificationResults.error === 'Install URL not configured') {
          this.$router.push({ path: '/settings', hash: 'socialProofWidgetSettings' })
        }

        return
      }

      this.$toast.success('Widget Installed!')
    },
  },

  mounted() {
    this.$store.dispatch('socialProof/refresh')
    this.$store.dispatch('socialProof/getWidgetInstallSnippet')
  },

  methods: {
    ...mapActions('socialProof', ['enableBusiness', 'updateConfiguration', 'verifyInstallation']),
    copyAccessKey() {
      this.copyToClipboard(this.accessKey)
      this.$toast.success('Copied to clipboard!')
    },
    copyInstallSnippet() {
      this.copyToClipboard(this.widgetInstallSnippet)
      this.$toast.success('Copied to clipboard!')
    },
    handleSocialProofFormSave() {
      this.showSocialProofForm = false
      this.currentSocialProof = undefined
      this.currentSocialProofIndex = undefined
      this.$toast.success('Social Proof saved!')
    },
    handleSocialProofFormCancel() {
      this.showSocialProofForm = false
      this.showSelectSocialProofMessage = true
      this.currentSocialProof = undefined
      this.currentSocialProofIndex = undefined
    },
    handleCreateNewSocialProofClicked() {
      this.showSocialProofForm = false

      setTimeout(() => {
        this.showSocialProofForm = true
        this.showSelectSocialProofMessage = false
      }, this.showSocialProofFormTimer)

      this.currentSocialProof = undefined
      this.currentSocialProofIndex = undefined
    },
    showSocialProofWidgetPreview(proofId) {
      this.socialProofWidgetPreviewProofId = proofId
    },
    handleSocialProofCardClicked(event) {
      this.showSocialProofForm = false

      setTimeout(() => {
        this.showSocialProofForm = true
        this.showSelectSocialProofMessage = false
      }, this.showSocialProofFormTimer)

      this.currentSocialProof = JSON.parse(JSON.stringify(this.entities.find((socialProof) => socialProof.id === event)))
    },
  },
})
</script>

<style lang="scss" scoped>
#socialProofWidget {
  width: 100%;
}

.social-proof-card-container {
  justify-content: center;
}

.col-mobile-scrolling {
  min-width: 400px;
  overflow-x: auto;
  flex-basis: auto;

  #social-proof-card-list {
    display: flex;
  }
}

@media (min-width: 960px) {
  #socialProofs {
    width: 1280px;
  }

  #social-proofs-list-col {
    order: 1;

    #social-proof-card-list {
      display: block;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: calc(100vh - 220px);

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  #social-proof-manage-col {
    order: 2;
  }
}
</style>
