<template>
  <v-dialog :value="true" persistent max-width="450">
    <v-card>
      <v-card-title class="headline">Share Feedback</v-card-title>
      <v-card-text>
        <p>Help keep {{ influencerName }} engaged with your brand. Give them a little love by sending them a kudos:</p>
        <v-checkbox
          v-for="(feedbackType, index) in feedbackTypes"
          :key="index"
          v-model="proofFeedback"
          :value="feedbackType.type"
          :on-icon="feedbackType.icon"
          :off-icon="feedbackType.icon"
          :label="feedbackType.title"
          class="mt-0 mb-0"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="$emit('proof-feedback-modal-close')">Close</v-btn>
        <v-btn :loading="clientProofFeedbackSaveLoading" :disabled="proofFeedback.length === 0" color="primary darken-1" text @click="saveFeedback">
          Share
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { ProofFeedback } from '@/models/Proof'

export default Vue.extend({
  name: 'ProofFeedbackModal',

  props: {
    proof: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    proofFeedback: [],
  }),

  computed: {
    ...mapGetters('timeline', ['clientProofFeedbackSaveLoading']),
    influencerName() {
      return this.proof?.influencer?.first_name || 'the local'
    },
    feedbackTypes() {
      return Object.values(ProofFeedback)
    },
  },

  watch: {
    urlShortCode() {
      this.errors.shortUrlError = null
    },
  },

  methods: {
    ...mapActions('timeline', ['saveClientProofFeedback']),
    saveFeedback() {
      this.saveClientProofFeedback({ proof: this.proof, clientFeedback: this.proofFeedback }).then(() => {
        this.$emit('proof-feedback-modal-close')
        this.$toast.success(`Thanks, we've let ${this.influencerName} know!`)
      })
    },
  },
})
</script>
