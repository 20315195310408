<template>
  <div id="settings">
    <v-container>
      <h1><v-icon style="cursor: pointer" class="mt-n2 ml-2 mr-2">mdi-receipt-text-plus</v-icon>Proofs Not yet send to Chargebee</h1>
      <h5>These items have been reviewed and when a enough are collected the batch can be sent to Chargebee</h5>

      <div v-if="loadingSummary">
        <app-spinner :spinner-size="40" class="mt-3" />
      </div>
      <h2>By business</h2>
      <div class="mt-6">
        <v-row class="ma-0 pa-0">
          <v-col cols="4" class="ma-0 pa-0">
            <span class="text-h5"> Business </span>
          </v-col>
          <v-col cols="3" class="ma-0 pa-0 text-h5">
            <span class=""> Chargbee Addon Id </span>
          </v-col>
          <v-col cols="1" class="ma-0 pa-0 text-h5">
            <span class=""> Count </span>
          </v-col>
          <v-col cols="4" class="ma-0 pa-0"> </v-col>
        </v-row>
        <v-row v-if="!chargeBeeSummary || chargeBeeSummary.length == 0" class="ma-0 pa-0">
          <v-col cols="12" class="ma-0" align="center"><span class="font-weight-black text-h5">No approved proofs with addons found!</span> </v-col>
        </v-row>
        <v-row v-for="(row, idx) in chargeBeeSummary" :key="`summaryRow-${idx}}`" class="ma-0 pa-0 mt-n4">
          <v-col cols="4" class="ma-0">
            <span class="font-weight-black">{{ idx }} - {{ comboTitle(row.business) }} </span>
          </v-col>
          <v-col cols="3" class="ma-0"> {{ row.chargbeeAddonId }}</v-col>
          <v-col cols="1" class="ma-0"> {{ row.count }}</v-col>
          <v-col cols="4" class="ma-0"></v-col>
        </v-row>
      </div>
      <div class="mt-6">
        <v-row>
          <v-col cols="8" align="right">
            <v-btn color="primary" @click="initiateCharges">DO IT</v-btn>
          </v-col>
          <v-col cols="48"> </v-col>
        </v-row>
      </div>
      <div class="mt-6">
        <h2>By Addon</h2>
        <v-row class="ma-0 pa-0">
          <v-col cols="1" class="ma-0 pa-0" />
          <v-col cols="4" class="ma-0 pa-0">
            <span class="text-h5"> Addon </span>
          </v-col>
          <v-col cols="4" class="ma-0 pa-0 text-h5">
            <span class=""> Count </span>
          </v-col>
          <v-col cols="3" class="ma-0 pa-0" />
        </v-row>
        <v-row v-if="!addonTotals || addonTotals.length == 0" class="ma-0 pa-0">
          <v-col cols="12" class="ma-0" align="center"><span class="font-weight-black text-h5">No summary!</span> </v-col>
        </v-row>
        <v-row v-for="(row, idx) in addonTotals" :key="`summaryRow-${idx}}`" class="ma-0 pa-0 mt-n4">
          <v-col cols="1" class="ma-0 pa-0" />
          <v-col cols="4" class="ma-0"> {{ row.chargbeeAddonId }}</v-col>
          <v-col cols="4" class="ma-0"> {{ row.count }}</v-col>
          <v-col cols="3" class="ma-0"></v-col>
        </v-row>
        <v-row class="ma-0 pa-0">
          <v-col cols="1" class="ma-0 pa-0 mt-n4" />
          <v-col cols="4" class="ma-0 font-weight-black"> Total</v-col>
          <v-col cols="4" class="ma-0 font-weight-black"> {{ addonTotals.reduce((total, cv) => total + cv.count, 0) }}</v-col>
          <v-col cols="3" class="ma-0"></v-col>
        </v-row>
      </div>

      <hr class="mt-6" />
      <h1><v-icon style="cursor: pointer" class="mt-n2 ml-2 mr-2">mdi-cash-multiple</v-icon>Gift cards to send</h1>
      <h5>Chargebee says these are paid, but Gift card not marked as sent. They need to be sent and then marked here</h5>

      <div v-if="loadingPaidItems">
        <app-spinner :spinner-size="40" class="mt-3" />
      </div>
      <div class="mt-6">
        <v-row class="ma-0 pa-0">
          <v-col cols="3" class="ma-0 pa-0">
            <span class="text-h8"> Business </span>
          </v-col>
          <v-col cols="2" class="ma-0 pa-0 text-h8">
            <span class=""> Chargbee Addon Id </span>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0 text-h8">
            <span class=""> Emails </span>
          </v-col>
          <v-col cols="1" class="ma-0 pa-0 text-h8">Mark</v-col>
        </v-row>
        <v-row v-if="!paidItems || paidItems.length == 0" class="ma-0 pa-0">
          <v-col cols="12" class="ma-0" align="center"><span class="font-weight-black text-h5">No Paid but Not Sent Items found!</span> </v-col>
        </v-row>
        <v-row v-for="(row, idx) in paidItems" :key="`summaryRow-${idx}}`" class="mt-10 pa-0">
          <v-col cols="3" class="ma-0">
            <span class="font-weight-black">{{ idx }} - {{ row.brand }}:</span>
            <div class="ml-5">
              <span class="font-weight-light">
                {{
                  row.business
                    .map((e) => e.title)
                    .filter((v, i, a) => a.indexOf(v) === i)
                    .join(', ')
                }}
              </span>
            </div>
          </v-col>
          <v-col cols="2" class="ma-0"> {{ row.chargbeeAddonId }}</v-col>
          <v-col cols="6" class="ma-0">
            <v-row align="center" justify="center">
              <v-col cols="1">
                <v-icon style="cursor: pointer" class="mt-n7 ml-2" @click="copyEmails(row.emails.join(', '))">mdi-content-copy</v-icon>
              </v-col>
              <v-col cols="11">
                <p class="caption">{{ row.emails.join(', ') }}</p>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="1" class="ma-0"> <v-btn color="primary" @click="markItemsAsSent(row)">Mark</v-btn></v-col>
        </v-row>
      </div>
      <hr class="mt-6" />
      <h1><v-icon style="cursor: pointer" class="mt-n2 ml-2 mr-2">mdi-cash-multiple</v-icon>Chargebee initiated not yet paid</h1>
      <h5>Chargebee hasn't gotten then money for these items yet. After we confirm they have the money we'll move to items to send. This happens hourly.</h5>

      <div v-if="loadingChargedButNotPaid">
        <app-spinner :spinner-size="40" class="mt-3" />
      </div>
      <div class="mt-6">
        <v-row class="ma-0 pa-0">
          <v-col cols="3" class="ma-0 pa-0">
            <span class="text-h8"> Business </span>
          </v-col>
          <v-col cols="1" class="ma-0 pa-0 text-h8">
            <span class=""> Chargebee Invoice</span>
          </v-col>
          <v-col cols="3" class="ma-0 pa-0 text-h8">
            <span class=""> Charged Date</span>
          </v-col>
          <v-col cols="5" class="ma-0 pa-0 text-h8">
            <span class=""> Emails </span>
          </v-col>
        </v-row>
        <v-row v-if="!chargedButNotPaid || chargedButNotPaid.length == 0" class="ma-0 pa-0">
          <v-col cols="12" class="ma-0" align="center"><span class="font-weight-black text-h5">No Paid but Not Sent Items found!</span> </v-col>
        </v-row>
        <v-row v-for="(row, idx) in chargedButNotPaid" :key="`summaryRow-${idx}}`" class="ma-0 pa-0">
          <v-col cols="3" class="ma-0">
            <span class="font-weight-black">{{ idx }} - {{ comboTitle(row.business) }} </span>
          </v-col>
          <v-col cols="1" class="ma-0"> {{ row.invoiceIds.filter((v, i, a) => a.indexOf(v) === i).join(', ') }}</v-col>
          <v-col cols="3" class="ma-0"> {{ row.chargedDate }}</v-col>
          <v-col cols="4" class="ma-0">
            <v-row align="center" justify="center">
              <v-col cols="1">
                <v-icon style="cursor: pointer" class="mt-n7 ml-2" @click="copyEmails(row.emails.join(', '))">mdi-content-copy</v-icon>
              </v-col>
              <v-col cols="11">
                <p class="caption">{{ row.emails.join(', ') }}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <hr class="mt-6" />
      <h1 class="mt-6"><v-icon style="cursor: pointer" class="mt-n2 ml-2 mr-2">mdi-gift-open-outline</v-icon>Items marked as sent for the past 30 days</h1>
      <h5>The last 30 days of items that we have completed and sent gift cards. Just left for verification purposes.</h5>

      <div v-if="loadingPaidItems">
        <app-spinner :spinner-size="40" class="mt-3" />
      </div>
      <div class="mt-6">
        <v-row class="ma-0 pa-0">
          <v-col cols="3" class="ma-0 pa-0">
            <span class="text-h8"> Business </span>
          </v-col>
          <v-col cols="2" class="ma-0 pa-0 text-h8">
            <span class=""> Chargbee Addon Id </span>
          </v-col>
          <v-col cols="7" class="ma-0 pa-0 text-h8">
            <span class=""> Emails </span>
          </v-col>
        </v-row>
        <v-row v-if="!sentItems || sentItems.length == 0" class="ma-0 pa-0">
          <v-col cols="12" class="ma-0" align="center"><span class="font-weight-black text-h5">No Found Items!</span> </v-col>
        </v-row>

        <v-row v-for="(row, idx) in sentItems" :key="`summaryRow-${idx}}`" class="ma-0 pa-0">
          <v-col cols="3" class="ma-0">
            <span class="font-weight-black">{{ idx }} - {{ comboTitle(row.business) }} </span>
          </v-col>
          <v-col cols="2" class="ma-0"> {{ row.chargbeeAddonId }}</v-col>
          <v-col cols="7" class="ma-0">
            <v-row align="center" justify="center">
              <v-col cols="1">
                <v-icon style="cursor: pointer" class="mt-n7 ml-2" @click="copyEmails(row.emails.join(', '))">mdi-content-copy</v-icon>
              </v-col>
              <v-col cols="11">
                <p class="caption">{{ row.emails.join(', ') }}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { ApiService } from '@/services'
import { copyToClipboard } from '@/mixins/copyToClipboard'

export default {
  name: 'SwayUserLookup',

  metaInfo: {
    title: 'AdminSettings',
  },

  mixins: [copyToClipboard],

  data: () => ({
    chargeBeeSummary: [],
    loadingSummary: false,
    paidItems: [],
    loadingPaidItems: false,
    sentItems: [],
    loadingSentItems: false,
    chargedButNotPaid: [],
    loadingChargedButNotPaid: false,
  }),

  computed: {
    addonTotals: function () {
      const addonTotals = {}
      this.chargeBeeSummary.forEach((row) => {
        const addon = row.chargbeeAddonId
        const val = parseInt(row.count)
        addonTotals[addon] = addonTotals[addon] ? addonTotals[addon] + val : val
      })
      if ((Object.keys(addonTotals).length = 0)) return []
      const returnVal = []
      Object.keys(addonTotals).forEach((e) => returnVal.push({ chargbeeAddonId: e, count: addonTotals[e] }))
      return returnVal
    },
  },

  mounted() {
    this.refreshLists()
  },

  methods: {
    refreshLists: function () {
      this.loadingSummary = true
      ApiService.get(`chargebeeAdmin/`).then((r) => {
        if (r.error) {
          this.$toast.error(`Error updating value ${r.error}`)
          this.chargeBeeSummary = []
        } else {
          this.chargeBeeSummary = r.data
        }
        this.loadingSummary = false
      })

      this.loadingPaidItems = true
      ApiService.get(`chargebeeAdmin/paidItems`).then((r) => {
        if (r.error) {
          this.$toast.error(`Error updating value ${r.error}`)
          this.paidItems = []
        } else {
          this.paidItems = r.data
        }

        this.loadingPaidItems = false
      })

      this.loadingSentItems = true
      ApiService.get(`chargebeeAdmin/sentItems`).then((r) => {
        if (r.error) {
          this.$toast.error(`Error updating value ${r.error}`)
          this.sentItems = []
        } else {
          this.sentItems = r.data
        }

        this.loadingSentItems = false
      })

      this.loadingChargedButNotPaid = true
      ApiService.get(`chargebeeAdmin/chargedButNotPaid`).then((r) => {
        if (r.error) {
          this.$toast.error(`Error updating value ${r.error}`)
          this.chargedButNotPaid = []
        } else {
          this.chargedButNotPaid = r.data
        }

        this.loadingChargedButNotPaid = false
      })
    },
    initiateCharges: function () {
      ApiService.post(`chargebeeAdmin/runCharges`).then((r) => {
        if (r.error) {
          this.$toast.error(`Error updating value ${r.error}`)
        }
        this.$toast.success(`Made ${r.data.invoiceCountSuccess} invoices failed ${r.data.invoiceCountFailure}`)
        this.refreshLists()
      })
    },
    copyEmails: function (emails) {
      this.copyToClipboard(emails)
      this.$toast.success(`Copied ${emails.split(',').length} to clipboard!`)
    },
    markItemsAsSent: function (row) {
      ApiService.post(`chargebeeAdmin/markPaidItems`, { downloadedProofIds: row.sideEffectIds }).then((r) => {
        if (r.error) {
          this.$toast.error(`Error: ${r.error}`)
        }
        this.$toast.success(`Marked ${r.data} proofs`)
        this.refreshLists()
      })
    },
    comboTitle: function (business) {
      return business?.brand?.trim() === business?.title?.trim() ? business.brand : `${business.brand} - ${business.title}`
    },
  },
}
</script>

<style scoped>
#settings {
  width: 100%;
}
</style>
