<template>
  <v-select
    :loading="!offerSummariesLoaded"
    :disabled="!offerSummariesLoaded"
    :items="offerSummaries"
    :return-object="true"
    :menu-props="{ maxHeight: '70vh' }"
    :value="value"
    item-text="summary_value"
    item-value="id"
    outlined
    @change="$emit('change', $event)"
  />
</template>

<script>
import Vue from 'vue'

import { mapGetters } from 'vuex'

import { ApiService } from '@/services/index'
import { OfferSummaryPerformance } from '@/models'

export default Vue.extend({
  name: 'OfferSummarySelector',

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    offerSummaries: [],
    offerSummariesLoaded: false,
  }),

  computed: {
    ...mapGetters('business', ['entity']),
  },

  mounted() {
    ApiService.get(`offerSummary/${this.entity.industry_category_id}`).then((response) => {
      this.offerSummaries = Object.keys(OfferSummaryPerformance).reduce((offerSummaries, performance) => {
        const summariesByPerformance = response.data.filter((offerSummary) => offerSummary.performance === performance)

        if (summariesByPerformance.length > 0) {
          offerSummaries.push({ header: OfferSummaryPerformance[performance].title })
          offerSummaries.push(...summariesByPerformance)
        }

        return offerSummaries
      }, [])

      this.offerSummariesLoaded = true
    })
  },
})
</script>
