<template>
  <div id="locations">
    <NewLocationModal
      v-if="showNewLocationModal"
      :location-saving="newLocationSaving"
      @new-location-modal-cancel="showNewLocationModal = false"
      @new-location-modal-save="handleSaveNewLocation"
    />

    <ManualLocationModal
      v-if="showAddManualLocationModal"
      :location-saving="newLocationSaving"
      @manual-location-modal-cancel="showAddManualLocationModal = false"
      @manual-location-modal-save="handleSaveNewLocation"
    />

    <v-container>
      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn v-if="Object.keys(locationsByState).length > 0" color="primary" @click="showNewLocationModal = true">
            <img width="24" height="24" class="pr-1" :src="require('@/assets/svg/locations-add.svg')" /> Add Location
          </v-btn>
          <v-btn v-if="isAdmin" color="orange" class="ml-3 white--text" @click="showAddManualLocationModal = true">
            <img width="24" height="24" class="pr-1" :src="require('@/assets/svg/locations-add.svg')" /> Add Manually
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <app-spinner v-if="loading" />
        <v-col v-else :cols="12">
          <div v-if="Object.keys(locationsByState).length === 0">
            <h2 class="text-center font-weight-light">Welcome to the locations screen.</h2>
            <p style="padding-top: 1em; padding-left: 1.4em">
              Adding locations is the first step in building your offer. Here you will add the addresses and Google review links for the locations participating
              in your offer. To get started click or tap "Add Location".
            </p>
            <p class="text-center">
              <v-btn color="text-center primary" @click="showNewLocationModal = true">
                <img width="24" height="24" class="pr-1" :src="require('@/assets/svg/locations-add.svg')" /> Add Location
              </v-btn>
            </p>
            <ul class="breathable-text">
              <li><strong>Enter a location:</strong> Start typing in your location address for Google maps to find and validate it.</li>
              <li>
                <strong>Tags:</strong>If you have multiple locations, tags can be used to quickly select a group of locations by city, state, zip, or
                characteristics.
                <ul>
                  <li><strong>Default Tags:</strong> We automatically create tags for city, state, and zipcode.</li>
                  <li>
                    <strong>Custom Tags:</strong> If you have multiple locations and want to group them you can add a tag for easy reference later when building
                    an offer (i.e. Dine-in only).
                  </li>
                </ul>
              </li>
              <li>
                <strong>Google Review Url:</strong> Copy and paste in the full google maps URL for your location. Locals will use this url link if asked to
                leave you a review for your offer. You can skip this field if your business is not set up on Google.
              </li>
            </ul>
          </div>

          <div v-for="locationState in Object.keys(locationsByState)" :key="locationState" class="mb-6">
            <div class="mb-2">
              <span class="text-h5 text-left font-weight-bold">{{ locationState }}</span>
              <span class="ml-3 font-weight-light">
                {{ locationsByState[locationState].length }} {{ 'location' | pluralize(locationsByState[locationState].length) }}
              </span>
            </div>

            <v-expansion-panels inset hover>
              <LocationDetailsPanel v-for="location in locationsByState[locationState]" :key="location.id" :location="location" />
            </v-expansion-panels>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NewLocationModal from '@/components/Locations/NewLocationModal'
import ManualLocationModal from '@/components/Locations/ManualLocationModal'
import LocationDetailsPanel from '@/components/Locations/LocationDetailsPanel'

export default {
  name: 'Locations',

  metaInfo: {
    title: 'Locations',
  },

  components: {
    NewLocationModal,
    ManualLocationModal,
    LocationDetailsPanel,
  },

  data: () => ({
    showNewLocationModal: false,
    showAddManualLocationModal: false,
  }),

  computed: {
    ...mapGetters('account', ['isAdmin']),
    ...mapGetters('location', ['loading', 'newLocationSaving', 'locationsByState']),
  },

  watch: {
    newLocationSaving: function () {
      if (this.newLocationSaving === false) {
        this.showNewLocationModal = false
        this.showAddManualLocationModal = false
      }
    },
  },

  mounted() {
    this.$store.dispatch('location/refresh')
  },

  methods: {
    ...mapActions('location', ['saveLocation']),
    handleSaveNewLocation(location) {
      this.saveLocation({ addressData: { address: location } })
    },
  },
}
</script>

<style scoped lang="scss">
#locations {
  width: 100%;
}
.action_highlight {
  color: $lf-red;
}
strong {
  text-transform: uppercase;
}
li {
  padding-top: 1.2em;
  list-style: none;
}
</style>
