<template>
  <v-dialog :value="true" persistent max-width="800" @keydown.esc="handleModalClose">
    <app-spinner v-if="loading" :spinner-size="80" class="spinner-background" />

    <v-card>
      <v-card-text class="pb-0 pt-8">
        <v-row>
          <v-col cols="4" align="center" justify="center" class="grow">
            <h5 v-if="proof.proof_type" class="color light-gray text-uppercase opacity-50 ml-3">
              <v-img :src="getProofIcon" aspect-ratio="1" height="24" width="24" class="p-0 mr-1 mb-3 d-inline-flex" />
              <span class="text-uppercase"> {{ proof.proof_type | prettyString }} </span>
            </h5>
            <ScreenShotPost v-if="proof.proof_image_url" :img-url="proof.proof_image_url" classes="" />
          </v-col>
          <v-col cols="8">
            <v-row align="center" justify="center" class="mt-0">
              <v-col cols="12" align="center" justify="center">
                <v-img :src="require('@/assets/svg/sway-logo.svg')" height="24" width="96" class="p-0" />
              </v-col>
            </v-row>
            <v-row v-if="proof.first_name" align="center" justify="center" class="mt-0">
              <v-col cols="3" align="center" justify="center">
                <v-icon large class="pa-0 ma-0 mt-n4">mdi-account-circle-outline</v-icon>
                <span class="overline d-block">local</span>
              </v-col>
              <v-col cols="9" align="left" justify="center">
                <h1>{{ proof.first_name }}</h1>
                <h3>e: {{ proof.email || proof.email_address }}</h3>
                <h3>p: {{ proof.phone }}</h3>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-0">
              <v-col cols="3" align="center" justify="center">
                <v-icon large class="pa-0 ma-0 block">mdi-offer</v-icon>
                <span class="overline d-block">offer</span>
              </v-col>
              <v-col cols="9" align="left" justify="center">
                <v-row>
                  <v-col cols="12">
                    <span class="title-2 mb-0">{{ offer.offer_summary }}</span>
                  </v-col>
                </v-row>
                <h5 class="color light-gray text-uppercase opacity-50"></h5>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-0">
              <v-col cols="3" align="center" justify="center">
                <p :class="['text-h4', 'font-weight-medium', 'mb-0', colorFromProofType].join(' ')">${{ amount }}</p>
                <span :class="['overline', 'd-block', colorFromProofType].join(' ')">value</span>
              </v-col>
              <v-col cols="9" align="left" justify="center" class="pa-0">
                <h5 v-if="proof.proof_type" class="color light-gray text-uppercase opacity-50 ml-3">
                  <v-img :src="getProofIcon" aspect-ratio="1" height="24" width="24" class="p-0 mr-1 d-inline-flex" />
                  <span class="text-uppercase"> {{ proof.proof_type | prettyString }} </span>
                </h5>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="proof && isAdmin">
          <v-col cols="12">
            <v-expansion-panels flat>
              <v-expansion-panel>
                <v-expansion-panel-header> Admin </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12">
                      <v-select v-model="proof.proof_type" :items="taskTypes" item-text="title" item-value="type" outlined label="Proof Type" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" justify="left"> <v-btn @click="handleDeleteProof">Delete 😱</v-btn></v-col>

                    <v-col cols="6" align="right" justify="right">
                      <v-btn color="primary" :disabled="btnSaving" @click="handleSaveButtonClicked">Save</v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="handleModalClose">Close</v-btn>
        <!-- <v-btn :loading="false" color="primary darken-1" text @click="handleModalClose">Feedback</v-btn> -->
      </v-card-actions>
    </v-card>

    <ProofFeedbackModal v-if="showProofFeedbackModal" :proof="proofWithUser" @proof-feedback-modal-close="handleFeedbackClose" />
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import taskTypes from '../../models/taskType'

import { ApiService } from '@/services/index'
import { ProofFeedback } from '@/models/Proof'

import ScreenShotPost from '@/components/ProofCardList/ProofCardContent/ScreenShotPost'
import ProofFeedbackModal from '@/components/ProofCardList/ProofFeedbackModal'

export default Vue.extend({
  name: 'ProofDetailModal',
  components: {
    ScreenShotPost,
    ProofFeedbackModal,
  },
  props: {
    swayProofId: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    proof: {},
    offer: {},
    proof_num: null,
    loading: false,
    showProofFeedbackModal: false,
    proofTypesLoading: false,
    btnSaving: false,
    newType: null,
    wasDeleted: false,
  }),
  computed: {
    ...mapGetters('account', ['isAdmin']),
    taskTypes() {
      return Object.values(taskTypes)
    },
    proofWithUser() {
      return { ...this.proof, influencer: { ...this.user } }
    },
    feedbackTypes() {
      return Object.values(ProofFeedback)
    },
    colorFromProofType() {
      if (this.proof.proof_type && this.proof.proof_type.toLowerCase().includes('review')) return 'review-color'
      return 'post-color'
    },
    data: function () {
      return {
        chart: {
          type: 'radialBar',
        },
        colors: ['#98E3F8', '#1F487E'],
        series: [0.5 * 100, 0.4 * 100],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined,
            },
            track: {
              show: true,
            },
            dataLabels: {
              name: {
                show: true,
              },
              value: {
                show: true,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
        labels: ['Redeems', 'Posts'],
      }
    },
    getProofIcon() {
      if (!this.proof.proof_type) return require('@/assets/svg/sway-logo.svg')

      const proofType = taskTypes[this.proof.proof_type]
      return proofType ? proofType.icon : ''
    },
  },
  mounted: function () {
    ApiService.get(`dashboard/swayProofDetails/${this.swayProofId}`).then((response) => {
      this.proof = response.data.sway_proof
      this.offer = response.data.sway_offer
      this.loading = false
    })
  },

  methods: {
    handleSaveButtonClicked() {
      if (!this.proof || !this.proof.proof_type) return
      this.btnSaving = true
      ApiService.put(`proof/${this.proof.id}/updateSwayProofType/${this.proof.proof_type}`).then((response) => {
        this.btnSaving = false
        if (response.data.error) {
          this.$toast.error(`Error updating ${JSON.stringify(response.data.error)}`)
        } else {
          this.newType = this.proof.proof_type
          this.$toast.success(`Type updated to ${JSON.stringify(response.data)}`)
          this.handleModalClose()
        }
      })
    },
    handleDeleteProof() {
      ApiService.delete(`proof/${this.proof.id}`).then((response) => {
        if (response.data.error) {
          this.$toast.error(`Error deleting ${JSON.stringify(response.data.error)}`)
        } else {
          this.wasDeleted = true
          this.$toast.success(`Proof Deleted... ${JSON.stringify(response.data)} Hope that's what you wanted. 😱`)
          this.handleModalClose()
        }
      })
    },
    handleModalClose() {
      this.$emit('detail-modal-close', this.newType, this.wasDeleted)
    },
    iconForType(fb) {
      return ProofFeedback[fb].icon
    },
  },
})
</script>

<style scoped>
.review-color {
  color: #98e3f8 !important;
}

.post-color {
  color: #1f487e !important;
}

.ticket-color {
  color: #376996 !important;
}

.border {
  border: thin solid #cccccc;
}
</style>
