<template>
  <v-expansion-panel :disabled="disabled" @click.native.stop>
    <v-expansion-panel-header class="pl-10">Social Proof Tags & Mentions</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12">
          <p :class="['v-label theme--light', disabled ? 'grey--text' : '']" style="font-size: 14px">
            Customers will be asked to include the following hashtags and mentions when sharing their proof.
          </p>
        </v-col>
      </v-row>

      <v-row class="mt-n6 mb-3">
        <v-col cols="12">
          <v-combobox
            ref="hashtags"
            v-model="hash_tags"
            :items="[]"
            :append-icon="null"
            :disabled="disabled"
            label="Hashtags"
            flat
            chips
            outlined
            multiple
            hide-details
            @keydown.space="$refs.hashtags.blur()"
            @change="handleSaveHashtags()"
          >
            <template #selection="{ attrs, item, selected }">
              <v-chip v-bind="attrs" :input-value="selected" :color="`${disabled ? 'light-grey' : 'primary'} lighten-1`">
                <strong>{{ formatHashtag(item) }}</strong>
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>

      <v-row v-for="(taskType, index) in [...socialPostTaskTypes, ...reviewTaskTypes]" :key="index" class="task-type-row" align="center">
        <v-col cols="2" align="center" class="pa-0 mt-n2">
          <v-img :src="taskType.task.icon" height="24" width="24" :class="['p-0 d-inline-flex', taskType.enabled ? '' : 'disabled']" />
          <v-switch
            v-model="taskType.enabled"
            :disabled="disabled"
            :color="disabled ? 'grey' : 'primary'"
            inset
            class="task-type-switch"
            @change="handleToggleTaskType"
          />
        </v-col>

        <v-col cols="10">
          <v-row>
            <v-col cols="12">
              <v-combobox
                v-if="taskType.mentions"
                v-model="taskType.mentions"
                :items="[]"
                :append-icon="null"
                :disabled="disabled || !taskType.enabled"
                label="@ Mentions"
                append-outer-icon="mdi-content-copy"
                flat
                chips
                outlined
                multiple
                hide-details
                @change="handleSaveMentions(taskType)"
                @click:append-outer="copyMentions(taskType.mentions)"
              >
                <template #selection="{ attrs, item, selected }">
                  <v-chip v-bind="attrs" :input-value="selected" :disabled="!taskType.enabled" :color="`${disabled ? 'light-grey' : 'primary'} lighten-1`">
                    <strong>{{ formatMention(item) }}</strong>
                  </v-chip>
                </template>
              </v-combobox>

              <v-text-field
                v-else
                v-model="taskType.url"
                :disabled="disabled || !taskType.enabled"
                :rules="optionalUrlRules"
                flat
                outlined
                hide-details
                label="Review Url"
                @change="handleSaveReviewUrl(taskType)"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="taskType.proofSample"
                :disabled="disabled || !taskType.enabled"
                flat
                outlined
                hide-details
                :rows="2"
                :label="`Sample ${taskType.task.verb}`"
                @blur="handleSaveSampleProof(taskType)"
                @keydown.enter.prevent="$event.target.blur()"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue from 'vue'

import taskTypes from '@/models/taskType'

import validationRules from '@/utils/validation'
import { copyToClipboard } from '@/mixins/copyToClipboard'
import { formatHashtag, formatMention } from '@/utils/formatsocials'

export default Vue.extend({
  name: 'SwayOfferSocialsPanel',

  mixins: [copyToClipboard],

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    tasks: {
      type: Array,
      default: () => [],
    },
    hashtags: {
      type: Array,
      default: () => [],
    },
    instagramMentions: {
      type: Array,
      default: () => [],
    },
    facebookMentions: {
      type: Array,
      default: () => [],
    },
    tiktokMentions: {
      type: Array,
      default: () => [],
    },
    googleReviewUrl: {
      type: String,
      default: null,
    },
    facebookReviewUrl: {
      type: String,
      default: null,
    },
    instagramProofSample: {
      type: String,
      default: null,
    },
    facebookProofSample: {
      type: String,
      default: null,
    },
    tiktokProofSample: {
      type: String,
      default: null,
    },
    googleReviewProofSample: {
      type: String,
      default: null,
    },
    facebookReviewProofSample: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    hash_tags: [],
    socialPostTaskTypes: [
      {
        task: taskTypes.INSTAGRAM_POST,
        enabled: false,
        proofSample: '',
        mentions: [],
        saveField: 'instagram_mentions',
        proofSampleSaveField: 'instagram_proof_sample',
      },
      {
        task: taskTypes.FACEBOOK_POST,
        enabled: false,
        proofSample: '',
        mentions: [],
        saveField: 'facebook_mentions',
        proofSampleSaveField: 'facebook_proof_sample',
      },
      {
        task: taskTypes.TIK_TOK,
        enabled: false,
        proofSample: '',
        mentions: [],
        saveField: 'tiktok_mentions',
        proofSampleSaveField: 'tiktok_proof_sample',
      },
    ],
    reviewTaskTypes: [
      {
        task: taskTypes.ONLINE_REVIEW,
        enabled: false,
        proofSample: '',
        url: '',
        saveField: 'google_review_url',
        proofSampleSaveField: 'google_review_proof_sample',
      },
      {
        task: taskTypes.FACEBOOK_REVIEW,
        enabled: false,
        proofSample: '',
        url: '',
        saveField: 'facebook_review_url',
        proofSampleSaveField: 'facebook_review_proof_sample',
      },
    ],
    optionalUrlRules: validationRules.optionalUrlRules,
  }),

  mounted: function () {
    this.hash_tags = this.hashtags

    this.socialPostTaskTypes = this.socialPostTaskTypes.map((proofType) => {
      const enabled = this.tasks.includes(proofType.task.type)

      if (proofType.task === taskTypes.INSTAGRAM_POST)
        return { ...proofType, enabled, mentions: this.instagramMentions, proofSample: this.instagramProofSample }

      if (proofType.task === taskTypes.FACEBOOK_POST) return { ...proofType, enabled, mentions: this.facebookMentions, proofSample: this.facebookProofSample }

      if (proofType.task === taskTypes.TIK_TOK) return { ...proofType, enabled, mentions: this.tiktokMentions, proofSample: this.tiktokProofSample }

      return proofType
    })

    this.reviewTaskTypes = this.reviewTaskTypes.map((proofType) => {
      const enabled = this.tasks.includes(proofType.task.type)

      if (proofType.task === taskTypes.ONLINE_REVIEW) return { ...proofType, enabled, url: this.googleReviewUrl, proofSample: this.googleReviewProofSample }
      if (proofType.task === taskTypes.FACEBOOK_REVIEW)
        return { ...proofType, enabled, url: this.facebookReviewUrl, proofSample: this.facebookReviewProofSample }

      return proofType
    })
  },

  methods: {
    trim(strings) {
      return strings.map((string) => string.trim())
    },

    formatHashtag: (hashtag) => formatHashtag(hashtag),

    formatMention: (mention) => formatMention(mention),

    copyMentions(mentions) {
      this.copyToClipboard(`${mentions.map((mention) => formatMention(mention)).join(' ')}`)
      this.$toast.success('Copied to clipboard!')
    },

    handleSaveHashtags() {
      this.$emit('save', { hashtags: this.trim(this.hash_tags) })
    },

    handleSaveSampleProof(taskType) {
      this.$emit('save', { [taskType.proofSampleSaveField]: taskType.proofSample })
    },

    handleSaveMentions(taskType) {
      this.$emit('save', { [taskType.saveField]: this.trim(taskType.mentions) })
    },

    handleSaveReviewUrl(taskType) {
      this.$emit('save', { [taskType.saveField]: taskType.url.trim() })
    },

    handleToggleTaskType() {
      this.$emit('save', {
        tasks: [...this.socialPostTaskTypes, ...this.reviewTaskTypes].filter((taskType) => taskType.enabled).map((taskType) => taskType.task.type),
      })
    },
  },
})
</script>

<style scoped>
.disabled {
  opacity: 0.3;
}

.task-type-switch {
  margin-top: 0;
  width: 40px;
}

.task-type-row {
  border-top: 1px solid #dedede;
  padding-top: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
