export const copyToClipboard = {
  methods: {
    copyToClipboard(valueToCopy: string) {
      const el = document.createElement('textarea')
      el.addEventListener('focusin', (e) => e.stopPropagation())
      el.value = valueToCopy
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
  },
}
