<template>
  <div>
    <v-dialog :value="showImageModal" persistent max-width="400">
      <v-card>
        <v-card-title class="headline pb-0">Preview</v-card-title>
        <v-card-text class="pt-5 pb-0">
          <div v-if="imagePreview" class="upload-placeholder base-image-input" :style="{ 'background-image': `url(${imagePreview})` }" />
          <v-skeleton-loader v-else type="image" />

          <v-progress-linear v-if="progressBarVisible" v-model="imageUploadProgress" height="25" color="pink" class="progress-bar" striped>
            <span class="white--text text font-weight-bold">{{ imageProgressAction }}</span>
          </v-progress-linear>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="progressBarVisible" color="darken-1" text @click="closeModal()">Cancel</v-btn>
          <v-btn :disabled="imagePreview == null || progressBarVisible" color="primary darken-1" text @click="uploadImage()">
            {{ previewSuccessButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div :class="['image-upload-form', imageUrl ? '' : 'placeholder']" align="center" justify="center" @click="chooseImage">
      <div v-if="!imageUrl" class="upload-text">
        <slot>
          <h2>Click to upload image</h2>
        </slot>
        <img class="mt-4" width="60" :src="require('@/assets/svg/upload-icon.svg')" />
      </div>

      <v-btn v-if="allowImageDeletion && imageUrl" small fab color="red accent-3" class="delete-icon" @click.stop="deleteImage()">
        <v-icon color="white">mdi-image-remove</v-icon>
      </v-btn>

      <div class="text-center upload-placeholder base-image-input" :style="imageUrl ? { 'background-image': `url(${imageUrl})` } : {}">
        <input ref="imageFileInput" class="file-input" type="file" accept="image/*" @change="onFileSelected" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

import { ApiService } from '@/services/index'

import imageCompression from 'browser-image-compression'

export default Vue.extend({
  name: 'ImageUploadForm',

  props: {
    editMode: {
      type: Boolean,
      required: true,
    },
    imageType: {
      type: String,
      required: true,
    },
    allowImageDeletion: {
      type: Boolean,
      default: false,
    },
    imageUrl: {
      type: String,
      default: () => null,
    },
    previewSuccessButtonText: {
      type: String,
      default: () => 'Save',
    },
  },

  data: () => ({
    imageData: null,
    imagePreview: null,
    showImageModal: false,
    imageUploadProgress: -1,
    imageProgressAction: '',
  }),

  computed: {
    progressBarVisible() {
      return this.imageUploadProgress >= 0
    },
  },

  watch: {
    imageUrl: function (event) {
      this.imageData = null
      this.imagePreview = null
      this.showImageModal = false
      this.imageUploadProgress = -1
    },
  },

  methods: {
    closeModal() {
      this.imageData = null
      this.imagePreview = null
      this.showImageModal = false
    },
    chooseImage(event) {
      if (!this.editMode) return

      this.$refs.imageFileInput.click()

      if (event) {
        event.stopPropagation()
      }
    },
    onFileSelected: function (event) {
      if (!event.target.files || !event.target.files[0]) return

      this.showImageModal = true
      this.imageProgressAction = 'Generating Preview'

      imageCompression(event.target.files[0], {
        maxSizeMB: 0.3,
        onProgress: (progress) => {
          this.imageUploadProgress = progress
        },
      })
        .then((response) => {
          this.imageData = response

          imageCompression.getDataUrlFromFile(response).then((response) => {
            this.imagePreview = response
            this.imageUploadProgress = -1

            this.$store.dispatch('heap/track', { name: 'Image: Previewed' })
          })
        })
        .catch((error) => {
          this.$toast.error('Error Uploading Image')
          this.$store.dispatch('heap/track', { name: 'Error: Image Compression', properties: error })
        })
    },
    uploadImage: function () {
      this.imageUploadProgress = 0
      this.imageProgressAction = 'Uploading'

      ApiService.get(`signedUrl/${this.imageType}/${this.imageData.type.split('/')[1]}`)
        .then((response) => {
          return response.data
        })
        .then((signedUrlResponse) => {
          const requestConfig = {
            headers: { 'Content-Type': this.imageData.type },
            onUploadProgress: (progressEvent) => {
              this.imageUploadProgress = (progressEvent.loaded / progressEvent.total) * 100
            },
          }

          return ApiService.customRequest()
            .put(signedUrlResponse.signedUrl, this.imageData, requestConfig)
            .then(() => {
              this.showImageModal = false
              this.$emit('image-uploaded', { image_url: signedUrlResponse.publicUrl })
            })
        })
        .catch((error) => {
          this.imageUploadProgress = -1
          this.imageProgressAction = ''

          this.$toast.error('Error Uploading Image')
          this.$store.dispatch('heap/track', { name: 'Image: Upload Error', properties: error })
        })
    },
    deleteImage: function () {
      this.$emit('image-deleted', { image_url: null })
    },
  },
})
</script>

<style scoped>
.image-upload-form {
  width: 100%;
  cursor: pointer;
  position: relative;
  background-color: #fff;
  background-image: none;
}

.image-upload-form.placeholder {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D3D3D3FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.progress-bar {
  margin-top: 10px;
}

.upload-save-button {
  padding-right: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: 200px;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
}

.base-image-input {
  display: block;
  width: 100%;
  height: 200px;
}

.upload-placeholder {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center, center;
}

.upload-placeholder-error {
  border: 1px solid red;
}

.upload-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  color: #8e8e8e;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: 700;
}

.file-input {
  display: none;
}

.delete-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
