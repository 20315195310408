<template>
  <BaseOfferPanel
    offer-type-icon="ca-icon"
    :offer-type="offerType"
    :offer-image-url="swayOffer.offer_image_url"
    :offer-summary="swayOffer.offer_summary"
    :offer-start-date="swayOffer.offer_start_date"
  >
    <template #offerPreview>
      <SwayOfferPreview :sway-offer="swayOffer" :preview-mode="previewMode" :preview-modes="previewModes" @sway-offer-preview-mode-change="changePreviewMode" />
    </template>

    <template #offerControls>
      <SwayOfferControls :sway-offer="swayOffer" />
    </template>

    <template #formBase>
      <SwayOfferFormBase :sway-offer="swayOffer" @sway-screenshot-flow-panel-change="changePreviewMode" />
    </template>
  </BaseOfferPanel>
</template>

<script>
import Vue from 'vue'

import { SWAY_OFFER_PREVIEW_MODE_TYPES } from '@/models/SwayOffer'

import BaseOfferPanel from '@/components/Offers/OfferPanels/BaseOfferPanel'
import SwayOfferPreview from '@/components/Offers/OfferForm/SwayOfferPreview'
import SwayOfferFormBase from '@/components/Offers/OfferForm/SwayOfferFormBase'
import SwayOfferControls from '@/components/Offers/OfferForm/SwayOfferControls'

export default Vue.extend({
  name: 'BaseSwayOfferPanel',

  components: {
    BaseOfferPanel,
    SwayOfferPreview,
    SwayOfferFormBase,
    SwayOfferControls,
  },

  props: {
    swayOffer: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    previewMode: SWAY_OFFER_PREVIEW_MODE_TYPES.YOUR_CAMPAIGN,
  }),

  computed: {
    offerType() {
      return [
        ...(this.swayOffer.flow.claim_enabled ? ['Screenshot'] : []),
        ...(this.swayOffer.flow.contact_info_enabled ? ['Contact Info'] : []),
        ...(this.swayOffer.flow.end_type === 'VOUCHER' ? ['Voucher'] : ['Whats Next']),
      ].join(', ')
    },
    previewModes: function () {
      return [
        ...[SWAY_OFFER_PREVIEW_MODE_TYPES.YOUR_CAMPAIGN],
        ...(this.swayOffer.flow.claim_enabled ? [SWAY_OFFER_PREVIEW_MODE_TYPES.SCREENSHOT] : []),
        ...(this.swayOffer.flow.claim_enabled && this.swayOffer.flow.claim_data.require_social_proof
          ? [SWAY_OFFER_PREVIEW_MODE_TYPES.PROOF_REQUIREMENTS_TUTORIAL]
          : []),
        ...(this.swayOffer.flow.contact_info_enabled ? [SWAY_OFFER_PREVIEW_MODE_TYPES.CONTACT_INFO] : []),
        ...(this.swayOffer.flow.end_type === 'VOUCHER' ? [SWAY_OFFER_PREVIEW_MODE_TYPES.VOUCHER] : [SWAY_OFFER_PREVIEW_MODE_TYPES.WHATS_NEXT]),
      ]
    },
  },

  watch: {
    previewModes: function (updatedPreviewModes) {
      if (!updatedPreviewModes.includes(this.previewMode)) {
        this.previewMode = SWAY_OFFER_PREVIEW_MODE_TYPES.YOUR_CAMPAIGN
      }
    },
  },

  methods: {
    changePreviewMode(event) {
      this.previewMode = event
    },
  },
})
</script>
