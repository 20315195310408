<template>
  <v-dialog :value="true" persistent max-width="800" @keydown.esc="handleModalClose">
    <v-card>
      <v-card-text class="pb-0 pt-8">
        <v-row>
          <v-col>
            <h3>Choose a cvs file to upload</h3>
            <p>The first column of each row in the CSV will be considered a code</p>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="8">
            <span class="mr-2 font-weight-black">Choose CSV:</span>
            <input ref="csvFile" type="file" @change="processCSV" />
          </v-col>
          <v-col cols="4"><v-switch v-model="hasHeader" outlined label="hasHeader" @change="processCSV" /></v-col>
        </v-row>
        <v-row v-if="codes.length > 0">
          <v-col cols="12">
            <p class="font-weight-black text-h5">{{ codes.length }} codes found</p>
            <p class="font-weight-black mb-0">First 5 codes</p>
            <span class="caption">{{ firstCodes.join(', ') }}</span>
            <div v-if="lastCodes.length > 0" class="mt-2">
              <p class="font-weight-black mb-0">Last 5 codes</p>
              <span class="caption">{{ lastCodes.join(', ') }}</span>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('cancel-modal')">Cancel</v-btn>
        <v-btn color="primary darken-1" text @click="$emit('upload-new-codes', { codes })"> UPLOAD </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import Papa from 'papaparse'

export default Vue.extend({
  name: 'UploadCodeListModal',

  components: {},
  props: {
    codeListId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    csvFile: null,
    codes: [],
    hasHeader: true,
  }),

  computed: {
    firstCodes() {
      if (this.codes.length < 5) return this.codes
      return this.codes.slice(0, 5)
    },
    lastCodes() {
      if (this.codes.length < 5) return this.codes
      return this.codes.slice(this.codes.length - 5 - 1, this.codes.length - 1)
    },
  },

  mounted() {},

  methods: {
    processCSV() {
      this.csvFile = this.$refs.csvFile.files[0]
      Papa.parse(this.csvFile, {
        header: this.hasHeader,
        skipEmptyLines: true,
        delimiter: ',',
        complete: function (result) {
          this.rawParse = result
          if (this.hasHeader) {
            const colName = result.meta.fields[0]
            this.codes = result.data.map((e) => e[colName])
          } else {
            this.codes = result.data.map((e) => e[0])
          }
        }.bind(this),
      })
    },
  },
})
</script>
<style scoped>
.row-height {
  height: 8em;
}
</style>