<template>
  <div>
    <h3>Business</h3>
    <v-form ref="businessSettingsForm">
      <v-row class="mt-0">
        <v-col cols="12">
          <v-text-field v-model="settings.brand" outlined label="Brand" :rules="validationRules.requiredRule" />
        </v-col>
      </v-row>

      <v-row class="mt-n4">
        <v-col cols="12">
          <v-text-field v-model="settings.title" outlined label="Title" :rules="validationRules.requiredRule" />
        </v-col>
      </v-row>

      <v-row class="mt-n4">
        <v-col cols="12">
          <v-select
            v-model="settings.industryCategoryId"
            :loading="subIndustriesLoading"
            :disabled="subIndustriesLoading"
            :items="subIndustries"
            :rules="validationRules.requiredRule"
            outlined
            item-text="title"
            item-value="id"
            label="Sub-Industry"
          />
        </v-col>
      </v-row>

      <v-row class="mt-n4">
        <v-col cols="8 offset-2" class="text-center">
          <ImageUploadForm
            :edit-mode="true"
            :allow-image-deletion="true"
            :image-url="settings.logo_url"
            image-type="businessLogo"
            preview-success-button-text="Select"
            @image-deleted="handleUpdateLogoImage"
            @image-uploaded="handleUpdateLogoImage"
          >
            <h1>Business Logo</h1>
            <h3 class="mt-6">Click to upload image</h3>
          </ImageUploadForm>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn :loading="loading" color="primary" @click="handleSaveButtonClicked">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'

import { ApiService } from '@/services/index'

import ImageUploadForm from '@/components/ImageUploadForm'

export default Vue.extend({
  name: 'BusinessSettings',

  components: {
    ImageUploadForm,
  },

  props: {
    settings: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    loading: false,
    subIndustries: [],
    subIndustriesLoading: true,
    validationRules: {
      requiredRule: [(v) => !!v || 'Required'],
    },
  }),

  mounted: function () {
    ApiService.get('industry/business/categories').then((response) => {
      this.subIndustries = response.data
      this.subIndustriesLoading = false
    })
  },

  methods: {
    ...mapActions('settings', ['updateBusinessSettings']),
    async handleSaveButtonClicked() {
      if (!this.$refs.businessSettingsForm.validate()) return

      this.loading = true

      const businessSettings = {
        brand: this.settings.brand,
        title: this.settings.title,
        logo_url: this.settings.logo_url,
        industryCategoryId: this.settings.industryCategoryId,
      }

      await this.updateBusinessSettings({ businessSettings: { ...businessSettings } })

      this.loading = false
      this.$toast.success('Business Settings Updated')
    },
    handleUpdateLogoImage(uploadResults) {
      this.settings.logo_url = uploadResults.image_url
    },
  },
})
</script>
