<template>
  <div id="setup">
    <v-container>
      <v-row>
        <v-col class="text-h5 font-weight-bold text-center">Your account has been created!</v-col>
      </v-row>
      <v-row>
        <v-col cols="10" offset="1" class="text-center">
          Now that your account has been created, it's time to build a campaign that you can share with your customers. We've prepared a list of things that
          will help you get there quickly.
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-expansion-panels v-model="currentSetupPanel" accordion>
            <v-expansion-panel>
              <v-expansion-panel-header :color="entity.social_setup ? '#EBEBEB' : ''">
                <template #default="{ open }">
                  <span :class="`${open ? 'font-weight-bold' : ''}`">
                    <v-icon v-if="entity.social_setup" color="#4CAF50" class="mr-2">mdi-check-circle</v-icon>
                    Setup your business
                  </span>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row :class="entity.social_setup ? 'pt-5' : ''">
                  <v-col> Set your social media accounts, and check your brand and company names. </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn to="/settings" rounded color="primary">Visit Settings</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="font-italic">Estimated time: 2 minutes</v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header :color="entity.locations_setup ? '#EBEBEB' : ''">
                <template #default="{ open }">
                  <span :class="`${open ? 'font-weight-bold' : ''}`">
                    <v-icon v-if="entity.locations_setup" color="#4CAF50" class="mr-2">mdi-check-circle</v-icon>
                    Add your Locations
                  </span>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row :class="entity.locations_setup ? 'pt-5' : ''">
                  <v-col> Let's add all locations that will be participating in your offers. </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn to="/locations" rounded color="primary">Add Locations</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="font-italic">Estimated time: 5 minutes</v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header :color="entity.offers_setup ? '#EBEBEB' : ''">
                <template #default="{ open }">
                  <span :class="`${open ? 'font-weight-bold' : ''}`">
                    <v-icon v-if="entity.offers_setup" color="#4CAF50" class="mr-2">mdi-check-circle</v-icon>
                    Create Offer
                  </span>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row :class="entity.offers_setup ? 'pt-5' : ''">
                  <v-col>Let's create your first offer.</v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn to="/offers" rounded color="primary">Create Offer</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Setup',

  metaInfo: {
    title: 'Setup',
  },

  data: () => ({
    currentSetupPanel: 0,
  }),

  computed: {
    ...mapGetters('business', ['entity', 'currentBusinessSetupProgress']),
  },

  created() {
    if (this.$router.currentRoute.path === '/' && this.currentBusinessSetupProgress.progress === 100) {
      this.$router.replace({ path: '/timeline' })
      return
    }

    this.$router.replace({ path: '/setup' })

    if (!this.entity.social_setup) {
      this.currentSetupPanel = 0
    } else if (!this.entity.locations_setup) {
      this.currentSetupPanel = 1
    } else if (!this.entity.offers_setup) {
      this.currentSetupPanel = 2
    } else {
      this.currentSetupPanel = 3
    }
  },
}
</script>

<style scoped>
#setup {
  width: 100%;
}
</style>
