<template>
  <v-app-bar color="transparent" flat app absolute height="90px">
    <v-btn icon @click="setDrawer(!drawer)">
      <v-icon v-if="drawer"> mdi-menu-open </v-icon>

      <v-icon v-else style="transform: scale(-1, 1)"> mdi-backburger </v-icon>
    </v-btn>

    <v-toolbar-title v-text="$route.name" />

    <v-spacer />

    <v-menu left bottom offset-y>
      <template #activator="{ on }">
        <v-btn text v-on="on">
          <v-icon class="mr-lg-4">mdi-chevron-down</v-icon>
          <span class="hidden-md-and-down text-none">{{ safeFullName }}</span>
        </v-btn>
      </template>

      <v-list>
        <v-list-item :to="'/account'">
          <v-icon class="mr-lg-4">mdi-account</v-icon>
          <v-list-item-title>Account Details</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-icon class="mr-lg-4">mdi-logout</v-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-divider v-if="displayBusinessSelector" vertical class="mr-2 ml-2" />

    <v-menu v-if="displayBusinessSelector" v-model="businessMenuOpen" :close-on-content-click="false" left bottom offset-y>
      <template #activator="{ on }">
        <v-btn text v-on="on">
          <v-icon class="mr-lg-4">mdi-chevron-down</v-icon>
          <span class="color lf-blue hidden-md-and-down">{{ entity.title }}</span>
        </v-btn>
      </template>

      <v-list id="businessSelectList">
        <v-list-item v-if="displayRecentBusinesses" class="mb-4">
          <span class="caption">Recent Businesses</span>
        </v-list-item>
        <v-list-item v-if="displayRecentBusinesses" class="mt-n3">
          <ul id="recentBusinessesList">
            <li v-for="business in recentBusinesses" :key="business.id" class="mb-1">
              <a href="#" class="accent--text mt-n10" @click.prevent="onBusinessSelected(business.id)">{{ business.title }}</a>
            </li>
          </ul>
        </v-list-item>
        <v-list-item v-if="displayRecentBusinesses" class="mt-1 mb-3">
          <v-divider />
        </v-list-item>
        <v-list-item>
          <v-autocomplete
            :items="entities"
            :value="entity"
            outlined
            label="Business Search"
            item-value="id"
            item-text="combo_title"
            @change="onBusinessSelected"
          />
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { UserService } from '../../services'

export default {
  name: 'AppBar',

  data() {
    return {
      businessMenuOpen: false,
    }
  },

  computed: {
    ...mapGetters('account', ['getFirstName', 'getLastName']),
    ...mapGetters('business', ['entities', 'entity', 'recentBusinesses']),
    ...mapGetters('ui', ['drawer']),
    displayBusinessSelector() {
      return this.entities.length > 1
    },
    displayRecentBusinesses() {
      return this.recentBusinesses.length > 0
    },
    safeFullName() {
      if (this.getFirstName || this.getLastName) {
        return `${this.getFirstName} ${this.getLastName}`.trim()
      }

      return UserService.getEmail()
    },
  },

  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('business', ['selected']),
    ...mapActions('ui', ['setDrawer']),

    onBusinessSelected(businessId) {
      this.businessMenuOpen = false
      this.selected(businessId)
    },
  },
}
</script>

<style lang="scss" scoped>
#businessSelectList > .v-list-item {
  min-height: 15px;
}

#recentBusinessesList {
  list-style: none;
  padding-left: 10px;
}

.v-divider--vertical {
  height: 65%;
  min-height: 50%;
  margin: auto;
}
</style>
