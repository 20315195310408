<template>
  <div>
    <OfferWebMarketsModal
      v-if="showWebMarketsModal"
      :url="offer.offer_url"
      @save="handleUpdateWebMarkets"
      @offer-web-markets-modal-close="showWebMarketsModal = false"
    />

    <v-expansion-panels>
      <OfferImagePanel :image-type="`offer`" :image-url="offer.offer_image_url" @save="handleUpdateOffer">
        <h2>Click to upload image</h2>
        <h5 class="font-weight-light">380x200 image dimensions</h5>
      </OfferImagePanel>

      <OfferRedeemInstructionsPanel
        v-if="offer.offer_order_flow_type === 'URL' || offer.offer_order_flow_type === 'CALL'"
        :offer-order-flow-type="offer.offer_order_flow_type"
        :offer-url="offer.offer_url"
        :offer-description-url="offer.offer_description_url"
        :offer-phone="offer.offer_phone"
        @save="handleUpdateOffer"
      />

      <OfferSocialsPanel
        :hashtags="offer.hashtags"
        :instagram-mentions="offer.instagram_mentions"
        :facebook-mentions="offer.facebook_mentions"
        @save="handleUpdateOffer"
      />

      <OfferUrlPanel v-if="!offerRequiresPreApproval" :urls="offer.urls" :offer-id="offer.id" @save="handleUpdateOffer" />

      <template v-if="!isClientLite">
        <OfferSpecialInstructionsPanel :offer-special-instructions="offer.offer_special_instructions" @save="handleUpdateOffer" />

        <OfferDatesPanel
          :is-admin="isAdmin"
          :start-date="offer.offer_start_date"
          :expiration-date="offer.expiration_date"
          :event-dates="offer.event_date_option"
          :offer-order-flow-type="offer.offer_order_flow_type"
          :needs-approval="offerRequiresPreApproval"
          @save="handleUpdateOffer"
        />

        <OfferLocationsPanel v-if="offer.offer_type === 'LOCAL'" :offer-id="offer.id" @handle-update-locations="handleUpdateLocations" />

        <OfferCouponPanel :coupon-code="offer.coupon_code" :coupon-image-url="offer.coupon_image_url" @save="handleUpdateOffer" />
      </template>

      <OfferAdminPanel
        v-if="isAdmin"
        :offer-type="offer.offer_type"
        :tasks="offer.tasks"
        :custom-offer-summary="offer.offer_summary"
        :display-priority="offer.display_priority"
        :number-tasks-required="offer.number_tasks_required"
        :offers-to-grant-per-month="offer.offers_to_grant_per_month"
        :min-days-between-offer="offer.min_days_between_offer"
        @save="handleUpdateOffer"
      />
    </v-expansion-panels>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import { OfferStatus } from '@/models/Offer'

import OfferAdminPanel from '@/components/Offers/OfferPanels/OfferAdminPanel'
import OfferCouponPanel from '@/components/Offers/OfferPanels/OfferCouponPanel'
import OfferLocationsPanel from '@/components/Offers/OfferPanels/OfferLocationsPanel'
import OfferDatesPanel from '@/components/Offers/OfferPanels/OfferDatesPanel'
import OfferSocialsPanel from '@/components/Offers/OfferPanels/OfferSocialsPanel'
import OfferUrlPanel from '@/components/Offers/OfferPanels/OfferUrlPanel'
import OfferImagePanel from '@/components/Offers/OfferPanels/OfferImagePanel'
import OfferRedeemInstructionsPanel from '@/components/Offers/OfferPanels/OfferRedeemInstructionsPanel'
import OfferSpecialInstructionsPanel from '@/components/Offers/OfferPanels/OfferSpecialInstructionsPanel'
import OfferWebMarketsModal from '@/components/Offers/OfferForm/OfferWebMarketsModal'
import OfferLocationsModal from '@/components/Offers/OfferLocationsModal'

export default Vue.extend({
  name: 'OfferFormBase',

  components: {
    OfferAdminPanel,
    OfferCouponPanel,
    OfferLocationsPanel,
    OfferDatesPanel,
    OfferSocialsPanel,
    OfferUrlPanel,
    OfferImagePanel,
    OfferRedeemInstructionsPanel,
    OfferSpecialInstructionsPanel,
    OfferWebMarketsModal,
  },

  props: {
    offer: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    showWebMarketsModal: false,
  }),

  computed: {
    ...mapGetters('business', ['entity']),
    ...mapGetters('account', ['isAdmin', 'isClientLite']),
    offerRequiresPreApproval() {
      return this.offer.offer_status === OfferStatus.NEEDS_APPROVAL
    },
  },

  methods: {
    ...mapActions('offer', ['updateOffer', 'updateLocationForOffer']),

    handleUpdateOffer(event) {
      this.updateOffer({ offerData: { ...this.offer, ...event } })
    },

    handleUpdateLocations: function (event) {
      this.updateLocationForOffer({ offerId: this.offer.id, locationTagIds: event.location_tags.map((e) => e.id) })
    },

    handleUpdateWebMarkets: function (event) {
      this.showWebMarketsModal = false
      this.handleUpdateOffer(event)
    },
  },
})
</script>
