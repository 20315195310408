<template>
  <v-container>
    <app-spinner v-if="loading" class="mt-6" @submit="doEmailLogin" />

    <div v-else-if="!showCheckEmail">
      <v-row class="text-center">
        <v-col>
          <h2 class="text-center font-weight-light text--secondary">Sign in to continue</h2>
        </v-col>
      </v-row>
      <v-form ref="loginForm" v-model="valid">
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                ref="emailField"
                v-model="email"
                :rules="emailRules"
                :validate-on-blur="true"
                outlined
                label="E-mail"
                type="email"
                required
                @keypress.enter.prevent="doEmailLogin"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-btn x-large color="primary" block @click="doEmailLogin">SIGN IN</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>

    <div v-else class="text-center">
      <h2 class="mt-3 font-weight-light text--secondary">Awesome!</h2>
      <p class="mt-6">Check your email for a link that will log you in!</p>
      <p>
        Didn't get an email?
        <a href @click.prevent="doEmailLogin">Click here to resend it!</a>
      </p>
    </div>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'

import { ApiService } from '@/services/index'
import validationRules from '@/utils/validation'

export default Vue.extend({
  name: 'Login',

  metaInfo: {
    title: 'Login',
  },

  data: () => ({
    valid: false,
    email: '',
    loading: false,
    showCheckEmail: false,
    emailRules: validationRules.emailRules,
  }),

  methods: {
    ...mapActions('auth', ['login']),
    doEmailLogin: function () {
      if (this.$refs.loginForm && !this.$refs.loginForm.validate()) {
        this.$refs.emailField.blur()
        return
      }

      this.loading = true

      ApiService.post(`auth/client`, {
        email: this.email,
        redirect: this.$router.currentRoute.query.redirect,
      })
        .then((response) => {
          if (response.data.error) {
            throw response.data.error
          }

          this.loading = false
          this.showCheckEmail = true
        })
        .catch((e) => {
          this.loading = false
          this.$toast.error(e)
        })
    },
  },
})
</script>
