<template>
  <v-row align="center">
    <v-col cols="12" align="center">
      <div style="cursor: pointer" @click="downloadQrCode()">
        <qrcode-vue ref="qr" :value="url" :size="size" render-as="canvas" level="M" />
      </div>
      <p class="caption">Click to download</p>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue'
import QrcodeVue from 'qrcode.vue'

export default Vue.extend({
  name: 'ShortUrlQrCode',

  components: { QrcodeVue },

  props: {
    url: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 200,
    },
  },

  methods: {
    downloadQrCode() {
      const data = this.$refs.qr.$el.children[0].toDataURL()
      let downloadLink = document.createElement('a')
      downloadLink.href = data
      downloadLink.download = 'qrCode.jpeg'
      downloadLink.click()
      return
    },
  },
})
</script>
