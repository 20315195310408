import router from '@/router'

import { Business, BusinessSocialSettings, CurrentBusiness } from '../../models'

import { ApiService, TokenService } from '../../services'

type BusinessState = {
  entities?: Business[]
  entity?: CurrentBusiness
  recentBusinesses: Business[]
}

const state: BusinessState = {
  entities: [],
  entity: null,
  recentBusinesses: [],
}

const mutations = {
  entities(state: BusinessState, payload: Business[]) {
    state.entities = payload
  },
  entity(state: BusinessState, payload: CurrentBusiness) {
    state.entity = payload
  },
  recentBusinesses(state: BusinessState, payload: Business[]) {
    state.recentBusinesses = payload
  },
}

const actions = {
  async getBusinesses({ commit }) {
    await ApiService.get('businesses/all')
      .then((response) => {
        commit('entities', response.data.businesses)
        commit('recentBusinesses', response.data.recentBusinesses)
      })
      .catch((error) => {
        console.error('entities error: ', error)
      })
  },

  async getCurrentBusiness({ commit }) {
    await ApiService.get('businesses/current')
      .then((response) => {
        commit('entity', response.data)
      })
      .catch((error) => {
        console.error('entity error: ', error)
      })
  },

  updateBusiness({ commit }, { title, brand, industryCategoryId }: { title: string; brand: string; industryCategoryId: string }) {
    const business = { ...state.entity } as CurrentBusiness
    business.title = title
    business.brand = brand
    business.industry_category_id = industryCategoryId

    commit('entity', business)
  },
  updateBusinessSocial({ commit }, { default_instagram_mentions, default_facebook_mentions, default_hashtags }: BusinessSocialSettings) {
    const business = { ...state.entity } as CurrentBusiness
    business.default_facebook_mentions = default_facebook_mentions
    business.default_instagram_mentions = default_instagram_mentions
    business.default_hashtags = default_hashtags
    if (default_hashtags && default_hashtags.length > 0) {
      business.social_setup = true
    }
    commit('entity', business)
  },
  selected({ commit, dispatch }, businessId: string) {
    ApiService.get(`businesses/swap/${businessId}`).then((response) => {
      commit('entity', response.data.currentBusiness)
      commit('recentBusinesses', response.data.recentBusinesses)

      TokenService.saveToken(response.data.token)
      ApiService.setAuthHeader()

      dispatch('timeline/refresh', { selectedDate: new Date() }, { root: true })

      router.push('/').catch((error) => {
        console.warn(`Duplicate Navigation Path`)
      })
    })
  },
}

const getters = {
  entities: () => state.entities,
  entity: () => state.entity,
  recentBusinesses: () => state.recentBusinesses,
  currentBusinessSetupProgress: () => {
    if (!state.entity)
      return {
        progress: 0,
        complete: false,
        nextStep: '',
      }

    let progress = 0
    let nextStep = null

    if (state.entity.social_setup) {
      progress += 33
      nextStep = 'Setup Locations'
    } else {
      nextStep = 'Setup Social'
    }

    if (state.entity.locations_setup) {
      progress += 33
    } else {
      nextStep = nextStep || 'Setup Locations'
    }

    if (state.entity.offers_setup) {
      progress += 33
    } else {
      nextStep = nextStep || 'Setup Offers'
    }

    if (progress >= 98) progress = 100

    return {
      progress,
      complete: progress === 100,
      nextStep,
    }
  },
}

export const business = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
