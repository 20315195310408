import Vue from 'vue'

Vue.filter('manyNumberPretty', (numberValue: number) => {
  if (numberValue <= 0) {
    return '0'
  }
  if (numberValue >= 25 && numberValue <= 100) {
    return 'A Few'
  } else if (numberValue >= 100 && numberValue <= 1000) {
    return 'Some'
  } else if (numberValue > 1000) {
    return 'Many'
  } else return '' + numberValue
})

Vue.filter('comma', (dollarValue: number) => {
  return dollarValue >= 100000
    ? new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 4,
      }).format(dollarValue / 1000) + 'k'
    : new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 4,
      }).format(dollarValue)
})

Vue.filter('dollars', (dollarValue: number) => {
  return dollarValue >= 100000
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumSignificantDigits: 8,
      }).format(dollarValue / 1000) + 'k'
    : new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumSignificantDigits: 8,
      }).format(dollarValue)
})
