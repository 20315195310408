<template>
  <div>
    <h3>Value Settings</h3>
    <div class="ml-2">
      <v-form ref="businessValueSettinsForm">
        <v-row class="mt-0">
          <v-col cols="12">
            <v-text-field
              ref="postValue"
              v-model="settings.post_marketing_value"
              label="Value For a Post"
              hint="We set this to a default from our industry research, you
              can override here"
              flat
              outlined
              number
              prefix="$"
              @keydown.space="$refs.hashtags.blur()"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n2">
          <v-col cols="12">
            <v-text-field
              ref="reviewValue"
              v-model="settings.review_marketing_value"
              label="Value For a Post"
              hint="We set this to a default from our industry research, you
              can override here"
              flat
              outlined
              number
              prefix="$"
              @keydown.space="$refs.hashtags.blur()"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n2">
          <v-col cols="12">
            <v-text-field
              ref="reviewValue"
              v-model="settings.average_ticket_value"
              label="Average Ticket Value"
              hint="We set this to a default from using your industry, you can refine here"
              flat
              outlined
              number
              prefix="$"
              @keydown.space="$refs.hashtags.blur()"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn :loading="loading" color="primary" @click="handleSaveButtonClicked">Save</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'BusinessValueSettings',

  props: {
    settings: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    loading: false,
  }),

  methods: {
    ...mapActions('settings', ['updateBusinessValue']),
    async handleSaveButtonClicked() {
      this.loading = true

      const businessValueSettings = {
        post_marketing_value: this.settings.post_marketing_value,
        review_marketing_value: this.settings.review_marketing_value,
        average_ticket_value: this.settings.average_ticket_value,
      }

      await this.updateBusinessValue({ businessValueSettings: { ...businessValueSettings } })

      this.loading = false
      this.$toast.success('Business Value Settings Updated')
    },
  },
})
</script>
