<template>
  <v-app>
    <v-main>
      <LfAppEnvironmentBanner v-if="appEnvironment.bannerVisible" :environment="appEnvironment.environment" :version="appVersion" />

      <v-container fluid class="pr-lg-8 pl-lg-8">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import '@/assets/scss/theme.scss'
import '@/assets/scss/animations.scss'
import '@/assets/scss/generic.scss'
import '@/assets/scss/overrides.scss'

import { mapGetters } from 'vuex'

import { LfAppEnvironmentBanner } from 'lf-common'

export default {
  name: 'Auth',

  components: {
    LfAppEnvironmentBanner,
  },

  computed: {
    ...mapGetters('app', ['appEnvironment', 'appVersion']),
  },
}
</script>
