<template>
  <div id="users">
    <app-spinner v-if="loading" />
    <v-container v-else>
      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn color="primary" @click="handleCreateNewUserClicked"> <v-icon class="pr-1">mdi-account-multiple-plus</v-icon> Add User </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="showUserForm ? 6 : 12">
          <h2 v-if="entities.length === 0" class="text-center font-weight-light">No Users</h2>
          <v-list-item-group v-model="currentUserIndex" color="primary" @change="handleUserRowClicked">
            <v-list-item v-for="user in entities" :key="user.id" two-line>
              <v-list-item-content>
                <v-list-item-title>{{ user.first_name }} {{ user.last_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-col>
        <v-col v-if="showUserForm" cols="6">
          <UserForm :current-user="currentUser" @user-form-save="handleUserFormSave" @user-form-cancel="handleUserFormCancel" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from 'vue'

import { mapGetters } from 'vuex'
import UserForm from '@/components/Users/UserForm'

export default Vue.extend({
  name: 'Users',

  metaInfo: {
    title: 'Users',
  },

  components: {
    UserForm,
  },

  data: () => ({
    showUserForm: false,
    currentUser: undefined,
    currentUserIndex: undefined,
  }),

  computed: {
    ...mapGetters('user', ['loading', 'entities']),
  },

  mounted() {
    this.$store.dispatch('user/refresh')
  },

  methods: {
    handleUserFormSave() {
      this.showUserForm = false
      this.currentUser = undefined
      this.currentUserIndex = undefined
      this.$toast.success('User saved!')
    },
    handleUserFormCancel() {
      this.showUserForm = false
      this.currentUser = undefined
      this.currentUserIndex = undefined
    },
    handleCreateNewUserClicked() {
      this.showUserForm = true
      this.currentUser = undefined
      this.currentUserIndex = undefined
    },
    handleUserRowClicked(itemIndex) {
      this.showUserForm = itemIndex !== undefined
      this.currentUser = itemIndex !== undefined ? JSON.parse(JSON.stringify(this.entities[itemIndex])) : undefined
    },
  },
})
</script>

<style scoped>
#users {
  width: 100%;
}
</style>
