<template>
  <v-row dense>
    <v-col v-if="imgUrl" cols="12">
      <v-img :src="imgUrl" cover :eager="true" :class="classes" @click="proofImageClicked" />
      <ImageUploadForm
        ref="imageUploadForm"
        :image-type="imageType"
        :edit-mode="true"
        :image-url="imgUrl"
        style="display: none"
        @image-uploaded="handleUpdateOfferImage"
      />
    </v-col>

    <v-col v-else-if="messageContent" cols="12">
      <v-list-item-content v-text="messageContent" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import ImageUploadForm from '@/components/ImageUploadForm'

export default {
  name: 'ScreenShotPost',

  components: {
    ImageUploadForm,
  },

  props: {
    proofType: {
      type: String || null,
      default: null,
    },
    imgUrl: {
      type: String,
      required: true,
    },
    messageContent: {
      type: String,
      default: () => '',
    },
    classes: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters('account', ['isAdmin']),
    imageType() {
      return this.proofType ? 'proof' : 'reverseFlowProof'
    },
  },

  methods: {
    proofImageClicked() {
      if (!this.isAdmin) return

      this.$refs.imageUploadForm.chooseImage()
    },
    handleUpdateOfferImage(event) {
      this.$emit('proof-image-updated', { img_url: event.image_url })
    },
  },
}
</script>
