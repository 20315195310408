<template>
  <div id="reports">
    <app-spinner v-if="loading" :spinner-size="80" class="spinner-background" />
    <v-row class="text-center mb-5">
      <v-col cols="12">
        <v-tabs :centered="true" :grow="true" icons-and-text>
          <v-tab v-for="(graphType, index) in graphTypes" :key="index" @click="changeGraph(graphType.type)">
            {{ graphType.label }}
            <v-icon>{{ graphType.icon }}</v-icon>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="isMobile ? 2 : 12">
        <v-slider
          v-model="timeInterval"
          :vertical="isMobile"
          :track-color="'primary'"
          :track-fill-color="'grey-lighten-2'"
          :tick-labels="timeSeriesLabels"
          :max="timeSeriesLabels.length - 1"
          ticks="always"
          @change="onTimeSeriesGroupChange"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="actionData" cols="12" class="text-center graph-container">
        <VueApexCharts width="100%" :options="actionData.options.line" :series="actionData.series" :class="{ hidden: !isLine }" />
        <VueApexCharts width="100%" :options="actionData.options.bar" :series="actionData.series" :class="{ hidden: isLine }" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import VueApexCharts from 'vue-apexcharts'
import dayjs from 'dayjs'

export default {
  name: 'Reports',

  components: {
    VueApexCharts,
  },

  metaInfo: {
    title: 'Reports',
  },

  data() {
    return {
      timeInterval: 3,
      currentGraphType: 'DATE',
      graphTypes: [
        {
          label: 'Activity Summary',
          type: 'DATE',
          icon: 'mdi-finance',
        },
        {
          label: 'Activity By Offer',
          type: 'OFFER',
          icon: 'mdi-tag',
        },
        {
          label: 'Activity By Market',
          type: 'MARKET',
          icon: 'mdi-map-marker',
        },
        {
          label: 'Activity By Sway Offer',
          type: 'SWAY',
          icon: 'mdi-tag-text',
        },
      ],
      graphSettings: [
        {
          label: 'Year',
          startDate: dayjs().subtract(52, 'week'),
          timeSeriesGroup: 'month',
        },
        {
          label: '6 months',
          startDate: dayjs().subtract(6, 'months').startOf('month'),
          timeSeriesGroup: 'month',
        },
        {
          label: '90 Days',
          startDate: dayjs().subtract(90, 'day'),
          timeSeriesGroup: 'week',
        },
        {
          label: '4 Weeks',
          startDate: dayjs().subtract(4, 'week'),
          timeSeriesGroup: 'week',
        },
        {
          label: 'Month to Date',
          startDate: dayjs().startOf('month'),
          timeSeriesGroup: 'week',
        },
      ],
    }
  },

  computed: {
    ...mapGetters('analytics', ['actionData', 'loading']),
    timeSeriesLabels() {
      return this.graphSettings.map((setting) => setting.label)
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    isLine() {
      return this.currentGraphType === 'DATE' || this.currentGraphType === 'SWAY'
    },
  },

  beforeDestroy: function () {
    this.clearActionData()
  },

  beforeMount: function () {
    this.clearActionData()
  },

  mounted() {
    this.onTimeSeriesGroupChange(3)
  },

  methods: {
    ...mapActions('analytics', ['clearActionData', 'getActionData']),
    changeGraph(graphType) {
      this.currentGraphType = graphType
      this.getActionData({ ...this.graphSettings[this.timeInterval], graphType: this.currentGraphType })
    },
    onTimeSeriesGroupChange(event) {
      this.getActionData({ ...this.graphSettings[event], graphType: this.currentGraphType })
    },
  },
}
</script>

<style scoped>
.hidden {
  display: none;
}

#reports {
  width: 100%;
}

.graph-container {
  height: calc(60vh);
}

.spinner-background {
  top: 50%;
  left: 0%;
  background: none;
  position: absolute;
}
</style>
