import Vue from 'vue'
import { ApiService } from '@/services'
import { User } from '@/models/User'

type UserState = {
  loading: boolean
  entities: User[]
}

const state: UserState = {
  loading: false,
  entities: [],
}

const getters = {
  loading: () => state.loading,
  entities: () => state.entities,
}

const actions = {
  refresh({ commit }) {
    commit('loading', true)
    ApiService.get('businessUser/all').then((response) => {
      commit('refresh', response.data)
      commit('loading', false)
    })
  },
  saveUser({ commit }, { userData }: { userData: User }) {
    commit('loading', true)
    ApiService.post('businessUser', { user: userData }).then((response) => {
      userData.id = response.data.id

      commit('saveUser', userData)
      commit('loading', false)
    })
  },
  updateUser({ commit }, { userData }: { userData: User }) {
    commit('loading', true)
    ApiService.put(`businessUser/${userData.id}`, { user: userData }).then(() => {
      commit('updateUser', userData)
      commit('loading', false)
    })
  },
}

const mutations = {
  loading(state: UserState, payload: boolean) {
    state.loading = payload
  },
  saveUser(state: UserState, payload: User) {
    state.entities = state.entities.concat([payload])
  },
  updateUser(state: UserState, payload: User) {
    Vue.set(
      state.entities,
      state.entities.findIndex((user) => user.id === payload.id),
      payload
    )
  },
  refresh(state: UserState, payload: User[]) {
    state.entities = payload
  },
}

export const user = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
