<template>
  <OffersList
    :loading="offersLoading"
    :is-client-lite="true"
    :network-offers="networkOffers"
    :sway-offers="swayOffers"
    @offer-list-new-offer-modal-open="showNewOfferModal = true"
  >
    <NewClientLiteOfferModal v-if="showNewOfferModal" @new-offer-modal="showNewOfferModal = false" @new-offer-modal-close="showNewOfferModal = false" />
  </OffersList>
</template>

<script>
import Vue from 'vue'

import { mapGetters } from 'vuex'

import OffersList from '@/views/components/OffersList'
import NewClientLiteOfferModal from '@/components/Offers/NewOfferModals/NewClientLiteOfferModal'

export default Vue.extend({
  name: 'ClientLiteOffers',

  metaInfo: {
    title: 'Offers',
  },

  components: {
    OffersList,
    NewClientLiteOfferModal,
  },

  data: () => ({
    showNewOfferModal: false,
  }),

  computed: {
    ...mapGetters('offer', ['networkOffers']),
    ...mapGetters('swayoffer', ['swayOffers']),
    offersLoading() {
      return this.$store.getters['offer/loading'] || this.$store.getters['swayoffer/loading']
    },
  },

  mounted() {
    this.$store.dispatch('offer/refresh')
    this.$store.dispatch('swayoffer/refresh')
  },
})
</script>
