<template>
  <div class="offer-controls">
    <OfferExpirationDateModal v-if="showExpirationModal" @offer-expiration-date-save="stopOffer" @offer-expiration-date-cancel="showExpirationModal = false" />

    <v-btn v-if="canApproveOffer" :disabled="actionLoading" :loading="approveActionLoading" small @click.stop="approveOffer()"> Approve </v-btn>
    <v-btn v-if="canPauseOffer" :disabled="actionLoading" :loading="pauseActionLoading" small @click.stop="pauseOffer()">Pause</v-btn>
    <v-btn v-if="canStopOffer" :disabled="actionLoading" :loading="stopActionLoading" small @click.stop="showExpirationModal = true">Stop</v-btn>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import { ApiService } from '@/services/index'

import { OfferStatus } from '@/models/Offer'
import OfferExpirationDateModal from '@/components/Offers/OfferForm/OfferExpirationDateModal'

export default Vue.extend({
  name: 'OfferControls',

  components: {
    OfferExpirationDateModal,
  },

  props: {
    offerId: {
      type: String,
      required: true,
    },
    offerStatus: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    actionLoading: false,
    approveActionLoading: false,
    pauseActionLoading: false,
    stopActionLoading: false,
    showExpirationModal: false,
  }),

  computed: {
    ...mapGetters('account', ['isAdmin']),
    canApproveOffer() {
      return this.isAdmin && this.offerId && (this.offerStatus === OfferStatus.NEEDS_APPROVAL || this.offerStatus === OfferStatus.PAUSED)
    },
    canPauseOffer() {
      return this.isAdmin && this.offerId && this.offerStatus === OfferStatus.ACTIVE
    },
    canStopOffer() {
      return this.isAdmin && this.offerId && this.offerStatus !== OfferStatus.COMPLETE
    },
  },

  methods: {
    ...mapActions('offer', ['refreshOffer']),
    approveOffer() {
      this.approveActionLoading = true

      ApiService.put(`offer/approve/${this.offerId}`).then((response) => {
        this.approveActionLoading = false

        if (response.data.error) {
          this.$toast.error(response.data.error)
        } else {
          this.$toast.success('Offer Approved')
          this.refreshOffer({
            refreshOfferData: { id: this.offerId, offer_status: response.data.offer_status, short_url_id: response.data.short_url_id },
          })
        }
      })
    },

    pauseOffer() {
      this.pauseActionLoading = true

      ApiService.put(`offer/pause/${this.offerId}`).then((response) => {
        this.pauseActionLoading = false

        if (response.data.error) {
          this.$toast.error(response.data.error)
        } else {
          this.$toast.success('Offer Paused')
          this.refreshOffer({
            refreshOfferData: { id: this.offerId, offer_status: response.data.offer_status, expiration_date: response.data.expiration_date },
          })
        }
      })
    },

    stopOffer(stopDate) {
      this.stopActionLoading = true
      this.showExpirationModal = false

      ApiService.put(`offer/stop/${this.offerId}/${stopDate}`).then((response) => {
        this.stopActionLoading = false

        if (response.data.error) {
          this.$toast.error(response.data.error)
        } else {
          this.$toast.success('Offer Expiration Updated')
          this.refreshOffer({
            refreshOfferData: { id: this.offerId, offer_status: response.data.offer_status, expiration_date: response.data.expiration_date },
          })
        }
      })
    },
  },
})
</script>

<style scoped>
.offer-controls {
  display: flex;
  margin-bottom: 14px;
  flex-direction: row;
  justify-content: center;
}

.offer-controls > button {
  margin-left: 5px;
  margin-right: 5px;
}
</style>
