<template>
  <v-dialog :value="true" persistent max-width="400">
    <v-card>
      <v-card-title class="headline">When should this offer stop?</v-card-title>
      <v-card-text>
        <v-date-picker v-model="expirationDate"></v-date-picker>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" @click="$emit('offer-expiration-date-cancel')">Cancel</v-btn>
        <v-btn color="primary" @click="$emit('offer-expiration-date-save', expirationDate)">Stop Offer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'OfferExpirationDateModal',

  data: () => ({
    expirationDate: new Date().toISOString().substr(0, 10),
  }),
})
</script>
