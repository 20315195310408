<template>
  <v-app>
    <app-spinner v-if="!initialized" class="mt-6" />

    <div v-else>
      <v-progress-linear v-if="appProgressBarVisible" fixed indeterminate height="6" class="app-progress-bar" />

      <AppBar />

      <NavigationDrawer />

      <v-main>
        <v-container id="main-container" class="pr-lg-8 pl-lg-8 fill-height" fluid>
          <LfAppEnvironmentBanner v-if="appEnvironment.bannerVisible" :environment="appEnvironment.environment" :version="appVersion" />

          <v-alert :value="appOutOfDate" dense class="app-update-banner ml-2 mr-2 mt-2 mb-n1" icon="mdi-update" type="info">
            <v-row dense>
              <v-col class="grow">
                <span class="font-weight-bold">Update available!</span>
              </v-col>
              <v-col class="shrink">
                <v-btn small outlined class="float-right" @click="reloadApp()">Update</v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <router-view />
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AppBar from './components/AppBar'
import NavigationDrawer from './components/NavigationDrawer'
import { LfAppEnvironmentBanner } from 'lf-common'

import '@/assets/scss/theme.scss'
import '@/assets/scss/animations.scss'
import '@/assets/scss/generic.scss'
import '@/assets/scss/overrides.scss'

export default {
  name: 'Index',

  components: {
    AppBar,
    NavigationDrawer,
    LfAppEnvironmentBanner,
  },

  data: () => ({
    initialized: false,
  }),

  computed: {
    ...mapGetters('auth', ['loggedIn']),
    ...mapGetters('app', ['appOutOfDate', 'appProgressBarVisible', 'appEnvironment', 'appVersion']),
  },

  async mounted() {
    this.initialized = !this.loggedIn

    if (!this.initialized) {
      await this.getAccountDetails()
        .then(async (response) => {
          await this.getBusinesses()

          await this.getCurrentBusiness()
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$toast.error('Invalid credentials please try again')
            this.logout()
          } else {
            this.$toast.error('Internal error please try again in a few minutes')
          }
        })

      this.initialized = true
    }
  },

  methods: {
    ...mapActions('account', ['getAccountDetails']),
    ...mapActions('business', ['getBusinesses', 'getCurrentBusiness']),
    ...mapActions('auth', ['logout']),

    reloadApp() {
      location.reload()
    },
  },
}
</script>

<style lang="scss">
.app-progress-bar {
  top: 0;
  z-index: 100;
}

.app-progress-bar .v-progress-linear__background {
  left: 0 !important;
  width: 100% !important;
}

#main-container {
  &.fill-height {
    align-items: normal;
  }
}

.v-dialog {
  max-height: 100% !important;
}

.app-update-banner {
  width: 100%;
}

@media (min-width: 1921px) {
  #main-container {
    &.container--fluid {
      max-width: 1780px;
    }

    &.fill-height {
      align-items: normal;
    }
  }
}
</style>
