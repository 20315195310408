<template>
  <v-list flat class="mt-n1">
    <legend :class="['v-label theme--light', !enabled ? 'grey--text' : '']" style="font-size: 14px">{{ title }}</legend>
    <v-icon v-if="defaultSteps.length > 0" class="float-right mt-n5" style="cursor: pointer" @click="resetSteps()">mdi-restore</v-icon>
    <v-list-item v-if="steps.length === 0" class="font-weight-thin justify-center">No {{ stepVerb | pluralize(2) }}</v-list-item>

    <draggable :value="steps" group="steps" ghost-class="step-ghost" chosen-class="step-chosen" @update="reorderSteps">
      <v-list-item v-for="(step, index) in steps" :key="`step-${index}`" class="step" style="width: 100%">
        <template #default>
          <v-list-item-action class="mr-2">
            <v-icon v-if="enabled" color="primary" title="Delete" @click="removeStep(index)">mdi-playlist-remove</v-icon>
            <v-icon v-else color="grey">mdi-playlist-remove</v-icon>
          </v-list-item-action>

          <v-list-item-icon v-if="step.icon" class="pr-2">
            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path :d="stepIconPath(step.icon)" />
            </svg>
          </v-list-item-icon>
          <v-list-item-content two-line>
            <v-list-item-title>{{ step.title }}</v-list-item-title>
            <v-list-item-subtitle v-if="includeSubTitle" class="pt-1">{{ step.subtitle }}</v-list-item-subtitle>
            <v-list-item-subtitle v-if="includeReferralCode" class="pt-1">{{ step.referral_code }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-list-item>
    </draggable>

    <v-list-item v-if="!newStepVisible" class="justify-end">
      <v-btn :disabled="!enabled" :tile="true" dense small @click="newStepVisible = true">New {{ stepVerb }}</v-btn>
    </v-list-item>

    <v-list-item v-else class="pa-0">
      <v-list-item-content>
        <v-form ref="newStepForm">
          <legend :class="['v-label theme--light mb-1', !enabled ? 'grey--text' : '']" style="font-size: 14px">New {{ stepVerb }}</legend>
          <v-sheet :outlined="true" elevation="1" class="pa-4">
            <v-select v-if="includeIcon" v-model="newStep.icon" :disabled="!enabled" :items="newStepIcons" outlined label="Icon" />
            <v-textarea v-model="newStep.title" :disabled="!enabled" :rules="requiredRule" outlined auto-grow rows="1" label="Title" />
            <v-textarea v-if="includeSubTitle" v-model="newStep.subtitle" :disabled="!enabled" outlined auto-grow rows="1" label="Subtitle" />
            <v-textarea v-if="newStep.icon === 'code'" v-model="newStep.nocode" :disabled="!enabled" outlined auto-grow rows="1" label="No Code Warning" />
            <v-text-field v-if="includeReferralCode" v-model="newStep.referral_code" :disabled="!enabled" outlined label="Referral Code" />
            <div class="text-right">
              <v-btn :disabled="!enabled" :tile="true" color="primary" @click="addStep">
                <v-icon left>mdi-playlist-plus</v-icon>
                Save
              </v-btn>
            </div>
          </v-sheet>
        </v-form>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import Vue from 'vue'

import draggable from 'vuedraggable'

import validationRules from '@/utils/validation'

export default Vue.extend({
  name: 'SwayOfferSteps',

  components: { draggable },

  props: {
    title: {
      type: String,
      default: 'Steps',
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    includeIcon: {
      type: Boolean,
      default: false,
    },
    includeSubTitle: {
      type: Boolean,
      default: false,
    },
    includeNoCode: {
      type: Boolean,
      default: false,
    },
    includeReferralCode: {
      type: Boolean,
      default: false,
    },
    stepVerb: {
      type: String,
      default: 'Step',
    },
    steps: {
      type: Array,
      default: () => [],
    },
    defaultSteps: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    newStep: {
      icon: null,
      title: null,
      subtitle: null,
      referral_code: null,
      nocode: null,
    },
    newStepVisible: false,
    requiredRule: validationRules.requiredRule,
  }),

  computed: {
    newStepIcons() {
      return ['giftcard', 'calendar', 'megaphone', 'barcode', 'circleoutline', 'circlecheckoutline', 'code']
    },
  },

  methods: {
    stepIconPath(iconName) {
      switch (iconName) {
        case 'screenshot':
          return 'M7,1A2,2 0 0,0 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3A2,2 0 0,0 17,1H7M7,4H17V20H7V4M9,6V10H10.5V7.5H13V6H9M13.5,14V16.5H11V18H15V14H13.5Z'
        case 'giftcard':
          return 'M20,14H4V8H9.08L7,10.83L8.62,12L11,8.76L12,7.4L13,8.76L15.38,12L17,10.83L14.92,8H20M20,19H4V17H20M9,4A1,1 0 0,1 10,5A1,1 0 0,1 9,6A1,1 0 0,1 8,5A1,1 0 0,1 9,4M15,4A1,1 0 0,1 16,5A1,1 0 0,1 15,6A1,1 0 0,1 14,5A1,1 0 0,1 15,4M20,6H17.82C17.93,5.69 18,5.35 18,5A3,3 0 0,0 15,2C13.95,2 13.04,2.54 12.5,3.35L12,4L11.5,3.34C10.96,2.54 10.05,2 9,2A3,3 0 0,0 6,5C6,5.35 6.07,5.69 6.18,6H4C2.89,6 2,6.89 2,8V19C2,20.11 2.89,21 4,21H20C21.11,21 22,20.11 22,19V8C22,6.89 21.11,6 20,6Z'
        case 'calendar':
          return 'M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z'
        case 'megaphone':
          return 'M12,8H4A2,2 0 0,0 2,10V14A2,2 0 0,0 4,16H5V20A1,1 0 0,0 6,21H8A1,1 0 0,0 9,20V16H12L17,20V4L12,8M15,15.6L13,14H4V10H13L15,8.4V15.6M21.5,12C21.5,13.71 20.54,15.26 19,16V8C20.53,8.75 21.5,10.3 21.5,12Z'
        case 'barcode':
          return 'M4,6H6V18H4V6M7,6H8V18H7V6M9,6H12V18H9V6M13,6H14V18H13V6M16,6H18V18H16V6M19,6H20V18H19V6M2,4V8H0V4A2,2 0 0,1 2,2H6V4H2M22,2A2,2 0 0,1 24,4V8H22V4H18V2H22M2,16V20H6V22H2A2,2 0 0,1 0,20V16H2M22,20V16H24V20A2,2 0 0,1 22,22H18V20H22Z'
        case 'circleoutline':
          return 'M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'
        case 'circlecheckoutline':
          return 'M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z'
        case 'code':
          return 'M4,6H6V18H4V6M7,6H8V18H7V6M9,6H12V18H9V6M13,6H14V18H13V6M16,6H18V18H16V6M19,6H20V18H19V6M2,4V8H0V4A2,2 0 0,1 2,2H6V4H2M22,2A2,2 0 0,1 24,4V8H22V4H18V2H22M2,16V20H6V22H2A2,2 0 0,1 0,20V16H2M22,20V16H24V20A2,2 0 0,1 22,22H18V20H22Z'
        default:
          return 'M13 7H11V9H13V7M13 11H11V17H13V11M17 1H7C5.9 1 5 1.9 5 3V21C5 22.1 5.9 23 7 23H17C18.1 23 19 22.1 19 21V3C19 1.9 18.1 1 17 1M17 19H7V5H17V19Z'
      }
    },

    resetNewStep() {
      this.newStep = {
        icon: null,
        title: null,
        subtitle: null,
        referral_code: null,
        nocode: null,
      }

      this.newStepVisible = false
    },

    addStep() {
      if (!this.$refs.newStepForm.validate()) return

      this.$emit('save', [
        ...this.steps,
        {
          title: this.newStep.title,
          ...(this.includeIcon ? { icon: this.newStep.icon } : {}),
          ...(this.includeSubTitle ? { subtitle: this.newStep.subtitle } : {}),
          ...(this.includeReferralCode ? { referral_code: this.newStep.referral_code } : {}),
          ...(this.newStep.nocode ? { nocode: this.newStep.nocode } : {}),
        },
      ])

      this.resetNewStep()
    },

    removeStep(stepIndex) {
      const steps = [...this.steps]
      steps.splice(stepIndex, 1)

      this.$emit('save', steps)
    },

    resetSteps() {
      this.$emit('save', [...this.defaultSteps])

      this.resetNewStep()
    },

    reorderSteps(event) {
      const steps = [...this.steps]
      const step = steps[event.oldIndex]
      steps.splice(event.oldIndex, 1)
      steps.splice(event.newIndex, 0, step)

      this.$emit('save', steps)
    },
  },
})
</script>

<style scoped>
.step {
  padding: 0;
  cursor: pointer;
}

.step-ghost {
  opacity: 0.4;
}

.step-chosen {
  background-color: #eee;
}
</style>
