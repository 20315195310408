export enum SwayOfferFlowEndType {
  VOUCHER = 'VOUCHER',
  WHATS_NEXT = 'WHATS_NEXT',
}

export enum SwayOfferFlowContactInfoType {
  FIRST_NAME = 'FIRST_NAME',
  PHONE_NUMBER = 'PHONE_NUMBER',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  ADDRESS = 'ADDRESS',
  ZIP_CODE = 'ZIP_CODE',
  INSTAGRAM_HANDLE = 'INSTAGRAM_HANDLE',
}

export interface SwayOfferFlowContactInfoCustomType {
  title: string
  choices: Array<string>
  required: boolean
}

export interface SwayOfferFlowContactInfoCustomTextType {
  title: string
  required: boolean
}

export interface SwayOffer {
  id: string
  business_id: string
  creator_app_user_id: string
  expired: Boolean
  offer_summary: string
  offer_image_url: string | null
  tasks: Array<string>
  hashtags: Array<string>
  facebook_mentions: Array<string>
  instagram_mentions: Array<string>
  tiktok_mentions: Array<string>
}

export interface SwayOfferBase {
  offer_summary: string
  offer_image_url: string | null
  hashtags: Array<string> | null
  instagram_mentions: Array<string> | null
  facebook_mentions: Array<string> | null
}

export interface SwayOfferFlowBase {
  claim_enabled: boolean
  contact_info_enabled: boolean
  end_type: SwayOfferFlowEndType
}

export interface SwayOfferFlow extends SwayOfferFlowBase {
  id: string
  claim_data: object
  proof_requirements_data: object
  contact_info_data: object
  voucher_data: object
  whats_next_data: object
}

export interface SwayOfferStep {
  title: string
  icon?: string | null
  subtitle?: string | null
  referral_code?: string | null
}

export interface SwayOfferFlowClaimData {
  instructions_title: string
  instructions: Array<SwayOfferStep>
  require_social_proof: boolean
  review_proof: boolean
  step_one_title: string
  step_one_text: string
}

export interface SwayOfferFlowProofRequirementsData {
  title: string
  subtitle: string
  steps: Array<SwayOfferStep>
  example_image_urls: Array<string>
}

export interface SwayOfferFlowContactInfoData {
  title: string
  subtitle: string
  step: Array<SwayOfferStep>
  proof_created_email_template_id: string
  collect_custom_info: SwayOfferFlowContactInfoCustomType | null
  collect_custom_text: SwayOfferFlowContactInfoCustomTextType | null
  collect_contact_info: Array<SwayOfferFlowContactInfoType>
  required_contact_info: Array<SwayOfferFlowContactInfoType>
  prevent_duplicate_contact_info: Array<SwayOfferFlowContactInfoType>
}

export interface SwayOfferFlowVoucherData {
  title: string
  subtitle: string
  steps: Array<string>
}

export interface SwayOfferFlowWhatsNextData {
  title: string
  subtitle: string
  steps_title: string
  steps: Array<SwayOfferStep>
  steps_include_social: boolean
}

export interface SwayOfferFlowDataDefaults {
  claimData: SwayOfferFlowClaimData
  proofRequirementsData: SwayOfferFlowProofRequirementsData
  contactInfoData: SwayOfferFlowContactInfoData
  voucherData: SwayOfferFlowVoucherData
  whatsNextData: SwayOfferFlowWhatsNextData
}

export const SWAY_OFFER_PREVIEW_MODE_TYPES = {
  YOUR_CAMPAIGN: 'Your Campaign',
  SCREENSHOT: 'Screenshot',
  PROOF_REQUIREMENTS_TUTORIAL: 'Proof Requirements Tutorial',
  CONTACT_INFO: 'Contact Info',
  VOUCHER: 'Voucher',
  WHATS_NEXT: 'Whats Next',
}
