<template>
  <div id="swayWallOfLoveConfigurations">
    <app-spinner v-if="loading" />

    <v-container v-else-if="!widgets || widgets.length === 0">
      <v-row>
        <v-col class="text-center">
          <v-btn color="primary" @click="enableBusiness">Enable Sway Wall Of Love</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <template v-else>
      <v-row>
        <v-col cols="12">
          <v-expansion-panels v-model="selectedWidgetIndex">
            <SwayWallOfLoveSettings v-for="widget in widgets" :key="widget.access_key" :widget="widget" @color-updated="handleColorUpdated" />
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div v-if="selectedWidgetIndex >= 0" style="overflow: scroll; width: 100%; height: 100vh">
            <iframe
              id="widgetPreview"
              height="1000"
              width="1000"
              frameborder="0"
              :src="`${divocApiUrl}/swayWallOfLove/preview/${widgets[selectedWidgetIndex].access_key}`"
            ></iframe>
          </div>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'

import { ApiService } from '@/services/index'

import SwayWallOfLoveSettings from '@/components/SwayWallOfLove/SwayWallOfLoveSettings'

export default Vue.extend({
  name: 'SwayWallOfLove',

  metaInfo: {
    title: 'Sway Wall Of Love',
  },

  components: { SwayWallOfLoveSettings },

  data: () => ({
    loading: true,
    widgets: [],
    selectedWidgetIndex: -1,
  }),

  computed: {
    divocApiUrl() {
      return process.env.VUE_APP_DIVOC_API_URL
    },
  },

  mounted() {
    ApiService.get('/swayWallOfLove/all').then((r) => {
      if (!r.data.error) {
        this.widgets = r.data.socialProofConfigurations
      }

      this.loading = false
    })
  },

  methods: {
    enableBusiness() {
      this.loading = true

      ApiService.post('/swayWallOfLove/enableBusiness').then((r) => {
        if (!r.data.error) {
          this.widgets = r.data.socialProofConfigurations
        }

        this.loading = false
      })
    },

    handleColorUpdated(event) {
      ApiService.put(`/swayWallOfLove/config/${event.accessKey}/colors`, { field: event.field, value: event.value }).then((response) => {
        if (response.data.error) {
          this.$toast.error('Error updating colors')
          return
        }

        Vue.set(
          this.widgets,
          this.widgets.findIndex((widget) => widget.access_key === event.accessKey),
          { ...response.data.config }
        )

        document.getElementById('widgetPreview').src += ''

        this.$toast.success('Color updated!')
      })
    },
  },
})
</script>

<style lang="scss" scoped>
#swayWallOfLoveConfigurations {
  width: 100%;
}
</style>
