export interface OfferSummaryPerformance {
  title: string
}

export const OfferSummaryPerformance = Object.freeze({
  BEST_PERFORMING: {
    title: '🔥 Best Performing',
  } as OfferSummaryPerformance,
  GREAT: {
    title: '😍 Great Offers',
  } as OfferSummaryPerformance,
  OK: {
    title: '😀 Ok Offers',
  } as OfferSummaryPerformance,
})
