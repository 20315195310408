<template>
  <v-container fluid>
    <app-spinner v-if="loading" :spinner-size="80" class="spinner-background" />
    <div v-else>
      <v-row class="dashboard-background mb-0">
        <v-col md="12" lg="4">
          <h1 class="black--text"><span class="font-weight-regular">Welcome,</span> {{ entity.title }}.</h1>
          <p class="black--text mt-4 mb-0 font-weight-bold">
            You've been a valued customer since <strong>{{ entity.created_at | prettyDate }}</strong> and you've seen
            <strong>{{ voucherCount | comma }}</strong> customers come into your locations!
          </p>
        </v-col>
      </v-row>
      <v-row class="mt-8" align="center" justify="center">
        <v-col sm="12" md="6" class="order-sm-0 order-md-0" align="center" justify="center">
          <month-ring-card />
        </v-col>
        <v-col sm="12" md="6" class="order-sm-2 order-md-1" align="center" justify="center">
          <monthly-total-card />
        </v-col>
        <v-col cols="12" class="order-sm-1 order-md-2">
          <activity-log-table :business-created="entity.created_at" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import MonthRingCard from '../../components/Dashboard/MonthRingCard.vue'
import MonthlyTotalCard from '../../components/Dashboard/MonthlyTotalCard.vue'
import ActivityLogTable from '../../components/Dashboard/ActivityLogTable.vue'
import { ApiService } from '@/services/index'

export default {
  name: 'Dashboard',

  metaInfo: {
    title: 'Dashboard',
  },

  components: {
    MonthRingCard,
    MonthlyTotalCard,
    ActivityLogTable,
  },
  data: () => ({
    monthlyData: {},
    loading: false,
    voucherCount: 0,
  }),
  computed: { ...mapGetters('business', ['entity']) },
  watch: {},
  mounted() {
    ApiService.get('businesses/voucherActivity').then((response) => {
      this.voucherCount = response.data.voucher_count
      this.loading = false
    })
  },

  methods: {
    iconFromType(type) {
      switch (type) {
        case 'post':
          return 'mdi-account-arrow-up'

        case 'redeem':
          return 'mdi-account-cash'

        case 'review':
          return 'mdi-star-check'

        case 'offer_starts':
          return 'mdi-play'

        case 'offer_end':
          return 'mdi-alert-octagon'

        default:
          return ''
          break
      }
    },
  },
}
</script>

<style scoped>
.spinner-background {
  top: 50%;
  left: 0%;
  background: none;
  position: absolute;
}

.dashboard-background {
  background-image: url('../../assets/images/DashboardHeroImage.png');
  min-height: 350px;
  width: 100%;
  background-position: center left 33%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
</style>
