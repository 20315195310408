<template>
  <v-expansion-panel>
    <LocationTagWarningModal
      v-if="showLocationTagWarning"
      :added-to-offers="addedToOffers"
      :removed-from-offers="removedFromOffers"
      :new-custom-tags="customTags"
      :initial-custom-tags="location.custom_tags"
      @location-tag-warning-modal-saved="handleLocationTagWarningModalSave"
      @location-tag-warning-modal-closed="handleLocationTagWarningModalCancel"
    />

    <v-expansion-panel-header>
      <template #default="{ open }">
        <v-row no-gutters>
          <v-col cols="10">{{ location.address }}, {{ location.city }}, {{ location.state }}, {{ location.zipcode }}</v-col>
          <v-col cols="2">
            <v-btn
              v-if="open && isAdmin"
              :loading="archiveButtonLoading"
              :icon="isMobile"
              small
              outlined
              color="red accent-3"
              @click.stop="handleArchiveButtonClicked"
            >
              <v-icon>mdi-delete</v-icon>
              <span v-if="!isMobile">Archive</span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-form ref="locationForm">
        <v-row class="mb-n7">
          <v-col cols="12">
            <span class="caption text--secondary">Default Tags</span>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12">
            <v-chip v-for="tag in location.system_tags" :key="tag" color="primary lighten-1" class="ma-1">
              <strong>{{ tag }}</strong>
            </v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-combobox
              v-model="customTags"
              :items="[]"
              :append-icon="null"
              :persistent-hint="true"
              label="Custom Tags"
              chips
              multiple
              flat
              outlined
              small-chips
              @change="handleSaveCustomTags()"
            >
              <template #selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  color="primary lighten-1"
                  class="mt-2"
                  close
                  @click="select"
                  @click:close="removeCustomTag(item)"
                >
                  <strong>{{ item }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row class="mt-n1">
          <v-col cols="12">
            <v-form ref="addressPhoneForm">
              <v-text-field
                v-model="addressPhone"
                v-mask="'(###) ###-####'"
                :rules="optionalPhoneRules"
                outlined
                type="tel"
                label="Phone Number"
                placeholder="(555) 555-5555"
                @blur="handleSaveAddressPhone()"
                @keydown.enter="$event.target.blur()"
              />
            </v-form>
          </v-col>
        </v-row>
        <v-row class="mt-n1">
          <v-col cols="12">
            <v-form ref="googleReviewUrlForm">
              <v-text-field
                v-model="googleReviewUrl"
                :rules="[googleReviewUrlRule]"
                outlined
                label="Google Review URL"
                @blur="handleSaveGoogleReviewUrl()"
                @keydown.enter="$event.target.blur()"
              />
            </v-form>
          </v-col>
        </v-row>
        <v-row class="mt-n1">
          <v-col cols="12">
            <v-combobox
              ref="hashtags"
              v-model="hashtags"
              :items="[]"
              :append-icon="null"
              label="Hashtags"
              hint="Locals will be required to use these hashtags in their social media posts"
              flat
              chips
              outlined
              multiple
              @keydown.space="$refs.hashtags.blur()"
              @change="handleSaveHashtags()"
            >
              <template #selection="{ attrs, item, selected }">
                <v-chip v-bind="attrs" :input-value="selected" color="primary lighten-1">
                  <strong>{{ formatHashtag(item) }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row class="mt-n1">
          <v-col cols="12">
            <v-combobox
              v-model="instagramMentions"
              :items="[]"
              :append-icon="null"
              label="Instagram Mentions"
              hint="Locals will be asked to use these Instagram mentions in their social media posts"
              flat
              chips
              outlined
              multiple
              @change="handleSaveInstagramMentions()"
            >
              <template #selection="{ attrs, item, selected }">
                <v-chip v-bind="attrs" :input-value="selected" color="primary lighten-1">
                  <strong>{{ formatMention(item) }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row class="mt-n1">
          <v-col cols="12">
            <v-combobox
              v-model="facebookMentions"
              :items="[]"
              :append-icon="null"
              label="Facebook Mentions"
              hint="Locals will be asked to use these Facebook mentions in their social media posts"
              flat
              chips
              outlined
              multiple
              @change="handleSaveFacebookMentions()"
            >
              <template #selection="{ attrs, item, selected }">
                <v-chip v-bind="attrs" :input-value="selected" color="primary lighten-1">
                  <strong>{{ formatMention(item) }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <img :src="getMapUrl" />
          </v-col>
        </v-row>
      </v-form>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import validUrl from 'valid-url'
import validationRules from '@/utils/validation'
import { mask } from 'vue-the-mask'

import { formatHashtag, formatMention } from '@/utils/formatsocials'

import LocationTagWarningModal from '@/components/Locations/LocationTagWarningModal'

import { ApiService } from '@/services/index'

import { LocationTagWarningModalMode } from '@/models/index'

export default Vue.extend({
  name: 'LocationForm',

  components: {
    LocationTagWarningModal,
  },

  directives: {
    mask,
  },

  props: {
    location: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    return {
      customTags: [],
      addressPhone: '',
      googleReviewUrl: '',
      hashtags: [],
      instagramMentions: [],
      facebookMentions: [],
      showLocationTagWarning: false,
      locationTagWarningModalMode: null,
      optionalPhoneRules: validationRules.optionalPhoneRules,
      googleReviewUrlRule: (v) => {
        if (
          !v ||
          v.length === 0 ||
          (validUrl.isWebUri(v) && (v.includes('google.com/maps/place') || v.includes('goo.gl/maps/') || v.includes('search.google.com/local/writereview')))
        )
          return true
        return 'URL must be a valid Google Review URL'
      },
    }
  },

  computed: {
    ...mapGetters('account', ['isAdmin']),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    getMapUrl() {
      const width = this.$vuetify.breakpoint.smAndDown ? 250 : 400
      return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+ec1f56(${this.location.longitude},${this.location.latitude})/${this.location.longitude},${this.location.latitude},11/${width}x150?logo=false&access_token=${process.env.VUE_APP_MAPBOX_API_KEY}`
    },
    archiveButtonLoading() {
      return this.locationTagWarningModalMode === LocationTagWarningModalMode.ARCHIVE
    },
  },

  watch: {
    googleReviewUrl: function () {
      if (!this.$refs.googleReviewUrlForm) return

      this.$refs.googleReviewUrlForm.resetValidation()
    },
  },

  mounted: function () {
    this.customTags = [...this.location.custom_tags]
    this.addressPhone = this.location.address_phone
    this.googleReviewUrl = this.location.google_review_url
    this.hashtags = this.location.hashtags
    this.instagramMentions = this.location.instagram_mentions
    this.facebookMentions = this.location.facebook_mentions
  },

  methods: {
    ...mapActions('location', ['archiveLocation', 'saveLocation', 'updateLocation']),
    formatHashtag: (hashtag) => formatHashtag(hashtag),
    formatMention: (mention) => formatMention(mention),
    removeCustomTag(tagToRemove) {
      this.customTags = this.customTags.filter((tag) => tag !== tagToRemove)

      const tags = this.location.system_tags.concat(this.customTags)
      this.checkTagUsage(LocationTagWarningModalMode.SAVE, tags)
    },
    checkTagUsage(tagWarningModalMode, tags) {
      this.locationTagWarningModalMode = tagWarningModalMode

      ApiService.get(`tag/offer/${this.location.id}`, {
        params: {
          tags: tags,
        },
      }).then((response) => {
        this.addedToOffers = response.data.addedToOffers
        this.removedFromOffers = response.data.removedFromOffers
        this.showLocationTagWarning = this.addedToOffers.length > 0 || this.removedFromOffers.length > 0

        if (!this.showLocationTagWarning) {
          this.handleLocationTagWarningModalSave(this.customTags)
        }
      })
    },
    handleArchiveButtonClicked() {
      this.checkTagUsage(LocationTagWarningModalMode.ARCHIVE, [])
    },
    handleSaveCustomTags() {
      const tags = this.location.system_tags.concat(this.customTags)
      this.checkTagUsage(LocationTagWarningModalMode.SAVE, tags)
    },
    handleLocationTagWarningModalCancel(customTags) {
      this.showLocationTagWarning = false
      this.locationTagWarningModalMode = null

      this.customTags = customTags
    },
    handleLocationTagWarningModalSave(customTags) {
      this.showLocationTagWarning = false

      switch (this.locationTagWarningModalMode) {
        case LocationTagWarningModalMode.SAVE:
          this.updateLocation({ addressData: { address: { ...this.location, custom_tags: customTags } } })
          break
        case LocationTagWarningModalMode.ARCHIVE:
          this.archiveLocation({ addressId: this.location.id })
          break
        default:
          console.error('Unknown location tag warning modal mode', this.locationTagWarningModalMode)
      }
    },
    handleSaveAddressPhone() {
      if (!this.$refs.addressPhoneForm.validate()) return

      this.updateLocation({ addressData: { address: { ...this.location, address_phone: this.addressPhone } } })
    },
    handleSaveGoogleReviewUrl() {
      if (!this.$refs.googleReviewUrlForm.validate()) return

      this.updateLocation({ addressData: { address: { ...this.location, google_review_url: this.googleReviewUrl } } })
    },
    handleSaveHashtags() {
      this.updateLocation({ addressData: { address: { ...this.location, hashtags: this.hashtags } } })
    },
    handleSaveInstagramMentions() {
      this.updateLocation({ addressData: { address: { ...this.location, instagram_mentions: this.instagramMentions } } })
    },
    handleSaveFacebookMentions() {
      this.updateLocation({ addressData: { address: { ...this.location, facebook_mentions: this.facebookMentions } } })
    },
  },
})
</script>
