declare global {
  interface Window {
    heap: any
  }
}

function formatCustomProperties(key, value) {
  if (!value) return {}
  if (!(value instanceof Error)) return value

  return Object.getOwnPropertyNames(value).reduce(
    (obj, propName) => {
      obj[propName] = value[propName]
      return obj
    },
    { name: value.name }
  )
}

const actions = {
  init({ dispatch }, { userId }: { userId: string }) {
    window.heap.identify(userId)
    dispatch('track', { name: 'Login' })
  },
  dispose({ dispatch }) {
    dispatch('track', { name: 'Logout' })
    window.heap.resetIdentity()
  },
  track({ commit }, event: { name: string; properties?: { [key: string]: any } }) {
    const customProperties = JSON.stringify(event.properties, formatCustomProperties, 2)
    window.heap.track(event.name, { ...JSON.parse(customProperties) })
  },
  updateUserProperties({ commit }, { firstName, lastName, email }: { firstName: string; lastName: string; email: string }) {
    window.heap.addUserProperties({ First_Name: firstName, Last_Name: lastName, Email: email })
  },
}

const getters = {}

export const heap = {
  namespaced: true,
  state: {},
  mutations: {},
  actions,
  getters,
}
