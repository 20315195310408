export interface Business {
  id: string
  created_at: Date
  updated_at: Date
  title: string
  brand: string
  alias: string
  combo_title?: string
  business_status: BusinessStatus
  industry_category_id: string
  default_instagram_mentions: Array<string>
  default_facebook_mentions: Array<string>
  default_hashtags: Array<string>
  facebook_review_url: string
}

export interface BusinessSocialSettings {
  default_instagram_mentions: Array<string>
  default_facebook_mentions: Array<string>
  default_hashtags: Array<string>
}
export interface BusinessValueSettings {
  post_marketing_value: Number
  review_marketing_value: Number
  average_ticket_value: Number
}

export interface CurrentBusiness extends Business {
  locations_setup: boolean
  offers_setup: boolean
  social_setup: boolean
}

export interface BusinessSettings {
  brand: string
  title: string
  subIndustry: string
}

export enum BusinessStatus {
  ACTIVE,
  PAUSED,
}
