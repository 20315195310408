import { render, staticRenderFns } from "./ProofCardList.vue?vue&type=template&id=f680ae74&"
import script from "./ProofCardList.vue?vue&type=script&lang=ts&"
export * from "./ProofCardList.vue?vue&type=script&lang=ts&"
import style0 from "./ProofCardList.vue?vue&type=style&index=0&id=f680ae74&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Scroll})
