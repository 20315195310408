<template>
  <v-navigation-drawer id="dashboard-navigation-drawer" v-model="drawer" app mobile-breakpoint="960" width="240px">
    <template #prepend>
      <v-list-item href="/" class="logo-wrapper">
        <v-list-item-avatar tile width="auto" height="31px">
          <v-img :src="require('@/assets/images/localfluence-logo-rgb.png')" />
        </v-list-item-avatar>
      </v-list-item>
    </template>

    <v-list nav>
      <template v-for="(route, index) in routes">
        <template v-if="route.alias === '/setup'">
          <v-list-item v-if="!currentBusinessSetupProgress.complete" :key="index" :to="`/setup`" class="mt-5 mb-5 pt-2 pb-2" three-line>
            <v-list-item-content>
              <v-list-item-title>
                <v-icon>mdi-power-settings</v-icon>
                <span class="ml-2 font-weight-black">Setup: </span>
                <span class="font-italic">{{ currentBusinessSetupProgress.nextStep }}</span>
              </v-list-item-title>
              <v-list-item-subtitle class="mt-2">
                <v-progress-linear rounded :value="currentBusinessSetupProgress.progress" :bottom="true" height="6" />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item v-else :key="index" :to="route.path" class="mt-5 mb-5 pt-2 pb-2">
          <v-icon class="mr-4">{{ route.icon }}</v-icon>
          {{ route.name }}
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NavigationDrawer',

  computed: {
    ...mapGetters('account', ['isAdmin', 'getUserType']),
    ...mapGetters('business', ['currentBusinessSetupProgress']),
    routes() {
      const isAdmin = this.isAdmin
      const userType = this.getUserType
      return this.$router.options.routes[0].children.filter(function (route) {
        if (route.meta?.hidden) return false

        return isAdmin || route.meta?.authorize?.includes(userType)
      })
    },
    drawer: {
      get() {
        return this.$store.state.ui.drawer
      },
      set(value) {
        this.$store.dispatch('ui/setDrawer', value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
#dashboard-navigation-drawer {
  .v-list-item--active::before {
    opacity: 0;
  }

  .v-list {
    .v-list-item--active {
      color: $lf-blue;
    }
  }

  .logo-wrapper {
    height: 90px;
  }
}
</style>
