<template>
  <v-dialog :value="true" :fullscreen="$vuetify.breakpoint.smAndDown" persistent scrollable max-width="850">
    <v-card class="pt-4">
      <div v-if="showSwayLoadingImage" class="pa-10" align="center" justify="center">
        <v-img :src="require('@/assets/images/sway-loading.png')" max-width="500" class="loading-image" />
      </div>

      <v-card-text v-else :class="$vuetify.breakpoint.smAndDown ? 'pa-0' : ''">
        <v-stepper v-model="offerFlowStep" vertical class="elevation-0">
          <v-stepper-step :complete="offerFlowStep > 1" :editable="!!offerType" step="1" edit-icon="$complete">
            <h2 class="step-title">What is your main advertising goal?</h2>
            <small v-if="offerFlowStep == 1" class="step-sub-title">
              How would you like to gain new customers? Learn more about how the
              <a href="https://localfluencehelp.helpscoutdocs.com/article/63-localfluence-marketplace-customer-advocates" target="_blank">
                Localfluence Marketplace & Customer Advocates
              </a>
              <v-icon small color="primary" class="mt-n1 pr-1 ml-1">mdi-open-in-new</v-icon>
              work
            </small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-row class="ma-0" align="center" justify="center">
              <OfferTypeButton
                :selected="offerType === 'NETWORK'"
                icon="ad-icon"
                sub-title="Place Ad In Marketplace"
                title="Get new customers through the localfluence marketplace"
                text="The Localfluence marketplace consists of locals who will promote your business to friends & family via social media in-exchange for an exclusive offer."
                @offer-modal-option-button-clicked="offerType = 'NETWORK'"
              />

              <OfferTypeButton
                :selected="offerType === 'SWAY'"
                icon="ca-icon"
                sub-title="Customer Advocate Campaign"
                title="Create brand awareness through your existing customers"
                text="Tools to help encourage your existing customers to promote your business through social posts and online reviews."
                @offer-modal-option-button-clicked="offerType = 'SWAY'"
              />
            </v-row>

            <v-card-actions class="mt-2 justify-end">
              <v-btn color="darken-1" class="mr-4" @click="() => $emit('new-offer-modal-close')">Cancel</v-btn>
              <v-btn :disabled="!offerType" color="primary" @click="offerFlowStep = 2">Next</v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-step :complete="offerFlowStep > 2" :editable="!!selectedOfferSummary || !!offerSummary" step="2" edit-icon="$complete">
            <h2 class="step-title">{{ offerSummaryStepText }}</h2>
            <template v-if="offerFlowStep == 2">
              <div v-if="offerType === 'NETWORK' && offerFlowStep == 2">
                <small class="step-sub-title">
                  Your offer will be available for up to 5 locals each month on localfluence.com. Below are offers that we have seen perform best for your
                  business category.
                  <br />
                  <a href="https://localfluencehelp.helpscoutdocs.com/article/64-learn-more-about-offers" target="_blank">Learn more about offers</a>
                  <v-icon small color="primary" class="mt-n1 pr-1 ml-1">mdi-open-in-new</v-icon>
                </small>
              </div>
              <small v-else-if="offerType === 'SWAY'" class="step-sub-title">
                Create a special deal or discount that you can offer your existing customers in exchange for a social post or online review from them.
                <a href="https://localfluencehelp.helpscoutdocs.com/article/68-creating-your-customer-advocate-campaign" target="_blank">See example offers</a>
                <v-icon small color="primary" class="mt-n1 pr-1 ml-1">mdi-open-in-new</v-icon>
              </small>
            </template>
          </v-stepper-step>

          <v-stepper-content step="2">
            <template v-if="offerType === 'NETWORK'">
              <v-row align="center" justify="center" class="my-0">
                <v-col cols="11" class="pb-0">
                  <OfferSummarySelector v-model="selectedOfferSummary" />
                </v-col>
              </v-row>
            </template>

            <v-row v-else-if="offerType === 'SWAY'" class="mt-0">
              <v-col cols="12">
                <v-text-field v-model="offerSummary" hint="One Free Lip Wax" persistent-hint outlined label="Customer Advocate Deal" />
              </v-col>
            </v-row>

            <v-card-actions class="justify-end">
              <v-btn color="darken-1" class="mr-4" @click="() => $emit('new-offer-modal-close')">Cancel</v-btn>
              <v-btn
                :disabled="(offerType === 'NETWORK' && !selectedOfferSummary) || (offerType === 'SWAY' && offerSummary.length === 0)"
                color="primary"
                @click="offerFlowStep = 3"
                >Next</v-btn
              >
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-step
            :complete="offerFlowStep > 3"
            :editable="(offerType === 'NETWORK' && !!selectedOfferSummary) || (offerType === 'SWAY' && offerSummary.length > 0)"
            step="3"
            edit-icon="$complete"
          >
            <h2 class="step-title">Add your business social media hashtags and tags</h2>
            <small v-if="offerFlowStep == 3" class="step-sub-title">{{ socialsStepSubTitle }}</small>
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-row class="my-0">
              <v-col class="pt-4">
                <SocialsForm
                  :hash-tags="hashtags"
                  :instagram-mentions="instagramMentions"
                  :facebook-mentions="facebookMentions"
                  hash-tag-hints="#saltlakecityskincare #utahskincare"
                  instagram-mentions-hint="@saltlakecityskincare"
                  facebook-mentions-hint="@saltlakecityskincare"
                  @save-hashtags="handleUpdateHashtags"
                  @save-facebook-mentions="handleSaveFacebookMentions"
                  @save-instagram-mentions="handleUpdateInstagramMentions"
                />
              </v-col>
            </v-row>

            <v-card-actions class="justify-end">
              <v-btn color="darken-1" class="mr-4" @click="() => $emit('new-offer-modal-close')">Cancel</v-btn>
              <v-btn v-if="offerType === 'SWAY'" color="primary" @click="createNewSwayOffer()">Create Campaign</v-btn>
              <v-btn v-else color="primary" @click="offerFlowStep = 4">{{ socialsNextButtonText }}</v-btn>
            </v-card-actions>
          </v-stepper-content>

          <div v-if="offerType === 'NETWORK'">
            <v-stepper-step :complete="offerFlowStep > 4" :editable="!!selectedOfferSummary" step="4" edit-icon="$complete">
              <h2 class="step-title">Offer Image</h2>
              <small v-if="offerFlowStep == 4" class="step-sub-title">
                Upload a 380x200 image that best represents the offer or your brand.
                <a href="https://localfluencehelp.helpscoutdocs.com/article/65-image-examples-and-resources" target="_blank">Image examples and resources</a>
                <v-icon small color="primary" class="mt-n1 pr-1 ml-1">mdi-open-in-new</v-icon>
              </small>
            </v-stepper-step>

            <v-stepper-content step="4" align="center" :class="$vuetify.breakpoint.smAndDown ? 'pl-0' : ''">
              <ImageUploadForm
                image-type="offer"
                :edit-mode="true"
                :image-url="offerImageUrl"
                class="mb-4"
                style="max-width: 380px"
                @image-uploaded="handleUpdateOfferImage"
              >
                <h2>Click to upload image</h2>
                <h5 class="font-weight-light">380x200 image dimensions</h5>
              </ImageUploadForm>

              <v-card-actions class="justify-end">
                <v-btn color="darken-1" class="mr-4" @click="() => $emit('new-offer-modal-close')">Cancel</v-btn>
                <v-btn color="primary" @click="offerFlowStep = 5">{{ offerImageNextButtonText }}</v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-step :complete="offerFlowStep > 5" :editable="selectedOfferSummary && !!location" step="5" edit-icon="$complete">
              <h2 class="step-title">Where will locals go for their appointment?</h2>
              <small v-if="offerFlowStep == 5 && !location" class="mt-1">Enter your business address below.</small>
            </v-stepper-step>

            <v-stepper-content step="5">
              <v-row class="my-0">
                <v-col>
                  <app-spinner v-if="!googleMapsInitialized" />
                  <VueGoogleAutocomplete
                    v-else-if="!location || !location.id"
                    id="addressSearch"
                    ref="vueGoogleAutoComplete"
                    class="address-search v-input v-text-field"
                    placeholder="Enter a location"
                    types="geocode"
                    :country="'us'"
                    @placechanged="getAddressData"
                  />
                  <div v-else class="text-center">
                    <div class="text-h6 font-weight-light">{{ location.address }}, {{ location.city }}, {{ location.state }}, {{ location.zipcode }}</div>
                    <div class="caption font-weight-light">
                      (If you need to change your location address, email us at
                      <a href="mailto:accounts@localfluence.com" target="_blank">accounts@localfluence.com</a>)
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row v-if="location" class="my-0">
                <v-col cols="12" class="text-center pb-0">
                  <img :src="getMapUrl" />
                </v-col>
              </v-row>

              <v-card-actions class="mt-4 justify-end">
                <v-btn color="darken-1" class="mr-4" @click="() => $emit('new-offer-modal-close')">Cancel</v-btn>
                <v-btn :disabled="!location" color="primary" @click="offerFlowStep = 6">Next</v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-step :complete="offerFlowStep > 6" :editable="!!phoneOrUrl" step="6" edit-icon="$complete">
              <h2 class="step-title">Add your scheduling details</h2>
              <small v-if="offerFlowStep == 6" class="mt-1">Enter a phone number, booking website, or both.</small>
            </v-stepper-step>

            <v-stepper-content step="6" :class="$vuetify.breakpoint.smAndDown ? 'pl-2' : ''">
              <v-form ref="callOfferOrderFlowForm">
                <v-row class="mt-2">
                  <v-col class="pb-0">
                    <label class="subtitle-1">Do you have a website where customers can schedule a visit?</label>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col cols="11" class="pt-0 pb-0">
                    <v-text-field
                      key="offerUrlInput"
                      v-model="url"
                      :rules="optionalUrlRules"
                      :validate-on-blur="true"
                      :disabled="offerMutationLoading"
                      outlined
                      type="url"
                      placeholder="https://www.google.com"
                    />
                  </v-col>
                </v-row>
                <v-row class="mt-2">
                  <v-col class="pb-0">
                    <label class="subtitle-1">Which phone number should customers call to place their order?</label>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col cols="11" class="pt-0 pb-0">
                    <v-text-field
                      key="offerPhoneInput"
                      v-model="phone"
                      v-mask="'(###) ###-####'"
                      :rules="optionalPhoneRules"
                      :validate-on-blur="true"
                      :disabled="offerMutationLoading"
                      outlined
                      type="tel"
                      placeholder="(555) 555-5555"
                    />
                  </v-col>
                </v-row>
              </v-form>

              <v-card-actions class="mt-4 justify-end">
                <v-btn color="darken-1" class="mr-4" @click="() => $emit('new-offer-modal-close')">Cancel</v-btn>
                <v-btn :disabled="!phoneOrUrl" :loading="offerMutationLoading" color="primary" @click="createNewOffer()">SUBMIT FOR APPROVAL</v-btn>
              </v-card-actions>
            </v-stepper-content>
          </div>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { mask } from 'vue-the-mask'

import { ApiService } from '@/services/index'
import validationRules from '@/utils/validation'

import { mapActions, mapGetters } from 'vuex'

import VueGoogleAutocomplete from 'vue-google-autocomplete'
import GoogleMapService from '@/services/googleMap.service'

import ImageUploadForm from '@/components/ImageUploadForm'
import SocialsForm from '@/components/Offers/OfferForm/SocialsForm'
import OfferTypeButton from '@/components/Offers/NewOfferModals/OfferTypeButton'
import OfferSummarySelector from '@/components/Offers/NewOfferModals/OfferSummarySelector'

export default Vue.extend({
  name: 'NewClientLiteOfferModal',

  directives: {
    mask,
  },

  components: {
    ImageUploadForm,
    SocialsForm,
    VueGoogleAutocomplete,
    OfferTypeButton,
    OfferSummarySelector,
  },

  data: () => ({
    offerType: null,
    offerFlowStep: 1,
    offerSummary: '',
    hashtags: [],
    instagramMentions: [],
    facebookMentions: [],
    phone: '',
    url: '',
    descriptionUrl: '',
    offerImageUrl: null,
    location: null,
    selectedOfferSummary: null,
    showSwayLoadingImage: false,
    optionalUrlRules: validationRules.optionalUrlRules,
    optionalPhoneRules: validationRules.optionalPhoneRules,
  }),

  computed: {
    ...mapGetters('offer', ['offerMutationLoading']),
    getMapUrl() {
      const width = this.$vuetify.breakpoint.smAndDown ? 250 : 400
      return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+ec1f56(${this.location.longitude},${this.location.latitude})/${this.location.longitude},${this.location.latitude},11/${width}x150?logo=false&access_token=${process.env.VUE_APP_MAPBOX_API_KEY}`
    },
    offerSummaryStepText() {
      return this.offerType === 'NETWORK' ? 'Select a pre-approved offer' : 'What would you like to offer your existing customers?'
    },
    socialsStepSubTitle() {
      return `${
        this.offerType === 'NETWORK' ? 'Localfluence users' : 'Your customers'
      } will be asked to include these in their social post when they share about your business.`
    },
    socialsNextButtonText() {
      return [...this.hashtags, ...this.instagramMentions, ...this.facebookMentions].length > 0 ? 'Next' : 'Skip'
    },
    offerImageNextButtonText() {
      return this.offerImageUrl ? 'Next' : 'Skip'
    },
    phoneOrUrl() {
      return this.phone || this.url
    },
  },

  mounted() {
    GoogleMapService.init().then(() => {
      this.googleMapsInitialized = true
    })

    ApiService.get('address/all').then((response) => {
      this.location = response.data.length > 0 ? response.data[0] : null
    })
  },

  methods: {
    ...mapActions('offer', ['createOffer', 'updateLocationForOffer']),
    ...mapActions('location', ['saveLocation']),
    ...mapActions('swayoffer', ['createSwayOffer']),
    getAddressData: function (addressData, placeResultData) {
      this.addressDetailsLoading = true

      const address = addressData.street_number && addressData.route ? `${addressData.street_number} ${addressData.route}` : placeResultData.name
      const state = placeResultData.address_components.find((addressComponent) => addressComponent.types.includes('administrative_area_level_1'))

      const systemTags = [
        ...(address ? [address] : []),
        ...(addressData.locality ? [addressData.locality] : []),
        ...(addressData.postal_code ? [addressData.postal_code] : []),
        ...(state ? [state.long_name] : []),
      ]

      this.location = {
        city: addressData.locality,
        state: state ? state.long_name : '',
        address: address,
        zipcode: addressData.postal_code,
        latitude: addressData.latitude,
        longitude: addressData.longitude,
        google_place_id: placeResultData.place_id,
        google_review_url: `http://search.google.com/local/writereview?placeid=${placeResultData.place_id}`,
        system_tags: systemTags,
        custom_tags: [],
      }
    },
    handleUpdateHashtags(hashtags) {
      this.hashtags = hashtags
    },
    handleSaveFacebookMentions(mentions) {
      this.facebookMentions = mentions
    },
    handleUpdateInstagramMentions(mentions) {
      this.instagramMentions = mentions
    },
    handleUpdateOfferImage(event) {
      this.offerImageUrl = event.image_url
    },
    async createNewSwayOffer() {
      this.showSwayLoadingImage = true

      await this.createSwayOffer({
        swayOfferBase: {
          offer_summary: this.offerSummary,
          hashtags: this.hashtags,
          instagram_mentions: this.instagramMentions,
          facebook_mentions: this.facebookMentions,
        },
        flow: {
          claim_enabled: true,
          contact_info_enabled: false,
          end_type: 'VOUCHER',
        },
      }).then(() => {
        setTimeout(() => {
          $emit('new-offer-modal-close')
          this.$toast.success('Campaign Created!')
        }, 3000)
      })
    },
    async createNewOffer() {
      if (!this.$refs.callOfferOrderFlowForm.validate()) return

      if (this.location && !this.location.id) {
        await this.saveLocation({ addressData: { address: this.location } })
      }

      await this.createOffer({
        offer: {
          offer_type: 'LOCAL',
          offer_order_flow_type: 'CALL',
          offer_summary: this.selectedOfferSummary.summary_value,
          offer_summary_id: this.selectedOfferSummary.id,
          offer_image_url: this.offerImageUrl,
          hashtags: this.hashtags,
          instagram_mentions: this.instagramMentions,
          facebook_mentions: this.facebookMentions,
          offer_url: this.url ? this.url.trim() : null,
          offer_phone: this.phone ? this.phone.trim() : null,
        },
      }).then((offerId) => {
        // Null should add all
        this.updateLocationForOffer({ offerId, locationTagIds: null }).then(() => {
          $emit('new-offer-modal-close')
        })
      })
    },
  },
})
</script>

<style scoped>
.step-title {
  line-height: 1.2;
  font-weight: 300;
  color: #707070 !important;
}

.step-sub-title {
  margin-top: 6px;
  font-weight: 400;
  line-height: 1.3;
  color: #707070 !important;
}

.address-search {
  width: 100%;
  padding: 12px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
}

.loading-image {
  animation: pulse 2s linear infinite;
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
