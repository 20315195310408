<template>
  <div v-click-outside="closeSearch" class="sway-offer-search-field">
    <button :class="`sway-offer-search-button ${searchExpanded ? 'active' : 'inactive'}`" @click="toggleSearch">
      <div class="selected-item">
        <div v-if="selectedOffer" style="display: flex">
          <img v-if="selectedOffer.offer_image_url" :src="selectedOffer.offer_image_url" class="offer-image" />
          <div style="flex: 1">
            <span>{{ selectedOffer.offer_summary }}</span>
            <span style="font-size: 0.8rem; display: block">{{ selectedOffer.internal_notes }}</span>
          </div>
        </div>
        <div v-else>Sway Offers</div>
      </div>
      <div class="chevron">
        <svg class="chevron-icon" viewBox="0 0 20 20">
          <path
            fill="currentColor"
            fill-rule="evenodd"
            d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </button>

    <ul v-if="searchExpanded" class="list">
      <li v-for="networkOffer in networkOffers" :key="`search-result-${networkOffer.id}`" class="list-item" @click.stop="onSearchResultSelected(networkOffer)">
        <div class="list-item-content">
          <img v-if="networkOffer.offer_image_url" :src="networkOffer.offer_image_url" class="list-item-image" />
          <div v-else style="width: 64px; height: 64px; color: #bbb" class="list-item-image"></div>

          <div class="search-result-labels">
            <div class="truncate">{{ networkOffer.offer_summary }}</div>
            <div v-if="networkOffer.internal_notes" class="internal-notes truncate">
              {{ networkOffer.internal_notes }}
            </div>
          </div>
        </div>

        <span v-if="selectedOfferId === networkOffer.id" class="list-item-selected">
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
          </svg>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from 'vue'

import ClickOutside from 'vue-click-outside'

export default Vue.extend({
  name: 'NetworkOfferSearchField',

  directives: {
    ClickOutside,
  },

  props: {
    selectedOfferId: {
      type: String,
      default: null,
    },
    networkOffers: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    searchExpanded: false,
  }),

  computed: {
    selectedOffer() {
      return this.networkOffers.find((networkOffer) => networkOffer.id === this.selectedOfferId)
    },
  },

  methods: {
    closeSearch() {
      this.searchExpanded = false
    },

    toggleSearch() {
      this.searchExpanded = !this.searchExpanded
    },

    onSearchResultSelected(networkOffer) {
      this.handleSearchFieldChanged(networkOffer)
      this.closeSearch()
    },

    handleSearchFieldChanged(networkOffer) {
      this.$emit('network-offer-selected', networkOffer ? networkOffer.id : null)
    },
  },
})
</script>

<style>
.sway-offer-search-field {
  width: 100%;
  position: relative;
  margin-top: 0.25rem;
}

.sway-offer-search-button {
  width: 100%;
  cursor: pointer;
  text-align: left;
  position: relative;
  padding-top: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 2.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  background-color: #ffffff;
  border: 1px solid #999999;
}

.sway-offer-search-button.active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.offer-image {
  height: 5rem;
  flex-shrink: 0;
  margin-right: 1rem;
  border-radius: 10px;
}

.selected-item {
  display: flex;
  justify-content: left;
}

.chevron {
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  position: absolute;
  align-items: center;
  margin-left: 0.75rem;
  padding-right: 0.5rem;
}

.chevron-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #9ca3af;
}

.list {
  width: 100%;
  z-index: 10;
  overflow: auto;
  margin-top: 0;
  padding-left: 0 !important;
  font-size: 1rem;
  list-style: none;
  max-height: 14rem;
  position: absolute;
  line-height: 1.5rem;
  background-color: #ffffff;
  border: 1px solid #999999;
  border-top: 0;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.list-item {
  color: #111827;
  cursor: pointer;
  position: relative;
  padding-top: 0.5rem;
  padding-left: 0.75rem;
  padding-bottom: 0.5rem;
  padding-right: 2.25rem;
  border-top: 1px solid #999999;
}

.list-item:first-child {
  border-top: 0;
}

.list-item:hover {
  background: #eaeaea;
}

.list-item-content {
  gap: 10px;
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.list-item-image {
  width: 4rem;
  height: 4rem;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 10px;
}

.list-item-selected {
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  color: #848b91;
  position: absolute;
  padding-right: 1rem;
  align-items: center;
}

.search-result-labels {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.internal-notes {
  font-size: 0.7em;
}
</style>
