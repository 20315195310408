<template>
  <div id="offers" class="margin-auto">
    <slot></slot>

    <app-spinner v-if="loading" />
    <template v-else>
      <div class="text-center mb-10">
        <h2 class="font-weight-light">{{ offerListTitle.plural }}</h2>
        <p class="mt-2">Create & launch {{ offerListTitle.plural.toLowerCase() }} from this screen</p>
        <v-btn color="primary" @click="() => $emit('offer-list-new-offer-modal-open')">
          <img width="24" height="24" class="pr-1" :src="require('@/assets/svg/offers-add.svg')" />Create {{ offerListTitle.noun }}
        </v-btn>
      </div>

      <template v-for="offerStatus in Object.keys(offersByStatus)">
        <div
          :id="`offerlist-${offerStatus}`"
          :key="offerStatus"
          :class="['mb-6', offersByStatus[offerStatus].networkOffers.length + offersByStatus[offerStatus].swayOffers.length === 0 ? 'd-none' : '']"
        >
          <div class="mb-2 text-left">
            <v-icon dense small :color="offersByStatus[offerStatus].color" class="pr-1 mt-n1">
              {{ offersByStatus[offerStatus].icon }}
            </v-icon>
            <span class="text-h6 font-weight-bold">{{ offersByStatus[offerStatus].title }}</span>
            <span class="ml-3 font-weight-light">
              {{ offersByStatus[offerStatus].networkOffers.length + offersByStatus[offerStatus].swayOffers.length }}
              {{ offerListTitle.noun | pluralize(offersByStatus[offerStatus].networkOffers.length + offersByStatus[offerStatus].swayOffers.length) }}
            </span>
            <div v-if="offersByStatus[offerStatus].type === 'NEEDS_APPROVAL'" class="ml-5 caption font-weight-light">
              {{ offerListTitle.plural }} are normally approved within 2 business days
            </div>
          </div>

          <v-expansion-panels v-model="offersByStatus[offerStatus].selectedOfferIndex" inset hover>
            <template v-for="offer in offersByStatus[offerStatus].networkOffers">
              <BaseNetworkOfferPanel :id="`offerId-${offer.id}`" :key="offer.id" :offer="offer" />
            </template>

            <template v-for="swayOffer in offersByStatus[offerStatus].swayOffers">
              <BaseSwayOfferPanel :id="`offerId-${swayOffer.id}`" :key="swayOffer.id" :sway-offer="swayOffer" />
            </template>
          </v-expansion-panels>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'

import BaseNetworkOfferPanel from '@/components/Offers/OfferPanels/BaseNetworkOfferPanel'
import BaseSwayOfferPanel from '@/components/Offers/OfferPanels/BaseSwayOfferPanel'

export default Vue.extend({
  name: 'Offers',

  components: {
    BaseNetworkOfferPanel,
    BaseSwayOfferPanel,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isClientLite: {
      type: Boolean,
      default: false,
    },
    swayOffers: {
      type: Array,
      default: () => [],
    },
    networkOffers: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    offersByStatus: {
      NEEDS_APPROVAL: {
        type: 'NEEDS_APPROVAL',
        icon: 'mdi-check-circle',
        title: 'Submitted for Approval',
        color: 'grey',
        networkOffers: [],
        swayOffers: [],
        selectedOfferIndex: null,
      },
      SCHEDULED: {
        type: 'SCHEDULED',
        icon: 'mdi-calendar',
        title: 'Scheduled',
        color: 'green',
        networkOffers: [],
        swayOffers: [],
        selectedOfferIndex: null,
      },
      ACTIVE: {
        type: 'ACTIVE',
        icon: 'mdi-clock-outline',
        title: 'Active',
        color: 'green',
        networkOffers: [],
        swayOffers: [],
        selectedOfferIndex: null,
      },
      COMPLETE: {
        type: 'COMPLETE',
        icon: 'mdi-stop-circle',
        title: 'Complete',
        color: 'black',
        networkOffers: [],
        swayOffers: [],
        selectedOfferIndex: null,
      },
      FULL: {
        type: 'FULL',
        icon: 'mdi-clipboard-list-outline',
        title: 'Full',
        color: 'red',
        networkOffers: [],
        swayOffers: [],
        selectedOfferIndex: null,
      },
    },
  }),

  computed: {
    offerListTitle() {
      if (this.isClientLite) return { noun: 'Campaign', plural: 'Campaigns' }
      if (this.swayOffers.length > 0) return { noun: 'Sway Offer', plural: 'Sway Offers' }

      return { noun: 'Offer', plural: 'Offers' }
    },
  },

  watch: {
    swayOffers: function (swayOffers) {
      this.buildOfferList('swayOffers', swayOffers)
    },
    networkOffers: function (networkOffers) {
      this.buildOfferList('networkOffers', networkOffers)
    },
  },

  methods: {
    getAllSwayOffers() {
      const retVal = []
      Object.values(this.offersByStatus).forEach((e) => retVal.push(...e.swayOffers))
      return retVal
    },
    getAllNetworkOffers() {
      const retVal = []
      Object.values(this.offersByStatus).forEach((e) => {
        retVal.push(...e.networkOffers)
      })
      return retVal
    },
    buildOfferList(offerType, updatedOffers) {
      if (!['swayOffers', 'networkOffers'].includes(offerType)) return

      const existingOfferCount = Object.values(this.offersByStatus).reduce(function (offerCount, offerStatus) {
        return offerCount + offerStatus[offerType].length
      }, 0)

      const expandOfferDetails = updatedOffers.length - existingOfferCount === 1

      updatedOffers.forEach((offer) => {
        const newOfferStatus = offer.offer_status
        const oldOfferArray =
          offerType === 'swayOffers' ? this.getAllSwayOffers().filter((o) => o.id === offer.id) : this.getAllNetworkOffers().filter((o) => o.id === offer.id)
        const oldOffer = oldOfferArray.length > 0 ? oldOfferArray[0] : null

        if (!oldOffer || newOfferStatus !== oldOffer.offer_status) {
          if (oldOffer) {
            Vue.delete(
              this.offersByStatus[oldOffer.offer_status][offerType],
              this.offersByStatus[oldOffer.offer_status][offerType].findIndex((o) => o.id === offer.id)
            )
            Vue.set(this.offersByStatus[oldOffer.offer_status][offerType], 'selectedOfferIndex', [])
          }

          Vue.set(
            this.offersByStatus[newOfferStatus][offerType],
            this.offersByStatus[newOfferStatus][offerType].findIndex((o) => o.id === offer.id),
            offer
          )
        }

        const offerExists = this.offersByStatus[newOfferStatus][offerType].some((o) => o.id === offer.id)

        if (offerExists) {
          Vue.set(
            this.offersByStatus[newOfferStatus][offerType],
            this.offersByStatus[newOfferStatus][offerType].findIndex((o) => o.id === offer.id),
            offer
          )
        } else {
          if (expandOfferDetails) {
            this.offersByStatus[newOfferStatus][offerType].unshift(offer)
            this.offersByStatus[newOfferStatus].selectedOfferIndex =
              this.offersByStatus[newOfferStatus].networkOffers.length + this.offersByStatus[newOfferStatus].swayOffers.length - 1

            Vue.nextTick(() => {
              this.$vuetify.goTo(`#offerId-${offer.id}`)
            })
          } else {
            this.offersByStatus[newOfferStatus][offerType].push(offer)
          }
        }
      })
    },
  },
})
</script>

<style lang="scss" scoped>
#offers {
  width: 100%;
}
</style>
