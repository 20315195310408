<template>
  <div>
    <v-row dense @click="currentProof = currentProof + (1 % proofs.length)">
      <v-col v-if="proofs" cols="12">
        <v-carousel v-model="currentProof" hide-delimiters :show-arrows="false" height="350px">
          <v-carousel-item v-for="proof in proofs" :key="proof.id">
            <v-img :src="proof.img_url" contain :class="classes" height="350px" />
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
    <v-row dense align="center" justify="center">
      <v-col v-for="(proof, index) in proofs" :key="proof.id" :cols="Math.ceil(8 / proofs.length)" @click="currentProof = index">
        <v-img :src="proof.img_url" contain :class="classes" height="50px" />
        <div :class="`selectBar ${currentProof === index ? 'highlight' : ''}`"></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ScreenShotPost',

  props: {
    proofs: {
      type: Array,
      required: true,
    },
    classes: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    currentProof: 0,
  }),
}
</script>
<style scoped>
.selectBar {
  margin-top: 5px;
  width: 30px;
  height: 2px;
}

.highlight {
  border-bottom: 2px solid blue;
}
</style>
