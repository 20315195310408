export const formatMention = (rawMention: string) => {
  const mention = rawMention.trim()

  return mention.startsWith('@') ? mention : `@${mention}`
}

export const formatHashtag = (rawHashtag: string) => {
  const hashtag = rawHashtag.trim()

  return hashtag.startsWith('#') ? hashtag : `#${hashtag}`
}
