var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',{nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('v-expansion-panel-header',[_vm._v("Dates")]),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"mt-n4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"slide-y-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Start Date","outlined":"","readonly":""},model:{value:(_vm.startDateFormatted),callback:function ($$v) {_vm.startDateFormatted=$$v},expression:"startDateFormatted"}},on))]}}]),model:{value:(_vm.startDatePicker),callback:function ($$v) {_vm.startDatePicker=$$v},expression:"startDatePicker"}},[_c('v-date-picker',{attrs:{"show-current":false},on:{"input":function($event){_vm.startDatePicker = false},"change":function($event){return _vm.handleSaveStartDate()}},model:{value:(_vm.offer_start_date),callback:function ($$v) {_vm.offer_start_date=$$v},expression:"offer_start_date"}})],1)],1)],1),(_vm.isAdmin)?_c('v-row',{staticClass:"mt-n6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"slide-y-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Expiration Date","placeholder":"Ongoing","persistent-hint":true,"outlined":"","readonly":"","clearable":""},on:{"click:clear":function($event){return _vm.clearExpirationDate()}},model:{value:(_vm.expirationDateFormatted),callback:function ($$v) {_vm.expirationDateFormatted=$$v},expression:"expirationDateFormatted"}},on))]}}],null,false,1416653153),model:{value:(_vm.expirationDatePicker),callback:function ($$v) {_vm.expirationDatePicker=$$v},expression:"expirationDatePicker"}},[_c('v-date-picker',{attrs:{"show-current":false},on:{"input":function($event){_vm.expirationDatePicker = false},"change":function($event){return _vm.handleSaveExpirationDate()}},model:{value:(_vm.expiration_date),callback:function ($$v) {_vm.expiration_date=$$v},expression:"expiration_date"}})],1)],1)],1):_vm._e(),(_vm.offerOrderFlowType === 'EVENT')?_c('v-row',{staticClass:"mt-n6"},[_c('v-col',{staticClass:"pb-0"},[(_vm.needsApproval)?_c('label',{staticClass:"subtitle-1"},[_vm._v("Enter dates and times for the event.")]):_c('label',{staticClass:"subtitle-1"},[_vm._v("Dates and times for the event.")])])],1):_vm._e(),(_vm.offerOrderFlowType === 'EVENT' && _vm.needsApproval)?_c('v-row',[_c('v-col',{staticClass:"pa-0 pl-3",attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"slide-y-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Date","readonly":"","outlined":""},model:{value:(_vm.eventDate),callback:function ($$v) {_vm.eventDate=$$v},expression:"eventDate"}},on))]}}],null,false,2384847799),model:{value:(_vm.eventDatePicker),callback:function ($$v) {_vm.eventDatePicker=$$v},expression:"eventDatePicker"}},[_c('v-date-picker',{attrs:{"show-current":false},on:{"input":function($event){_vm.eventDatePicker = false}},model:{value:(_vm.eventDate),callback:function ($$v) {_vm.eventDate=$$v},expression:"eventDate"}})],1)],1),_c('v-col',{staticClass:"pa-0 pl-3",attrs:{"cols":"4"}},[_c('v-menu',{ref:"startTimePickerMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.startTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.startTime=$event},"update:return-value":function($event){_vm.startTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start","prepend-icon":"mdi-clock-time-four-outline","readonly":"","outlined":""},model:{value:(_vm.startTimeAP),callback:function ($$v) {_vm.startTimeAP=$$v},expression:"startTimeAP"}},'v-text-field',attrs,false),on))]}}],null,false,2324224972),model:{value:(_vm.startTimePicker),callback:function ($$v) {_vm.startTimePicker=$$v},expression:"startTimePicker"}},[(_vm.startTimePicker)?_c('v-time-picker',{attrs:{"allowed-minutes":_vm.allowedMinutes,"full-width":"","format":"ampm","ampm-in-title":""},on:{"click:minute":function($event){return _vm.$refs.startTimePickerMenu.save(_vm.startTime)}},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}}):_vm._e()],1)],1),_c('v-col',{staticClass:"pa-0 pl-3",attrs:{"cols":"4"}},[_c('v-menu',{ref:"endTimePickerMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.endTimePicker,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.endTimePicker=$event},"update:return-value":function($event){_vm.endTimePicker=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End","prepend-icon":"mdi-clock-time-four-outline","readonly":"","outlined":""},model:{value:(_vm.endTimeAP),callback:function ($$v) {_vm.endTimeAP=$$v},expression:"endTimeAP"}},'v-text-field',attrs,false),on))]}}],null,false,2488849292),model:{value:(_vm.endTimePicker),callback:function ($$v) {_vm.endTimePicker=$$v},expression:"endTimePicker"}},[(_vm.endTimePicker)?_c('v-time-picker',{attrs:{"allowed-minutes":_vm.allowedMinutes,"full-width":"","format":"ampm","ampm-in-title":""},on:{"click:minute":function($event){return _vm.$refs.endTimePickerMenu.save(_vm.endTime)}},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}}):_vm._e()],1)],1)],1):_vm._e(),(_vm.offerOrderFlowType === 'EVENT' && _vm.needsApproval)?_c('v-row',{staticClass:"mt-n6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"primary rounded",on:{"click":function($event){return _vm.addTime(_vm.eventDate, _vm.startTime, _vm.endTime)}}},[_vm._v("+")])],1)],1):_vm._e(),(_vm.offerOrderFlowType === 'EVENT' && _vm.needsApproval)?_c('v-row',{staticClass:"mt-n2"},[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.displayEventTimes),function(time){return _c('v-chip',{key:time,staticClass:"ma-1",attrs:{"color":"primary lighten-1","close":"","close-icon":"mdi-delete"},on:{"click:close":function($event){return _vm.removeTime(time)}}},[_vm._v(" "+_vm._s(time)+" ")])}),1)],1):_vm._e(),(_vm.offerOrderFlowType === 'EVENT' && !_vm.needsApproval)?_c('v-row',{staticClass:"mt-n2"},[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.displayEventTimes),function(time){return _c('v-chip',{key:time,staticClass:"ma-1",attrs:{"color":"primary lighten-1"}},[_vm._v(" "+_vm._s(time)+" ")])}),1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }